import React, { useState } from "react";
import { useSelector } from "react-redux";

// components (global)
import DashboardButton from "components/Button/DashboardButton";

// components (local)
import EntryPassDoorAccessDialog from "./EntryPassDoorAccessDialog";
import FalcoDoorAccessDialog from "./FalcoDoorAccessDialog";
import SoyalDoorAccessDialog from "./SoyalDoorAccessDialog";
import MicroEngineDoorAccessDialog from "./MicroEngineDoorAccessDialog";

// material-ui
import { Button } from "@material-ui/core";
import { MeetingRoom as DoorIcon } from "@material-ui/icons";

const AddDoorAccess = ({ dashboardButton }) => {
  // selector
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const accessCardSettingSelector = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor] &&
        state.firestore.data.vendors[selectedVendor].access_card_settings
        ? state.firestore.data.vendors[selectedVendor].access_card_settings
        : null
      : null;
  });

  // state
  const [open, setOpen] = useState(false);
  const [vendorType] = useState(
    accessCardSettingSelector
      ? accessCardSettingSelector.type.toLowerCase()
      : null
  );

  // functions
  const handleClickOpen = () => {
    if (!open) setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {dashboardButton ? (
        <DashboardButton
          icon={<DoorIcon />}
          onClick={handleClickOpen}
          title="Add Door Access"
        />
      ) : (
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          add door access
        </Button>
      )}

      {vendorType === "entrypass" && (
        <EntryPassDoorAccessDialog open={open} handleClose={handleClose} />
      )}

      {vendorType === "falco" && (
        <FalcoDoorAccessDialog open={open} handleClose={handleClose} />
      )}

      {vendorType === "soyal" && (
        <SoyalDoorAccessDialog open={open} handleClose={handleClose} />
      )}

      {vendorType === "microengine" && (
        <MicroEngineDoorAccessDialog open={open} handleClose={handleClose} />
      )}
    </React.Fragment>
  );
};

export default AddDoorAccess;
