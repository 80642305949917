import React, { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";

// firebase
import { isEmpty, isLoaded } from "react-redux-firebase";

// global components
import { LoadingSpinnerFull } from "components";

// layout
import { Main as MainLayout, Minimal as MinimalLayout } from "layouts";

// redux
import { getProperty } from "stores/property/PropertyAction";

const ProfileLoaded = ({ children }) => {
  return children;

  // const { auth, profile } = useSelector(state => ({
  //   auth: state.firebase.auth,
  //   profile: state.firebase.profile
  // }));

  // const [property, dispatch] = useReducer(getProperty);

  // console.log("property", property);

  // // dispatch()

  // // const au = useSelector(state => state.firebase.profile);
  // // const profile = useSelector(state => state.firebase.profile);
  // // const property = useSelector(state => state.property);

  // // const dispatch = useDispatch();

  // // if (isLoaded(profile) && !isLoaded(property)) dispatch;

  // if (isEmpty(profile)) return <MinimalLayout>{children}</MinimalLayout>;

  // if (!isLoaded(profile) && !isLoaded(property)) return <LoadingSpinnerFull />;

  // return <MainLayout>{children}</MainLayout>;
};

export default ProfileLoaded;
