import React from "react";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";

// firebase
import { useFirestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";

// material-ui
import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    marginBottom: theme.spacing(1),
    position: "relative",
    "&:after": {
      position: "absolute",
      bottom: -8,
      left: 0,
      content: '" "',
      height: 3,
      width: 48,
      backgroundColor: theme.palette.primary.main,
    },
  },
  card: {
    marginTop: theme.spacing(2),
  },
  tableCell: {
    textAlign: "center",
  },
}));

const TransactionTab = () => {
  const classes = useStyles();

  // selector
  const cardId = useSelector((state) => state.main.toggleDrawer.parameterId);
  const transactions = useSelector(
    (state) => state.firestore.ordered.individual_transactions
  );

  // firestore
  useFirestoreConnect(() => [
    {
      collection: `ac_transactions`,
      where: [["profile_id", "==", cardId]],
      orderBy: [["created_at", "desc"]],
      limit: 20,
      storeAs: "individual_transactions",
    },
  ]);

  if (!isLoaded(transactions)) return null;

  return (
    <React.Fragment>
      <Grid direction="row" alignItems="center" container justify="center">
        <Grid item md={12}>
          <Typography component="h2" variant="h6" className={classes.title}>
            Showing latest 20 transactions
          </Typography>
        </Grid>
      </Grid>

      <Card className={classes.card}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Door</TableCell>
                  <TableCell>Event</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoaded(transactions) ? (
                  <TableRow>
                    <TableCell className={classes.tableCell} colSpan={5}>
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : isLoaded(transactions) && isEmpty(transactions) ? (
                  <TableRow>
                    <TableCell className={classes.tableCell} colSpan={5}>
                      No record found...
                    </TableCell>
                  </TableRow>
                ) : (
                  transactions.map((transaction) => (
                    <TableRow key={transaction.id}>
                      <TableCell>
                        {moment(transaction.created_at.toDate()).format(
                          "YYYY-MM-DD"
                        )}
                      </TableCell>
                      <TableCell>
                        {moment(transaction.created_at.toDate()).format(
                          "HH:mm:ss"
                        )}
                      </TableCell>
                      <TableCell>{transaction.door_name}</TableCell>
                      <TableCell>{transaction.message}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </Card>
    </React.Fragment>
  );
};

export default TransactionTab;
