import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import Img from "react-image";
//components

//material-ui
import { Avatar, CardMedia } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//helpers
import { getDownloadUrlPath } from "helpers/firebaseHelpers";

//utils

//redux

//assets
import ApartmentOutlinedIcon from "@material-ui/icons/ApartmentOutlined";
import noImageFound from "assets/images/noimage.jpg";
//styles

const useStyles = makeStyles(theme => ({
  avatarClassName: { width: 42, height: 42, minWidth: 42, minHeight: 42 },
  cardClassName: {
    width: "100%",
    height: 140,
    minWidth: "100%",
    minHeight: 140
  }
}));

function PropertyImage({ id, type }) {
  const classes = useStyles();
  const imageUrl = useSelector(
    state => state.firestore.data.properties[id].image_url
  );

  //states
  const [propertyImage, setPropertyImage] = useState(null);
  useEffect(() => {
    return getDownloadUrlPath(imageUrl, (url, err) => {
      if (url) setPropertyImage(url);
      else setPropertyImage("");
    });
  }, [imageUrl]);

  let skeletonImage = <div></div>;
  let showImage = <div></div>;
  let noImage = <div></div>;

  switch (type) {
    case "avatar":
      skeletonImage = (
        <Skeleton
          variant="circle"
          className={classes.avatarClassName}
        ></Skeleton>
      );
      showImage = (
        <Avatar className={classes.avatarClassName}>
          <Img className={classes.avatarClassName} src={propertyImage} />
        </Avatar>
      );
      noImage = (
        <Avatar className={classes.avatarClassName}>
          <ApartmentOutlinedIcon />
        </Avatar>
      );
      break;
    case "card":
      skeletonImage = (
        <Skeleton variant="rect" className={classes.cardClassName}></Skeleton>
      );
      showImage = (
        <CardMedia
          height="140"
          component="img"
          alt="Property Image"
          image={propertyImage}
          title="Property Image"
        />
      );
      noImage = (
        <CardMedia
          height="140"
          component="img"
          alt="Property Image"
          image={noImageFound}
          title="Property Image"
        />
      );
      break;
    default:
      break;
  }

  if (propertyImage === null) return skeletonImage;

  if (propertyImage !== "") return showImage;
  else return noImage;
}
export default PropertyImage;
