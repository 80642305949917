import React from "react";
import { useFormContext, Controller } from "react-hook-form";

// material-ui
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const CustomAutocomplete = ({
  defaultValue,
  label,
  required = true,
  name,
  options,
}) => {
  const { errors, control } = useFormContext();

  // selector
  //   const unitsSelector = useSelector((state) => {
  //     return state.firestore.ordered.units
  //       ? orderBy(
  //           state.firestore.ordered.units,
  //           ["block_name", "house_number"],
  //           ["asc", "asc"]
  //         )
  //       : null;
  //   });

  // memo
  //   const defaultValueOption = useMemo(() => {
  //     return find(unitsSelector, (unit) => unit.id === defaultValue);
  //   }, [defaultValue, unitsSelector]);

  return (
    <Controller
      as={
        <Autocomplete
          size="small"
          id="unit-list"
          options={options}
          getOptionLabel={(option) => (option.name ? option.name : "")}
          renderInput={(params) => (
            <TextField
              error={!!errors[name]}
              helperText={errors[name] && "Unit is Required"}
              {...params}
              label={label}
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: "disabled",
              }}
            />
          )}
        />
      }
      rules={{ required: required }}
      onChange={([, selected]) => selected}
      control={control}
      name={name}
      defaultValue={defaultValue ? defaultValue : null}
    />
  );
};

export default React.memo(CustomAutocomplete);
