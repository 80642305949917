import React from "react";
import { useSelector } from "react-redux";

// components (global)
import { Page, PageHeader } from "components/Pages";

// components (local)
import { AdminTransactionTable, GuardTransactionTable } from "./components";

// firebase
import { useFirestoreConnect } from "react-redux-firebase";

// material-ui
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
}));

const TransactionList = () => {
  const classes = useStyles();

  // selector
  const selectedPropertyId = useSelector(
    (state) => state.main.selectedProperty
  );

  // firestore
  useFirestoreConnect([
    {
      collection: "ac_transactions",
      where: [["property_id", "==", selectedPropertyId]],
      orderBy: ["created_at", "desc"],
      limit: 20,
      storeAs: "transactions",
    },
  ]);

  return (
    <Page className={classes.root} title="Vehicle">
      <Container maxWidth={false}>
        <PageHeader
          pageTiltleLink="transactions"
          title="View latest 20 transactions"
        />

        <AdminTransactionTable />
        {/* {accessLevel.toLowerCase() === "guard" ? (
          <GuardTransactionTable />
        ) : (
          <AdminTransactionTable />
        )} */}
      </Container>
    </Page>
  );
};

export default TransactionList;
