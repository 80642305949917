import { CircularProgress, Stack } from "@mui/material";
import React from "react";
import { Button, Typography, Box } from "@material-ui/core";
import Lottie from "react-lottie";
import cardDenied from "../lotties/card-denied";

function OverlayWithText({
  open,
  color,
  loadingText,
  animationData,
  height,
  width,
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,

    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      bgcolor="rgba(255, 255, 255, 0.45)"
      color={color ?? "#000"}
      display={open ? "flex" : "none"}
      width="100%"
      top={0}
      left={0}
      right={0}
      bottom={0}
      position="absolute"
      zIndex={(theme) => theme.zIndex.drawer + 1}
    >
      <Lottie
        options={defaultOptions}
        height={height}
        width={width}
        speed={2}
      />

      <Typography component="h1" variant="body2">
        {loadingText ?? "Submitting, please do not refresh page"}
      </Typography>
    </Stack>
  );
}

export default OverlayWithText;
