import * as authTypes from "./authTypes";

const init_load = {
  isLoading: false,
  loadError: null,
  loadData: null
};

const initialState = {
  ...init_load
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.USER_LOGIN:
      console.log("USERLOGIN");
      return state;

    case authTypes.USER_IS_AUTHENTICATING:
      console.log("loading...");
      return {
        ...state,
        ...init_load,
        isLoading: true
      };
    case authTypes.USER_AUTHENTICATE_SUCCESS:
      console.log("USER_AUTHENTICATE_SUCCESS");
      return {
        ...state,
        ...init_load,
        loadData: action.payload
      };
    case authTypes.USER_AUTHENTICATE_FAILED:
      console.log("USER_AUTHENTICATE_FAILED", action.payload);
      return {
        ...state,
        ...init_load,
        loadError: action.payload
      };
    case authTypes.LOGIN_SUCCESS:
      return {
        ...state,
        ...init_load,
        loadData: action.payload
      };
    case authTypes.LOGIN_FAILURE:
      return {
        ...state,
        authError: action.payload.message
      };
    default:
      return state;
  }
};

export default authReducer;
