import React from "react";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButton, Paper, Select, MenuItem } from "@material-ui/core";
import {
  Table,
  TableHeaderRow,
  TableFixedColumns,
  TableFilterRow,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  table: {
    whiteSpace: "nowrap",
    tableLayout: "auto",
    "& th": { backgroundColor: "#fafafa" },
  },
  title: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    alignSelf: "center",
  },
}));

export const GroupIcon = (props) => {
  if (props.disabled) return null;
  else
    return (
      <IconButton onClick={props.onGroup}>
        <MenuIcon fontSize="small" />
      </IconButton>
    );
};

export const FilterCell = (props) => {
  const { filteringEnabled, column } = props;
  if (!filteringEnabled) {
    if (column.name === "menuButton")
      return (
        <TableFixedColumns.Cell
          showLeftDivider
          position={0}
          side="right"
          component={(props) => {
            return <TableHeaderRow.Cell {...props} />;
          }}
        />
      );
    else return <TableHeaderRow.Cell />;
  } else {
    if (column.type === "boolean") {
      return (
        <TableHeaderRow.Cell>
          <Select
            defaultValue=""
            id="select"
            fullWidth
            onChange={(e) => props.onFilter({ value: e.target.value })}
          >
            <MenuItem value="">&nbsp;</MenuItem>
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </TableHeaderRow.Cell>
      );
    } else return <TableFilterRow.Cell {...props} />;
  }
};

export const ToolbarRoot = ({ ...restProps }) => {
  return (
    <Paper
      style={{ marginBottom: "4px" }}
      variant="elevation"
      elevation={1}
      component={Toolbar.Root}
      {...restProps}
    />
  );
};

export const TableComponentBase = ({ ...restProps }) => {
  const classes = useStyles();
  return <Table.Table {...restProps} className={classes.table} />;
};
