import React from "react";
import { Controller } from "react-hook-form";

//components

//material-ui
import { FormControl, InputLabel, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({ formControl: { width: "100%" } }));
function StyledSelect({
  error,
  errorText,
  control,
  rules,
  label,
  name,
  onChange,
  children,
  ...rest
}) {
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      size="small"
      error={error}
      {...rest}
    >
      <InputLabel id={name}>{label}</InputLabel>
      <Controller
        as={children}
        name={name}
        labelWidth={
          document.getElementById(name)?.clientWidth || name.length * 9
        }
        control={control}
        rules={rules}
        onChange={onChange}
      />
      {error && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
}

export default React.memo(StyledSelect);
