import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { filter, includes, orderBy, map } from "lodash";
import { CSVLink } from "react-csv";
// components (global)
import DashboardButton from "components/Button/DashboardButton";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
// components (local)
import { useFirestore } from "react-redux-firebase";

import { closeDrawer } from "stores/main/MainActions";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  Grid,
  Paper,
  LinearProgress,
  MenuItem,
} from "@material-ui/core";
import Icon from "@mui/material/Icon";
import { makeStyles } from "@material-ui/core/styles";
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Sorting,
  FilterRow,
  Selection,
  Toolbar,
  Item,
  Paging,
  Scrolling,
  Pager,
} from "devextreme-react/data-grid";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  link: {
    marginRight: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));
const ExportUser = ({ dashboardButton }) => {
  const classes = useStyles();

  // selector
  const userSelector = useSelector((state) => state.firestore.ordered.jc_users);
  const firestore = useFirestore();

  const [anchorEl, setAnchorEl] = useState(null);
  useFirestoreConnect(() => [
    {
      collection: `users`,

      orderBy: [["card_access", "desc"]],
      limit: 500,
      storeAs: "jc_users",
    },
  ]);
  // stateå
  const [open, setOpen] = useState(false);
  const userData = useMemo(() => {
    let flatResult = [];
    if (userSelector) {
      return map(userSelector, (trail) => {
        return {
          ...trail,
          name: trail.name,
          email: trail.email,
        };
      });
    }
    // return orderBy(
    //   flatResult,
    //   ["created_at", "created_time"],
    //   ["desc", "desc"]
    // );
  }, [userSelector]);
  // firestore

  const handleClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // functions
  const handleClickOpen = () => {
    if (!open) setOpen(true);
  };
  const downloadCsvButton = (
    <CSVLink
      style={{ textDecoration: "none" }}
      filename={`user-data${moment().format("YYYY-MM-DD")}.csv`}
      data={userData}
      className={classes.link}
    >
      <Button underline="none" color="primary" variant="contained" mr={2}>
        Export CSV
      </Button>
    </CSVLink>
  );

  return (
    <React.Fragment>
      <Paper>
        <DataGrid
          columnAutoWidth={false}
          dataSource={userData}
          // onRowDblClick={onRowDblClick}

          showColumnLines={false}
        >
          <FilterRow visible={true} />

          <Column caption="Name" dataField="name" />
          <Column caption="Email" dataField="email" />

          <Paging defaultPageSize={10} />

          <Pager
            visible={true}
            displayMode="full"
            showPageSizeSelector={false}
            showInfo={true}
            showNavigationButtons={true}
          />
        </DataGrid>
      </Paper>
      <Grid item container xs={16} alignItems="center" justify="center">
        {!!userData && userData.length > 0 && downloadCsvButton}
      </Grid>
    </React.Fragment>
  );
};

export default ExportUser;
