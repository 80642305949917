import React from "react";

function TrueFalseWithDot({ dotBoolean, text }) {
  return dotBoolean ? (
    <React.Fragment>
      <span style={{ color: "green", textDecorationLine: "line-through" }}>
        &#9679;
      </span>{" "}
      {text}
    </React.Fragment>
  ) : (
    <React.Fragment>
      <span style={{ color: "red" }}>&#9679;</span> {text}
    </React.Fragment>
  );
}

export default TrueFalseWithDot;
