import * as actionTypes from "./ActionType";

export const toggleEditAccessRoleDialogOpen = (payload) => {
  return {
    type: actionTypes.TOGGLE_EDIT_ACCESS_ROLE_DIALOG_OPEN,
    payload: payload,
  };
};

export const toggleEditAccessRoleDialogClose = () => {
  return {
    type: actionTypes.TOGGLE_EDIT_ACCESS_ROLE_DIALOG_CLOSE,
  };
};
