import { useSelector } from "react-redux";
import firebase from "firebase/compat/app";
import { useFirestoreConnect } from "react-redux-firebase";

export function getDownloadUrlPath(gsFilePath, callback) {
  // const firebase = useFirebase();

  try {
    firebase
      .storage()
      .refFromURL(gsFilePath)
      .getDownloadURL()
      .then((url) => callback(url));
  } catch (err) {
    // console.log(err);
    callback(null, err);
  }
}

export function getUrlPath(refPath, fileName, callback) {
  try {
    firebase
      .storage()
      .ref(refPath)
      .child(fileName)
      .getDownloadURL()
      .then((url) => callback(url));
  } catch (err) {
    // console.log(err);
    callback(null, err);
  }
}

// export function useGetPropertiesData() {
//   const profile = useSelector((state) => state.firebase.profile);

//   console.log("profile", profile);

//   const collections = [
//     {
//       collection: "properties",
//       doc: "KKxRD8MuPuvHKqrhbHWK",
//       storeAs: "properties",
//     },
//     {
//       collection: "properties",
//       doc: "QetUAPqXjYMUgRlT8EwB",
//       storeAs: "properties",
//     },
//   ];

//   useFirestoreConnect(() => collections);
// }

export function useGetInitFirestoreData() {
  //load organizations,properties
  useFirestoreConnect(() => [
    {
      collection: "properties",
      orderBy: [["name", "asc"]],
      where: [["jagacard", "==", true]],
    },
  ]);
}

export function useGetAccessRoleData(id) {
  const selectedProperty = useSelector((state) => state.main.selectedProperty);

  useFirestoreConnect(() => [
    {
      collection: "properties",
      doc: selectedProperty,
      subcollections: [
        {
          collection: "ac_roles",
        },
      ],
      storeAs: "roles",
    },
  ]);
}

export function useGetVendorData() {
  const selectedProperty = useSelector((state) => state.main.selectedProperty);

  useFirestoreConnect(() => [
    {
      collection: "ac_vendors",
      where: [["property_id", "==", selectedProperty]],
      storeAs: "vendors",
      limit: 1,
    },
  ]);
}

export function useGetAllVendors() {
  const selectedProperty = useSelector((state) => state.main.selectedProperty);

  useFirestoreConnect(() => [
    {
      collection: "ac_vendors",
      storeAs: "all_vendors",
      limit: 200,
    },
  ]);
}

export function useGetJcUser() {
  const selectedProperty = useSelector((state) => state.main.selectedProperty);

  useFirestoreConnect(() => [
    {
      collection: `users`,
      where: [["card_access", "==", true]],

      storeAs: "users",
    },
  ]);
}

export function useGetApnrVendorData() {
  const selectedProperty = useSelector((state) => state.main.selectedProperty);

  useFirestoreConnect(() => [
    {
      collection: "apnr_vendors",
      where: [["property_id", "==", selectedProperty]],
      storeAs: "apnrVendors",
      limit: 1,
    },
  ]);
}
export function useGetAccessData() {
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const selectedApnrVendor = useSelector(
    (state) => state.main.selectedApnrVendor
  );

  useFirestoreConnect([
    {
      collection: `ac_vendors/${selectedVendor}/access_levels`,
      orderBy: "number",
      storeAs: "accessLevels",
    },
    {
      collection: `ac_vendors/${selectedVendor}/access_groups`,
      storeAs: "accessGroups",
    },
    {
      collection: `ac_vendors/${selectedVendor}/lift_access_levels`,
      storeAs: "liftAccess",
    },
    {
      collection: `ac_vendors/${selectedVendor}/unit_access`,
      storeAs: "unit_access",
    },
    {
      collection: `apnr_vendors/${selectedApnrVendor}/vehicle_groups`,
      storeAs: "vehicleGroups",
    },
  ]);
}

export function useGetModeratorsData() {
  const selectedProperty = useSelector((state) => state.main.selectedProperty);

  useFirestoreConnect(() => [
    {
      collection: "users",
      where: [["properties", "array-contains", selectedProperty]],
      storeAs: "moderators",
    },
  ]);
}
