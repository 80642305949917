import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { orderBy, find } from "lodash";
import { useFormContext } from "react-hook-form";

// material-ui
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const FloorAutocomplete = ({ required = true, defaultValue }) => {
  const { register, errors, setValue } = useFormContext();

  // selector
  const floorsSelector = useSelector((state) => {
    return state.firestore.ordered.floors
      ? orderBy(
          state.firestore.ordered.floors,
          ["block_name", "name"],
          ["asc", "asc"]
        )
      : null;
  });

  // effect
  useEffect(() => {
    register({ name: "floorId" }, { required: false });
  }, [register]);

  useEffect(() => {
    if (defaultValue) {
      setValue("floorId", defaultValue);
    }
  }, [defaultValue, setValue]);

  // memo
  const defaultValueOption = useMemo(() => {
    return find(floorsSelector, (unit) => unit.id === defaultValue);
  }, [defaultValue, floorsSelector]);

  // functions
  const onChange = (e, value) => {
    setValue("floorId", value ? value.id : "");
  };

  return (
    <Autocomplete
      size="small"
      id="floor-list"
      options={floorsSelector}
      groupBy={(option) => option.block_name}
      getOptionLabel={(option) => (option.name ? option.name : "")}
      onChange={onChange}
      defaultValue={defaultValueOption || ""}
      renderInput={(params) => (
        <TextField
          error={!!errors.floor}
          helperText={errors.floor && "Floor is Required"}
          {...params}
          label="Choose a floor *"
          fullWidth
          size="small"
          name="floor"
          autoComplete="off"
          variant="outlined"
          inputRef={register({ required: required })}
          inputProps={{
            ...params.inputProps,
            autoComplete: "disabled",
          }}
        />
      )}
    />
  );
};

export default React.memo(FloorAutocomplete);
