import React from "react";

import ProgressiveImage from "react-progressive-bg-image";

// assets
import LandingImgUrl from "assets/img/img-landing.png";
import LandingImgPlaceholderUrl from "assets/img/no-img-placeholder.jpg";

// components
import { Footer, Header, LoginForm } from "./components";

// material-ui
import {
  Box,
  CssBaseline,
  Container,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    display: "flex",
    minHeight: "100vh",
    backgroundSize: "cover",
  },
  login: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 8),
    },
  },
}));

const Login = () => {
  const classes = useStyles();

  return (
    <ProgressiveImage
      src={LandingImgUrl}
      placeholder={LandingImgPlaceholderUrl}
      alt="landingImg"
      className={classes.root}
    >
      <CssBaseline />
      <Header />
      <Container maxWidth="sm">
        <Paper className={classes.login}>
          <Grid
            container
            justify="space-evenly"
            alignContent="center"
            direction="column"
            spacing={1}
          >
            <Grid container item justify="center">
              <Grid item xs={12}>
                <Typography gutterBottom component="div">
                  <Box textAlign="center" fontSize={"1.5rem"}>
                    Welcome To JaGaCard
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="div" gutterBottom>
                  <Box textAlign="center" fontSize={"0.7rem"}>
                    Log in with your account credentials
                  </Box>
                </Typography>
              </Grid>
            </Grid>
            <LoginForm />
          </Grid>
        </Paper>
      </Container>

      <Footer />
    </ProgressiveImage>
  );
};

export default Login;
