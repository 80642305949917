import * as type from "./CommonType";

const initialState = {
  loadingScreen: true,
  fetching_property: false,
  sidebar_open: false,
  add_vehicle_dialog_open: false,
  edit_vehicle_dialog_open: false,
};

const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.TOGGLE_NAV:
      return {
        ...state,
        sidebar_open: !state.sidebar_open,
      };
    case type.TOGGLE_ADD_VEHICLE_DIALOG:
      return {
        ...state,
        add_vehicle_dialog_open: !state.add_vehicle_dialog_open,
      };
    case type.TOGGLE_EDIT_VEHICLE_DIALOG:
      return {
        ...state,
        edit_vehicle_dialog_open: !state.edit_vehicle_dialog_open,
      };
      
    case "SHOW_LOADING":
      return {
        ...state,
        loadingScreen: action.payload,
      };
    case "DISMISS_LOADING":
      return {
        ...state,
        loadingScreen: false,
      };
    default:
      return state;
  }
};

export default CommonReducer;
