import React from "react";
import { useSelector } from "react-redux";

// components (local)
import {
  AddFloorAccess,
  EditFloorAccess,
  FloorAccessTable,
} from "./components";

// firebase
import { useFirestoreConnect } from "react-redux-firebase";

// materail-ui
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const FloorAccessTab = () => {
  const classes = useStyles();

  // selector
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const editFloorAccessOpen = useSelector(
    (state) => state.setting.edit_floor_access_dialog.open
  );

  // firestore
  useFirestoreConnect([
    {
      collection: "ac_vendors",
      doc: selectedVendor,
      subcollections: [
        {
          collection: "floor_access",
          orderBy: "floor_name",
        },
      ],
      storeAs: "floorAccess",
    },
  ]);

  return (
    <div className={classes.root}>
      {editFloorAccessOpen && <EditFloorAccess />}

      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item container justify="space-between">
          <Grid item>
            <Typography component="h2" gutterBottom variant="overline">
              Floor Access
            </Typography>
            <Typography component="h1" variant="h3">
              Manage all floor access for resident & visitor
            </Typography>
          </Grid>

          <Grid item>
            <AddFloorAccess />
          </Grid>
        </Grid>
      </Grid>

      <FloorAccessTable />
    </div>
  );
};

export default FloorAccessTab;
