import React from "react";

import {
  Loading,
  AuthIsLoaded,
  ProfileIsLoaded,
  PropertyIsLoaded,
  AccessRoleIsLoaded,
  InitialDataIsLoaded,
} from "helpers/routeHelpers";

const Routes = () => {
  return (
    <div>
      <Loading />
      <AuthIsLoaded>
        <ProfileIsLoaded>
          <PropertyIsLoaded>
            <AccessRoleIsLoaded>
              <InitialDataIsLoaded />
            </AccessRoleIsLoaded>
          </PropertyIsLoaded>
        </ProfileIsLoaded>
      </AuthIsLoaded>
    </div>
  );
};

export default Routes;
