import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filter, includes } from "lodash";

// components (global)
import { CustomerDrawer, Paginate } from "components";
import { Page, PageHeader } from "components/Pages";
import { StyledTextField } from "components/TextField";

// components (local)
import {
  AddVehicleDialog,
  EditVehicleDialog,
  Filter,
  VehicleDetails,
  VehicleSkeleton,
} from "./components";

// components
import { VehicleCard } from "./components";

// firebase
import { isLoaded } from "react-redux-firebase";

// material-ui
import {
  FilterList as FilterListIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import {
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// stores
import { openDrawer } from "stores/main/MainActions";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  progress: {
    paddingTop: theme.spacing(2),
    textAlign: "center",
  },
  paginate: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));

const Vehicle = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // variable
  const perPage = 12;

  // selector
  const vehiclesSelector = useSelector(
    (state) => state.firestore.ordered.vehicles
  );

  // state
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);

  // memo
  const vehiclesSearch = useMemo(() => {
    setOffset(0);

    // const fil = filter(vehicleSelector, vehicle => {
    //   return (
    //     vehicle.unit.block_name === "Lift A" &&
    //     vehicle.unit.unit_name === "3A-1"
    //   );
    // });

    // console.log("fil", fil);

    return filter(vehiclesSelector, (vehicle) =>
      includes(
        vehicle.identity_number.toString().toLowerCase(),
        search.toString().toLowerCase()
      )
    );
  }, [vehiclesSelector, search]);

  const pageCount = useMemo(
    () => Math.ceil(vehiclesSearch.length / perPage),
    [vehiclesSearch]
  );

  const vehicles = useMemo(
    () => vehiclesSearch.slice(offset, offset + perPage),
    [vehiclesSearch, offset]
  );

  // functions
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleFilter = () => {
    dispatch(
      openDrawer({ toggle: "resident_vehicle_filter", parameterId: "" })
    );
  };

  const handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * perPage);
    setOffset(offset);
  };

  return (
    <Page className={classes.root} title="Vehicle">
      <Container maxWidth={false}>
        <PageHeader
          pageTiltleLink="vehicle"
          title="Manage resident vehicle"
          firstButton={<AddVehicleDialog />}
          search={
            <StyledTextField
              defaultValue={search}
              component={Paper}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Search by vehicle number…"
            />
          }
          filter={
            <Tooltip title="More Filter">
              <IconButton onClick={handleFilter}>
                <FilterListIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          }
        />

        <Grid container>
          <Grid item md={12}>
            <Typography className={classes.title} variant="h5">
              {`Showing ${vehiclesSearch.length} vehicles`}
            </Typography>
          </Grid>
        </Grid>

        <CustomerDrawer uniqName="view_resident_vehicle" size={2}>
          <VehicleDetails />
        </CustomerDrawer>
        <CustomerDrawer uniqName="resident_vehicle_filter">
          <Filter />
        </CustomerDrawer>

        <EditVehicleDialog />

        <div className={classes.content}>
          {!isLoaded(vehiclesSelector) ? (
            <Grid container spacing={3}>
              <Grid item lg={3}>
                <VehicleSkeleton />
              </Grid>
              <Grid item lg={3}>
                <VehicleSkeleton />
              </Grid>
              <Grid item lg={3}>
                <VehicleSkeleton />
              </Grid>
              <Grid item lg={3}>
                <VehicleSkeleton />
              </Grid>
            </Grid>
          ) : (
            vehicles.length > 0 && (
              <div>
                <Grid container spacing={3}>
                  {vehicles.map((vehicle) => (
                    <Grid item key={vehicle.id} lg={3} md={4} xs={12}>
                      <VehicleCard
                        id={vehicle.id}
                        identity_number={vehicle.identity_number}
                        block_name={vehicle.unit.block_name}
                        unit_name={vehicle.unit.unit_name}
                        profile_name={vehicle.profile_name}
                        status={vehicle.status}
                      />
                    </Grid>
                  ))}
                </Grid>

                <div className={classes.paginate}>
                  <Paginate
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                  />
                </div>
              </div>
            )
          )}
        </div>
      </Container>
    </Page>
  );
};

export default React.memo(Vehicle);
