import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { cloneDeep } from "lodash";
import moment from "moment";
import classNames from "classnames";

// asset
import activePlaceHolder from "assets/images/active-card-bg.png";
import deletePlaceHolder from "assets/images/inactive-card-bg.png";
import blockPlaceHolder from "assets/images/suspended-card-bg.png";
import wave from "assets/images/wave.png";

// components (global)
import { ButtonColor } from "components/Button";

// firebase
import { useFirestore } from "react-redux-firebase";

// helpers
import { Capitalized } from "helpers/textHelpers";

// material-ui
import {
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
  MoreHoriz as MoreHorizIcon,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

// stores
import { openDrawer } from "stores/main/MainActions";
import { toggleEditResidentDialogOpen } from "stores/card/CardAction";
import CardSettings from "views/CardSettings";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  profileCode: {
    paddingTop: theme.spacing(1),
  },
  cardNumber: {
    paddingTop: theme.spacing(1),
  },
  cardType: {
    paddingBottom: theme.spacing(2),
  },
  paginate: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
  activePaperContainer: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundImage: `url(${activePlaceHolder})`,
    width: theme.spacing(33),
    height: theme.spacing(20.5),
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
    overflow: "hidden",
  },
  deletePaperContainer: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundImage: `url(${deletePlaceHolder})`,
    width: theme.spacing(33),
    height: theme.spacing(20.5),
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
  },
  blockedPaperContainer: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundImage: `url(${blockPlaceHolder})`,
    width: theme.spacing(33),
    height: theme.spacing(20.5),
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
  },
}));

const ResidentCard = ({ card }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const firestore = useFirestore();

  // selector
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const vendor = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor]
      : null;
  });
  const roles = useSelector((state) => state.main.roles);

  // state
  const [anchorEl, setAnchorEl] = useState(null);
  const [suspendCard, setSuspendCard] = useState(false);
  const [deleteCard, setDeleteCard] = useState(false);

  // variable
  const isSuspended = card.blocked_settings.enable;

  // functions
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSuspendCard(false);
    setDeleteCard(false);
  };

  const handleViewCardDetails = () => {
    dispatch(
      openDrawer({ toggle: "view_resident_card", parameterId: card.id })
    );
    handleCloseMenu();
  };

  const handleToggleSuspendCardButton = () => {
    setSuspendCard(!suspendCard);
  };

  const handleSuspendCard = async () => {
    const oldValue = cloneDeep(card);
    const updatedDate = new Date();

    handleCloseMenu();

    const newCardData = {
      blocked_settings: {
        count: isSuspended ? 0 : vendor.blocked_settings.number,
        enable: isSuspended ? false : true,
        enable_date: moment(updatedDate).startOf("day").toDate(),
      },
      updated_at: updatedDate,
      updated_by: {
        user_id: auth.uid,
        name: profile.name,
      },
    };

    if (isSuspended && card.status === "inactive") {
      newCardData["status"] = "active";
      newCardData["is_sync"] = false;
    }

    try {
      await firestore
        .collection("ac_profiles")
        .doc(card.id)
        .update(newCardData);

      await firestore.collection("ac_audit_trails").add({
        action: isSuspended ? `unsuspended` : `suspended`,
        created_at: updatedDate,
        created_by: {
          user_id: auth.uid,
          name: profile.name,
        },
        description: `${Capitalized(profile.name)} had ${
          isSuspended ? "un-suspended" : "suspended"
        } resident card ${card.identity_number}`,
        module: "access_card",
        profile: {
          id: card.id,
          identity_number: card.identity_number,
        },
        values: oldValue,
      });

      snackbar.enqueueSnackbar(
        isSuspended
          ? `Card No. ${card.identity_number} had been Un-suspended`
          : `Card No. ${card.identity_number} had been Suspended`,
        {
          variant: "success",
        }
      );
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, {
        variant: "error",
        persist: false,
      });
    }
  };

  const handleEditCard = () => {
    dispatch(toggleEditResidentDialogOpen({ open: true, id: card.id }));
    handleCloseMenu();
  };

  const handleToggleDeleteCardButton = () => {
    setDeleteCard(!deleteCard);
  };

  const handleDeleteCard = () => {
    const oldValue = cloneDeep(card);
    const deletedDate = new Date();

    handleCloseMenu();
    firestore
      .update(
        { collection: "ac_profiles", doc: card.id },
        {
          action: "delete",
          deleted_at: deletedDate,
          deleted_by: {
            user_id: auth.uid,
            name: profile.name,
          },
          is_sync: false,
          status: "deleted",
        }
      )
      .then(() => {
        firestore
          .collection("ac_audit_trails")
          .add({
            action: "deleted",
            created_at: deletedDate,
            created_by: {
              user_id: auth.uid,
              name: profile.name,
            },
            description: `${Capitalized(
              profile.name
            )} had deleted resident card ${card.identity_number}`,
            module: "access_card",
            profile: {
              id: card.id,
              identity_number: card.identity_number,
            },
            values: oldValue,
          })
          .then(() => {
            snackbar.enqueueSnackbar(
              `Card No. ${card.identity_number} had been deleted successfully`,
              {
                variant: "success",
              }
            );
          });
      })
      .catch((e) => {
        snackbar.enqueueSnackbar(e.message, {
          variant: "error",
          persist: false,
        });
      });
  };

  var cardView;
  if (card.status === "deleted") {
    cardView = classes.deletePaperContainer;
  } else if (card.status === "active" && !card.blocked_settings.enable) {
    cardView = classes.activePaperContainer;
  } else if (card.status === "active" && card.blocked_settings.enable) {
    cardView = classes.blockedPaperContainer;
  } else {
    cardView = classes.blockedPaperContainer;
  }

  return (
    <Grid item container key={card.id} md={3} justify="center">
      <Paper
        variant="outlined"
        elevation={11}
        className={
          // card.status === "deleted"
          //   ? classes.deletePaperContainer
          //   : classes.activePaperContainer
          cardView
        }
      >
        <Grid container direction="column">
          <Grid item container>
            <Grid item md={10} xs={10}>
              <Typography
                gutterBottom
                variant="caption"
                fontSize="8"
                fontStyle="italic"
                style={{ color: "white" }}
              >
                {"Resident Card"}
              </Typography>
            </Grid>
            <Grid item container md={2} xs={2} justify="flex-end">
              <MoreHorizIcon onClick={handleClick} style={{ color: "white" }} />
              <Menu
                id="enitMenu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                {card.status !== "deleted" && (
                  <MenuItem
                    onClick={
                      !suspendCard ? handleToggleSuspendCardButton : undefined
                    }
                    button={!suspendCard}
                    disabled={!roles.access_card?.resident?.suspend}
                  >
                    {suspendCard ? (
                      <React.Fragment>
                        <ButtonColor
                          buttonColor="red"
                          onClick={handleSuspendCard}
                          size="small"
                          variant="contained"
                        >
                          Yes
                        </ButtonColor>
                        <Button
                          style={{ marginLeft: "10px" }}
                          size="small"
                          color="primary"
                          variant="contained"
                          onClick={handleToggleSuspendCardButton}
                        >
                          No
                        </Button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <ListItemIcon onClick={() => {}}>
                          {isSuspended ? (
                            <PlayArrowIcon fontSize="small" />
                          ) : (
                            <PauseIcon fontSize="small" />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            isSuspended ? "Unsuspend Card" : "Suspend Card"
                          }
                        />
                      </React.Fragment>
                    )}
                  </MenuItem>
                )}

                <MenuItem onClick={handleViewCardDetails}>
                  <React.Fragment>
                    <ListItemIcon>
                      <VisibilityIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="View Details" />
                  </React.Fragment>
                </MenuItem>

                {card.status !== "deleted" && (
                  <MenuItem
                    onClick={handleEditCard}
                    disabled={!roles.access_card?.resident?.update}
                  >
                    <React.Fragment>
                      <ListItemIcon>
                        <EditIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Edit Card" />
                    </React.Fragment>
                  </MenuItem>
                )}

                {card.status !== "deleted" && (
                  <MenuItem
                    onClick={
                      !deleteCard ? handleToggleDeleteCardButton : undefined
                    }
                    button={!deleteCard}
                    disabled={!roles.access_card?.resident?.delete}
                  >
                    {deleteCard ? (
                      <React.Fragment>
                        <ButtonColor
                          buttonColor="red"
                          onClick={handleDeleteCard}
                          size="small"
                          variant="contained"
                        >
                          Yes
                        </ButtonColor>
                        <Button
                          style={{ marginLeft: "10px" }}
                          size="small"
                          color="primary"
                          variant="contained"
                          onClick={handleToggleDeleteCardButton}
                        >
                          No
                        </Button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <ListItemIcon onClick={() => {}}>
                          <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Delete Card" />
                      </React.Fragment>
                    )}
                  </MenuItem>
                )}
              </Menu>
            </Grid>
          </Grid>
          <Grid item container md={12}>
            <Grid
              item
              container
              md={12}
              justify="flex-start"
              alignItems="center"
            >
              <Typography
                gutterBottom
                variant="caption"
                style={{ color: "white" }}
              >
                {card.profile_name.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item container md={12}>
              <Grid item container md={12} justify="center" alignItems="center">
                <Typography
                  gutterBottom
                  variant="h6"
                  style={{ color: "white" }}
                >
                  {card.identity_number}
                </Typography>
              </Grid>

              <Grid
                item
                container
                md={12}
                justify="center"
                alignItems="center"
                className={classes.cardType}
              >
                <Typography
                  gutterBottom
                  align="center"
                  variant="caption"
                  style={{ color: "white" }}
                >
                  {card.blocked_settings.enable
                    ? "Suspended".toUpperCase()
                    : card.status === "inactive"
                    ? "Disabled".toUpperCase()
                    : card.status.toUpperCase()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container>
            <Grid item container md={12}>
              <Grid
                item
                container
                md={10}
                xs={10}
                justify="flex-start"
                alignItems="center"
              >
                <Typography
                  gutterBottom
                  variant="caption"
                  style={{ color: "white" }}
                >
                  {card.unit
                    ? `${card.unit.block_name}, ${card.unit.unit_name}`
                    : null}
                </Typography>
              </Grid>

              <Grid
                item
                container
                md={2}
                xs={2}
                justify="center"
                alignItems="center"
              >
                <Typography
                  gutterBottom
                  variant="caption"
                  style={{ color: "white" }}
                >
                  {card.card_type === "proximity"
                    ? "PROX"
                    : card.card_type.toUpperCase()}
                </Typography>
              </Grid>
              <Grid
                item
                container
                md={10}
                xs={10}
                justify="flex-start"
                alignItems="center"
              >
                <Typography
                  gutterBottom
                  variant="caption"
                  style={{ color: "white" }}
                >
                  {card.vehicle_info.number
                    ? card.vehicle_info.number.toUpperCase()
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ResidentCard;
