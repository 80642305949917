import * as actionTypes from "./ActionType";

const initialState = {
  edit_access_role_dialog: { open: false, id: "" },
};

function AccessRoleReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_EDIT_ACCESS_ROLE_DIALOG_OPEN:
      return {
        ...state,
        edit_access_role_dialog: {
          ...state.edit_access_role_dialog,
          ...action.payload,
        },
      };
    case actionTypes.TOGGLE_EDIT_ACCESS_ROLE_DIALOG_CLOSE:
      return {
        ...state,
        edit_access_role_dialog: { open: false, id: "" },
      };
    default:
      return state;
  }
}

export default AccessRoleReducer;
