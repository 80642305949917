import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { cloneDeep, find, orderBy, pick } from "lodash";
import moment from "moment";

// components (global)
import ButtonProgress from "components/ButtonProgress";
import CusomtDatePick from "components/DateTime/CustomDatePick";
import DialogWrapper from "components/Dialogs/DialogWrapper";
import DialogTitleWrapper from "components/Dialogs/DialogTitleWrapper";
import UnitAutocomplete from "components/CustomSelect/UnitAutocomplete";

// components (local)
import AccessGroup from "../AccessGroup";
import AccessLevel from "../AccessLevel";
import LiftAccess from "../LiftAccess";

// firebase
import { useFirestore } from "react-redux-firebase";

// form
import { useForm, FormContext } from "react-hook-form";
import Select, { SelectChangeEvent } from "@mui/material/Select";
// helpers
import { residentCardValidate } from "helpers/validation/residentCardValidate";
import { Capitalized } from "helpers/textHelpers";

// material-ui
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

// stores
import { toggleEditResidentDialogClose } from "stores/card/CardAction";
import { OverlayWithText } from "components/Box";
import updateAnimation from "components/lotties/update";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  accessLevel: {
    marginTop: "18px",
  },
  liftAccess: {
    marginTop: "18px",
  },
  accessGroup: {
    marginTop: "18px",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const EditResidentDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const firestore = useFirestore();

  // form
  const formMethods = useForm();
  const watchUnit = formMethods.watch("unit");

  // selector
  const { open, id } = useSelector((state) => state.card.edit_resident_dialog);
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const accessLevelsSelector = useSelector((state) => {
    return state.firestore.ordered?.accessLevels
      ? orderBy(state.firestore.ordered.accessLevels, ["name"])
      : [];
  });
  const floorAccessLevelsSelector = useSelector((state) => {
    return state.firestore.ordered?.liftAccess
      ? orderBy(state.firestore.ordered.liftAccess, ["name"])
      : [];
  });

  const accessGroupSelector = useSelector((state) => {
    return state.firestore.ordered?.accessGroups
      ? orderBy(state.firestore.ordered.accessGroups, ["name"])
      : [];
  });
  const accessCardSettings = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor].access_card_settings
      : null;
  });
  const unitAccessSelector = useSelector(
    (state) => state.firestore.data.unit_access
  );
  const card = useSelector((state) => {
    return id && state.firestore.data.resident_cards
      ? state.firestore.data.resident_cards[id]
      : null;
  });

  // state
  const [selectedAccessLevelId, setSelectedAccessLevelId] = useState(
    card?.access?.access_level?.id ?? ""
  );
  const [selectedFloorAccessLevelId, setSelectedFloorAccessLevelId] = useState(
    card?.access?.lift_access?.id ?? ""
  );
  const [selectedAccessGroup, setSelectedAccessGroup] = useState(
    card?.access?.access_groups ?? []
  );
  var [opacity, setOpacity] = React.useState(0.5);
  const [submitting, setSubmitting] = useState(false);
  const [unitAccess, setUnitAccess] = useState(
    unitAccessSelector && unitAccessSelector[card.unit?.unit_id]
      ? unitAccessSelector[card.unit?.unit_id].resident
      : null
  );
  const [status, setStatus] = useState(true);
  const [nonExpired, setNonExpired] = useState(true);
  const [antiPassback, setAntiPassback] = useState(false);
  const [isLift, setIsLift] = useState(false);
  const [cardTypes] = useState([
    { id: "rfid", name: "RFID" },
    { id: "mifare", name: "Mifare" },
    { id: "proximity", name: "Proximity" },
  ]);

  const watchNonExpired = formMethods.watch("nonExpired", nonExpired);

  // effect
  useEffect(() => {
    if (card) {
      setStatus(card.status === "active");
      setAntiPassback(card.anti_passback);
      setIsLift(card.is_lift);
    }
  }, [card]);

  useEffect(() => {
    if (card) {
      setNonExpired(!card.expired.enable);
    }
  }, [card]);

  useEffect(() => {
    if (watchUnit) {
      setUnitAccess(
        unitAccessSelector && unitAccessSelector[watchUnit.id]
          ? unitAccessSelector[watchUnit.id].resident
          : null
      );
    }
  }, [unitAccessSelector, watchUnit]);

  useEffect(() => {
    if (unitAccess?.lift_access?.id) {
      setSelectedFloorAccessLevelId(unitAccess.lift_access.id);
    }
  }, [unitAccess]);

  useEffect(() => {
    if (unitAccess?.lift_access?.id) {
      setSelectedFloorAccessLevelId(unitAccess.lift_access.id);
    }
  }, [unitAccess]);

  useEffect(() => {
    if (unitAccess?.access_group) {
      setSelectedAccessGroup(unitAccess.access_group);
    }
  }, [unitAccess]);
  // functions
  const handleClose = () => {
    if (!submitting) dispatch(toggleEditResidentDialogClose());
  };

  const handleTypeChange = (e, value) => {
    if (value) {
      formMethods.setValue("cardType", value.id);
    } else {
      formMethods.setValue("cardType", null);
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.checked);
  };
  const handleAccessGroupChange = (e, value) => {
    setSelectedAccessGroup(value);
  };

  const handleNonExpiredChange = (event) => {
    setNonExpired(event.target.checked);
  };

  const handleAntiPassbackChange = (event) => {
    setAntiPassback(event.target.checked);
  };

  const handleIsLiftChange = (event) => {
    setIsLift(event.target.checked);
  };

  useEffect(() => {
    if (!submitting) {
      document.body.style.opacity = 1;
    } else {
      document.body.style.opacity = 0.7;
    }
  }, [submitting]);

  const onSubmit = (value) => {
    if (!submitting) {
      setSubmitting(true);

      if (!accessCardSettings) {
        setSubmitting(false);
        return;
      }

      if (
        accessCardSettings.manual_assign_access_group &&
        selectedAccessGroup.length < 1
      ) {
        setSubmitting(false);

        snackbar.enqueueSnackbar(`Please select access group`, {
          variant: "error",
        });

        return;
      }

      let access = {};

      if (value.unit) {
        const unitAccess = unitAccessSelector
          ? unitAccessSelector[value.unit.id]
          : null;

        if (unitAccess) {
          access = {
            ...access,
            access_groups: unitAccess.resident.access_groups,
          };

          if (isLift) {
            access = {
              ...access,
              lift_access: unitAccess.resident.lift_access
                ? unitAccess.resident.lift_access
                : accessCardSettings.type.toLowerCase() === "falco"
                ? "00"
                : null,
            };
          } else {
            access = {
              ...access,
              lift_access:
                accessCardSettings.type.toLowerCase() === "falco" ? "00" : null,
            };
          }

          if (
            accessCardSettings.type.toLowerCase() === "entrypass" ||
            accessCardSettings.type.toLowerCase() === "falco"
          ) {
            access = {
              ...access,
              access_level: unitAccess.resident.access_level
                ? unitAccess.resident.access_level
                : null,
            };
          }

          // if user select different access level than default
          if (
            accessCardSettings.type.toLowerCase() === "entrypass" &&
            ((!!accessCardSettings?.manual_assign_access_level &&
              !!selectedAccessLevelId) ||
              (!!accessCardSettings?.manual_assign_floor_access_level &&
                isLift &&
                !!selectedFloorAccessLevelId))
          ) {
            const foundAccess = find(accessLevelsSelector, {
              id: selectedAccessLevelId,
            });

            if (foundAccess) {
              access = {
                ...access,
                access_level: pick(foundAccess, ["id", "name", "number"]),
              };
            }

            // if found manual assignlift access

            const foundLiftAccess = find(floorAccessLevelsSelector, {
              id: selectedFloorAccessLevelId,
            });
            if (foundLiftAccess) {
              access = {
                ...access,
                lift_access: pick(foundLiftAccess, ["id", "name", "number"]),
              };
            }
          }
          if (
            accessCardSettings.type.toLowerCase() === "entrypass" &&
            !!accessCardSettings?.manual_assign_access_group &&
            !!selectedAccessGroup
          ) {
            if (selectedAccessGroup) {
              access = {
                ...access,
                access_groups: selectedAccessGroup,
              };
            }
          }
        } else {
          access = {
            access_groups: null,
            lift_access: null,
          };

          if (
            accessCardSettings.type.toLowerCase() === "entrypass" ||
            accessCardSettings.type.toLowerCase() === "falco"
          ) {
            access = {
              ...access,
              access_level: null,
            };
          }
        }
      } else {
        access = {
          access_groups: null,
          lift_access: null,
        };

        if (
          accessCardSettings.type.toLowerCase() === "entrypass" ||
          accessCardSettings.type.toLowerCase() === "falco"
        ) {
          access = {
            ...access,
            access_level: null,
          };
        }
      }

      const updatedDate = new Date();
      const oldValue = cloneDeep(card);

      /**
       * validate whether sync to local server
       * when below elements changed, sync to local server
       * - unit
       * - profile name
       * - status
       * - anti passback
       * - is lift card
       */
      let isSync = card.is_sync; // Get card is_sync value to prevent sync still in progress
      let status = value.status ? "active" : "inactive";

      if (
        value.unit?.id !== card.unit?.unit_id ||
        value.profileName !== card.profile_name ||
        status !== card.status ||
        value.antiPassback !== card.anti_passback ||
        value.isLift !== card.is_lift ||
        (!!selectedAccessLevelId &&
          card?.access?.access_level?.id !== selectedAccessLevelId) ||
        (!!selectedFloorAccessLevelId &&
          card?.access?.lift_access?.id !== selectedFloorAccessLevelId) ||
        (!!selectedAccessGroup &&
          card?.access?.access_groups !== selectedAccessGroup)
      ) {
        isSync = false;
      }

      let data = {
        access,
        action: "modify",
        attempted: 0,
        anti_passback: value.antiPassback,
        card_type: value.cardType.toLowerCase(),
        contact_number: value.contactNo,
        expired: {
          date: !value.nonExpired
            ? new Date(
                value.expiredDate.getFullYear(),
                value.expiredDate.getMonth(),
                value.expiredDate.getDate()
              )
            : null,
          enable: !value.nonExpired,
        },
        is_lift: value.isLift,
        is_sync: isSync,
        parking_lot_no: value.parkingLot,
        profile_name: value.profileName,
        status: status,
        sticker_serial_no: value.stickerNo,
        updated_at: updatedDate,
        updated_by: {
          user_id: auth.uid,
          name: profile.name,
        },
        vehicle_info: {
          colour: value.vehicleColour,
          make: value.vehicleMake,
          model: value.vehicleModel,
          number: value.vehicleNo,
        },
      };

      if (value.unit) {
        data = {
          ...data,
          unit: {
            block_id: value.unit.block_id,
            block_name: value.unit.block_name,
            unit_id: value.unit.id,
            unit_name: value.unit.house_number,
          },
        };
      }

      firestore
        .update(`ac_profiles/${id}`, data)
        .then(() => {
          firestore
            .collection("ac_audit_trails")
            .add({
              action: "Card Modified",
              created_at: updatedDate,
              created_by: {
                user_id: auth.uid,
                name: profile.name,
              },
              description: `${Capitalized(
                profile.name
              )} modified resident card ${card.identity_number}`,
              module: "access_card",
              profile: {
                id,
                identity_number: card.identity_number,
              },
              values: oldValue,
            })
            .then(() => {
              setSubmitting(false);
              handleClose();
              snackbar.enqueueSnackbar(
                `Card No. ${card.identity_number} had been updated successfully`,
                {
                  variant: "success",
                }
              );
            })
            .catch((e) => {
              setSubmitting(false);
              handleClose();
              snackbar.enqueueSnackbar(e.message, {
                variant: "error",
                persist: false,
              });
            });
        })
        .catch((e) => {
          setSubmitting(false);
          handleClose();
          snackbar.enqueueSnackbar(e.message, {
            variant: "error",
            persist: false,
          });
        });
    }
  };

  if (!card) return null;

  const cardIdTF = (
    <TextField
      fullWidth
      disabled={true}
      size="small"
      id="profileCode"
      name="profileCode"
      label="Card ID *"
      type="text"
      variant="outlined"
      autoComplete="off"
      defaultValue={card.profile_code}
    />
  );

  const cardNoTF = (
    <TextField
      fullWidth
      disabled={true}
      size="small"
      id="identityNumber"
      name="identityNumber"
      label="Card No. *"
      type="text"
      variant="outlined"
      autoComplete="off"
      defaultValue={card.identity_number}
    />
  );

  const nameTF = (
    <TextField
      size="small"
      id="profileName"
      name="profileName"
      label="Name *"
      type="text"
      variant="outlined"
      autoComplete="off"
      defaultValue={card.profile_name}
      inputRef={formMethods.register(residentCardValidate.profileName.rules)}
      helperText={
        formMethods.errors.profileName &&
        residentCardValidate.profileName.message[
          formMethods.errors.profileName.type
        ]
      }
      error={!!formMethods.errors.profileName}
      fullWidth
    />
  );

  const cardTypeAC = (
    <Autocomplete
      autoHighlight
      options={cardTypes}
      getOptionLabel={(option) => (option.name ? option.name : null)}
      onChange={handleTypeChange}
      defaultValue={find(cardTypes, (type) => type.id === card.card_type)}
      renderInput={(params) => (
        <TextField
          error={!!formMethods.errors.cardType}
          helperText={
            formMethods.errors.cardType &&
            residentCardValidate.cardType.message[
              formMethods.errors.cardType.type
            ]
          }
          {...params}
          label="Choose a type *"
          fullWidth
          size="small"
          name="cardType"
          autoComplete="off"
          variant="outlined"
          inputRef={formMethods.register(residentCardValidate.cardType.rules)}
        />
      )}
    />
  );

  const nonExpiredCB = (
    <FormControlLabel
      control={
        <Checkbox
          name="nonExpired"
          checked={nonExpired}
          onChange={handleNonExpiredChange}
          color="primary"
          inputRef={formMethods.register}
        />
      }
      label="Non Expired"
    />
  );

  const expiredDatePicker = (
    <CusomtDatePick
      disabled={watchNonExpired}
      label="Expired Date"
      name="expiredDate"
      defaultValue={
        card.expired?.date
          ? card.expired?.date.toDate()
          : moment(new Date()).add(1, "days").toDate()
      }
      minDate={moment(new Date()).add(1, "days").toDate()}
    />
  );

  const cardSettingGroup = (
    <>
      <Grid item md={4}>
        <FormControlLabel
          control={
            <Checkbox
              name="status"
              checked={status}
              onChange={handleStatusChange}
              color="primary"
              inputRef={formMethods.register}
            />
          }
          label="Active"
        />
      </Grid>

      <Grid item md={4}>
        <FormControlLabel
          control={
            <Checkbox
              name="antiPassback"
              checked={antiPassback}
              onChange={handleAntiPassbackChange}
              color="primary"
              inputRef={formMethods.register}
            />
          }
          label="Anti-passback"
        />
      </Grid>

      <Grid item md={4}>
        <FormControlLabel
          control={
            <Checkbox
              name="isLift"
              checked={isLift}
              onChange={handleIsLiftChange}
              color="primary"
              inputRef={formMethods.register}
            />
          }
          label="Is Lift"
        />
      </Grid>
    </>
  );

  const leftGrid = (
    <Grid
      item
      container
      direction="row"
      spacing={2}
      md={7}
      justify="center"
      alignItems="flex-start"
    >
      <Grid item md={12}>
        <Typography variant="h4" component="h1">
          Card Details
        </Typography>
      </Grid>

      <Grid item md={12}>
        <UnitAutocomplete defaultValue={card.unit?.unit_id} required={false} />
      </Grid>

      <Grid item md={6}>
        {cardIdTF}
      </Grid>

      <Grid item md={6}>
        {cardNoTF}
      </Grid>

      <Grid item md={12}>
        {nameTF}
      </Grid>

      <Grid item md={12}>
        {cardTypeAC}
      </Grid>

      <Grid item md={4}>
        {nonExpiredCB}
      </Grid>

      <Grid item md={8}>
        {expiredDatePicker}
      </Grid>

      {cardSettingGroup}
    </Grid>
  );

  const contactNoTF = (
    <TextField
      size="small"
      id="contactNo"
      name="contactNo"
      label="Contact No."
      type="text"
      inputRef={formMethods.register}
      defaultValue={card.contact_number}
      autoComplete="off"
      fullWidth
      variant="outlined"
    />
  );

  const vehicleNoTF = (
    <TextField
      size="small"
      id="vehicleNo"
      name="vehicleNo"
      label="Vehicle No."
      type="text"
      inputRef={formMethods.register}
      defaultValue={card.vehicle_info.number}
      autoComplete="off"
      fullWidth
      variant="outlined"
    />
  );

  const vehicleMakeTF = (
    <TextField
      size="small"
      id="vehicleMake"
      name="vehicleMake"
      label="Vehicle Make"
      type="text"
      inputRef={formMethods.register}
      defaultValue={card.vehicle_info.make}
      autoComplete="off"
      fullWidth
      variant="outlined"
    />
  );

  const vehicleModelTF = (
    <TextField
      id="vehicleModel"
      name="vehicleModel"
      label="Vehicle Model"
      type="text"
      variant="outlined"
      autoComplete="off"
      size="small"
      inputRef={formMethods.register}
      defaultValue={card.vehicle_info.model}
      fullWidth
    />
  );

  const vehicleColorTF = (
    <TextField
      id="vehicleColour"
      name="vehicleColour"
      label="Vehicle Colour"
      type="text"
      variant="outlined"
      size="small"
      inputRef={formMethods.register}
      defaultValue={card.vehicle_info.colour}
      fullWidth
    />
  );

  const parkingLotTF = (
    <TextField
      id="parkingLot"
      name="parkingLot"
      label="Parking Lot No."
      type="text"
      variant="outlined"
      size="small"
      inputRef={formMethods.register}
      defaultValue={card.parking_lot_no}
      fullWidth
    />
  );

  const stickerNoTF = (
    <TextField
      id="stickerNo"
      name="stickerNo"
      label="Sticker No."
      type="text"
      variant="outlined"
      size="small"
      inputRef={formMethods.register}
      defaultValue={card.sticker_serial_no}
      fullWidth
    />
  );

  const rightGrid = (
    <Grid
      item
      container
      direction="row"
      spacing={2}
      md={5}
      justify="center"
      alignItems="flex-start"
    >
      <Grid item md={12}>
        <Typography variant="h4" component="h1">
          Other Details
        </Typography>
      </Grid>
      <Grid item container md={12}>
        {contactNoTF}
      </Grid>
      <Grid item md={12}>
        {vehicleNoTF}
      </Grid>
      <Grid item md={12}>
        {vehicleMakeTF}
      </Grid>
      <Grid item md={12}>
        {vehicleModelTF}
      </Grid>
      <Grid item md={12}>
        {vehicleColorTF}
      </Grid>
      <Grid item md={12}>
        {parkingLotTF}
      </Grid>
      <Grid item md={12}>
        {stickerNoTF}
      </Grid>
    </Grid>
  );

  const accessLevelAC = (
    <Grid item xs={4}>
      <Autocomplete
        autoComplete={true}
        className={classes.accessLevel}
        onChange={(e, value) => {
          setSelectedAccessLevelId(value?.id ?? "");
        }}
        options={accessLevelsSelector}
        getOptionLabel={(option) =>
          option.name ?? find(accessLevelsSelector, { id: option })?.name ?? ""
        }
        getOptionSelected={(option, value) => value === option.id}
        value={selectedAccessLevelId}
        renderInput={(params) => (
          <TextField
            error={!!formMethods.errors.accessLevel}
            helperText={
              !!formMethods.errors.accessLevel && `Access Level required`
            }
            {...params}
            fullWidth
            label="Choose Access Level"
            name="accessLevel"
            size="small"
            inputRef={formMethods.register({ required: true })}
          />
        )}
      />
    </Grid>
  );

  const accessGroupAC = (
    <Grid item xs={4}>
      <Autocomplete
        multiple
        autoComplete={true}
        className={classes.accessGroup}
        value={selectedAccessGroup}
        onChange={handleAccessGroupChange}
        options={accessGroupSelector}
        getOptionLabel={(option) =>
          option.name ?? find(accessGroupSelector, { id: option })?.name ?? ""
        }
        disableCloseOnSelect
        defaultValue={selectedAccessGroup}
        getOptionSelected={(option, value) => option === value}
        renderInput={(params) => (
          <TextField
            error={!!formMethods.errors.accessGroup}
            helperText={
              !!formMethods.errors.accessGroup && `Access Group required`
            }
            {...params}
            fullWidth
            label="Choose Access Group"
            name="accessGroup"
            size="small"
            inputRef={formMethods.register({
              validate: selectedAccessGroup.length > 0,
            })}
          />
        )}
      />
    </Grid>
  );

  const flooraccessLevelAC = (
    <Grid item xs={4}>
      <Autocomplete
        autoComplete={true}
        className={classes.liftAccess}
        onChange={(e, value) => {
          setSelectedFloorAccessLevelId(value?.id ?? "");
        }}
        options={floorAccessLevelsSelector}
        getOptionLabel={(option) =>
          option.name ??
          find(floorAccessLevelsSelector, { id: option })?.name ??
          ""
        }
        getOptionSelected={(option, value) => value === option.id}
        value={selectedFloorAccessLevelId}
        renderInput={(params) => (
          <TextField
            error={!!formMethods.errors.flooraccessLevel}
            helperText={
              !!formMethods.errors.flooraccessLevel && `Lift Access required`
            }
            {...params}
            fullWidth
            label="Choose Lift Access"
            name="liftAccess"
            size="small"
            inputRef={formMethods.register({ required: false })}
          />
        )}
      />
    </Grid>
  );

  const bottomGrid = (
    <Grid
      container
      spacing={1}
      direction="row"
      justify="space-between"
      alignItems="flex-start"
    >
      {accessCardSettings?.type?.toLowerCase() === "entrypass" ? (
        accessCardSettings?.manual_assign_access_group ? (
          accessGroupAC
        ) : (
          <AccessGroup accessGroups={unitAccess?.access_groups} />
        )
      ) : null}

      {accessCardSettings?.type?.toLowerCase() === "entrypass" ? (
        accessCardSettings?.manual_assign_access_level ? (
          accessLevelAC
        ) : (
          <AccessLevel accessLevel={card?.access?.access_level} />
        )
      ) : null}

      {accessCardSettings?.type?.toLowerCase() === "entrypass" ? (
        accessCardSettings?.manual_assign_floor_access_level ? (
          flooraccessLevelAC
        ) : (
          <LiftAccess isLift={isLift} liftAccess={card?.access?.lift_access} />
        )
      ) : null}
    </Grid>
  );

  return (
    <FormContext {...formMethods}>
      <form
        noValidate
        xs={12}
        onSubmit={formMethods.handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <DialogWrapper size="md" open={open}>
          <DialogTitleWrapper
            title="Edit Resident Card"
            handleClose={handleClose}
          />

          <DialogContent dividers>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              {leftGrid}
              {rightGrid}
            </Grid>
            <Divider className={classes.divider} />
            {bottomGrid}
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <ButtonProgress
              type="submit"
              containName="Save"
              margin={false}
              loading={submitting}
              disabled={submitting}
              onClick={formMethods.handleSubmit(onSubmit)}
            />
          </DialogActions>
          <OverlayWithText
            loadingText="Updating"
            open={submitting}
            animationData={updateAnimation}
            height={500}
            width={500}
          />
        </DialogWrapper>
      </form>
    </FormContext>
  );
};

export default EditResidentDialog;
