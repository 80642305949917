import React from "react";
import clsx from "clsx";

// components (local)
import MemberGrid from "./MemberGrid";

// material-ui
import { Search as SearchIcon } from "@material-ui/icons";
import {
  Grid,
  InputAdornment,
  Paper,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: { paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) },
  content: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));

const SubContent = ({ className, header, subHeader, members }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.content}>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.header}
        >
          <Grid item md={12} xs={12}>
            <Paper>
              <TextField
                //   onChange={handleSearchChange}
                size="small"
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div className={classes.searchIcon}>
                        <SearchIcon />
                      </div>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search Moderator Name…"
              />
            </Paper>
          </Grid>
        </Grid>

        <MemberGrid members={members} />
      </div>
    </div>
  );
};

export default SubContent;
