import React, { memo } from "react";
import { useSelector } from "react-redux";

// components (local)
import { AddVehicleDialog } from "../../components";

// firebase
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";

// library
import PerfectScrollbar from "react-perfect-scrollbar";

import { AvatarImage } from "components";

// material-ui
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles(() => ({
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  }
}));

const VehicleTable = () => {
  const classes = useStyles();

  // selector
  const property = useSelector(state => state.property);
  const vehicles = useSelector(state => state.firestore.ordered.vehicles);

  // firestore
  useFirestoreConnect([
    {
      collection: "ac_profiles",
      where: [
        ["vendor.type", "==", "Spix"],
        ["property_id", "==", property.id]
      ],
      // where: [["property_id", "==", selectedProperty.id]],
      // orderBy: "profile_code",
      // startAt: "V004",
      // endAt: "2wkkmARoDSxZt76ylqDG",
      // startAt: "2wkkmARoDSxZt76ylqDG",
      // endAt: "v5glElXFlJXTssAcmByM",
      limit: 10,
      storeAs: "vehicles"
    }
  ]);

  // functions
  const VehicleImage = fileName => {};

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Block & Unit</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Vehicle No.</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoaded(vehicles) ? (
                  <TableRow>
                    <TableCell colSpan={5}>Loading...</TableCell>
                  </TableRow>
                ) : (
                  vehicles.map(vehicle => (
                    <TableRow key={vehicle.id}>
                      <TableCell>{`${vehicle.unit.block_name}, ${vehicle.unit.unit_name}`}</TableCell>
                      <TableCell>
                        {vehicle.file_name ? (
                          <AvatarImage fileName={vehicle.file_name} />
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell>{vehicle.profile_name}</TableCell>
                      <TableCell>{vehicle.identity_number}</TableCell>
                      <TableCell>
                        {vehicle.status === "active" ? "Active" : "Disabled"}
                      </TableCell>
                      <TableCell>
                        <AddVehicleDialog vehicle={vehicle} />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={!isLoaded(vehicles) ? 0 : vehicles.length}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

export default React.memo(VehicleTable);
