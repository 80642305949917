import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { orderBy } from "lodash";
import axios from "http-axios";

// components (global)
import ButtonProgress from "components/ButtonProgress";
import DialogWrapper from "components/Dialogs/DialogWrapper";
import DialogTitleWrapper from "components/Dialogs/DialogTitleWrapper";

// firebase
import { useFirebase } from "react-redux-firebase";

// form
import { useForm, FormContext } from "react-hook-form";

// material-ui
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";

// stores
import { toggleEditFloorAccessDialogClose } from "stores/setting/SettingAction";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

const SoyalFloorAccessDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const snackbar = useSnackbar();

  // form
  const formMethods = useForm();

  // selector
  const { open, id } = useSelector(
    (state) => state.setting.edit_floor_access_dialog
  );
  const uid = useSelector((state) => state.firebase.auth.uid);
  const selectedPropertyId = useSelector(
    (state) => state.main.selectedProperty
  );
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const floorAccessSelector = useSelector((state) => {
    return state.firestore.data.floorAccess[id]
      ? state.firestore.data.floorAccess[id]
      : null;
  });
  const liftAccess = useSelector((state) => state.firestore.ordered.liftAccess);

  // state
  const [submitting, setSubmitting] = useState(false);

  // effect
  useEffect(() => {
    formMethods.register({ name: "residentLiftAccess" });
    formMethods.register({ name: "visitorLiftAccess" });
  }, [formMethods]);

  useEffect(() => {
    if (floorAccessSelector) {
      formMethods.setValue(
        "residentLiftAccess",
        floorAccessSelector.resident.lift_access
      );
      formMethods.setValue(
        "visitorLiftAccess",
        floorAccessSelector.visitor.lift_access
      );
    }
  }, [floorAccessSelector, formMethods]);

  // functions
  const onHandleClose = () => {
    if (!submitting) {
      dispatch(toggleEditFloorAccessDialogClose());
    }
  };

  const onSubmit = (value) => {
    if (!submitting) {
      setSubmitting(true);

      let residentLiftAccess = null;
      let visitorLiftAccess = null;

      if (value.residentLiftAccess) {
        delete value.residentLiftAccess.created_at;
        delete value.residentLiftAccess.created_by;

        residentLiftAccess = value.residentLiftAccess;
      }

      if (value.visitorLiftAccess) {
        delete value.visitorLiftAccess.created_at;
        delete value.visitorLiftAccess.created_by;

        visitorLiftAccess = value.visitorLiftAccess;
      }

      firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          };

          axios
            .post(
              `jagaCardUpdateFloorAccess`,
              {
                uid,
                property_id: selectedPropertyId,
                floor_id: id,
                vendor_id: selectedVendor,
                resident_lift_access: residentLiftAccess,
                visitor_lift_access: visitorLiftAccess,
              },
              {
                headers: headers,
              }
            )
            .then((res) => {
              if (res.data.result === "success") {
                setSubmitting(false);
                onHandleClose();
                snackbar.enqueueSnackbar("Floor Access had been added", {
                  variant: "success",
                });
              }
            })
            .catch((e) => {
              setSubmitting(false);
              onHandleClose();
              snackbar.enqueueSnackbar(e.message, {
                variant: "error",
                persist: false,
              });
            });
        });
    }
  };

  return (
    <FormContext {...formMethods}>
      <form
        noValidate
        xs={12}
        onSubmit={formMethods.handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <DialogWrapper size="md" open={open}>
          <DialogTitleWrapper
            title="Edit Floor Access"
            handleClose={onHandleClose}
          />

          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item container md={12} xs={12} spacing={2}>
                <Grid item md={6} xs={12}>
                  <TextField
                    disabled={true}
                    size="small"
                    margin="dense"
                    id="floorName"
                    name="floorName"
                    label="Floor Name"
                    type="text"
                    readOnly={true}
                    autoComplete="off"
                    defaultValue={floorAccessSelector.floor_name}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Divider />
              </Grid>

              <Grid item container md={6} xs={12} spacing={1}>
                <Grid item md={12} xs={12}>
                  <Typography className={classes.modalTitle} variant="h4">
                    Resident
                  </Typography>
                  <Typography className={classes.modalTitle} variant="h6">
                    Please choose a Lift Access
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Autocomplete
                    autoHighlight
                    options={orderBy(liftAccess, ["number"], ["asc"])}
                    getOptionLabel={(option) =>
                      option.display_name ? option.display_name : option.name
                    }
                    onChange={(e, value) => {
                      formMethods.setValue(
                        "residentLiftAccess",
                        value ? value : null
                      );
                    }}
                    defaultValue={floorAccessSelector.resident.lift_access}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a lift access *"
                        fullWidth
                        size="small"
                        autoComplete="off"
                        variant="outlined"
                        inputRef={formMethods.register}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item container md={6} xs={12} spacing={1}>
                <Grid item md={12} xs={12}>
                  <Typography className={classes.modalTitle} variant="h4">
                    Visitor
                  </Typography>
                  <Typography className={classes.modalTitle} variant="h6">
                    Please choose a Lift Access
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Autocomplete
                    autoHighlight
                    options={orderBy(liftAccess, ["number"], ["asc"])}
                    getOptionLabel={(option) =>
                      option.display_name ? option.display_name : option.name
                    }
                    onChange={(e, value) => {
                      formMethods.setValue(
                        "visitorLiftAccess",
                        value ? value : null
                      );
                    }}
                    defaultValue={floorAccessSelector.visitor.lift_access}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a lift access *"
                        fullWidth
                        size="small"
                        autoComplete="off"
                        variant="outlined"
                        inputRef={formMethods.register}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={onHandleClose}>
              Cancel
            </Button>
            <ButtonProgress
              type="submit"
              containName="Save"
              margin={false}
              loading={submitting}
              disabled={submitting}
              onClick={formMethods.handleSubmit(onSubmit)}
            />
          </DialogActions>
        </DialogWrapper>
      </form>
    </FormContext>
  );
};

export default SoyalFloorAccessDialog;
