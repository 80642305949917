import React from "react";

// material-ui
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    height: "100vh",
    display: "flex",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center"
  }
}));

const LoadingSpinnerFull = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress size={80} />
    </div>
  );
};

export default LoadingSpinnerFull;
