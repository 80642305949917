import React, { useState } from "react";
import { useSelector } from "react-redux";

// components (global)
import DashboardButton from "components/Button/DashboardButton";

// components (local)

// material-ui
import { Button } from "@material-ui/core";
import { TouchApp as DoorIcon } from "@material-ui/icons";
import { EntrypassCsv } from ".";

const CsvButton = ({ dashboardButton, name }) => {
  // selector

  // stateå
  const [open, setOpen] = useState(false);
  const [openUploadCSV, setOpenUploadCSV] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // functions
  const handleClickOpen = () => {
    if (!open) setOpen(true);
  };
  const handleClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUploadCSVClose = () => {
    setOpenUploadCSV(false);
    handleClose();
  };

  const handleUploadCSVOpen = () => {
    setOpenUploadCSV(true);
    handleClose();
  };

  return (
    <React.Fragment>
      {dashboardButton ? (
        <DashboardButton
          icon={<DoorIcon />}
          onClick={handleUploadCSVOpen}
          title={name}
          onClose={handleUploadCSVClose}
        />
      ) : (
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Convert EntryPass Data to CSV/Excel
        </Button>
      )}
      {openUploadCSV && (
        <EntrypassCsv
          open={openUploadCSV}
          handleDialogClose={handleUploadCSVClose}
        />
      )}
    </React.Fragment>
  );
};

export default CsvButton;
