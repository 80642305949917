export const settingsValidate = {
  block: {
    rules: {
      required: true
    },
    message: {
      required: "Block is Required"
    }
  },
  floor: {
    rules: {
      required: true
    },
    message: {
      required: "Floor is Required"
    }
  },
  vendor: {
    rules: {
      required: true
    },
    message: {
      required: "Vendor is Required"
    }
  },
  ipAddress: {
    rules: {
      required: true,
      pattern: /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
    },
    message: {
      required: "IP Address is required",
      pattern: "Invalid IP Address Format"
    }
  },
  ipAddressNotRequired: {
    rules: {
      // required: true,
      pattern: /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
    },
    message: {
      // required: "IP Address is required",
      pattern: "Invalid IP Address Format"
    }
  },
  portNo: {
    rules: {
      required: true,
      pattern: /^[0-9]*$/
    },
    message: {
      required: "Port No. is Required",
      pattern: "Invalid Port No. Format"
    }
  },
  dbInstance: {
    rules: {
      required: true
    },
    message: {
      required: "DB Instance is Required"
    }
  },
  dbName: {
    rules: {
      required: true
    },
    message: {
      required: "DB Name is Required"
    }
  },
  dbUsername: {
    rules: {
      required: true
    },
    message: {
      required: "DB Username is Required"
    }
  },
  dbPassword: {
    rules: {
      required: true
    },
    message: {
      required: "DB Username is Required"
    }
  },
  siteId: {
    rules: {
      required: false
    },
    message: {
      required: "Site Id is Required"
    }
  },
  doorId: {
    rules: {
      required: false
    },
    message: {
      required: "Door Id is Required"
    }
  },
  numberOfDay: {
    rules: {
      required: true,
      pattern: /^[0-9]*$/
    },
    message: {
      required: "DB Username is Required",
      pattern: "Invalid Number of Day Format"
    }
  },
  warningMessage: {
    rules: {
      required: true
      // maxLength: 100
    },
    message: {
      required: "DB Username is Required"
      // maxLength: "Warning Massage must be less than or equal to 100 characters"
    }
  },
  blockedMessage: {
    rules: {
      required: true
      // maxLength: 100
    },
    message: {
      required: "DB Username is Required"
      // maxLength: "Blocked Massage must be less than or equal to 100 characters"
    }
  },
  smsWarningMessage: {
    rules: {
      required: true,
      maxLength: 100
    },
    message: {
      required: "DB Username is Required",
      maxLength:
        "Warning Massage (SMS) must be less than or equal to 100 characters"
    }
  },
  smsBlockedMessage: {
    rules: {
      required: true,
      maxLength: 100
    },
    message: {
      required: "DB Username is Required",
      maxLength:
        "Blocked Massage (SMS) must be less than or equal to 100 characters"
    }
  },
  visitorEntry: {
    rules: {
      required: true
    },
    message: {
      required: "Visitor Entry Lane is Required"
    }
  },
  visitorExit: {
    rules: {
      required: true
    },
    message: {
      required: "Visitor Exit Lane is Required"
    }
  },
  deviceId: {
    rules: {
      required: true
    },
    message: {
      required: "Device ID is Required"
    }
  },
  sorting: {
    rules: {
      required: true
    },
    message: {
      required: "Sorting is Required"
    }
  },
  deviceType: {
    rules: {
      required: true
    },
    message: {
      required: "Device Type is Required"
    }
  },
  username: {
    rules: {
      required: true
    },
    message: {
      required: "Username is Required"
    }
  },
  password: {
    rules: {
      required: true
    },
    message: {
      required: "Password is Required"
    }
  }
};
