import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Grid, Typography } from "@material-ui/core";
// material-ui
import { Close as CloseIcon } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";
import { DetailsTab, TransactionTab } from "./components";
// stores
import { closeDrawer } from "stores/main/MainActions";
import { toggleEditResidentDialogOpen } from "stores/card/CardAction";
import { CustomTabs } from "components/Tabs";
import { toggleEditVehicleDialogOpen } from "stores/card/CardAction";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    paddingBottom: theme.spacing(1),
    flexShrink: 0,
    display: "flex",
  },
}));

const VehicleDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // functions
  const onClose = () => {
    dispatch(closeDrawer());
  };
  const cardId = useSelector((state) => state.main.toggleDrawer.parameterId);
  const card = useSelector((state) => {
    return state.firestore.data.vehicles
      ? state.firestore.data.vehicles[cardId]
      : null;
  });

  const [tabs] = useState([
    {
      value: "details",
      label: "Details",
      content: <DetailsTab />,
    },
    {
      value: "transactions",
      label: "Transactions",
      content: <TransactionTab />,
    },
  ]);
  const handleEditCard = () => {
    dispatch(toggleEditVehicleDialogOpen({ open: true, id: cardId }));
    onClose();
  };

  return (
    <div>
      <Grid container className={classes.header} justify="space-between">
        <Grid>
          <Button onClick={onClose} size="small">
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </Grid>
      </Grid>

      <Divider />

      <Grid
        direction="row"
        alignItems="flex-start"
        container
        justify="space-between"
        className={classes.content}
      >
        <Grid item>
          <Typography component="h1" variant="h3">
            {`${card.identity_number}`}
          </Typography>
        </Grid>
        {card.status !== "deleted" && (
          <Grid
            xs
            item
            alignItems="center"
            className={classes.header}
            container
            justify="flex-end"
            spacing={2}
          >
            <Grid item>
              {card.status !== "delete" && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleEditCard}
                >
                  Edit Card
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>

      <CustomTabs tabs={tabs} defaultTabIndex={0} />
    </div>
  );
};

export default VehicleDetails;
