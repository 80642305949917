import React from "react";
import { useSelector } from "react-redux";

// material-ui
import { Card, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    padding: theme.spacing(1),
  },
}));

const EntryPassLiftAccess = ({ liftAccessId }) => {
  // selector
  const liftAccessSelector = useSelector((state) => {
    return state.firestore.data.liftAccess
      ? state.firestore.data.liftAccess[liftAccessId]
      : null;
  });

  if (!liftAccessSelector)
    return (
      <Grid item md={12}>
        <Typography variant="caption" component="h1">
          No Lift Access
        </Typography>
      </Grid>
    );

  return (
    <Grid item md={12} key={liftAccessId}>
      <Typography variant="caption" component="h1">
        {`- ${
          liftAccessSelector.display_name
            ? liftAccessSelector.display_name
            : liftAccessSelector.name
        }`}
      </Typography>
    </Grid>
  );
};

const FalcoLiftAccess = ({ liftAccessId }) => {
  // selector
  const liftAccessSelector = useSelector((state) => {
    return state.firestore.data.liftAccess
      ? state.firestore.data.liftAccess[liftAccessId]
      : null;
  });

  if (!liftAccessSelector)
    return (
      <Grid item md={12}>
        <Typography variant="caption" component="h1">
          No Lift Access
        </Typography>
      </Grid>
    );

  return (
    <Grid item md={12} key={liftAccessId}>
      <Typography variant="caption" component="h1">
        {`- ${
          liftAccessSelector.display_name
            ? liftAccessSelector.display_name
            : liftAccessSelector.name
        }`}
      </Typography>
    </Grid>
  );
};

const SoyalLiftAccess = ({ liftAccessId }) => {
  // selector
  const liftAccessSelector = useSelector((state) => {
    return state.firestore.data.liftAccess
      ? state.firestore.data.liftAccess[liftAccessId]
      : null;
  });

  if (!liftAccessSelector)
    return (
      <Grid item md={12}>
        <Typography variant="caption" component="h1">
          No Lift Access
        </Typography>
      </Grid>
    );

  return (
    <Grid item md={12} key={liftAccessId}>
      <Typography variant="caption" component="h1">
        {`- ${
          liftAccessSelector.display_name
            ? liftAccessSelector.display_name
            : liftAccessSelector.name
        }`}
      </Typography>
    </Grid>
  );
};

const MicroEngineLiftAccess = ({ liftAccessId }) => {
  // selector
  const liftAccessSelector = useSelector((state) => {
    return state.firestore.data.liftAccess
      ? state.firestore.data.liftAccess[liftAccessId]
      : null;
  });

  if (!liftAccessSelector)
    return (
      <Grid item md={12}>
        <Typography variant="caption" component="h1">
          No Lift Access
        </Typography>
      </Grid>
    );

  return (
    <Grid item md={12} key={liftAccessId}>
      <Typography variant="caption" component="h1">
        {`- ${
          liftAccessSelector.display_name
            ? liftAccessSelector.display_name
            : liftAccessSelector.name
        }`}
      </Typography>
    </Grid>
  );
};

const LiftAccess = ({ isLift, liftAccess }) => {
  const classes = useStyles();

  // selector
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const vendorType = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[
          selectedVendor
        ].access_card_settings.type.toLowerCase()
      : null;
  });

  return (
    <Grid
      item
      container
      direction="row"
      spacing={2}
      md={4}
      alignItems="flex-start"
      justify="space-between"
    >
      <Grid item container spacing={1} md={12}>
        <Grid item md={12}>
          <Typography variant="h4" component="h1">
            Lift Access
          </Typography>
        </Grid>

        <Grid item md={12}>
          <Card className={classes.card}>
            <Grid container spacing={1}>
              {liftAccess && isLift ? (
                <React.Fragment>
                  {vendorType === "entrypass" && (
                    <EntryPassLiftAccess liftAccessId={liftAccess.id} />
                  )}

                  {vendorType === "falco" && (
                    <FalcoLiftAccess liftAccessId={liftAccess[0].id} />
                  )}

                  {vendorType === "soyal" && (
                    <SoyalLiftAccess liftAccessId={liftAccess.id} />
                  )}

                  {vendorType === "microengine" && (
                    <MicroEngineLiftAccess liftAccessId={liftAccess.id} />
                  )}
                </React.Fragment>
              ) : (
                <Grid item md={12}>
                  <Typography variant="caption" component="h1">
                    No Lift Access
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LiftAccess;
