import React, { useMemo } from "react";
import { useSelector } from "react-redux";

// components (local)
import DataTable from "./Table/DataTable";
import MicroEngineDataTable from "./Table/MicroEngineDataTable";
import ImportGroupDialog from "./ImportGroupDialog";

// material-ui
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1 },
  alignRight: { textAlign: "right" },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const AccessGroupTab = () => {
  const classes = useStyles();

  // selector
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const vendor = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor]
      : null;
  });

  const isMicroEngine = useMemo(
    () => vendor?.access_card_settings?.type?.toLowerCase() === "microengine",
    [vendor]
  );

  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={8}>
            <Typography component="h2" gutterBottom variant="overline">
              Access Group
            </Typography>
            <Typography component="h1" variant="h3">
              Manage all access group
            </Typography>
          </Grid>
          {isMicroEngine && (
            <Grid item xs={12} md={4} className={classes.alignRight}>
              <ImportGroupDialog />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          {isMicroEngine ? <MicroEngineDataTable /> : <DataTable />}
        </Grid>
      </Grid>
    </div>
  );
};

export default AccessGroupTab;
