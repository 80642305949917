import * as types from "./VehicleType";

export const openVehicleEditDialog = params => {
  return {
    type: types.OPEN_VEHICLE_EDIT_DIALOG,
    payload: params
  };
};

export const closeVehicleEditDialog = () => {
  return {
    type: types.CLOSE_VEHICLE_EDIT_DIALOG
  };
};
