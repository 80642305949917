import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import PerfectScrollBar from "react-perfect-scrollbar";

// components (global)
import { Page } from "components/Pages";

// material-ui
import { Fab, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@material-ui/icons";
import CsvButton from "./CsvButton";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const CsvDashboard = () => {
  const classes = useStyles();
  const buttonRef = useRef(null);

  // selector
  const roles = useSelector((state) => state.main.roles);

  // state
  const [btnArray, setBtnArray] = useState([]);

  useEffect(() => {
    if (roles) {
      setBtnArray([]);

      if (roles.access_card?.resident?.create) {
        setBtnArray((prevState) => [
          ...prevState,
          <Grid item key="entrypassCsv">
            <CsvButton dashboardButton name={"Convert EntryPass"} />
          </Grid>,
        ]);
      }

      if (roles.access_card?.resident?.create) {
        setBtnArray((prevState) => [
          ...prevState,
          <Grid item key="soyalCsv">
            <CsvButton dashboardButton name={"Convert Soyal"} />
          </Grid>,
        ]);
      }
    }
  }, [roles]);

  // inView
  const [refL, inViewL] = useInView({
    /* Optional options */
    threshold: 0,
  });
  const [refR, inViewR] = useInView({
    /* Optional options */
    threshold: 0,
  });

  // functions
  const scrollLeft = () => {
    buttonRef.current._container.scrollLeft =
      buttonRef.current._container.scrollLeft - 200;
  };

  const scrollRight = () => {
    buttonRef.current._container.scrollLeft =
      buttonRef.current._container.scrollLeft + 200;
  };

  return (
    <Page className={classes.root} title="Dashboard">
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12}>
          <Grid
            container
            spacing={4}
            direction="row"
            justify="flex-start"
            alignItems="center"
            component={PerfectScrollBar}
            wrap="nowrap"
            ref={buttonRef}
          >
            <Grid item component="div" ref={refL} />
            {!inViewL && (
              <Grid item className={classes.scrollLeftButton}>
                <Fab
                  size="small"
                  color="primary"
                  aria-label="add"
                  onClick={scrollLeft}
                >
                  <KeyboardArrowLeftIcon />
                </Fab>
              </Grid>
            )}
            {btnArray}
            <Grid item className={classes.scrollRightButton}>
              {!inViewR && (
                <Fab
                  size="small"
                  color="primary"
                  aria-label="add"
                  onClick={scrollRight}
                >
                  <KeyboardArrowRightIcon />
                </Fab>
              )}
            </Grid>
            <Grid item component="div" ref={refR}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default CsvDashboard;
