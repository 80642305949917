import React, { useMemo, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { filter, includes, orderBy, map } from "lodash";
import moment from "moment";

import { useSnackbar } from "notistack";
import { useFirestore } from "react-redux-firebase";
import Lottie from "react-lottie";
import DateFnsUtils from "@date-io/date-fns";
import animationData from "components/lotties/car-loop";
// components (global)

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  Button,
  Grid,
  Chip,
  Paper,
  LinearProgress,
  MenuItem,
  Box,
  Fab,
} from "@material-ui/core";
import MuiTooltip from "@material-ui/core/Tooltip";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { OverlayWithText } from "components/Box";

import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Sorting,
  FilterRow,
  Selection,
  Toolbar,
  Item,
  Paging,
  Scrolling,
  Pager,
} from "devextreme-react/data-grid";

// stores
import { openDrawer } from "stores/main/MainActions";
//styles
const Tooltip = withStyles((theme) => ({
  arrow: { color: "#e9e9e9" },
  tooltip: {
    backgroundColor: "#e9e9e9",
    maxWidth: 450,
    border: "1px solid #fff",
    padding: theme.spacing(2),
    borderRadius: 4,
    color: "#333",
    fontSize: "14px",
  },
}))(MuiTooltip);

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  link: {
    marginRight: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  search: { width: "100%", marginBottom: theme.spacing(2) },
  paginate: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  title: {
    position: "relative",
    "&:after": {
      position: "absolute",
      bottom: -8,
      left: 0,
      content: '" "',
      height: 3,
      width: 48,
      backgroundColor: theme.palette.primary.main,
    },
  },
  green: { color: "#038c00" },
  grey: { color: "#979797" },
  red: { color: "#ad2424" },
  descriptionCell: {
    width: "250px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    whiteSpace: "normal",
  },
}));

const Logs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const exporterRef = useRef(null);
  const snackbar = useSnackbar();
  const firestore = useFirestore();
  // variable

  const selectedProperty = useSelector((state) => state.main.selectedProperty);

  const auditSelector = useSelector(
    (state) => state.firestore.ordered.audit_trails
  );
  const [isLoading, setIsLoading] = useState(false);
  const [auditTrailsData, setAuditTrailsData] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf("day").toDate());
  const [endDate, setEndDate] = useState(moment().endOf("day").toDate());
  const [sorting, setSorting] = useState([]);
  const [grouping, setGrouping] = useState([]);

  // state
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);

  // memo

  const auditTrails = useMemo(() => {
    let flatResult = [];
    if (auditTrailsData) {
      return map(auditTrailsData, (trail) => {
        return {
          ...trail,
          created_date: trail.created_at
            ? moment.unix(trail.created_at?.seconds).format("DD/MM/YYYY")
            : moment.unix(trail.created_at?.seconds).format("DD/MM/YYYY"),
          created_time: trail.created_at
            ? moment.unix(trail.created_at?.seconds).format("h:mm:ss a")
            : moment.unix(trail.created_at?.seconds).format("h:mm:ss a"),
          block_name: trail.values.unit?.block_name ?? "",
          unit_name: trail.values.unit?.unit_name ?? "",
        };
      });
    }
    // return orderBy(
    //   flatResult,
    //   ["created_at", "created_time"],
    //   ["desc", "desc"]
    // );
  }, [auditTrailsData]);
  // functions
  const handleStartDateChange = (date) => {
    if (date != null) {
      if (date > endDate) {
        snackbar.enqueueSnackbar(`start date cannot later than end date`, {
          variant: "error",
        });

        return;
      }

      const newDate = moment(date, "DD/MM/YYYY");
      setStartDate(newDate.toDate());
    }
  };

  const handleEndDateChange = (date) => {
    if (date != null) {
      if (startDate > date) {
        snackbar.enqueueSnackbar(`start date cannot later than end date`, {
          variant: "error",
        });

        return;
      }

      const newDate = moment(date, "DD/MM/YYYY");
      setEndDate(newDate.toDate());
    }
  };

  const handleRestSorting = () => {
    setSorting([]);
  };

  const handleOnSearch = async () => {
    setIsLoading(true);

    const trailSnapshot = await firestore
      .collection("ac_audit_trails")
      .where("values.property_id", "==", selectedProperty)
      .where("values.profile_type", "==", "resident")
      .where("created_at", ">=", startDate)
      .where("created_at", "<=", endDate)
      .orderBy("created_at", "desc")
      .get();

    setIsLoading(false);
    if (!trailSnapshot.empty) {
      const data = map(trailSnapshot.docs, (doc) => {
        const auditTrails = doc.data();
        return {
          ...auditTrails,
          id: doc.id,
        };
      });

      setAuditTrailsData(data);
    }
  };
  // functions
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const descriptionCell = (cellData) => (
    <Tooltip arrow title={cellData.value ?? ""} placement="bottom">
      <Box className={classes.descriptionCell}>{cellData.value ?? ""}</Box>
    </Tooltip>
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.reportOption}>
        <Grid item>
          <Paper>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                inputVariant="outlined"
                fullWidth
                size="small"
                format="dd/MM/yyyy"
                label="Start Date"
                value={startDate}
                clearable={false}
                onChange={handleStartDateChange}
              />
            </MuiPickersUtilsProvider>
          </Paper>
        </Grid>
        <Grid item>
          <Paper>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                inputVariant="outlined"
                fullWidth
                size="small"
                format="dd/MM/yyyy"
                label="End Date"
                value={endDate}
                clearable={false}
                onChange={handleEndDateChange}
              />
            </MuiPickersUtilsProvider>
          </Paper>
        </Grid>
        <Grid item>
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            onClick={handleOnSearch}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.reportDetail}>
        <Grid item>
          <Chip
            label={`Audit Trails Between ${moment(startDate).format(
              "DD/MM/YYYY"
            )} - ${moment(endDate).format("DD/MM/YYYY")}`}
          />
        </Grid>
        <Grid item>
          <Chip label={`Total Audit Trails ${auditTrails.length}`} />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item md={12}>
          {/* <Typography className={classes.title} variant="h5">
            {`Showing ${auditTrails.length} audit trails`}
          </Typography> */}
        </Grid>
      </Grid>
      <Paper>
        {isLoading && <LinearProgress />}
        <div className={classes.content}>
          <React.Fragment>
            <Paper>
              <DataGrid
                columnAutoWidth={false}
                dataSource={auditTrails}
                // onRowDblClick={onRowDblClick}
                ref={exporterRef}
                showColumnLines={false}
              >
                <FilterRow visible={true} />

                <Column caption="Date" dataField="created_date" />
                <Column caption="Time" dataField="created_time" />

                <Column caption="Action By" dataField="created_by.name" />
                <Column
                  width={300}
                  caption="Details"
                  dataField="description"
                  cellRender={descriptionCell}
                />
                <Column
                  caption="Card Owned By"
                  dataField="values.profile_name"
                />
                <Column caption="Block Name" dataField="block_name" />
                <Column caption="Unit Name" dataField="unit_name" />

                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  displayMode="full"
                  showPageSizeSelector={false}
                  showInfo={true}
                  showNavigationButtons={true}
                />
              </DataGrid>
            </Paper>
          </React.Fragment>
        </div>
      </Paper>
      <OverlayWithText
        loadingText="Loading Data"
        open={isLoading}
        animationData={animationData}
        height={150}
        width={300}
      />
    </div>
  );
};

export default Logs;
