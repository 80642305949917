import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { find, includes, orderBy } from "lodash";

// components (global)
import ButtonProgress from "components/ButtonProgress";
import DialogWrapper from "components/Dialogs/DialogWrapper";
import DialogTitleWrapper from "components/Dialogs/DialogTitleWrapper";

// firebase
import { useFirestore } from "react-redux-firebase";

// form
import { useForm } from "react-hook-form";

// helpers
import { accessRoleValidate } from "helpers/validation/accessRoleValidate";
import { Capitalized } from "helpers/textHelpers";

// material-ui
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const InviteModeratorDialog = () => {
  const snackbar = useSnackbar();
  const firestore = useFirestore();
  const { errors, handleSubmit, register, setValue } = useForm();

  // selector
  const roles = useSelector((state) => {
    return state.firestore.ordered.roles
      ? orderBy(state.firestore.ordered.roles, ["name"], ["asc"])
      : null;
  });
  const selectedProperty = useSelector((state) => state.main.selectedProperty);

  // effect
  useEffect(() => {
    register({ name: "roleId" }, { required: false });
  }, [register]);

  // state
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // functions
  const handleClose = () => {
    if (!submitting) {
      setOpen(false);
    }
  };

  const handleRoleChange = (e, value) => {
    setValue("roleId", value ? value.id : null);
  };

  const onSubmit = async (value) => {
    if (!submitting) {
      setSubmitting(true);

      const userCol = firestore.collection("users");
      const snapshot = await userCol
        .where("email", "==", value.email)
        .limit(1)
        .get();

      let userData;

      if (snapshot.docs.length === 0) {
        // New account
      } else {
        // Existing account
        userData = snapshot.docs[0].data();
        userData.id = snapshot.docs[0].id;

        if (userData.is_jagacard_super_admin) {
          snackbar.enqueueSnackbar(`${value.email} is Super Admin`, {
            variant: "error",
          });
          setSubmitting(false);
          return;
        }

        let properties = userData.properties ?? {};
        const cardAccess = userData.card_access
          ? userData.card_access[selectedProperty]
          : null;

        if (cardAccess) {
          // Already assigned access role
          const role = find(roles, (role) => role.id === cardAccess.role_id);
          snackbar.enqueueSnackbar(
            `${value.email} had been assigned to ${role.name}`,
            {
              variant: "error",
            }
          );
          setSubmitting(false);
          return;
        }

        if (!includes(properties, selectedProperty)) {
          properties.push(selectedProperty);

          userData = {
            ...userData,
            properties,
          };
        }

        const cardAccessData = {
          [selectedProperty]: {
            role_id: value.roleId,
          },
        };

        userData = {
          ...userData,
          card_access: {
            ...userData.card_access,
            ...cardAccessData,
          },
        };

        firestore
          .update(`users/${userData.id}`, userData)
          .then(() => {
            setSubmitting(false);
            handleClose();
            snackbar.enqueueSnackbar(`${value.email} had been invited`, {
              variant: "success",
            });
          })
          .catch((e) => {
            setSubmitting(false);
            handleClose();
            snackbar.enqueueSnackbar(e.message, {
              variant: "error",
              persist: false,
            });
          });
      }
    }
  };

  return (
    <React.Fragment>
      <Button
        //   disabled={!roles.resident_card?.create}
        aria-controls="addCard"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        + Invite
      </Button>

      <form
        noValidate
        xs={12}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <DialogWrapper size="xs" open={open}>
          <DialogTitleWrapper
            title="Invite Moderator"
            handleClose={handleClose}
          />
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <TextField
                  size="small"
                  id="email"
                  name="email"
                  label="Email *"
                  type="text"
                  variant="outlined"
                  autoComplete="off"
                  inputRef={register(accessRoleValidate.email.rules)}
                  helperText={
                    errors.email &&
                    accessRoleValidate.email.message[errors.email.type]
                  }
                  error={!!errors.email}
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <Autocomplete
                  autoHighlight
                  options={roles}
                  getOptionLabel={(option) =>
                    option.name ? Capitalized(option.name) : null
                  }
                  onChange={handleRoleChange}
                  renderInput={(params) => (
                    <TextField
                      error={!!errors.role}
                      helperText={
                        errors.role &&
                        accessRoleValidate.role.message[errors.role.type]
                      }
                      {...params}
                      label="Choose a type *"
                      fullWidth
                      size="small"
                      name="role"
                      autoComplete="off"
                      variant="outlined"
                      inputRef={register(accessRoleValidate.role.rules)}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <ButtonProgress
              type="submit"
              containName="Save"
              margin={false}
              loading={submitting}
              disabled={submitting}
              onClick={handleSubmit(onSubmit)}
            />
          </DialogActions>
        </DialogWrapper>
      </form>
    </React.Fragment>
  );
};

export default InviteModeratorDialog;
