import React from "react";

// helpers
import { settingsValidate } from "helpers/validation/settingsValidate";

// material-ui
import { Grid, TextField, Typography } from "@material-ui/core";

const EntryPass = ({ errors, register, settings }) => {
  return (
    <Grid container spacing={3}>
      <Grid item container md={4} spacing={2}>
        <Grid item md={12}>
          <Typography variant="overline">Visitor Lane</Typography>
        </Grid>
        <Grid item md={12}>
          <TextField
            size="small"
            id="visitorEntry"
            name="visitorEntry"
            label="Entry Door Name *"
            type="text"
            variant="outlined"
            autoComplete="off"
            defaultValue={
              settings && settings[0] && settings[0].entry
                ? settings[0].entry.door_name
                : ""
            }
            inputRef={register(settingsValidate.visitorEntry.rules)}
            helperText={
              errors.visitorEntry &&
              settingsValidate.visitorEntry.message[errors.visitorEntry.type]
            }
            error={!!errors.visitorEntry}
            fullWidth
          />
        </Grid>
        <Grid item md={12}>
          <TextField
            size="small"
            id="visitorExit"
            name="visitorExit"
            label="Exit Door Name *"
            type="text"
            variant="outlined"
            autoComplete="off"
            defaultValue={
              settings && settings[0] && settings[0].exit
                ? settings[0].exit.door_name
                : ""
            }
            inputRef={register(settingsValidate.visitorExit.rules)}
            helperText={
              errors.visitorExit &&
              settingsValidate.visitorExit.message[errors.visitorExit.type]
            }
            error={!!errors.visitorExit}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid item container md={4} spacing={2}>
        <Grid item md={12}>
          <Typography variant="overline">Motor Lane</Typography>
        </Grid>
        <Grid item md={12}>
          <TextField
            size="small"
            id="motorEntry"
            name="motorEntry"
            label="Entry Door Name *"
            type="text"
            variant="outlined"
            autoComplete="off"
            defaultValue={
              settings && settings[1] && settings[1].entry
                ? settings[1].entry.door_name
                : ""
            }
            inputRef={register}
            fullWidth
          />
        </Grid>
        <Grid item md={12}>
          <TextField
            size="small"
            id="motorExit"
            name="motorExit"
            label="Exit Door Name *"
            type="text"
            variant="outlined"
            autoComplete="off"
            defaultValue={
              settings && settings[1] && settings[1].exit
                ? settings[1].exit.door_name
                : ""
            }
            inputRef={register}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid item container md={4} spacing={2}>
        <Grid item md={12}>
          <Typography variant="overline">Pedestrian Lane</Typography>
        </Grid>
        <Grid item md={12}>
          <TextField
            size="small"
            id="pedestrianEntry"
            name="pedestrianEntry"
            label="Entry Door Name *"
            type="text"
            variant="outlined"
            autoComplete="off"
            defaultValue={
              settings && settings[2] && settings[2].entry
                ? settings[2].entry.door_name
                : ""
            }
            inputRef={register}
            fullWidth
          />
        </Grid>
        <Grid item md={12}>
          <TextField
            size="small"
            id="pedestrianExit"
            name="pedestrianExit"
            label="Exit Door Name *"
            type="text"
            variant="outlined"
            autoComplete="off"
            defaultValue={
              settings && settings[2] && settings[2].exit
                ? settings[2].exit.door_name
                : ""
            }
            inputRef={register}
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EntryPass;
