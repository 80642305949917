export const deviceValidate = {
  email: {
    rules: {
      required: true,
      pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
    },
    message: {
      required: "Email is Required",
      pattern: "Wrong Email Format",
    },
  },
  name: {
    rules: {
      required: true,
    },
    message: {
      required: "Name is Required",
    },
  },
  password: {
    rules: {
      required: true,
      minLength: 6,
    },
    message: {
      required: "Password is required",
      minLength: "Password is less than 6",
    },
  },
  serviceId: {
    rules: {
      required: true,
      maxLength: 10,
    },
    message: {
      required: "Service ID is Required",
      maxLength: "Service ID must less or equal than 10",
    },
  },
  portNo: {
    rules: {
      required: true,
      pattern: /^[0-9]*$/,
    },
    message: {
      required: "Port No. is Required",
      pattern: "Invalid Port No. Format",
      exists: "Port No. had been exists",
    },
  },
  abbreviation: {
    rules: {
      required: true,
    },
    message: {
      required: "Abbreviation is Required",
    },
  },
  purposeName: {
    rules: {
      required: true,
    },
    message: {
      required: "Purpose Name is Required",
    },
  },
  entryAction: {
    rules: {
      required: true,
    },
    message: {
      required: "Entry Action is Required",
    },
  },
  entryDoorName: {
    rules: {
      required: true,
    },
    message: {
      required: "Entry Door Name is Required",
    },
  },
  exitAction: {
    rules: {
      required: true,
    },
    message: {
      required: "Exit Action is Required",
    },
  },
  exitDoorName: {
    rules: {
      required: true,
    },
    message: {
      required: "Exit Door Name is Required",
    },
  },
  entryDeviceId: {
    rules: {
      required: true,
    },
    message: {
      required: "Entry Device ID is Required",
    },
  },
  entryDeviceSorting: {
    rules: {
      required: true,
      pattern: /^[0-1]*$/,
    },
    message: {
      required: "Entry Device Sorting is Required",
      pattern: "Invalid Entry Device Sorting Format",
    },
  },
  entryDeviceType: {
    rules: {
      required: true,
      pattern: /^\d+$/,
    },
    message: {
      required: "Entry Device Type is Required",
      pattern: "Invalid Entry Type Format",
    },
  },
  exitDeviceId: {
    rules: {
      required: true,
    },
    message: {
      required: "Exit Device ID is Required",
    },
  },
  exitDeviceSorting: {
    rules: {
      required: true,
      pattern: /^[0-1]*$/,
    },
    message: {
      required: "Exit Device Sorting is Required",
      pattern: "Invalid Exit Device Sorting Format",
    },
  },
  exitDeviceType: {
    rules: {
      required: true,
      pattern: /^\d+$/,
    },
    message: {
      required: "Exit Device Type is Required",
      pattern: "Invalid Exit Type Format",
    },
  },
};
