import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSnackbar } from "notistack";
import { useFirebase } from "react-redux-firebase";
import axios from "http-axios";

// material-ui
import {
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
  PlaylistAdd as PlaylistAddIcon,
  Sync as SyncIcon,
} from "@material-ui/icons";
import {
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// stores
import {
  toggleAddServiceDialogOpen,
  toggleEditDeviceDialogOpen,
} from "stores/setting/SettingAction";
import { cos } from "mathjs";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    userSelect: "none",
    whiteSpace: "normal",
    height: "100%",
    display: "inline-flex",
    flexDirection: "column",
    verticalAlign: "top",
    width: 500,
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down("xs")]: {
      width: 300,
    },
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(0.5, 2),
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
  },
  headerAction: {
    marginLeft: "auto",
  },
  inner: {
    padding: theme.spacing(2, 3),
  },
}));

const DeviceList = ({ title, subTitle, id, className, children, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const firebase = useFirebase();

  // state
  const [anchorEl, setAnchorEl] = useState(null);

  // selector
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const vendor = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor]
      : null;
  });

  // functions
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditDevice = () => {
    handleMenuClose();
    dispatch(toggleEditDeviceDialogOpen({ open: true, id: id }));
  };

  const handleSyncPurpose = async () => {
    handleMenuClose();
    snackbar.enqueueSnackbar(`Start syncing visitor purpose`, {
      variant: "info",
      preventDuplicate: true,
    });

    try {
      const token = await firebase.auth().currentUser.getIdToken();
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
      console.log(token);

      const data = {
        acUserId: id,
      };

      const res = await axios.post(`jagaCardSyncVisitorPurpose`, data, {
        headers: headers,
      });

      if (res.data.result === "error") {
        snackbar.enqueueSnackbar(`Error: ${res.data.message}`, {
          variant: "danger",
        });

        return;
      }

      snackbar.enqueueSnackbar(`Visitor purpose synced successfully`, {
        variant: "success",
      });
    } catch (e) {
      console.log(e.message);
      snackbar.enqueueSnackbar(`Error: ${e.message}. Please try again`, {
        variant: "danger",
      });
    }
  };

  const handleAddService = () => {
    handleMenuClose();
    dispatch(toggleAddServiceDialogOpen({ open: true, id: id }));
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.header}>
        <Grid container>
          <Grid item md={12}>
            <Typography color="inherit" variant="h5">
              {title}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography color="inherit" variant="caption">
              {subTitle}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.headerAction}>
          <Tooltip title="More options">
            <IconButton
              color="inherit"
              edge="end"
              variant="contained"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>

          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            elevation={1}
            onClose={handleMenuClose}
            open={Boolean(anchorEl)}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={handleEditDevice}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary="Edit Device" />
            </MenuItem>

            {vendor &&
              vendor.access_card_settings.type.toLowerCase() ===
                "entrypass" && (
                <MenuItem onClick={handleAddService}>
                  <ListItemIcon>
                    <PlaylistAddIcon />
                  </ListItemIcon>
                  <ListItemText primary="Add xSocket Service" />
                </MenuItem>
              )}

            <MenuItem onClick={handleSyncPurpose}>
              <ListItemIcon>
                <SyncIcon />
              </ListItemIcon>
              <ListItemText primary="Sync visitor purposes" />
            </MenuItem>
          </Menu>
        </div>
      </div>

      <div className={classes.content}>
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <div className={classes.inner}>{children}</div>
        </PerfectScrollbar>
      </div>
    </div>
  );
};

export default DeviceList;
