import React from "react";

// component (local)
import { ResidentCard } from "../../components";

// material-ui
import { Grid } from "@material-ui/core";

const ResidentTable = ({ cards, rowsPerPage, page }) => {
  return (
    <Grid container>
      {cards.map(card => (
        <ResidentCard
          key={card.id}
          id={card.id}
          unit_id={card.unit.unit_id}
          identity_number={card.identity_number}
          profile_name={card.profile_name}
          block_name={card.unit.block_name}
          unit_name={card.unit.unit_name}
          card_type={card.card_type}
          status={card.status}
        />
      ))}
    </Grid>
  );
};

export default ResidentTable;
