import React, { useState } from "react";
import { map } from "lodash";

//material-ui
import { Divider, Grid, Hidden, Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//styles
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  tabContainer: {
    marginTop: theme.spacing(2),
  },
  tabcontainerInActive: {
    display: "none",
  },
  swipeable: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(0),
  },
  divider: {
    border: 0,
    borderBottom: "1px solid #d0d0d0",
  },
}));

function TabContainer({ id, active, children }) {
  const classes = useStyles();
  return (
    <div
      key={id}
      className={!active ? classes.tabcontainerInActive : classes.tabContainer}
    >
      {children}
    </div>
  );
}

const CustomTabs = ({ tabs, defaultTabIndex, orientation = "horizontal" }) => {
  const classes = useStyles();

  // state
  const [selectedTab, setSelectedTab] = useState(
    defaultTabIndex ? defaultTabIndex : 0
  );

  // functions
  const handleChange = (event, value) => {
    setSelectedTab(value);
  };

  return (
    <div>
      {orientation === "horizontal" ? (
        <React.Fragment>
          <Tabs
            orientation={orientation}
            value={selectedTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {map(tabs, (tab) => (
              <Tab key={tab.value} label={tab.label} wrapped />
            ))}
          </Tabs>

          <Divider className={classes.divider} />

          {map(tabs, (tab, key) => (
            <TabContainer key={key} id={key} active={selectedTab === key}>
              {tab.content}
            </TabContainer>
          ))}
        </React.Fragment>
      ) : (
        <div className={classes.root}>
          <Hidden mdUp>
            <Grid container spacing={2} justify="center">
              <Grid item sm={12} xs={12}>
                <Tabs
                  value={selectedTab}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {map(tabs, (tab) => (
                    <Tab key={tab.value} label={tab.label} wrapped />
                  ))}
                </Tabs>
              </Grid>
              <Grid item sm={12} xs={12}>
                {map(tabs, (tab, key) => (
                  <TabContainer key={key} id={key} active={selectedTab === key}>
                    {tab.content}
                  </TabContainer>
                ))}
              </Grid>
            </Grid>
          </Hidden>

          <Hidden smDown>
            <Grid container spacing={2} justify="center">
              <Grid item md={2} sm={2} xs={12}>
                <Tabs
                  orientation={orientation}
                  value={selectedTab}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {map(tabs, (tab) => (
                    <Tab key={tab.value} label={tab.label} wrapped />
                  ))}
                </Tabs>
              </Grid>
              <Grid item md={10} sm={10} xs={12} className={classes.tabs}>
                {map(tabs, (tab, key) => (
                  <TabContainer key={key} id={key} active={selectedTab === key}>
                    {tab.content}
                  </TabContainer>
                ))}
              </Grid>
            </Grid>
          </Hidden>
        </div>
      )}
    </div>
  );
};

export default React.memo(CustomTabs);
