import React from "react";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useMediaQuery, Drawer, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// stores
import { closeDrawer } from "stores/main/MainActions";

// styles
const useStyles = makeStyles(theme => ({
  root: {},
  drawer: {
    padding: theme.spacing(3),
    backgroundColor: "#F7F7F7"
  },
  drawerClose: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  }
}));

const CustomDrawer = ({ uniqName, children, size = 0 }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  // selector
  const toggleDrawer = useSelector(state => state.main.toggleDrawer.toggle);

  // media query
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true
  });

  // variable
  const width = [25, 50, 75, 100];

  // functions
  const handleCloseDrawer = () => {
    dispatch(closeDrawer());
  };

  return (
    <Drawer
      open={toggleDrawer === uniqName}
      anchor="right"
      PaperProps={{
        className: classes.drawer,
        style: { width: `${isXsDown ? 100 : width[size]}%` }
      }}
      onClose={handleCloseDrawer}
    >
      {isXsDown && (
        <div className={classes.drawerClose}>
          {/* <IconButton onClick={handleCloseDrawer} size="small">
            <CloseIcon />
          </IconButton> */}
        </div>
      )}
      {toggleDrawer === uniqName && children}
    </Drawer>
  );
};

export default CustomDrawer;
