import React, { useEffect, useState } from "react";

// material-ui
import { Fade } from "@material-ui/core";

const FadeTransitions = ({ children }) => {
  const [fade, setFade] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => setFade(true), 100);
    return () => {
      setFade(false);
      clearTimeout(timer);
    };
  }, []);

  return (
    <Fade in={fade}>
      <div>{children}</div>
    </Fade>
  );
};

export default FadeTransitions;
