import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";

// global components
import { LoadingSpinnerFull as LoadingScreen } from "components";

// import { useFirebase } from "react-redux-firebase";
import queryString from "query-string";

export const userIsAuthenticated = connectedRouterRedirect({
  // A nice display name for this check
  wrapperDisplayName: "UserIsAuthenticated",
  AuthenticatingComponent: LoadingScreen,
  allowRedirectBack: true,

  // The url to redirect user to if they fail
  redirectPath: (state, ownProps) => "/login",
  authenticatingSelector: ({ firebase: { profile, auth, isInitializing } }) =>
    !auth.isLoaded || !profile.isLoaded || isInitializing === true,

  // If selector is true, wraaper will not redirect
  authenticatedSelector: ({ firebase: { auth, profile }, common }) =>
    auth.isLoaded && !auth.isEmpty && !profile.isEmpty
});

export const userIsNotAuthenticated = connectedRouterRedirect({
  // A nice display name for this check
  wrapperDisplayName: "UserIsNotAuthenticated",
  AuthenticatingComponent: LoadingScreen,
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // This sends the user either to the query param route if we have one,
  // or to the landing page if none is specified and the user is already logged in
  redirectPath: (state, ownProps) => {
    if (ownProps.location.search !== "") {
      const query = queryString.parse(ownProps.location.search);
      return query.redirect;
    }

    return "/dashboard";
  },

  authenticatingSelector: ({ firebase: { auth, isInitializing } }) =>
    !auth.isLoaded || isInitializing === true,
  // If selector is true, wrapper will not redirect
  // So if there is no user data, then we show the page
  authenticatedSelector: ({ firebase: { auth } }) =>
    auth.isLoaded && auth.isEmpty
});
