import React, { useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { filter, includes, orderBy, map } from "lodash";
// firebase
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";

// material-ui
import {
  Avatar,
  Card,
  Grid,
  IconButton,
  List,
  ListItemAvatar,
  ListItem,
  ListItemText,
  Box,
  Tooltip,
  Paper,
  Typography,
} from "@material-ui/core";
import { red, green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Sorting,
  FilterRow,
  Selection,
  Toolbar,
  Item,
  Paging,
  Scrolling,
  Pager,
} from "devextreme-react/data-grid";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  tabContent: { padding: theme.spacing(2) },
  title: {
    marginBottom: theme.spacing(1),
    position: "relative",
    "&:after": {
      position: "absolute",
      bottom: -8,
      left: 0,
      content: '" "',
      height: 3,
      width: 48,
      backgroundColor: theme.palette.primary.main,
    },
  },
  card: {
    padding: theme.spacing(1),
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
  },
  iconRed: {
    backgroundColor: red[500],
  },
  iconGreen: {
    backgroundColor: green[500],
  },
  inline: {
    marginRight: theme.spacing(3),
  },
  descriptionCell: {
    width: "400px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    whiteSpace: "normal",
  },
}));

const AuditTrailsTab = () => {
  const classes = useStyles();
  const exporterRef = useRef(null);
  // selector
  const cardId = useSelector((state) => state.main.toggleDrawer.parameterId);
  const auditTrails = useSelector(
    (state) => state.firestore.ordered.audit_trails
  );

  // firestore
  useFirestoreConnect(() => [
    {
      collection: `ac_audit_trails`,
      where: [["profile.id", "==", cardId]],
      orderBy: [["created_at", "desc"]],
      limit: 20,
      storeAs: "audit_trails",
    },
  ]);
  const auditTrailsData = useMemo(() => {
    let flatResult = [];
    if (auditTrails) {
      return map(auditTrails, (trail) => {
        return {
          ...trail,
          created_date: trail.created_at
            ? moment.unix(trail.created_at?.seconds).format("DD/MM/YYYY")
            : moment.unix(trail.created_at?.seconds).format("DD/MM/YYYY"),
          created_time: trail.created_at
            ? moment.unix(trail.created_at?.seconds).format("h:mm:ss a")
            : moment.unix(trail.created_at?.seconds).format("h:mm:ss a"),
        };
      });
    }
  }, [auditTrails]);

  if (!isLoaded(auditTrails)) return null;
  const descriptionCell = (cellData) => (
    <Tooltip arrow title={cellData.value ?? ""} placement="bottom">
      <Box className={classes.descriptionCell}>{cellData.value ?? ""}</Box>
    </Tooltip>
  );

  return (
    <React.Fragment>
      <Grid direction="row" alignItems="center" container justify="center">
        <Grid item md={12}>
          <Typography component="h2" variant="h6" className={classes.title}>
            Showing latest 20 audit trails
          </Typography>
        </Grid>
      </Grid>

      <React.Fragment>
        <Paper>
          <DataGrid
            columnAutoWidth={false}
            HorizontalAlignment="Stretch"
            VerticalAlignment="Stretch"
            dataSource={auditTrailsData}
            // onRowDblClick={onRowDblClick}
            ref={exporterRef}
            showColumnLines={false}
          >
            <FilterRow visible={false} />

            <Column width={150} caption="Date" dataField="created_date" />
            <Column width={150} caption="Time" dataField="created_time" />
            <Column width={150} caption="Action" dataField="action" />

            <Column
              width={150}
              caption="Action By"
              dataField="created_by.name"
            />
            <Column
              caption="Details"
              dataField="description"
              cellRender={descriptionCell}
            />

            <Paging defaultPageSize={10} />
            <Pager
              visible={true}
              displayMode="full"
              showPageSizeSelector={false}
              showInfo={true}
              showNavigationButtons={true}
            />
          </DataGrid>
        </Paper>
      </React.Fragment>
    </React.Fragment>
  );
};

export default AuditTrailsTab;
