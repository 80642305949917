export const TOGGLE_EDIT_DEVICE_DIALOG_OPEN = "TOGGLE_EDIT_DEVICE_DIALOG_OPEN";
export const TOGGLE_EDIT_DEVICE_DIALOG_CLOSE =
  "TOGGLE_EDIT_DEVICE_DIALOG_CLOSE";

export const TOGGLE_ADD_SERVICE_DIALOG_OPEN = "TOGGLE_ADD_SERVICE_DIALOG_OPEN";
export const TOGGLE_ADD_SERVICE_DIALOG_CLOSE =
  "TOGGLE_ADD_SERVICE_DIALOG_CLOSE";

export const TOGGLE_EDIT_SERVICE_DIALOG_OPEN =
  "TOGGLE_EDIT_SERVICE_DIALOG_OPEN";
export const TOGGLE_EDIT_SERVICE_DIALOG_CLOSE =
  "TOGGLE_EDIT_SERVICE_DIALOG_CLOSE";

export const TOGGLE_EDIT_DOOR_ACCESS_DIALOG_OPEN =
  "TOGGLE_EDIT_DOOR_ACCESS_DIALOG_OPEN";
export const TOGGLE_EDIT_DOOR_ACCESS_DIALOG_CLOSE =
  "TOGGLE_EDIT_DOOR_ACCESS_DIALOG_CLOSE";

export const TOGGLE_EDIT_FLOOR_ACCESS_DIALOG_OPEN =
  "TOGGLE_EDIT_FLOOR_ACCESS_DIALOG_OPEN";
export const TOGGLE_EDIT_FLOOR_ACCESS_DIALOG_CLOSE =
  "TOGGLE_EDIT_FLOOR_ACCESS_DIALOG_CLOSE";

export const TOGGLE_EDIT_VISIT_PURPOSE_ACTION_DIALOG_OPEN =
  "TOGGLE_EDIT_VISIT_PURPOSE_ACTION_DIALOG_OPEN";
export const TOGGLE_EDIT_VISIT_PURPOSE_ACTION_DIALOG_CLOSE =
  "TOGGLE_EDIT_VISIT_PURPOSE_ACTION_DIALOG_CLOSE";
