import React from "react";

// components (global)
import { Page } from "components/Pages";

//
import Transaction from "./components/Transaction";

// material-ui
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(3),
  },
  content: {},
}));

const Report = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Report">
      <Container maxWidth={false}>
        <Grid direction="row" alignItems="center" container justify="center">
          <Grid item md={12}>
            <Typography component="h2" gutterBottom variant="overline">
              Report
            </Typography>
            <Typography component="h1" variant="h3">
              Transaction
            </Typography>
          </Grid>
        </Grid>

        <Transaction />
      </Container>
    </Page>
  );
};

export default Report;
