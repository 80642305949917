export const visitorCardValidate = {
  identityNumber: {
    rules: {
      required: true,
    },
    message: {
      required: "Card No. is Required",
      exists: "Card No. had been exists",
    },
  },
  profileName: {
    rules: {
      required: true,
    },
    message: { required: "Name is Required" },
  },
  cardType: {
    rules: {
      required: true,
    },
    message: { required: "Card Type is Required" },
  },
  passNumber: {
    rules: {
      required: true,
    },
    message: {
      required: "Pass Number is Required",
      exists: "Pass Number had been exists",
    },
  },
};
