import React from "react";
import moment from "moment";

// material-ui
import {
  AccessAlarm as AccessAlarmIcon,
  MoreHoriz as MoreHorizIcon,
} from "@material-ui/icons";
import {
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  avatar: {
    width: 92,
    height: 92,
    minWidth: 92,
    minHeight: 92,
    backgroundColor: theme.palette.grey[500],
    position: "absolute",
    marginTop: -46,
    marginLeft: theme.spacing(2),
  },
  card: {
    marginTop: 46,
  },
  cardContent: {
    marginTop: theme.spacing(6),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(0),
  },
  actionButton: {
    padding: 0,
  },
  showMoreButton: {
    marginLeft: "auto",
  },
}));

const MemberCard = ({ member }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Skeleton variant="circle" className={classes.avatar} />
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="h5" component="h2">
          {member.name}
        </Typography>

        <Divider className={classes.divider} variant="fullWidth" />

        <div style={{ marginTop: "10px" }}>
          <Grid container alignItems="center" justify="flex-start" spacing={1}>
            <Grid item>
              <AccessAlarmIcon fontSize="small" style={{ padding: "0px" }} />
            </Grid>
            <Grid item>
              <Typography variant="body2" style={{ marginBottom: "5px" }}>
                {moment(member.created_at?.toDate()).format("DD MMM YYYY")}
              </Typography>
            </Grid>
          </Grid>
        </div>

        <Chip
          style={{
            marginTop: "16px",
            color: "white",
            backgroundColor: "#515b60",
          }}
          size="small"
          label={member.role ? member.role.name : "No Access Role"}
        />
      </CardContent>
      <CardActions className={classes.actionButton} disableSpacing>
        <IconButton className={classes.showMoreButton} aria-label="show more">
          <MoreHorizIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default MemberCard;
