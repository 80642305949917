import React, { useState } from "react";
import { useSelector } from "react-redux";

import AccessGroup from "../../../AccessGroup";
import AccessLevel from "../../../AccessLevel";
import LiftAccess from "../../../LiftAccess";

// material-ui
import {
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    tabContent: { padding: theme.spacing(2) },
  },
}));

const DetailsTab = () => {
  const classes = useStyles();

  // selector
  const cardId = useSelector((state) => state.main.toggleDrawer.parameterId);
  const card = useSelector((state) => {
    return state.firestore.data.resident_cards
      ? state.firestore.data.resident_cards[cardId]
      : null;
  });
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const accessCardSettings = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor].access_card_settings
      : null;
  });
  const unitAccessSelector = useSelector(
    (state) => state.firestore.data.unit_access
  );

  // state
  const [unitAccess] = useState(
    card.unit && unitAccessSelector && unitAccessSelector[card.unit.unit_id]
      ? unitAccessSelector[card.unit.unit_id].resident
      : null
  );

  return (
    <Grid
      alignItems="flex-start"
      container
      justify="space-between"
      spacing={4}
      className={classes.tabContent}
    >
      <Grid item md={6} xs={12}>
        <Grid item md={12} xs={12}>
          <Typography variant="h4" gutterBottom>
            Card Details
          </Typography>
        </Grid>

        <Grid item md={12} xs={12}>
          <Card>
            <List component="nav">
              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="h6">Unit</Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography variant="caption">
                        {card.unit
                          ? `${card.unit.block_name}, ${card.unit.unit_name}`
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="h6">Type</Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography variant="caption" gutterBottom>
                        {card.profile_type.toUpperCase()}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="h6">Card ID</Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography variant="caption" gutterBottom>
                        {card.profile_code}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="h6">Card No.</Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography variant="caption" gutterBottom>
                        {card.identity_number}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="h6">Card Name</Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography variant="caption" gutterBottom>
                        {card.profile_name
                          ? card.profile_name.toUpperCase()
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="h6">Card Type</Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography variant="caption" gutterBottom>
                        {card.card_type.toUpperCase()}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="h6">Status</Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography variant="caption" gutterBottom>
                        {card.status.toUpperCase()}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="h6">Anti-passback</Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography variant="caption" gutterBottom>
                        {card.anti_passback ? "YES" : "NO"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="h6">Lift Card</Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography variant="caption" gutterBottom>
                        {card.is_lift ? "YES" : "NO"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            </List>
          </Card>
        </Grid>
      </Grid>

      <Grid item container md={6} xs={12}>
        <Grid item md={12} xs={12}>
          <Typography variant="h4" gutterBottom>
            Other Details
          </Typography>
        </Grid>

        <Grid item md={12} xs={12}>
          <Card>
            <List>
              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={5} xs={4}>
                      <Typography variant="h6">Contact No.</Typography>
                    </Grid>
                    <Grid item md={7} xs={8}>
                      <Typography variant="caption">
                        {card.contact_number ? card.contact_number : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={5} xs={4}>
                      <Typography variant="h6">Vehicle No.</Typography>
                    </Grid>
                    <Grid item md={7} xs={8}>
                      <Typography variant="caption">
                        {card.vehicle_info.number
                          ? card.vehicle_info.number
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={5} xs={4}>
                      <Typography variant="h6">Vehicle Make</Typography>
                    </Grid>
                    <Grid item md={7} xs={8}>
                      <Typography variant="caption">
                        {card.vehicle_info.make ? card.vehicle_info.make : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={5} xs={4}>
                      <Typography variant="h6">Vehicle Model</Typography>
                    </Grid>
                    <Grid item md={7} xs={8}>
                      <Typography variant="caption">
                        {card.vehicle_info.model
                          ? card.vehicle_info.model
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={5} xs={4}>
                      <Typography variant="h6">Vehicle Colour</Typography>
                    </Grid>
                    <Grid item md={7} xs={8}>
                      <Typography variant="caption">
                        {card.vehicle_info.colour
                          ? card.vehicle_info.colour
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={5} xs={4}>
                      <Typography variant="h6">Sticker Serial No.</Typography>
                    </Grid>
                    <Grid item md={7} xs={8}>
                      <Typography variant="caption">
                        {card.sticker_serial_no ? card.sticker_serial_no : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={5} xs={4}>
                      <Typography variant="h6">Parking Lot No.</Typography>
                    </Grid>
                    <Grid item md={7} xs={8}>
                      <Typography variant="caption">
                        {card.parking_lot_no
                          ? card.parking_lot_no.toUpperCase()
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={5} xs={4}>
                      <Typography variant="h6">
                        Suspended Days Remaining
                      </Typography>
                    </Grid>
                    <Grid item md={7} xs={8}>
                      <Typography variant="caption">
                        {card.blocked_settings.enable
                          ? card.blocked_settings.remaining_balance
                            ? card.blocked_settings.remaining_balance
                            : "-"
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            </List>
          </Card>
        </Grid>
      </Grid>

      <Grid item container md={12} spacing={1} justify="space-between">
        <AccessGroup accessGroups={card?.access?.access_groups} />

        {accessCardSettings?.type?.toLowerCase() === "entrypass" && (
          <AccessLevel accessLevel={card?.access?.access_level} />
        )}
        {accessCardSettings?.type?.toLowerCase() === "entrypass" && (
          <LiftAccess
            isLift={card.is_lift}
            liftAccess={card?.access?.lift_access}
          />
        )}

        {/* <LiftAccess
          isLift={card.is_lift}
          liftAccess={unitAccess?.lift_access}
        /> */}
      </Grid>
    </Grid>
  );
};

export default DetailsTab;
