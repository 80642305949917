import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { orderBy, find } from "lodash";
import { useFormContext, Controller } from "react-hook-form";

// material-ui
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const UnitAutocomplete = ({ required = true, defaultValue }) => {
  const { errors, control } = useFormContext();

  // selector
  const unitsSelector = useSelector((state) => {
    return state.firestore.ordered.units
      ? orderBy(
          state.firestore.ordered.units,
          ["block_name", "house_number"],
          ["asc", "asc"]
        )
      : null;
  });

  // memo
  const defaultValueOption = useMemo(() => {
    return find(unitsSelector, (unit) => unit.id === defaultValue);
  }, [defaultValue, unitsSelector]);

  return (
    <Controller
      as={
        <Autocomplete
          size="small"
          id="unit-list"
          options={unitsSelector}
          groupBy={(option) => option.block_name}
          getOptionLabel={(option) =>
            option.house_number ? option.house_number : ""
          }
          renderInput={(params) => (
            <TextField
              error={!!errors.unit}
              helperText={errors.unit && "Unit is Required"}
              {...params}
              label="Choose an unit *"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: "disabled",
              }}
            />
          )}
        />
      }
      rules={{ required: required }}
      onChange={([, selected]) => selected}
      control={control}
      name="unit"
      defaultValue={defaultValueOption ? defaultValueOption : null}
    />
  );
};

export default React.memo(UnitAutocomplete);
