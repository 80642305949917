import * as types from "./VehicleType";

const initialState = {
  vehicle_edit_dialog: { open: false, id: "", image: "" }
};

const VehicleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.OPEN_VEHICLE_EDIT_DIALOG:
      return {
        ...state,
        vehicle_edit_dialog: {
          ...state.vehicle_edit_dialog,
          ...action.payload
        }
      };
    case types.CLOSE_VEHICLE_EDIT_DIALOG:
      return {
        ...state,
        vehicle_edit_dialog: { open: false, id: "" }
      };
    default:
      return state;
  }
};

export default VehicleReducer;
