import React from "react";
import { useSelector } from "react-redux";

// components (local)
import { DoorAccessCard } from "./components";

// material-ui
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles(theme => ({
  root: { marginTop: theme.spacing(2) }
}));

const DoorAccessTable = () => {
  const classes = useStyles();

  // selector
  const blockAccessSelector = useSelector(
    state => state.firestore.data.blockAccess
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {blockAccessSelector
          ? Object.keys(blockAccessSelector).map(key => {
              const blockAccess = blockAccessSelector[key];

              return (
                <DoorAccessCard blockAccess={blockAccess} key={key} id={key} />
              );
            })
          : null}
      </Grid>
    </div>
  );
};

export default DoorAccessTable;
