import React from "react";
import clsx from "clsx";

import SidebarNavMenu from "./SidebarNavMenu";

// material-ui
import { Box, List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles(theme => ({
  root: { padding: 0 },
  item: {
    flexDirection: "column",
    display: "flex",
    paddingBottom: 0,
    paddingTop: 0
  }
}));

const SidebarNav = ({ pages, className, ...rest }) => {
  const classes = useStyles();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map(page => (
        <ListItem className={classes.item} disableGutters key={page.title}>
          <Box style={{ width: "100%" }}>
            <SidebarNavMenu page={page} />
          </Box>
        </ListItem>
      ))}
    </List>
  );
};

export default SidebarNav;
