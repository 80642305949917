import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { useSnackbar } from "notistack";
import chunk from "lodash/chunk";
import ceil from "lodash/ceil";
import parseInt from "lodash/parseInt";

//components
import DialogWrapper from "components/Dialogs/DialogWrapper";
import DialogTitleWrapper from "components/Dialogs/DialogTitleWrapper";
import FileDropZone from "./FileDropZone";
import DataTable from "./DataTable";

//material-ui
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//material-icon
import ImportExportIcon from "@material-ui/icons/ImportExport";

//helpers
//assets
import uploadImg from "assets/images/ic-upload.png";
import uploadSuccessfullImg from "assets/images/ic-successfully.png";

const csv = require("csvtojson");

//styles
const useStyles = makeStyles((theme) => ({
  dialogContent: { padding: "0" },
  content: { padding: "40px 10px" },
  link: { color: "#AD2424", fontSize: "14px", textDecoration: "none" },
}));

function ImportGroupDialog() {
  const firestore = useFirestore();
  const snackbar = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [csvJsonData, setCsvJsonData] = useState([]);
  const [tableHeader, setTableHeader] = useState();
  const [step, setStep] = useState(0);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);

  const handleClose = () => {
    setOpenDialog(false);
    setStep(0);
    setTableHeader();
    setCsvJsonData([]);
  };

  const handleReUpload = () => {
    setStep(0);
    setCsvJsonData([]);
  };

  const handleUploadCSV = () => {
    setStep(2);

    if (csvJsonData.length === 0) {
      setStep(3);
      return;
    }

    let chunkSize = 5;
    let chunks = chunk(csvJsonData, chunkSize);
    const accessGroupRef = firestore
      .collection("ac_vendors")
      .doc(selectedVendor)
      .collection("access_groups");

    chunks.forEach(async (groupList, i) => {
      const batch = firestore.batch();

      try {
        groupList.forEach((group) => {
          batch.set(accessGroupRef.doc(), {
            created_at: new Date(),
            created_by: {
              user_id: auth.uid,
              name: profile.name,
            },
            name: group.name ?? "",
            description: group.description ?? "",
            door_group_id: parseInt(group.door_group_id),
          });
        });

        await batch.commit();
        setUploadPercentage(ceil(((i + 1) / chunks.length) * 100));

        if (i >= chunks.length - 1) {
          setStep(3);
        }
      } catch (e) {
        snackbar.enqueueSnackbar(e.message, { variant: "error" });
        handleClose();
      }
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    const [file] = acceptedFiles;
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => {
      const csvStr = reader.result;
      csv({
        noheader: true,
        output: "json",
      })
        .fromString(csvStr)
        .then((csvRows) => {
          const toJson = [];
          const toJsonHeader = [];
          csvRows.forEach((aCsvRow, i) => {
            if (i !== 0) {
              const builtObject = {};

              Object.keys(aCsvRow).forEach((aKey) => {
                const valueToAddInBuiltObject = aCsvRow[aKey];
                const keyToAddInBuiltObject = csvRows[0][aKey];
                builtObject[
                  keyToAddInBuiltObject
                ] = valueToAddInBuiltObject.toString().trim();
              });
              builtObject["id"] = i;

              toJson.push(builtObject);
            } else {
              Object.keys(aCsvRow).forEach((aKey) => {
                const builtObject = {};
                builtObject["name"] = aCsvRow[aKey];
                builtObject["title"] = aCsvRow[aKey];
                toJsonHeader.push(builtObject);
              });
            }
          });

          setTableHeader(toJsonHeader);
          setCsvJsonData(toJson);

          if (toJson.length > 0) {
            setStep(1);
          }
        });
    };
  }, []);

  const progressContent = [
    <FileDropZone onDrop={onDrop} />,
    <DataTable
      rows={csvJsonData}
      columns={tableHeader}
      onCommitChanges={setCsvJsonData}
    />,
    <UploadProgressPage uploadPercentage={uploadPercentage} />,
    <UploadSuccessPage />,
  ];

  const progressAction = [
    null,
    <React.Fragment>
      <Button onClick={handleReUpload} color="primary">
        Back
      </Button>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        onClick={handleUploadCSV}
      >
        {"Save & Confirm"}
      </Button>
    </React.Fragment>,
    null,
    <Button
      variant="contained"
      color="primary"
      type="submit"
      onClick={handleClose}
    >
      Done
    </Button>,
  ];

  return (
    <React.Fragment>
      <Button
        aria-controls="importCode"
        aria-haspopup="true"
        onClick={() => {
          setOpenDialog(true);
        }}
        variant="contained"
        color="primary"
        startIcon={<ImportExportIcon />}
      >
        Upload CSV
      </Button>
      {openDialog && (
        <form autoComplete="off" noValidate={true}>
          <DialogWrapper open={openDialog}>
            <DialogTitleWrapper
              handleClose={handleClose}
              title="Import Access Group"
            />
            <DialogContent dividers>{progressContent[step]}</DialogContent>
            <DialogActions>{progressAction[step]}</DialogActions>
          </DialogWrapper>
        </form>
      )}
    </React.Fragment>
  );
}

const UploadProgressPage = ({ uploadPercentage }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.content}
    >
      <Grid item>
        <Box component="img" src={uploadImg} alt={uploadImg}></Box>
      </Grid>
      <Grid item>
        <Typography compnent="p" variant="h4">
          {uploadPercentage} %
        </Typography>
        <Typography compnent="p" variant="h6">
          Your file has been uploading, please wait for a moment.
        </Typography>
      </Grid>
    </Grid>
  );
};

const UploadSuccessPage = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.content}
    >
      <Grid item>
        <Box
          component="img"
          src={uploadSuccessfullImg}
          alt={uploadSuccessfullImg}
        ></Box>
      </Grid>
      <Grid item>
        <Typography compnent="p" variant="h4">
          Successfully
        </Typography>
        <Typography compnent="p" variant="h6">
          Your file has been uploaded sucessfully.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default React.memo(ImportGroupDialog);
