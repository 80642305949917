import React, { useState } from "react";
import { useSelector } from "react-redux";

// components (global)
import DashboardButton from "components/Button/DashboardButton";

// components (local)
import { ManualAddDialog, UploadCsvDialog } from "./components";

// material-ui
import {
  Add as AddIcon,
  Publish as PublishIcon,
  CreditCard as CreditCardIcon,
} from "@material-ui/icons";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";

const AddResident = ({ dashboardButton }) => {
  // state
  const [anchorEl, setAnchorEl] = useState(null);
  const [openManualKey, setOpenManualKey] = useState(false);
  const [openUploadCSV, setOpenUploadCSV] = useState(false);

  // selector
  const roles = useSelector((state) => state.main.roles);

  // functions
  const handleClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleManualKeyInOpen = () => {
    setOpenManualKey(true);
    handleClose();
  };

  const handleManualKeyInClose = () => {
    setOpenManualKey(false);
  };

  const handleUploadCSVOpen = () => {
    setOpenUploadCSV(true);
    handleClose();
  };

  const handleUploadCSVClose = () => {
    setOpenUploadCSV(false);
  };

  return (
    <React.Fragment>
      {dashboardButton ? (
        <DashboardButton
          icon={<CreditCardIcon />}
          onClick={handleClick}
          title="Add Resident Card"
        />
      ) : (
        <Button
          disabled={!roles.access_card?.resident?.create}
          aria-controls="addCard"
          aria-haspopup="true"
          onClick={handleClick}
          variant="contained"
          color="primary"
        >
          + Add
        </Button>
      )}

      <Menu
        id="add-card-menu"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem onClick={handleManualKeyInOpen}>
          <ListItemIcon>
            <AddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Manual Add" />
        </MenuItem>
        <MenuItem onClick={handleUploadCSVOpen}>
          <ListItemIcon>
            <PublishIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Upload CSV File" />
        </MenuItem>
      </Menu>

      {openManualKey && (
        <ManualAddDialog
          open={openManualKey}
          handleDialogClose={handleManualKeyInClose}
        />
      )}

      {openUploadCSV && (
        <UploadCsvDialog
          open={openUploadCSV}
          handleDialogClose={handleUploadCSVClose}
        />
      )}
    </React.Fragment>
  );
};

export default AddResident;
