import React, { useState } from "react";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";

// devexpress
import { Table, TableEditRow } from "@devexpress/dx-react-grid-material-ui";

// material-ui
import { FormControlLabel, TextField } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  checkbox: {
    alignItems: "center",
  },
}));

const AutoCompleteEditCell = (props) => {
  const { column, value: defaultValue } = props;

  // state
  const [selected, setSelected] = useState(defaultValue);

  // functions
  const handleChange = (e, value) => {
    if (value) {
      props.onValueChange(value);
      setSelected(value);
    }
  };

  return (
    <TableEditRow.Cell width={200} {...props}>
      {React.cloneElement(column.component, {
        onChange: handleChange,
        value: selected,
      })}
    </TableEditRow.Cell>
  );
};

const CheckboxEditCell = (props) => {
  const { column, value: defaultValue } = props;

  const classes = useStyles();

  // state
  const [checked, setChecked] = useState(defaultValue);

  // functions
  const handleChange = (event) => {
    props.onValueChange(event.target.checked ? "Yes" : "No");
    setChecked(event.target.checked);
  };

  return (
    <TableEditRow.Cell width={200} {...props} className={classes.checkbox}>
      {React.cloneElement(column.component, {
        onChange: handleChange,
        checked: Boolean(checked),
      })}
    </TableEditRow.Cell>
  );
};

const DateTimePickEditCell = (props) => {
  const { column, value: defaultValue, onValueChange } = props;

  // state
  const [currentDate, setCurrentDate] = useState(
    defaultValue ? moment(defaultValue, "DD/MM/YYYY").toDate() : null
  );

  // functions
  const handleChange = (date) => {
    if (date != null) {
      const newDate = moment(date, "DD/MM/YYYY");
      setCurrentDate(newDate.toDate());
      onValueChange(newDate.format("DD/MM/YYYY"));
    } else {
      setCurrentDate(null);
      onValueChange(null);
    }
  };

  return (
    <TableEditRow.Cell {...props}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {React.cloneElement(column.component, {
          onChange: handleChange,
          value: currentDate,
        })}
      </MuiPickersUtilsProvider>
    </TableEditRow.Cell>
  );
};

const TextFieldEditCell = (props) => {
  const { value: defaultValue, onValueChange } = props;

  // functions
  const handleChange = (event) => {
    onValueChange(event.target.value);
  };

  return (
    <TableEditRow.Cell {...props}>
      <TextField
        size="small"
        type="text"
        variant="outlined"
        autoComplete="off"
        inputProps={{
          defaultValue: defaultValue,
          onBlur: handleChange,
        }}
        fullWidth
      />
    </TableEditRow.Cell>
  );
};

const CustomEditCell = (props) => {
  const { column } = props;

  if (props.editingEnabled) {
    if (column.component?.type === Autocomplete) {
      return <AutoCompleteEditCell {...props} />;
    } else if (column.component?.type === FormControlLabel) {
      return <CheckboxEditCell {...props} />;
    } else if (column.component?.type === DatePicker) {
      return <DateTimePickEditCell {...props} />;
    } else {
      return <TextFieldEditCell {...props} />;
    }
  } else {
    const newProps = {
      ...props,
      editingenabled: props.editingEnabled.toString(),
    };

    delete newProps.editingEnabled;
    delete newProps.onValueChange;

    return <Table.Cell {...newProps} />;
  }
};

export default CustomEditCell;
