import React, { memo, useMemo, useState } from "react";

//assets
import profilePlaceholder from "assets/images/profile-placeholder.png";

// firebase
import { useFirebase } from "react-redux-firebase";

import { getUrlPath } from "helpers";

// material-ui
import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//styles
const useStyles = makeStyles(theme => ({
  //   profileAvatarSmall: {
  //     width: 32,
  //     height: 32
  //   },
  //   profileAvatarBig: { width: 36, height: 36 }
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  }
}));

// const getDownloadUrlPath = (gsFilePath, callback) => {
//   // const firebase = useFirebase();

//   try {
//     firebase
//       .storage()
//       .refFromURL(gsFilePath)
//       .getDownloadURL()
//       .then(url => callback(url));
//   } catch (err) {
//     // console.log(err);
//     callback(null, err);
//   }
// }

const AvatarImage = ({ fileName }) => {
  const classes = useStyles();

  //states
  const [image, setImage] = useState(profilePlaceholder);

  useMemo(() => {
    getUrlPath("IoT/vehicles", fileName, (url, err) => {
      if (!err) {
        setImage(url);
      } else {
        setImage(profilePlaceholder);
      }
    });
  }, [fileName, setImage]);

  return <Avatar variant="square" src={image} className={classes.large} />;
};

export default memo(AvatarImage);
