import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { map, orderBy } from "lodash";
import moment from "moment";

// devexpress
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedSorting,
  GroupingState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid as GridTable,
  GroupingPanel,
  Table,
  TableFilterRow,
  TableGroupRow,
  TableHeaderRow,
  TableFixedColumns,
  DragDropProvider,
} from "@devexpress/dx-react-grid-material-ui";
import { Template, TemplatePlaceholder } from "@devexpress/dx-react-core";
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Sorting,
  FilterRow,
  Selection,
  Toolbar,
  Item,
  Paging,
  Scrolling,
  Pager,
} from "devextreme-react/data-grid";
// material-ui
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Paper,
  Tooltip,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Menu as MenuIcon,
  RotateLeft as RotateLeftIcon,
} from "@material-ui/icons";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
}));

const TransactionTable = () => {
  const classes = useStyles();
  const theme = useTheme();
  // selector
  const transactionsSelector = useSelector(
    (state) => state.firestore.ordered.transactions
  );

  // memo
  const transactions = useMemo(() => {
    let flatResult = [];
    if (transactionsSelector) {
      flatResult = map(transactionsSelector, (transaction) => {
        return {
          ...transaction,
          block_name: transaction.block_name ? transaction.block_name : "-",
          unit_name: transaction.unit_name ? transaction.unit_name : "-",
          profile_name: transaction.profile_name
            ? transaction.profile_name
            : "-",
          // trans_date: transaction.transaction_date
          //   ? moment
          //       .unix(transaction.transaction_date?.seconds)
          //       .format("DD/MM/YYYY h:mm:ss a")
          //   : moment.unix(transaction.created_at?.seconds).format("DD/MM/YYYY"),
          // trans_time: transaction.transaction_date
          //   ? moment
          //       .unix(transaction.transaction_date?.seconds)
          //       .format("h:mm:ss a")
          //   : moment.unix(transaction.created_at?.seconds).format("h:mm:ss a"),
        };
      });
    }
    return orderBy(flatResult, ["trans_date", "trans_time"], ["desc", "desc"]);
  }, [transactionsSelector]);

  // state
  const [sorting, setSorting] = useState([]);
  const [grouping, setGrouping] = useState([]);
  const [columns] = useState([
    {
      name: "trans_date",
      title: "Date & Time",
    },
    {
      name: "block_name",
      title: "Block",
    },
    {
      name: "unit_name",
      title: "Unit",
    },
    {
      name: "profile_name",
      title: "Name",
    },
    {
      name: "identity_number",
      title: "Identity Number",
    },
    {
      name: "door_name",
      title: "Door",
    },
    {
      name: "message",
      title: "Event",
    },
    // {
    //   name: "description",
    //   title: "Description",
    // },
    // { name: "created_at", title: "Uploaded Date" },
  ]);
  const [columnExtensions] = useState([
    {
      columnName: "trans_date",
      groupingEnabled: false,
      width: 150,
    },
    {
      columnName: "block_name",
      width: 200,
    },
    {
      columnName: "unit_name",
      width: 200,
    },
    {
      columnName: "profile_name",
      width: 200,
    },
    {
      columnName: "identity_number",
      width: 150,
    },
    {
      columnName: "door",
      width: 150,
    },
    {
      columnName: "message",
      width: 150,
    },
    {
      columnName: "remark",
      sortingEnabled: false,
      groupingEnabled: false,
    },
    {
      columnName: "menuButton",
      filteringEnabled: false,
      sortingEnabled: false,
      groupingEnabled: false,
      align: "center",
      width: 60,
    },
  ]);

  const GroupIcon = (props) => {
    if (props.disabled) return null;
    else
      return (
        <IconButton onClick={props.onGroup}>
          <MenuIcon fontSize="small" />
        </IconButton>
      );
  };

  const ToolbarRoot = ({ ...restProps }) => {
    return (
      <Paper
        style={{ marginBottom: "4px" }}
        variant="elevation"
        elevation={1}
        component={Toolbar.Root}
        {...restProps}
      />
    );
  };

  const TableComponentBase = ({ ...restProps }) => {
    const classes = useStyles();
    return <Table.Table {...restProps} className={classes.table} />;
  };

  const GroupCellContent = ({ column, row }) => {
    switch (column.name) {
      case "checkedOut":
        return (
          <span>
            <b>{column.title}</b>: {row.value ? row.value : "Not Check Out"}
          </span>
        );
      default:
        return (
          <span>
            <b>{column.title}</b>: {row.value}
          </span>
        );
    }
  };

  const FilterCell = (props) => {
    const { filteringEnabled, column } = props;
    if (!filteringEnabled) {
      if (column.name === "menuButton")
        return (
          <TableFixedColumns.Cell
            showLeftDivider
            position={0}
            side="right"
            component={(props) => {
              return <TableHeaderRow.Cell {...props} />;
            }}
          />
        );
      else return <TableHeaderRow.Cell />;
    } else {
      if (column.type === "boolean") {
        return (
          <TableHeaderRow.Cell>
            <Select
              defaultValue=""
              id="select"
              fullWidth
              onChange={(e) => props.onFilter({ value: e.target.value })}
            >
              <MenuItem value="">&nbsp;</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </TableHeaderRow.Cell>
        );
      } else return <TableFilterRow.Cell {...props} />;
    }
  };

  function DateTimeCell(cellData) {
    const date = cellData.value
      ? moment.unix(cellData.value?.seconds).format("DD/MM/YYYY")
      : moment.unix(cellData.value?.seconds).format("DD/MM/YYYY");
    const time = cellData.value
      ? moment.unix(cellData.value?.seconds).format("h:mm:ss a")
      : moment.unix(cellData.value?.seconds).format("h:mm:ss a");

    return (
      <div>
        <span
          style={{
            marginLeft: theme.spacing(1),
            display: "inline-block",
            verticalAlign: "middle",
            cursor: "pointer",
            width: "160px",
          }}
        >
          <Typography component="p" variant="caption">
            {date}
          </Typography>
          <Typography component="p" style={{ whiteSpace: "pre-line" }}>
            {time}
          </Typography>
        </span>
      </div>
    );
  }

  const handleRestSorting = () => {
    setSorting([]);
  };

  // return (
  //   <Card>
  //     <CardContent className={classes.content}>
  //       <GridTable
  //         getRowId={(row) => row.id}
  //         rows={transactions}
  //         columns={columns}
  //       >
  //         <DataTypeProvider
  //           formatterComponent={({ value, row }) => (
  //             <React.Fragment>
  //               <Box>
  //                 <Typography component="p" variant="caption">
  //                   {row.trans_date}
  //                 </Typography>

  //                 <Typography component="p" variant="h6">
  //                   {row.trans_time}
  //                 </Typography>
  //               </Box>
  //             </React.Fragment>
  //           )}
  //           for={["trans_date"]}
  //         />

  //         <DataTypeProvider
  //           formatterComponent={({ value, row }) => (
  //             <React.Fragment>
  //               <Box width={200}>
  //                 <Typography component="p" variant="h6">
  //                   {row.block_name ? row.block_name : null}
  //                 </Typography>
  //               </Box>
  //             </React.Fragment>
  //           )}
  //           for={["block_name"]}
  //         />

  //         <DataTypeProvider
  //           formatterComponent={({ value, row }) => (
  //             <React.Fragment>
  //               <Box width={200}>
  //                 <Typography component="p" variant="h6">
  //                   {row.unit_name ? row.unit_name : null}
  //                 </Typography>
  //               </Box>
  //             </React.Fragment>
  //           )}
  //           for={["unit_name"]}
  //         />

  //         <DataTypeProvider
  //           formatterComponent={({ value, row }) => (
  //             <React.Fragment>
  //               <Box width={400}>
  //                 <Typography component="p" variant="h6">
  //                   {row.profile_name}
  //                 </Typography>
  //               </Box>
  //             </React.Fragment>
  //           )}
  //           for={["profile_name"]}
  //         />

  //         <DataTypeProvider
  //           formatterComponent={({ value, row }) => (
  //             <React.Fragment>
  //               <Box width={200}>
  //                 {row.confidence && (
  //                   <Typography component="p" variant="caption">
  //                     {`Confidence: ${parseFloat(row.confidence).toFixed(2)}`}
  //                   </Typography>
  //                 )}
  //                 <Typography component="p" variant="h6">
  //                   {row.identity_number}
  //                 </Typography>
  //               </Box>
  //             </React.Fragment>
  //           )}
  //           for={["identity_number"]}
  //         />

  //         <FilteringState
  //           defaultFilters={[]}
  //           columnExtensions={columnExtensions}
  //         />
  //         <IntegratedFiltering />

  //         <SortingState
  //           sorting={sorting}
  //           onSortingChange={setSorting}
  //           columnExtensions={columnExtensions}
  //         />
  //         <IntegratedSorting />

  //         <GroupingState
  //           grouping={grouping}
  //           onGroupingChange={setGrouping}
  //           columnExtensions={columnExtensions}
  //         />
  //         <IntegratedGrouping />

  //         <DragDropProvider />

  //         <Table
  //           tableComponent={TableComponentBase}
  //           columnExtensions={columnExtensions}
  //         />
  //         <TableHeaderRow
  //           showSortingControls
  //           showGroupingControls
  //           groupButtonComponent={GroupIcon}
  //         />

  //         <TableGroupRow contentComponent={GroupCellContent} />
  //         <TableFilterRow cellComponent={FilterCell} />
  //         <Toolbar rootComponent={ToolbarRoot} />

  //         <GroupingPanel showSortingControls showGroupingControls />

  //         <TableFixedColumns leftColumns={["trans_date"]} />

  //         <Template name="toolbarContent">
  //           <TemplatePlaceholder />
  //           {sorting.length > 0 && (
  //             <IconButton
  //               style={{ margin: "0px 8px" }}
  //               variant="contained"
  //               color="default"
  //               onClick={handleRestSorting}
  //             >
  //               <Tooltip title="Reset Sorting">
  //                 <RotateLeftIcon fontSize="default" />
  //               </Tooltip>
  //             </IconButton>
  //           )}
  //         </Template>
  //       </GridTable>
  //     </CardContent>
  //   </Card>
  // );

  return (
    <React.Fragment>
      <Paper>
        <DataGrid
          columnAutoWidth={false}
          dataSource={transactions}
          // onRowDblClick={onRowDblClick}

          showColumnLines={false}
        >
          <FilterRow visible={false} />

          <Column
            width={150}
            caption=" Date & Time"
            dataField="transaction_date"
            cellRender={DateTimeCell}
          />
          {/* <Column width={100} caption="Time" dataField="trans_time" /> */}
          <Column caption="Block Name" dataField="block_name" />
          <Column caption="Unit Name" dataField="unit_name" />
          <Column caption="Card Name" dataField="profile_name" />
          <Column caption="Card No." dataField="identity_number" />
          <Column caption="Door" dataField="door_name" />
          <Column caption="Event" dataField="message" />

          <Paging defaultPageSize={20} />
        </DataGrid>
      </Paper>
      <Grid item container xs={16} alignItems="center" justify="center"></Grid>
    </React.Fragment>
  );
};

export default TransactionTable;
