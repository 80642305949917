import React from "react";

//material-ui
import { Grid, Typography, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//assets
import logoIcon from "assets/logo.svg";

//styles
const useStyles = makeStyles(theme => ({
  header: {
    background:
      "linear-gradient(180deg, rgba(245,132,31,1) 0%, rgba(247,150,63,1) 100%)",
    marginBottom: "auto",
    color: "white",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  logo: { marginRight: theme.spacing(1) },
  logoLabel: { marginLeft: theme.spacing(1) }
}));

function Header() {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="center"
      alignContent="center"
      alignItems="center"
      direction="row"
      className={classes.header}
    >
      <Grid item>
        <Avatar alt="logoIcon" src={logoIcon} />
      </Grid>
      <Grid item className={classes.logoLabel}>
        <Typography variant="h5" component="h1" display="inline">
          JaGaCard
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Header;
