import React from "react";
import { DialogTitle, Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    backgroundColor: "#f7f7f7",
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

function DialogTitleWrapper({ title, handleClose }) {
  const classes = useStyles();
  return (
    <DialogTitle disableTypography className={classes.dialogTitle}>
      <Typography variant="h4">{title}</Typography>
      {handleClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default React.memo(DialogTitleWrapper);
