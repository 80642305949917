import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { cloneDeep } from "lodash";

// asset
import activePlaceHolder from "assets/images/active-card-bg.png";
import deletePlaceHolder from "assets/images/inactive-card-bg.png";

// components (global)
import { ButtonColor } from "components/Button";
// import RoleMenu from "components/Menu/RoleMenu";

// firebase
import { useFirestore } from "react-redux-firebase";

// material-ui
import {
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  MoreHoriz as MoreHorizIcon,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

// stores
import { openDrawer } from "stores/main/MainActions";
import { toggleEditVisitorDialogOpen } from "stores/card/CardAction";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  cardNumber: {
    paddingTop: theme.spacing(3),
  },
  cardType: {
    paddingBottom: theme.spacing(2),
  },
  paginate: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
  activePaperContainer: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundImage: `url(${activePlaceHolder})`,
    width: theme.spacing(33),
    height: theme.spacing(20.5),
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
    overflow: "hidden",
  },
  deletePaperContainer: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundImage: `url(${deletePlaceHolder})`,
    width: theme.spacing(33),
    height: theme.spacing(20.5),
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
  },
}));

const VisitorCard = ({ card }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const firestore = useFirestore();

  // selector
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const roles = useSelector((state) => state.main.roles);

  // state
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteCard, setDeleteCard] = useState(false);

  // effect
  // useEffect(() => {
  //   if (card) {

  //   }
  // })

  // functions
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setDeleteCard(false);
  };

  const handleViewCardDetails = () => {
    dispatch(openDrawer({ toggle: "view_visitor_card", parameterId: card.id }));
    handleCloseMenu();
  };

  const handleEditCard = () => {
    dispatch(toggleEditVisitorDialogOpen({ open: true, id: card.id }));
    handleCloseMenu();
  };

  const handleToggleDeleteCardButton = () => {
    setDeleteCard(!deleteCard);
  };

  const handleDeleteCard = () => {
    const oldValue = cloneDeep(card);
    const deleteDate = new Date();

    handleCloseMenu();
    firestore
      .update(
        { collection: "ac_profiles", doc: card.id },
        {
          action: "delete",
          deleted_at: deleteDate,
          deleted_by: {
            user_id: auth.uid,
            name: profile.name,
          },
          is_sync: false,
          status: "deleted",
        }
      )
      .then(() => {
        firestore
          .collection("ac_audit_trails")
          .add({
            action: "deleted",
            created_at: deleteDate,
            created_by: {
              user_id: auth.uid,
              name: profile.name,
            },
            description: `Card number ${card.identity_number} had been deleted`,
            module: "access_card",
            profile: {
              id: card.id,
              identity_number: card.identity_number,
            },
            values: oldValue,
          })
          .then(() => {
            snackbar.enqueueSnackbar(
              `Card No. ${card.identity_number} had been deleted successfully`,
              {
                variant: "success",
              }
            );
          });
      })
      .catch((e) => {
        snackbar.enqueueSnackbar(e.message, {
          variant: "error",
          persist: false,
        });
      });
  };

  if (!card) return null;

  const menuItems = [
    {
      action: "read",
      component: (
        <MenuItem key="view" onClick={handleViewCardDetails}>
          <React.Fragment>
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="View Details" />
          </React.Fragment>
        </MenuItem>
      ),
    },
    {
      action: "update",
      hidden: card.status === "deleted",
      component: (
        <MenuItem key="update" onClick={handleEditCard}>
          <React.Fragment>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Edit Card" />
          </React.Fragment>
        </MenuItem>
      ),
    },
    {
      action: "delete",
      hidden: card.status === "deleted",
      component: (
        <MenuItem
          key="delete"
          onClick={!deleteCard ? handleToggleDeleteCardButton : undefined}
          button={!deleteCard}
          disabled={!roles.visitor_card?.delete}
        >
          {deleteCard ? (
            <React.Fragment>
              <ButtonColor
                buttonColor="red"
                onClick={handleDeleteCard}
                size="small"
                variant="contained"
              >
                Yes
              </ButtonColor>
              <Button
                style={{ marginLeft: "10px" }}
                size="small"
                color="primary"
                variant="contained"
                onClick={handleToggleDeleteCardButton}
              >
                No
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Delete Card" />
            </React.Fragment>
          )}
        </MenuItem>
      ),
    },
  ];

  return (
    <Grid item container key={card.id} md={3} justify="center">
      <Paper
        variant="outlined"
        elevation={10}
        className={
          card.status === "deleted"
            ? classes.deletePaperContainer
            : classes.activePaperContainer
        }
      >
        <Grid container direction="column">
          <Grid item container>
            <Grid item md={10} xs={10}>
              <Typography
                gutterBottom
                variant="caption"
                style={{ color: "white" }}
              >
                {card.profile_code}
              </Typography>
            </Grid>
            <Grid item container md={2} xs={2} justify="flex-end">
              <MoreHorizIcon onClick={handleClick} style={{ color: "white" }} />

              <Menu
                id="enitMenu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MenuItem key="view" onClick={handleViewCardDetails}>
                  <React.Fragment>
                    <ListItemIcon>
                      <VisibilityIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="View Details" />
                  </React.Fragment>
                </MenuItem>

                {card.status !== "deleted" && (
                  <MenuItem
                    key="update"
                    onClick={handleEditCard}
                    disabled={!roles.access_card?.visitor?.update}
                  >
                    <React.Fragment>
                      <ListItemIcon>
                        <EditIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Edit Card" />
                    </React.Fragment>
                  </MenuItem>
                )}

                {card.status !== "delete" && (
                  <MenuItem
                    key="delete"
                    onClick={
                      !deleteCard ? handleToggleDeleteCardButton : undefined
                    }
                    button={!deleteCard}
                    disabled={!roles.access_card?.visitor?.delete}
                  >
                    {deleteCard ? (
                      <React.Fragment>
                        <ButtonColor
                          buttonColor="red"
                          onClick={handleDeleteCard}
                          size="small"
                          variant="contained"
                        >
                          Yes
                        </ButtonColor>
                        <Button
                          style={{ marginLeft: "10px" }}
                          size="small"
                          color="primary"
                          variant="contained"
                          onClick={handleToggleDeleteCardButton}
                        >
                          No
                        </Button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <ListItemIcon>
                          <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Delete Card" />
                      </React.Fragment>
                    )}
                  </MenuItem>
                )}
              </Menu>

              {/* <RoleMenu
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                menuItems={menuItems}
                module="visitor_card"
              /> */}
            </Grid>
          </Grid>

          <Grid item container>
            <Grid item container md={12}>
              <Grid
                item
                container
                md={12}
                justify="center"
                alignItems="center"
                className={classes.cardNumber}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  style={{ color: "white" }}
                >
                  {card.identity_number}
                </Typography>
              </Grid>

              <Grid
                item
                container
                md={12}
                justify="center"
                alignItems="center"
                className={classes.cardType}
              >
                <Typography
                  gutterBottom
                  align="center"
                  variant="caption"
                  style={{ color: "white" }}
                >
                  {card.status.toUpperCase()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container>
            <Grid item container md={12}>
              <Grid
                item
                container
                md={10}
                xs={10}
                justify="flex-start"
                alignItems="center"
              >
                <Typography
                  gutterBottom
                  variant="caption"
                  style={{ color: "white" }}
                >
                  {card.profile_name ? card.profile_name.toUpperCase() : "-"}
                </Typography>
              </Grid>
              <Grid
                item
                container
                md={2}
                xs={2}
                justify="center"
                alignItems="center"
              >
                <Typography
                  gutterBottom
                  variant="caption"
                  style={{ color: "white" }}
                >
                  {card.card_type === "proximity"
                    ? "PROX"
                    : card.card_type.toUpperCase()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default VisitorCard;
