import React, { useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { filter, forEach, includes, cloneDeep, map } from "lodash";
import moment from "moment";
//components
import { ButtonProgress } from "components/Button";
import { DialogWrapper } from "components/Dialogs";
import { Capitalized } from "helpers/textHelpers";
import { OverlayWithText } from "components/Box";
import accessGranted from "components/lotties/access-granted";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
//material-ui
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";

const ItemTableCell = withStyles((theme) => ({
  arrow: { color: "#e9e9e9" },
  tooltip: {
    backgroundColor: "#e9e9e9",
    maxWidth: 450,
    border: "1px solid #fff",
    padding: theme.spacing(2),
    borderRadius: 4,
    color: "#333",
    fontSize: "14px",
  },
}))(MuiTableCell);

function BatchUnSuspendDialog({ cardIdList, handleClose }) {
  const firestore = useFirestore();
  const snackbar = useSnackbar();

  const [submitting, setSubmitting] = useState(false);

  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const vendor = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor]
      : null;
  });
  const cardSelector = useSelector(
    (state) => state.firestore.data.resident_cards ?? []
  );
  const cardArr = Object.keys(cardSelector).map((key) => ({
    id: key,
    ...cardSelector[key],
  }));
  const filteredCards = filter(cardArr, (card) =>
    includes(cardIdList, card.id)
  );
  const handleBatchSuspend = async () => {
    setSubmitting(true);

    const batches = [firestore.batch()];
    const profileRef = firestore.collection("ac_profiles");
    const auditRef = firestore.collection("ac_audit_trails");

    const writeLimit = 200;
    let operationCount = 0;

    try {
      const filteredCards = filter(cardArr, (card) =>
        includes(cardIdList, card.id)
      );

      forEach(filteredCards, (card) => {
        const oldValue = cloneDeep(card);
        console.log(card);
        if (
          !!card &&
          card.status !== "deleted" &&
          card.blocked_settings.enable === true
        ) {
          batches[batches.length - 1].update(profileRef.doc(card.id), {
            blocked_settings: {
              count: 0,
              enable: false,
            },
            updated_at: firestore.FieldValue.serverTimestamp(),
            updated_by: {
              user_id: auth.uid,
              name: profile.name,
            },
          });
          batches[batches.length - 1].set(auditRef.doc(), {
            action: "Card Un-Suspended",
            created_at: firestore.FieldValue.serverTimestamp(),
            created_by: {
              user_id: auth.uid,
              name: profile.name,
            },
            description: `${Capitalized(
              profile.name
            )} had ${"unsuspended"} resident card ${card.identity_number}`,
            module: "access_card",
            profile: {
              id: card.id,
              identity_number: card.identity_number,
            },
            values: oldValue,
            unit: card.unit,
          });
        }

        batches.push(firestore.batch());
      });

      await Promise.all(batches.map((batch) => batch.commit()));

      snackbar.enqueueSnackbar("Cards Un-suspended successfully", {
        variant: "success",
      });

      handleClose();
    } catch (error) {
      setSubmitting(false);
      snackbar.enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  const transactionTable = (items) => (
    <Grid mt={1} container spacing={2}>
      <Grid item xs={12}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ borderBottom: `2px solid #1F4E23` }}>
              <ItemTableCell width="20%">Block </ItemTableCell>
              <ItemTableCell>Unit</ItemTableCell>
              <ItemTableCell width="20%">Card Name</ItemTableCell>
              <ItemTableCell>Card Number</ItemTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(items, (item) => (
              <TableRow key={item.id}>
                {console.log(item.id)}
                <ItemTableCell>{item.unit.block_name}</ItemTableCell>
                <ItemTableCell>{item.unit.unit_name}</ItemTableCell>
                <ItemTableCell>{item.profile_name}</ItemTableCell>
                <ItemTableCell>{item.identity_number}</ItemTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );

  return (
    <DialogWrapper open={true} size="md">
      <DialogTitle>
        <b>CardStatus Update</b>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Un-Suspend selected Cards? Action cannot be undone.
        </Typography>
        {transactionTable(filteredCards)}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
          variant="contained"
        >
          Cancel
        </Button>
        <ButtonProgress
          margin={false}
          type="submit"
          onClick={handleBatchSuspend}
          loading={submitting}
          containName={"OK"}
        />
      </DialogActions>
    </DialogWrapper>
  );
}

export default React.memo(BatchUnSuspendDialog);
