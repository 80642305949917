import React from "react";
import { useSelector } from "react-redux";

// components (local)
import {
  AddDeviceDialog,
  AddServiceDialog,
  DeviceBoard,
  EditDeviceDialog,
  EditServiceDialog,
  EditVisitPurposeActionDialog,
} from "./components";

// material-ui
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const DeviceTab = () => {
  const classes = useStyles();

  // selector
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const vendor = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor]
      : null;
  });

  if (!vendor) return null;

  return (
    <div className={classes.root}>
      <EditDeviceDialog />
      <EditVisitPurposeActionDialog />

      {vendor &&
        vendor.access_card_settings.type.toUpperCase() === "ENTRYPASS" && (
          <React.Fragment>
            <AddServiceDialog />
            <EditServiceDialog />
          </React.Fragment>
        )}

      <Grid container direction="row" alignItems="flex-start" spacing={2}>
        <Grid item container justify="space-between">
          <Grid item>
            <Typography component="h2" gutterBottom variant="overline">
              Devices
            </Typography>
            <Typography component="h1" variant="h3">
              Manage all account settings
            </Typography>
          </Grid>

          <Grid item>
            <AddDeviceDialog />
          </Grid>
        </Grid>

        <Grid item container>
          <DeviceBoard />
        </Grid>
      </Grid>
    </div>
  );
};

export default DeviceTab;
