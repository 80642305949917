import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useFirebase } from "react-redux-firebase";

// components
import ButtonProgress from "components/ButtonProgress";

// helpers
import { loginValidator } from "utility/FormValidateHelper";

// material-ui
import {
  Checkbox,
  Grid,
  FormControlLabel,
  TextField,
  Typography,
  Link
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  error: {
    color: "red"
  },
  label: {
    marginRight: "0px"
  }
}));

const LoginForm = () => {
  const classes = useStyles();
  const firebase = useFirebase();
  const { handleSubmit, errors, formState, register } = useForm({
    mode: "onChange"
  });

  // useSelector
  const loginErrors = useSelector(state => state.firebase.authError);

  // useState
  const [loading, setLoading] = useState(false);

  //useEffect
  useEffect(() => {
    setLoading(false);
  }, [loginErrors]);

  // functions
  const onSubmit = credential => {
    setLoading(true);
    firebase.login(credential);
  };

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      className={classes.root}
    >
      <Grid container item justify="center" spacing={1}>
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="body2"
            component="h1"
            display="block"
            className={classes.error}
          >
            {loginErrors && "Incorrect email or password."}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            variant="outlined"
            margin="normal"
            error={!!errors.email}
            helperText={
              errors.email && loginValidator.email.message[errors.email.type]
            }
            inputRef={register(loginValidator.email.rules)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Password"
            type="password"
            variant="outlined"
            error={!!errors.password}
            helperText={
              errors.password &&
              loginValidator.password.message[errors.password.type]
            }
            inputRef={register(loginValidator.password.rules)}
          />
        </Grid>

        <Grid
          container
          item
          xs={12}
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <FormControlLabel control={<Checkbox />} label="Stay signed in" />
          </Grid>
          <Grid item>
            <FormControlLabel
              className={classes.label}
              control={<Link>Forgot Your Password?</Link>}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} justify="center">
        <Grid item>
          <ButtonProgress
            type="submit"
            containName="Log In"
            loading={loading}
            disabled={formState.dirty === false ? true : !formState.isValid}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
