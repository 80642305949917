import React from "react";

// material-ui
import {
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(1) },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  }
}));

const DetailsTab = ({ access }) => {
  const classes = useStyles();

  if (!access) return null;

  console.log(access);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item container md={6} xs={12}>
          <Grid item md={12}>
            <Typography variant="h6" className={classes.title}>
              Resident
            </Typography>
            <Card>
              <CardContent>
                <List dense={false}>
                  {access.resident.access_groups.map(group => (
                    <ListItem>
                      <ListItemText
                        primary={
                          group.display_name ? group.display_name : group.name
                        }
                        secondary={group.description ? group.description : "-"}
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item container md={6} xs={12}>
          <Grid item md={12}>
            <Typography variant="h6" className={classes.title}>
              Visitor
            </Typography>
            <Card>
              <CardContent>
                <List dense={false}>
                  {access.visitor.access_groups.map(group => (
                    <ListItem>
                      <ListItemText
                        primary={
                          group.display_name ? group.display_name : group.name
                        }
                        secondary={group.description ? group.description : "-"}
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default DetailsTab;
