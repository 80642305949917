import React from "react";

// helpers
import { settingsValidate } from "helpers/validation/settingsValidate";

// material-ui
import { Grid, TextField, Typography } from "@material-ui/core";

const Falco = ({ errors, register, settings }) => {
  return (
    <Grid container spacing={3}>
      {/* Visitor */}
      <Grid item container md={4} spacing={2}>
        <Grid item md={12}>
          <Typography variant="overline">Visitor Lane</Typography>
        </Grid>
        <Grid item md={12}>
          <TextField
            size="small"
            id="visitorEntryIpAddress"
            name="visitorEntryIpAddress"
            label="Entry IP Address *"
            type="text"
            variant="outlined"
            autoComplete="off"
            defaultValue={
              settings && settings[0] && settings[0].entry
                ? settings[0].entry.ip_address
                : ""
            }
            inputRef={register(settingsValidate.ipAddress.rules)}
            helperText={
              errors.visitorEntryIpAddress &&
              settingsValidate.ipAddress.message[
                errors.visitorEntryIpAddress.type
              ]
            }
            error={!!errors.visitorEntryIpAddress}
            fullWidth
          />
        </Grid>
        <Grid item md={12}>
          <TextField
            size="small"
            id="visitorExitIpAddress"
            name="visitorExitIpAddress"
            label="Exit IP Address *"
            type="text"
            variant="outlined"
            autoComplete="off"
            defaultValue={
              settings && settings[0] && settings[0].exit
                ? settings[0].exit.ip_address
                : ""
            }
            inputRef={register(settingsValidate.ipAddress.rules)}
            helperText={
              errors.visitorExitIpAddress &&
              settingsValidate.ipAddress.message[
                errors.visitorExitIpAddress.type
              ]
            }
            error={!!errors.visitorExitIpAddress}
            fullWidth
          />
        </Grid>
      </Grid>

      {/* Motor */}
      <Grid item container md={4} spacing={2}>
        <Grid item md={12}>
          <Typography variant="overline">Motor Lane</Typography>
        </Grid>
        <Grid item md={12}>
          <TextField
            size="small"
            id="motorEntryIpAddress"
            name="motorEntryIpAddress"
            label="Entry IP Address"
            type="text"
            variant="outlined"
            autoComplete="off"
            defaultValue={
              settings && settings[1] && settings[1].entry
                ? settings[1].entry.ip_address
                : ""
            }
            inputRef={register(settingsValidate.ipAddressNotRequired.rules)}
            helperText={
              errors.motorEntryIpAddress &&
              settingsValidate.ipAddressNotRequired.message[
                errors.motorEntryIpAddress.type
              ]
            }
            error={!!errors.motorEntryIpAddress}
            fullWidth
          />
        </Grid>
        <Grid item md={12}>
          <TextField
            size="small"
            id="motorExitIpAddress"
            name="motorExitIpAddress"
            label="Exit IP Address"
            type="text"
            variant="outlined"
            autoComplete="off"
            defaultValue={
              settings && settings[1] && settings[1].exit
                ? settings[1].exit.ip_address
                : ""
            }
            inputRef={register(settingsValidate.ipAddressNotRequired.rules)}
            helperText={
              errors.motorExitIpAddress &&
              settingsValidate.ipAddressNotRequired.message[
                errors.motorExitIpAddress.type
              ]
            }
            error={!!errors.motorExitIpAddress}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid item container md={4} spacing={2}>
        <Grid item md={12}>
          <Typography variant="overline">Pedestrian Lane</Typography>
        </Grid>
        <Grid item md={12}>
          <TextField
            size="small"
            id="pedestrianEntryIpAddress"
            name="pedestrianEntryIpAddress"
            label="Entry IP Address"
            type="text"
            variant="outlined"
            autoComplete="off"
            defaultValue={
              settings && settings[2] && settings[2].entry
                ? settings[2].entry.ip_address
                : ""
            }
            inputRef={register(settingsValidate.ipAddressNotRequired.rules)}
            helperText={
              errors.pedestrianEntryIpAddress &&
              settingsValidate.ipAddressNotRequired.message[
                errors.pedestrianEntryIpAddress.type
              ]
            }
            error={!!errors.pedestrianEntryIpAddress}
            fullWidth
          />
        </Grid>
        <Grid item md={12}>
          <TextField
            size="small"
            id="pedestrianExitIpAddress"
            name="pedestrianExitIpAddress"
            label="Exit IP Address"
            type="text"
            variant="outlined"
            autoComplete="off"
            defaultValue={
              settings && settings[2] && settings[2].exit
                ? settings[2].exit.ip_address
                : ""
            }
            inputRef={register(settingsValidate.ipAddressNotRequired.rules)}
            helperText={
              errors.pedestrianExitIpAddress &&
              settingsValidate.ipAddressNotRequired.message[
                errors.pedestrianExitIpAddress.type
              ]
            }
            error={!!errors.pedestrianExitIpAddress}
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Falco;
