import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import filter from "lodash/filter";
import includes from "lodash/includes";
// component (global)
import { PropertyImage } from "components";
import { StyledTextField } from "components/TextField";
import { replaceStringAll } from "helpers/textHelpers";

// material-ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
//redux
import { toggleSelectProperty, setProperty } from "stores/main/MainActions";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  searchListItem: {
    zIndex: 1,
    position: "sticky",
    top: "0",
    backgroundColor: "white",
  },
}));

const PropertyDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // selector
  const properties = useSelector((state) => state.main.properties);
  const selectedProperty = useSelector((state) => state.main.selectedProperty);
  const [search, setSearch] = useState("");
  const open = useSelector((state) => state.main.selectPropertyToggle);

  // state
  const [propertyId, setPropertyId] = useState("");

  // effect
  useEffect(() => {
    setPropertyId(selectedProperty);
    return () => {
      setPropertyId("");
    };
  }, [selectedProperty]);
  const propertiesMemo = useMemo(() => {
    if (properties && search) {
      const searchProperties = filter([...properties], (property) => {
        return (
          includes(
            property.name.toString().toLowerCase(),
            search.toString().toLowerCase()
          ) ||
          includes(
            replaceStringAll(
              replaceStringAll(property.name.toString().toLowerCase(), ",", ""),
              " ",
              ""
            ),
            replaceStringAll(
              replaceStringAll(search.toString().toLowerCase(), ",", ""),
              " ",
              ""
            )
          )
        );
      });
      return [...searchProperties];
    } else return [...properties];
  }, [properties, search]);

  // functions
  const handleClose = () => {
    dispatch(toggleSelectProperty());
  };

  const handleSelectProperty = (id) => {
    setPropertyId(id);
  };

  const changeToSelectProperty = () => {
    handleClose();

    var selectedProperty = properties.find((property) => {
      return property.id === propertyId;
    });

    dispatch(setProperty(selectedProperty.id));
  };
  //functions
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="select-property-title"
      open={open}
      fullWidth={true}
      scroll="paper"
    >
      <DialogTitle id="select-property-title">Select Property</DialogTitle>
      <DialogContent dividers={true} className={classes.content}>
        <List>
          <ListItem className={classes.searchListItem}>
            <StyledTextField
              fullWidth={true}
              variant="outlined"
              defaultValue={search}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Search… "
            />
          </ListItem>
          {propertiesMemo.map(({ id, name }) => {
            return (
              <ListItem
                button
                onClick={() => handleSelectProperty(id)}
                key={id}
                selected={id === propertyId}
                classes={{ selected: classes.selected }}
              >
                <ListItemAvatar>
                  <PropertyImage id={id} type="avatar" />
                </ListItemAvatar>
                <ListItemText primary={name} />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={changeToSelectProperty}
          color="primary"
          variant="contained"
          autoFocus
        >
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PropertyDialog;
