import React, { useState, useMemo, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { filter, includes, orderBy, map } from "lodash";
import { CSVLink } from "react-csv";
// components (global)

import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
// components (local)
import { useFirestore } from "react-redux-firebase";
import {
  toggleEditPropertyDialogOpen,
  toggleEditResidentDialogOpen,
} from "stores/card/CardAction";
import { closeDrawer, openDrawer } from "stores/main/MainActions";
import { CustomerDrawer } from "components";
// material-ui
import { Edit as EditIcon } from "@material-ui/icons";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  Grid,
  Paper,
  IconButton,
  LinearProgress,
  MenuItem,
} from "@material-ui/core";
import Icon from "@mui/material/Icon";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { makeStyles } from "@material-ui/core/styles";
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Sorting,
  FilterRow,
  Selection,
  Toolbar,
  Item,
  Paging,
  Scrolling,
  Pager,
} from "devextreme-react/data-grid";

import {
  usePopupState,
  bindTrigger,
  bindPopover,
  bindMenu,
} from "material-ui-popup-state/hooks";
import PropertyInfo from "./PropertyInfo";
import EditPropertyDialog from "./EditPropertyDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  link: {
    marginRight: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));
const VendorsData = ({ dashboardButton }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const exporterRef = useRef(null);
  // selector

  const vendors = useSelector((state) => state.firestore.ordered.all_vendors);

  console.log("vendors", vendors);

  // stateå
  console.log(vendors);

  const vendorData = useMemo(() => {
    let flatResult = [];

    if (vendors) {
      return map(vendors, (trail) => {
        return {
          id: trail.id,

          auto_check_out: !!trail.auto_checkout_settings?.enable ? "Yes" : "No",
          auto_check_out_door: trail.auto_checkout_settings?.door_name ?? "",
          site_wifi_id: trail.site_wifi_id ?? "",
          site_wifi_pw: trail.site_wifi_pw ?? "",
          ultraviewer_id: trail.ultraviewer_id ?? "",
          property: trail.property_name ?? "",
          vendor_type: trail.access_card_settings?.type ?? "",
          ultraviewer_pw: trail.ultraviewer_pw ?? "",
          integration_type: trail.integration_type ?? "",
          created_at: moment
            .unix(trail.created_at?.seconds)
            .format("DD/MM/YYYY"),
        };
      });
    }
    // return orderBy(
    //   flatResult,
    //   ["created_at", "created_time"],
    //   ["desc", "desc"]
    // );
  }, [vendors]);
  function onSelectionChanged({ selectedRowsData }) {
    // dynamically set height
  }

  const downloadCsvButton = (
    <CSVLink
      style={{ textDecoration: "none" }}
      filename={`user-data${moment().format("YYYY-MM-DD")}.csv`}
      data={vendorData}
      className={classes.link}
    >
      <Button underline="none" color="primary" variant="contained" mr={2}>
        Export CSV
      </Button>
    </CSVLink>
  );

  return (
    <div className={classes.root}>
      <React.Fragment>
        <CustomerDrawer uniqName="view_property_info" size={2}>
          <PropertyInfo />
        </CustomerDrawer>

        <Paper>
          <DataGrid
            columnAutoWidth={false}
            dataSource={vendorData}
            // onRowDblClick={onRowDblClick}
            onSelectionChanged={onSelectionChanged}
            rowMultiSelectWithClick={true}
            ref={exporterRef}
            showColumnLines={false}
          >
            <FilterRow visible={true} />
            <Selection mode="single" showCheckBoxesMode="onClick" />
            <Column caption="Property" dataField="property" />
            <Column caption="Vendor" dataField="vendor_type" />
            <Column caption="Integration Type" dataField="integration_type" />
            <Column caption="Ultraviewer ID" dataField="ultraviewer_id" />
            <Column caption="Ultraviewer PW" dataField="ultraviewer_pw" />

            <Column caption="Site Wifi ID" dataField="site_wifi_id" />
            <Column caption="Site Wifi PW" dataField="site_wifi_pw" />

            <Column
              fixed={true}
              fixedPosition="right"
              allowGrouping={false}
              allowExporting={false}
              allowFiltering={false}
              allowSorting={false}
              allowHiding={false}
              allowColumnReordering={false}
              allowFixing={false}
              width={50}
              cellRender={(cellData) => (
                <MenuButton vendorData={cellData.data} />
              )}
            />
            <Paging defaultPageSize={10} />

            <Pager
              visible={true}
              displayMode="full"
              showPageSizeSelector={false}
              showInfo={true}
              showNavigationButtons={true}
            />
          </DataGrid>
        </Paper>
        <Grid item container xs={16} alignItems="center" justify="center">
          {!!vendorData && vendorData.length > 0 && downloadCsvButton}
        </Grid>
      </React.Fragment>
      <EditPropertyDialog />
    </div>
  );
};

const MenuButton = ({ vendorData }) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "popupMenu",
  });
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(closeDrawer());
  };

  const handleVendorDetails = () => {
    console.log(vendorData);
    popupState.close();
    dispatch(
      openDrawer({ toggle: "view_property_info", parameterId: vendorData.id })
    );
  };
  const handleEditProperty = () => {
    dispatch(toggleEditPropertyDialogOpen({ open: true, id: vendorData.id }));
    console.log(vendorData);
    onClose();
  };

  return (
    <>
      <IconButton size="small" {...bindTrigger(popupState)}>
        <MoreHorizIcon fontSize="small" color="primary" />
      </IconButton>
      <Menu {...bindMenu(popupState)}>
        <MenuItem onClick={handleVendorDetails}>
          <ListItemIcon>
            <VisibilityIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="View Details" />
        </MenuItem>
        <MenuItem onClick={handleEditProperty}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit Property" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default VendorsData;
