import React from "react";
import { useDispatch } from "react-redux";

// material-ui
import { Close as CloseIcon } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// stores
import { closeDrawer } from "stores/main/MainActions";

// styles
const useStyles = makeStyles(theme => ({
  root: {},
  header: {
    // padding: theme.spacing(2, 1),
    flexShrink: 0,
    display: "flex",
    justifyContent: "space-between"
  }
}));

const Filter = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // functions
  const onClose = () => {
    dispatch(closeDrawer());
  };

  return (
    <div className={classes.header}>
      <Button onClick={onClose} size="small">
        <CloseIcon className={classes.buttonIcon} />
        Close
      </Button>
    </div>
  );
};

export default Filter;
