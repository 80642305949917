import React from "react";

//material-ui
import { Button, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//material-icons
import AddIcon from "@material-ui/icons/Add";

// styles
const useStyles = makeStyles((theme) => ({
  button: {
    display: "grid",
    width: "220px",
    height: "140px",
    backgroundColor: "white",
    color: theme.palette.primary.main,
    "&:hover": { backgroundColor: theme.palette.primary.main, color: "white" },
    "& svg": { fontSize: "50px" },
    fontSize: "16px",
    textTransform: "none",
    lineHeight: 1.5,
  },

  // button: {
  //   display: "grid",
  //   minHeight: "140px",
  //   backgroundColor: "white",
  //   color: theme.palette.primary.main,
  //   "&:hover": { backgroundColor: theme.palette.primary.main, color: "white" },
  //   "& svg": { fontSize: "35px" },
  //   fontSize: "16px",
  //   lineHeight: "1.2",
  //   width: "100%",
  // },
}));

function DashboardButton({ onClick, title, icon = null }) {
  const classes = useStyles();

  return (
    <Button
      aria-controls="addNotice"
      aria-haspopup="true"
      onClick={onClick}
      variant="contained"
      color="primary"
      className={classes.button}
      disableRipple
    >
      <div>
        <div>{!!icon ? icon : <AddIcon />}</div>
        <div>
          {title}
          {/* {`${title.split(" ")[0]}`} <br />
          {`${title.split(" ")[1]}`} */}
        </div>
      </div>

      {/* <Box>
        <Box>{!!icon ? icon : <AddIcon />}</Box>
        <Box>{title}</Box>
      </Box> */}
    </Button>
  );
}

export default React.memo(DashboardButton);
