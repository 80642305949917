const emailRules = {
  required: true,
  pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
};

export const loginValidator = {
  email: {
    rules: emailRules,
    message: {
      required: "Email is required",
      pattern: "Please enter a valid email address"
    }
  },
  password: {
    rules: {
      required: true,
      minLength: 6
    },
    message: {
      required: "Password is required",
      minLength: "Password must contain at least 6 characters"
    }
  }
};
