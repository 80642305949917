import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { filter, orderBy } from "lodash";
import { useFirestoreConnect } from "react-redux-firebase";

// components (local)
import Content from "./Content";

// firebase
import { isLoaded, isEmpty } from "react-redux-firebase";

// helpers
import { Capitalized } from "helpers/textHelpers";

const AccessRole = () => {
  // selector'

  const selectedProperty = useSelector((state) => state.main.selectedProperty);

  const moderatorsSelector = useSelector(
    (state) => state.firestore.data.moderators
  );
  const rolesSelector = useSelector((state) => state.firestore.data.roles);
  // memo
  const moderators = useMemo(() => {
    let moderatorsMapped = [];

    if (isLoaded(moderatorsSelector) && !isEmpty(moderatorsSelector)) {
      moderatorsMapped = Object.keys(moderatorsSelector).map((key) => {
        const moderator = moderatorsSelector[key];
        const roleId = moderator?.card_access
          ? moderator?.card_access[selectedProperty]?.role_id
          : null;

        const role =
          roleId && rolesSelector
            ? {
                ...rolesSelector[roleId],
                id: roleId,
              }
            : null;

        return {
          ...moderatorsSelector[key],
          id: key,
          role,
        };
      });
    }
    return orderBy(
      filter(
        moderatorsMapped,
        (moderator) => !moderator.is_jagacard_super_admin
      ),
      ["name"],
      ["asc"]
    );
  }, [moderatorsSelector, rolesSelector, selectedProperty]);

  const roles = useMemo(() => {
    let rolesMapped = [];

    if (moderators && isLoaded(rolesSelector) && !isEmpty(rolesSelector)) {
      rolesMapped = Object.keys(rolesSelector).map((key) => {
        const members = filter(
          moderators,
          (moderator) => moderator.role && moderator.role?.id === key
        );

        const role = {
          ...rolesSelector[key],
          id: key,
          name: Capitalized(rolesSelector[key].name),
          members: members,
        };
        return role;
      });
    }
    return orderBy(rolesMapped, ["name"], ["asc"]);
  }, [moderators, rolesSelector]);

  if (!isLoaded(rolesSelector) || !roles) return null;

  return <Content roles={roles} moderators={moderators} />;
};

export default AccessRole;
