import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { filter } from "lodash";
import axios from "http-axios";

// components (global)
import ButtonProgress from "components/ButtonProgress";
import DialogWrapper from "components/Dialogs/DialogWrapper";
import DialogTitleWrapper from "components/Dialogs/DialogTitleWrapper";

// firebase
import { useFirebase } from "react-redux-firebase";

// form
import { useForm } from "react-hook-form";

// helpers
import { settingsValidate } from "helpers/validation/settingsValidate";

// material-ui
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

const SoyalDoorAccessDialog = ({ open, handleClose }) => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const firebase = useFirebase();
  const { errors, handleSubmit, setValue, register } = useForm();

  // selector
  const uid = useSelector((state) => state.firebase.auth.uid);
  const blocks = useSelector((state) => state.firestore.ordered.blocks);
  const accessGroups = useSelector(
    (state) => state.firestore.ordered.accessGroups
  );
  const selectedPropertyId = useSelector(
    (state) => state.main.selectedProperty
  );
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const blockAccessSelector = useSelector(
    (state) => state.firestore.ordered.blockAccess
  );

  // state
  const [submitting, setSubmitting] = useState(false);

  // effect
  useEffect(() => {
    register("blockId");
    register("residentAccessGroup");
    register("visitorAccessGroup");
  }, [register]);

  // functions
  const onHandleClose = () => {
    if (!submitting) {
      handleClose();
    }
  };

  const onSubmit = (value) => {
    if (!submitting) {
      setSubmitting(true);

      let residentAccessGroup = null;
      let visitorAccessGroup = null;

      if (value.residentAccessGroup) {
        residentAccessGroup = value.residentAccessGroup;
      }

      if (value.visitorAccessGroup) {
        visitorAccessGroup = value.visitorAccessGroup;
      }

      firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          };

          axios
            .post(
              `jagaCardUpdateDoorAccess`,
              {
                uid,
                property_id: selectedPropertyId,
                block_id: value.blockId,
                vendor_id: selectedVendor,
                resident_access_group: residentAccessGroup,
                visitor_access_group: visitorAccessGroup,
              },
              {
                headers: headers,
              }
            )
            .then((res) => {
              if (res.data.result === "success") {
                setSubmitting(false);
                onHandleClose();
                snackbar.enqueueSnackbar("Door Access had been added", {
                  variant: "success",
                });
              }
            })
            .catch((e) => {
              setSubmitting(false);
              onHandleClose();
              snackbar.enqueueSnackbar(e.message, {
                variant: "error",
                persist: false,
              });
            });
        });
    }
  };

  return (
    <form
      noValidate
      xs={12}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <DialogWrapper size="md" open={open}>
        <DialogTitleWrapper
          title="Add Door Access"
          handleClose={onHandleClose}
        />

        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item container md={12} xs={12} spacing={2}>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  autoHighlight
                  options={blocks}
                  getOptionLabel={(option) => option.name}
                  getOptionDisabled={(option) => {
                    return (
                      filter(
                        blockAccessSelector,
                        (access) => access.block_name === option.name
                      ).length > 0
                    );
                  }}
                  onChange={(e, value) => {
                    setValue("blockId", value ? value.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={!!errors.block}
                      helperText={
                        errors.block &&
                        settingsValidate.block.message[errors.block.type]
                      }
                      {...params}
                      label="Choose a block *"
                      fullWidth
                      size="small"
                      name="block"
                      autoComplete="off"
                      variant="outlined"
                      inputRef={register(settingsValidate.block.rules)}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Divider />
            </Grid>

            <Grid item container md={6} xs={12} spacing={1}>
              <Grid item md={12} xs={12}>
                <Typography className={classes.modalTitle} variant="h4">
                  Resident
                </Typography>
                <Typography className={classes.modalTitle} variant="h6">
                  Please choose the Access Group
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Autocomplete
                  autoHighlight
                  options={accessGroups}
                  getOptionLabel={(option) =>
                    option.display_name ? option.display_name : option.name
                  }
                  onChange={(e, value) => {
                    setValue("residentAccessGroup", value ? value : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose an access group *"
                      fullWidth
                      size="small"
                      name="accessLevel"
                      autoComplete="off"
                      variant="outlined"
                      inputRef={register}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item container md={6} xs={12} spacing={1}>
              <Grid item md={12} xs={12}>
                <Typography className={classes.modalTitle} variant="h4">
                  Visitor
                </Typography>
                <Typography className={classes.modalTitle} variant="h6">
                  Please choose the Access Group
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Autocomplete
                  autoHighlight
                  options={accessGroups}
                  getOptionLabel={(option) =>
                    option.display_name ? option.display_name : option.name
                  }
                  onChange={(e, value) => {
                    setValue("visitorAccessGroup", value ? value : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose an access group *"
                      fullWidth
                      size="small"
                      name="accessLevel"
                      autoComplete="off"
                      variant="outlined"
                      inputRef={register}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onHandleClose}>
            Cancel
          </Button>
          <ButtonProgress
            type="submit"
            containName="Save"
            margin={false}
            loading={submitting}
            disabled={submitting}
            onClick={handleSubmit(onSubmit)}
          />
        </DialogActions>
      </DialogWrapper>
    </form>
  );
};

export default SoyalDoorAccessDialog;
