import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { useSnackbar } from "notistack";
// components (global)
import DashboardButton from "components/Button/DashboardButton";
import ButtonProgress from "components/ButtonProgress";

// firebase
import { useFirebase, useFirestore } from "react-redux-firebase";

// form
import { useForm } from "react-hook-form";

// helpers
import { vehicleValidate } from "helpers/validation/vehicleValidate";
import { Capitalized } from "helpers/textHelpers";
// material icons
import {
  AddPhotoAlternateOutlined as AddPhotoAlternateOutlinedIcon,
  DriveEta as DriveEtaIcon,
} from "@material-ui/icons";
import moment from "moment";
// material-ui
import {
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
  },
  modalTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.white,
  },
  modalSubTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.white,
    padding: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  image: {
    maxWidth: "100%",
    height: "350px",
    alignItems: "center",
  },
  field: {
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  column2: {
    marginLeft: theme.spacing(1),
  },
  edit: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const AddVehicleDialog = ({ dashboardButton, vehicle, vehicleImage }) => {
  const classes = useStyles();
  const firebase = useFirebase();
  const snackbar = useSnackbar();

  const firestore = useFirestore();
  const theme = useTheme();
  const { errors, handleSubmit, setError, setValue, register, watch } = useForm(
    {
      defaultValues: { vehicleNumber: "-" },
    }
  );

  // dropzone
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) setValue("vehicleImage", acceptedFiles[0]);
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: browseFile,
  } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png, image/jpg",
    maxSize: 5000000,
  });

  // form
  const watchBlock = watch("blockId");

  // selector
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const selectedPropertyId = useSelector(
    (state) => state.main.selectedProperty
  );
  const vendors = useSelector((state) => state.firestore.ordered.vendors);
  const blocks = useSelector((state) => state.firestore.ordered.blocks);
  const units = useSelector((state) => state.firestore.ordered.units);
  const selectedVendor = useSelector((state) => state.main.selectedApnrVendor);
  const vendor = useSelector((state) => {
    return state.firestore.data.apnrVendors
      ? state.firestore.data.apnrVendors[selectedVendor]
      : null;
  });
  // state
  const [open, setOpen] = React.useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [token, setToken] = useState();
  const [image, setImage] = useState({
    preview: "",
    raw: "",
    vehicleNo: "-",
    loading: false,
  });
  const [select, setSelect] = useState({
    blockState: null,
    unitState: null,
  });
  const [unitList, setUnitList] = useState(null);

  // effect
  useEffect(() => {
    register({ name: "blockId" });
    register({ name: "unitId" });
  }, [register]);

  // functions
  const initState = () => {
    setValue("blockId", undefined);
    setValue("unitId", undefined);

    setSelect({
      blockState: null,
      unitState: null,
    });
    setImage({
      preview: "",
      raw: "",
      vehicleNo: "",
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!submitting) {
      setOpen(false);
      initState();
    }
  };

  const handleImageChange = async (e) => {
    setImage({
      ...image,
      vehicleNo: "Loading...",
      loading: true,
    });

    var file = e.target.files[0];

    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios({
        method: "post",
        url: "https://plug.gospark.my/api/vehicle/verifyPlate",
        data: formData,
        headers: { "content-type": "application/octet-stream" },
      });

      if (response.data.status === "successful") {
        var maxWidth = 300;
        var maxHeight = 300;

        resize(file, maxWidth, maxHeight, (resizedDataUrl) => {
          setImage({
            preview: resizedDataUrl,
            raw: file,
            vehicleNo: response.data.raw.plate,
            loading: false,
          });
        });
      } else {
        setImage({
          ...image,
          vehicleNo: "-",
          loading: false,
        });

        setError("file", "unableExtract");
      }
    } catch (error) {
      console.log("response", error.response);
    }
  };

  const handleBlockChange = (e, value) => {
    setUnitList(null);

    if (value) {
      setSelect({ blockState: value, unitState: null });
      setValue("blockId", value.id);

      setUnitList(units.filter((unit) => unit.block_id === value.id));
    } else {
      setValue("blockId", null);
      setValue("unitId", null);
      setSelect({ blockState: null, unitState: null });
    }
  };

  const resize = (file, maxWidth, maxHeight, fn) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      var dataUrl = event.target.result;

      var image = new Image();
      image.src = dataUrl;
      image.onload = () => {
        var resizedDataUrl = resizeImage(image, maxWidth, maxHeight, 0.7);
        fn(resizedDataUrl);
      };
    };
  };

  const resizeImage = (image, maxWidth, maxHeight, quality) => {
    var canvas = document.createElement("canvas");

    var width = image.width;
    var height = image.height;

    if (width > height) {
      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
      }
    }

    canvas.width = width;
    canvas.height = height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, width, height);

    return canvas.toDataURL("image/jpeg", quality);
  };

  const onSubmit = async (value) => {
    if (!submitting) {
      setSubmitting(true);
      let data = {
        access: {
          vehicle_group: value.vehicleGroup,
        },

        attempted: 0,
        card_type: "lpr",
        created_at: new Date(),
        created_by: {
          user_id: auth.uid,
          name: profile.name,
        },
        identity_number: value.vehicleNumber,
        parking_lot_no: value.parkingLot,
        profile_name: value.profileName,
        profile_type: "resident",
        property_id: selectedPropertyId,
        status: "active",
        sticker_serial_no: value.stickerNo,
        unit: {
          block_id: value.blockId,
          block_name: value.block,
          unit_id: value.unitId,
          unit_name: value.unit,
        },
        // file_name: fileName,
        vehicle_make: value.vehicleMake,
        vehicle_model: value.vehicleModel,
        vehicle_colour: value.vehicleColour,
        vendor: {
          type: "Tapway",
        },
      };
      const createdDate = new Date();
      const doc = firestore.collection("ac_profiles").doc();
      doc.set(data).then(() => {
        firestore
          .collection("ac_audit_trails")
          .add({
            action: "Card Added",
            created_at: createdDate,
            created_by: {
              user_id: auth.uid,
              name: profile.name,
            },
            description: `${Capitalized(
              profile.name
            )} had added resident card ${value.vehicleNumber}`,
            module: "access_card",
            profile: {
              id: doc.id,
              identity_number: value.vehicleNumber,
            },
            values: data,
          })
          .then(() => {
            setSubmitting(false);
            handleClose();
            snackbar.enqueueSnackbar(
              `Vehicle No. ${value.identityNumber} had been added successfully`,
              {
                variant: "success",
              }
            );
          })
          .catch((e) => {
            setSubmitting(false);
            handleClose();
            snackbar.enqueueSnackbar(e.message, {
              variant: "error",
              persist: false,
            });
          });
      });

      if (vendor?.token != null) {
        const now = moment(new Date()).tz("Asia/Kuala_Lumpur").toDate();
        const diff = moment(
          moment(now).diff(moment(vendor?.token_last_updated.toDate()))
        );
        const diffDuration = moment.duration(diff);
        if (diffDuration.minutes() >= 25) {
          axios
            .post(
              `http://edge-remote.gotapway.com:15001/login`,
              {
                username: "jagaapp",
                password: "J@g@App123!",
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              firestore
                .collection("apnr_vendors")
                .doc(selectedVendor)
                .set(
                  {
                    token: res.data.token,
                    token_last_updated: new Date(),
                  },
                  { merge: true }
                )
                .catch((e) => {
                  setSubmitting(false);
                  handleClose();
                  snackbar.enqueueSnackbar(e.message, {
                    variant: "error",
                    persist: false,
                  });
                });
              axios
                .post(
                  `http://edge-remote.gotapway.com:15001/add_to_vehicle_mgmt`,
                  {
                    lp: value.vehicleNumber,
                    parker_status: "whitelist",
                    parker_type: "season",
                    name: value.profileName,
                    vehicle_make: value.vehicleMake,
                    group_id: value.vehicleGroup,
                    valid_from: moment(new Date()).format(
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                    valid_until: moment(
                      moment(new Date()).add(30, "y").toDate()
                    ).format("YYYY-MM-DD HH:mm:ss"),
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + res.data.token,
                    },
                  }
                )
                .then((res) => {
                  setSubmitting(false);
                })
                .catch((e) => {
                  setSubmitting(false);
                  handleClose();
                  snackbar.enqueueSnackbar(e.message, {
                    variant: "error",
                    persist: false,
                  });
                });
            })
            .catch((e) => {
              setSubmitting(false);
              handleClose();
              snackbar.enqueueSnackbar(e.message, {
                variant: "error",
                persist: false,
              });
            });
        } else {
          axios
            .post(
              `http://edge-remote.gotapway.com:15001/add_to_vehicle_mgmt`,
              {
                lp: value.vehicleNumber,
                parker_status: "whitelist",
                parker_type: "season",
                name: value.profileName,
                vehicle_make: value.vehicleMake,
                group_id: value.vehicleGroup,
                valid_from: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                valid_until: moment(
                  moment(new Date()).add(30, "y").toDate()
                ).format("YYYY-MM-DD HH:mm:ss"),
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + vendor?.token,
                },
              }
            )
            .then((res) => {
              setSubmitting(false);
            })
            .catch((e) => {
              setSubmitting(false);
              handleClose();
              snackbar.enqueueSnackbar(e.message, {
                variant: "error",
                persist: false,
              });
            });
        }
      } else {
        axios
          .post(
            `http://edge-remote.gotapway.com:15001/login`,
            {
              username: "jagaapp",
              password: "J@g@App123!",
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            firestore
              .collection("apnr_vendors")
              .doc(selectedVendor)
              .set(
                {
                  token: res.data.token,
                  token_last_updated: new Date(),
                },
                { merge: true }
              )
              .catch((e) => {
                setSubmitting(false);
                handleClose();
                snackbar.enqueueSnackbar(e.message, {
                  variant: "error",
                  persist: false,
                });
              });
            axios
              .post(
                `http://edge-remote.gotapway.com:15001/add_to_vehicle_mgmt`,
                {
                  lp: value.vehicleNumber,
                  parker_status: "whitelist",
                  parker_type: "season",
                  name: value.profileName,
                  vehicle_make: value.vehicleMake,
                  group_id: value.vehicleGroup,
                  valid_from: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                  valid_until: moment(
                    moment(new Date()).add(30, "y").toDate()
                  ).format("YYYY-MM-DD HH:mm:ss"),
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + res.data.token,
                  },
                }
              )
              .then((res) => {
                setSubmitting(false);
              })
              .catch((e) => {
                setSubmitting(false);
                handleClose();
                snackbar.enqueueSnackbar(e.message, {
                  variant: "error",
                  persist: false,
                });
              });
          })
          .catch((e) => {});
      }
    }
  };

  return (
    <div>
      {dashboardButton ? (
        <DashboardButton
          icon={<DriveEtaIcon />}
          onClick={handleClickOpen}
          title="Add Resident Vehicle"
        />
      ) : (
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          add vehicle
        </Button>
      )}

      <form
        noValidate
        xs={12}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <Dialog
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={handleClose}
          open={open}
          fullWidth={true}
          maxWidth="md"
          scroll="paper"
        >
          <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>
            <div>
              <Typography
                className={classes.modalTitle}
                variant="h3"
                component="h2"
              >
                Add Vehicle
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent dividers>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <Grid
                item
                container
                direction="row"
                spacing={2}
                md={7}
                justify="center"
                alignItems="flex-start"
              >
                <Grid item md={12}>
                  <Typography variant="h4" component="h1">
                    Vehicle Details
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Autocomplete
                    autoHighlight
                    value={
                      select.blockState !== null ? select.blockState : null
                    }
                    options={blocks}
                    getOptionLabel={(option) => option.name}
                    onChange={handleBlockChange}
                    renderInput={(params) => (
                      <TextField
                        error={!!errors.block}
                        helperText={
                          errors.block &&
                          vehicleValidate.block.message[errors.block.type]
                        }
                        {...params}
                        label="Choose a block *"
                        fullWidth
                        size="small"
                        name="block"
                        autoComplete="off"
                        variant="outlined"
                        inputRef={register(vehicleValidate.block.rules)}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12}>
                  <Autocomplete
                    autoHighlight
                    value={select.unitState !== null ? select.unitState : null}
                    disabled={!Boolean(watchBlock)}
                    options={
                      unitList
                        ? unitList.sort(
                            (a, b) =>
                              -b.house_number.localeCompare(a.house_number)
                          )
                        : []
                    }
                    getOptionLabel={(option) => option.house_number}
                    onChange={(e, value) => {
                      if (value) {
                        setSelect({ ...select, unitState: value });
                        setValue("unitId", value.id);
                      } else {
                        setSelect({ ...select, unitState: null });
                        setValue("unitId", null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={!!errors.block}
                        helperText={
                          errors.block &&
                          vehicleValidate.block.message[errors.block.type]
                        }
                        {...params}
                        label="Choose a unit *"
                        fullWidth
                        size="small"
                        name="unit"
                        autoComplete="off"
                        variant="outlined"
                        inputRef={register(vehicleValidate.block.rules)}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "disabled",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    size="small"
                    id="profileName"
                    name="profileName"
                    label="Name *"
                    type="text"
                    variant="outlined"
                    autoComplete="off"
                    inputRef={register(vehicleValidate.profileName.rules)}
                    helperText={
                      errors.profileName &&
                      vehicleValidate.profileName.message[
                        errors.profileName.type
                      ]
                    }
                    error={!!errors.profileName}
                    fullWidth
                  />
                </Grid>
                {/* <Grid item md={12}>
                  <Box
                    padding={1}
                    marginBottom={1}
                    border="dotted"
                    textAlign="center"
                  >
                    {image.preview ? (
                      <label htmlFor="outlined-button-file">
                        <img
                          src={vehicleImage ? vehicleImage : image.preview}
                          alt="animage"
                          className={classes.image}
                          maxheight="300"
                          style={{ padding: theme.spacing(2) }}
                        />
                      </label>
                    ) : image.loading ? (
                      <Grid
                        container
                        lg={12}
                        alignItems="center"
                        justify="center"
                        style={{ height: "150px" }}
                      >
                        <CircularProgress />
                      </Grid>
                    ) : (
                      <label htmlFor="outlined-button-file">
                        <div style={{ height: "150px" }}>
                          <React.Fragment>
                            <AddPhotoAlternateOutlinedIcon
                              style={{ width: 60, height: 60 }}
                              color={"primary"}
                            />
                            <Typography variant="h6" component="h1">
                              {"Drag & Drop an image here"}
                            </Typography>
                            <Typography variant="h6" component="h1">
                              {"*Only jpg,jpeg & png file"}
                            </Typography>
                            <Typography variant="h6" component="h1">
                              {"*File size < 5mb"}
                            </Typography>
                          </React.Fragment>
                        </div>
                      </label>
                    )}

                    <input
                      accept="image/*"
                      className={classes.input}
                      id="outlined-button-file"
                      name="file"
                      type="file"
                      onChange={handleImageChange}
                      ref={register(vehicleValidate.file.rules)}
                    />
                  </Box>
                </Grid> */}
                <Grid item md={12}>
                  <TextField
                    size="small"
                    margin="dense"
                    id="vehicleNumber"
                    name="vehicleNumber"
                    label="Vehicle No."
                    type="text"
                    readOnly={true}
                    inputRef={register}
                    autoComplete="off"
                    // value={image.vehicleNo ? image.vehicleNo : "-"}
                    fullWidth
                    helperText={
                      errors.file &&
                      vehicleValidate.file.message[errors.file.type]
                    }
                    error={!!errors.file}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    size="small"
                    margin="dense"
                    id="vehicleGroup"
                    name="vehicleGroup"
                    label="Vehicle Group."
                    type="text"
                    readOnly={true}
                    inputRef={register}
                    autoComplete="off"
                    // value={image.vehicleNo ? image.vehicleNo : "-"}
                    fullWidth
                    // helperText={
                    //   errors.file &&
                    //   vehicleValidate.file.message[errors.file.type]
                    // }
                    // error={!!errors.file}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                spacing={2}
                md={5}
                justify="center"
                alignItems="flex-start"
              >
                <Grid item md={12}>
                  <Typography variant="h4" component="h1">
                    Other Details
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <TextField
                    size="small"
                    id="vehicleMake"
                    name="vehicleMake"
                    label="Vehicle Make"
                    type="text"
                    inputRef={register}
                    autoComplete="off"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    id="vehicleModel"
                    name="vehicleModel"
                    label="Vehicle Model"
                    type="text"
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                    inputRef={register}
                    fullWidth
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    id="vehicleColour"
                    name="vehicleColour"
                    label="Vehicle Colour"
                    type="text"
                    variant="outlined"
                    size="small"
                    inputRef={register}
                    fullWidth
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    id="parkingLot"
                    name="parkingLot"
                    label="Parking Lot No."
                    type="text"
                    variant="outlined"
                    size="small"
                    inputRef={register}
                    fullWidth
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    id="stickerNo"
                    name="stickerNo"
                    label="Sticker No."
                    type="text"
                    variant="outlined"
                    size="small"
                    inputRef={register}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <ButtonProgress
              type="submit"
              containName="Save"
              margin={false}
              loading={submitting}
              disabled={submitting}
              onClick={handleSubmit(onSubmit)}
            />
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default AddVehicleDialog;
