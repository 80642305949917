import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { ShakeHorizontal } from "reshake";

//material-ui
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//styles
const useStyles = makeStyles((theme) => ({
  wrapper: (margin) => ({
    margin: margin ? theme.spacing(1) : theme.spacing(0),
    position: "relative",
    whiteSpace: "nowrap",
  }),
  buttonProgress: {
    color: "primary",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

function ButtonProgress({
  className,
  type,
  loading,
  onClick,
  containName,
  disabled,
  margin = true,
  animate,
  variant = "contained",
  color = "primary",
  ...rest
}) {
  const classes = useStyles(margin);
  const [runAnimate, setRunAnimate] = useState(false);

  useEffect(() => {
    let timer1;

    if (animate) {
      setRunAnimate(true);
      timer1 = setTimeout(() => setRunAnimate(false), 500);
    } else {
      setRunAnimate(false);
      clearTimeout(timer1);
    }

    return () => {
      setRunAnimate(false);
      clearTimeout(timer1);
    };
  }, [animate, setRunAnimate]);

  return (
    <div className={clsx(classes.wrapper, className)}>
      <ShakeHorizontal
        fixed={true}
        fixedStop={false}
        freez={false}
        active={runAnimate}
      >
        <Button
          type={type}
          variant={variant}
          color={loading ? "default" : color}
          onClick={onClick}
          disabled={loading || disabled}
          {...rest}
        >
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
          {containName}
        </Button>
      </ShakeHorizontal>
    </div>
  );
}

ButtonProgress.propTypes = {
  type: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.any,
  containName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default React.memo(ButtonProgress);
