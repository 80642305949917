import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDialog } from "muibox";
import { useHistory } from "react-router-dom";
import { actionTypes } from "redux-firestore";

// components
import { AvatarProfileImage } from "components";

// firebase
import { useFirebase } from "react-redux-firebase";

// material-icons
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";

// material-ui
import {
  Box,
  Button,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { makeStyles, fade } from "@material-ui/core/styles";

//redux
import { toggleSelectProperty } from "stores/main/MainActions";

// styles
const useStyles = makeStyles((theme) => ({
  profileButton: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    boxShadow: "none",
  },
  iconButton: {
    marginRight: theme.spacing(1),
  },
}));

const ProfileMenu = () => {
  const classes = useStyles();
  const history = useHistory();
  const dialog = useDialog();
  const dispatch = useDispatch();
  const { logout } = useFirebase();

  // useSelector
  const { name } = useSelector((state) => state.firebase.profile);
  const properties = useSelector((state) => state.main.properties);
  const selectedProperty = useSelector((state) => state.main.selectedProperty);
  const propertyName = useSelector((state) => {
    return state.firestore.data.properties
      ? state.firestore.data.properties[selectedProperty]?.name
      : "";
  });

  // states
  const [anchorEl, setAnchorEl] = useState(null);
  // const [propertyLength, setPropertyLength] = useState(0);

  // useEffect(() => {
  //   if (properties) {
  //     setPropertyLength(properties.length);
  //   }
  // }, [properties]);

  // functions
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleSelectProperty() {
    handleClose();
    dispatch(toggleSelectProperty());
  }

  const logOut = () => {
    handleClose();
    dialog
      .confirm({
        title: "Logout",
        message: "Are you sure you want to logout?",
        ok: { color: "primary", text: "OK", variant: "contained" },
        cancel: { color: "default", text: "Cancel", variant: "contained" },
      })
      .then(() =>
        logout().then(() => {
          history.push("/");
          localStorage.clear();
          // Clear redux-firestore state on logout
          dispatch({ type: actionTypes.CLEAR_DATA });
        })
      )
      .catch(() => null);
  };

  return (
    <Box display="flex">
      <Hidden smDown>
        <Button
          disableFocusRipple={true}
          disableRipple={true}
          size="small"
          color="primary"
          variant="contained"
          className={classes.profileButton}
          aria-controls="profile-menu"
          aria-haspopup="true"
          onClick={handleClick}
          startIcon={<AvatarProfileImage size="small" />}
          endIcon={<ArrowDropDownIcon />}
        >
          <Hidden smDown>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-start"
              spacing={0}
            >
              <Grid item xs={12}>
                <Typography noWrap variant="caption" style={{ color: "white" }}>
                  {name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography noWrap variant="caption" style={{ color: "white" }}>
                  {propertyName}
                </Typography>
              </Grid>
            </Grid>
          </Hidden>
        </Button>
      </Hidden>

      <Hidden mdUp>
        <IconButton
          size="small"
          aria-controls="profile-menu"
          aria-haspopup="true"
          className={classes.iconButton}
          onClick={handleClick}
        >
          <AvatarProfileImage size="big" />
        </IconButton>
      </Hidden>

      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {properties.length > 1 && (
          <MenuItem onClick={handleSelectProperty}>Change Property</MenuItem>
        )}
        <MenuItem onClick={logOut}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

export default ProfileMenu;
