import * as type from "./CommonType";

// Sidebar
export const toggleNav = () => {
  return {
    type: type.TOGGLE_NAV,
  };
};

// Vehicle
export const toggleAddVehicleDialog = () => {
  return {
    type: type.TOGGLE_ADD_VEHICLE_DIALOG,
  };
};

export const toggleEditVehicleDialog = () => {
  return {
    type: type.TOGGLE_EDIT_VEHICLE_DIALOG,
  };
};



export const showLoading = (show) => {
  return {
    type: "SHOW_LOADING",
    payload: show,
  };
};

export const dismissLoading = () => {
  return {
    type: "DISMISS_LOADING",
  };
};
