import * as actionTypes from "./ActionTypes";

export const openDrawer = (type) => {
  return {
    type: actionTypes.OPEN_DRAWER,
    payload: type,
  };
};

export const closeDrawer = () => {
  return {
    type: actionTypes.CLOSE_DRAWER,
  };
};
export const openSideBar = () => {
  return {
    type: actionTypes.OPEN_SIDEBAR,
  };
};
export const closeSideBar = () => {
  return {
    type: actionTypes.CLOSE_SIDEBAR,
  };
};
// export const selectProperty = (propertyId, accessLevel) => {
//   localStorage.setItem("property-selected", propertyId);
//   localStorage.setItem("access-level", accessLevel);

//   return {
//     type: actionTypes.SELECT_PROPERTY,
//     payload: {
//       id: propertyId,
//       access_level: accessLevel,
//     },
//   };
// };

export const setCurrentVendor = (payload) => {
  // localStorage.setItem("selected-vendor", payload.id);

  return {
    type: actionTypes.SET_VENDOR_ID,
    payload: payload,
  };
};

export const setCurrentApnrVendor = (payload) => {
  // localStorage.setItem("selected-vendor", payload.id);

  return {
    type: actionTypes.SET_APNR_VENDOR_ID,
    payload: payload,
  };
};

export const toggleSelectProperty = () => {
  return {
    type: actionTypes.TOGGLE_SELECT_PROPERTY,
  };
};

export const setProperties = (properties) => {
  if (localStorage.getItem("selected-property") === null) {
    localStorage.setItem("selected-property", properties[0].id);
  }

  return {
    type: actionTypes.SET_PROPERTIES,
    payload: properties,
  };
};

export const setProperty = (id) => {
  localStorage.setItem("selected-property", id);

  return {
    type: actionTypes.SET_PROPERTY,
    payload: id,
  };
};

export const setRoles = (payload) => {
  return {
    type: actionTypes.SET_ROLES,
    payload,
  };
};
