import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";

// components (global)
import { FadeTransitions } from "components/Pages";

// components (local)
import { PropertyDialog, Sidebar, TopBar } from "./components";

// material-ui
import { useMediaQuery, LinearProgress } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// helpers
import { userIsAuthenticated } from "utility/authHelper";

// firebase
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: "116px",
  },
  content: {
    height: "100%",
  },
}));

const Main = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  // selector
  const selectedProperty = useSelector((state) => state.main.selectedProperty);
  const propertyDialogOpen = useSelector(
    (state) => state.main.selectPropertyToggle
  );

  // firestore
  useFirestoreConnect([
    {
      collection: "ac_profiles",
      where: [
        ["profile_type", "==", "resident"],
        ["property_id", "==", selectedProperty],
        ["vendor.type", "in", ["EntryPass", "Falco", "Soyal", "MicroEngine"]],
      ],
      orderBy: "identity_number",
      storeAs: "resident_cards",
    },
    {
      collection: "ac_profiles",
      where: [
        ["profile_type", "==", "visitor"],
        ["property_id", "==", selectedProperty],
        ["vendor.type", "in", ["EntryPass", "Falco", "Soyal", "MicroEngine"]],
      ],
      orderBy: "identity_number",
      storeAs: "visitor_cards",
    },
  ]);

  useFirestoreConnect([
    {
      collection: "ac_profiles",
      where: [
        ["card_type", "==", "lpr"],

        ["property_id", "==", selectedProperty],
        ["profile_type", "==", "resident"],
      ],
      orderBy: "identity_number",
      storeAs: "vehicles",
    },
  ]);

  useFirestoreConnect([
    {
      collection: "ac_requests",
      where: [["property_id", "==", selectedProperty]],
      storeAs: "requests",
    },
  ]);

  useFirestoreConnect([
    {
      collection: "properties",
      doc: selectedProperty,
      subcollections: [
        {
          collection: "blocks",
          orderBy: "name",
        },
      ],
      storeAs: "blocks",
    },
    {
      collection: "floors",
      where: [["property_id", "==", selectedProperty]],
    },
    {
      collection: "units",
      where: [["property_id", "==", selectedProperty]],
    },
  ]);

  return (
    <FadeTransitions>
      <div
        className={clsx({
          [classes.root]: true,
          [classes.shiftContent]: isDesktop,
        })}
      >
        {propertyDialogOpen && <PropertyDialog />}

        <TopBar />
        <Sidebar />

        <Suspense fallback={<LinearProgress color="secondary" />}>
          <main className={classes.content}>{children}</main>
        </Suspense>
      </div>
    </FadeTransitions>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default userIsAuthenticated(Main);
