import * as actionTypes from "./ActionType";

export const toggleAddServiceDialogOpen = (playload) => {
  return {
    type: actionTypes.TOGGLE_ADD_SERVICE_DIALOG_OPEN,
    playload: playload,
  };
};

export const toggleAddServiceDialogClose = () => {
  return {
    type: actionTypes.TOGGLE_ADD_SERVICE_DIALOG_CLOSE,
  };
};

export const toggleEditServiceDialogOpen = (playload) => {
  return {
    type: actionTypes.TOGGLE_EDIT_SERVICE_DIALOG_OPEN,
    playload: playload,
  };
};

export const toggleEditServiceDialogClose = () => {
  return {
    type: actionTypes.TOGGLE_EDIT_SERVICE_DIALOG_CLOSE,
  };
};

export const toggleEditDeviceDialogOpen = (playload) => {
  return {
    type: actionTypes.TOGGLE_EDIT_DEVICE_DIALOG_OPEN,
    playload: playload,
  };
};

export const toggleEditDeviceDialogClose = () => {
  return {
    type: actionTypes.TOGGLE_EDIT_DEVICE_DIALOG_CLOSE,
  };
};

export const toggleEditDoorAccessDialogOpen = (playload) => {
  return {
    type: actionTypes.TOGGLE_EDIT_DOOR_ACCESS_DIALOG_OPEN,
    playload: playload,
  };
};

export const toggleEditDoorAccessDialogClose = () => {
  return {
    type: actionTypes.TOGGLE_EDIT_DOOR_ACCESS_DIALOG_CLOSE,
  };
};

export const toggleEditFloorAccessDialogOpen = (payload) => {
  return {
    type: actionTypes.TOGGLE_EDIT_FLOOR_ACCESS_DIALOG_OPEN,
    payload: payload,
  };
};

export const toggleEditFloorAccessDialogClose = () => {
  return {
    type: actionTypes.TOGGLE_EDIT_FLOOR_ACCESS_DIALOG_CLOSE,
  };
};

export const toggleEditVisitPurposeActionDialogOpen = (payload) => {
  return {
    type: actionTypes.TOGGLE_EDIT_VISIT_PURPOSE_ACTION_DIALOG_OPEN,
    payload: payload,
  };
};

export const toggleEditVisitPurposeActionDialogClose = () => {
  return {
    type: actionTypes.TOGGLE_EDIT_VISIT_PURPOSE_ACTION_DIALOG_CLOSE,
  };
};
