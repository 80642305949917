import React, { useState } from "react";
import { useSelector } from "react-redux";

// components (local)
import { DetailsTab } from "./components";

// firebase
import { isLoaded, isEmpty } from "react-redux-firebase";

// material-ui
import {
  Button,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles(theme => ({
  root: {},
  header: {
    paddingBottom: theme.spacing(1),
    flexShrink: 0,
    display: "flex"
  },
  content: { padding: theme.spacing(1) },
  tabContent: { padding: theme.spacing(2) },
  listOdd: { backgroundColor: "#f9f9f9" },
  listTitle: { width: theme.spacing(5) }
}));

const DoorAccessDrawer = () => {
  const classes = useStyles();

  // selector
  const id = useSelector(state => state.main.toggleDrawer.parameterId);
  const blockAccessSelector = useSelector(
    state => state.firestore.data.blockAccess[id]
  );

  // state
  const [tab, setTab] = useState("details");

  // functions
  const onClose = () => {};

  const handleTabsChange = (event, value) => {
    setTab(value);
  };

  if (isLoaded(blockAccessSelector) && isEmpty(blockAccessSelector))
    return null;

  return (
    <div>
      <Grid container className={classes.header} justify="space-between">
        <Grid>
          <Button onClick={onClose} size="small">
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </Grid>
      </Grid>

      <Divider />

      <Grid
        direction="row"
        alignItems="flex-start"
        container
        justify="space-between"
        className={classes.content}
      >
        <Grid item>
          <Typography component="h1" variant="h3">
            {blockAccessSelector.block_name}
          </Typography>
        </Grid>
        <Grid
          xs
          item
          alignItems="center"
          className={classes.header}
          container
          justify="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              // onClick={handleOpenEditUnit}
            >
              Edit Door Access
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Tabs
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        variant="scrollable"
      >
        <Tab label="Details" value="details" />
        <Tab label="Audit Logs" value="audit" />
      </Tabs>

      <Divider />

      {tab === "details" && <DetailsTab access={blockAccessSelector} />}
    </div>
  );
};

export default DoorAccessDrawer;
