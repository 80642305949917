export const featureList = {
  access_card: {
    label: "Access Card",
    open: false,
    sub_features: {
      resident: {
        label: "Resident",
        open: false,
        actions: {
          read: false,
          create: false,
          update: false,
          delete: false,
          suspend: false,
        },
      },
      visitor: {
        label: "Visitor",
        open: false,
        actions: {
          read: false,
          create: false,
          update: false,
          delete: false,
        },
      },
    },
  },
  access_card_settings: {
    label: "Access Card Settings",
    open: false,
    actions: {
      read: false,
    },
    sub_features: {
      general: {
        label: "General",
        open: false,
        actions: {
          read: false,
          create: false,
          update: false,
          update_suspend_settings: false,
          update_door_settings: false,
        },
      },
      device: {
        label: "Device",
        open: false,
        actions: {
          read: false,
          create: false,
          update: false,
        },
      },
      vendor_access: {
        label: "Vendor Access",
        open: false,
        actions: {
          read: false,
          update: false,
        },
      },
      door_access: {
        label: "Door Access",
        open: false,
        actions: {
          read: false,
          create: false,
          update: false,
        },
      },
      floor_access: {
        label: "Floor Access",
        open: false,
        actions: {
          read: false,
          create: false,
          update: false,
        },
      },
    },
  },
  access_role: {
    label: "Access Role",
    open: false,
    actions: {
      read: false,
      create: false,
      update: false,
      delete: false,
      invite: false,
    },
  },
  report: {
    label: "Report",
    open: false,
    sub_features: {
      access_card: {
        label: "Access Card",
        open: false,
        actions: {
          read: false,
          export: false,
        },
      },
      transaction: {
        label: "Transaction",
        open: false,
        actions: {
          read: false,
          export: false,
        },
      },
    },
  },
  request: {
    label: "Request",
    open: false,
    actions: {
      read: false,
      update: false,
    },
  },
  transaction: {
    label: "Transaction",
    open: false,
    actions: {
      read: false,
      export: false,
    },
  },
  vehicle: {
    label: "Vehicle",
    open: false,
    sub_features: {
      resident: {
        label: "Resident",
        open: false,
        actions: {
          read: false,
          create: false,
          update: false,
          delete: false,
        },
      },
      visitor: {
        label: "Visitor",
        open: false,
        actions: {
          read: false,
          create: false,
          update: false,
          delete: false,
        },
      },
    },
  },
  vehicle_settings: {
    label: "Vehicle Settings",
    open: false,
    actions: {
      read: false,
    },
  },
};
