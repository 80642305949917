import React, { useEffect, useState } from "react";
import { filter } from "lodash";

// helpers
import { settingsValidate } from "helpers/validation/settingsValidate";

// material-ui
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const Soyal = ({
  errors,
  register,
  unregister,
  settings,
  setValue,
  clearError
}) => {
  // variable
  const deviceTypes = [
    { id: 33, name: "Type 33 - 721 & 716" },
    { id: 36, name: "Type 36 - 821 & 829" }
  ];

  const sorting = [
    { id: 0, name: "0" },
    { id: 1, name: "1" }
  ];

  // state
  const [enableMotor, setEnableMotor] = useState(false);
  const [enablePedestrian, setEnablePedestrian] = useState(false);

  // effect
  useEffect(() => {
    register({ name: "visitorEntrySortingId" });
    register({ name: "visitorEntryTypeId" });
    register({ name: "visitorExitSortingId" });
    register({ name: "visitorExitTypeId" });

    register({ name: "motorEntrySortingId" });
    register({ name: "motorEntryTypeId" });
    register({ name: "motorExitSortingId" });
    register({ name: "motorExitTypeId" });

    register({ name: "pedestrianEntrySortingId" });
    register({ name: "pedestrianEntryTypeId" });
    register({ name: "pedestrianExitSortingId" });
    register({ name: "pedestrianExitTypeId" });

    // if (!enableMotor) {
    //   unregister([
    //     "motorEntrySortingId",
    //     "motorEntryTypeId",
    //     "motorExitSortingId",
    //     "motorExitTypeId"
    //   ]);
    // }

    // if (!enablePedestrian) {
    //   unregister([
    //     "pedestrianEntrySortingId",
    //     "pedestrianEntryTypeId",
    //     "pedestrianExitSortingId",
    //     "pedestrianExitTypeId"
    //   ]);
    // }
  }, [register]);

  useEffect(() => {
    if (settings) {
      if (settings[1]) {
        setEnableMotor(settings[1].enable);
      }

      if (settings[2]) {
        setEnablePedestrian(settings[2].enable);
      }

      setValue(
        "visitorEntrySortingId",
        settings[0] ? settings[0].entry.device_sorting : null
      );
      setValue(
        "visitorEntryTypeId",
        settings[0] ? settings[0].entry.device_type : null
      );
      setValue(
        "visitorExitSortingId",
        settings[0] ? settings[0].exit.device_sorting : null
      );
      setValue(
        "visitorExitTypeId",
        settings[0] ? settings[0].exit.device_type : null
      );

      setValue(
        "motorEntrySortingId",
        settings[1] ? settings[1].entry.device_sorting : null
      );
      setValue(
        "motorEntryTypeId",
        settings[1] ? settings[1].entry.device_type : null
      );
      setValue(
        "motorExitSortingId",
        settings[1] ? settings[1].exit.device_sorting : null
      );
      setValue(
        "motorExitTypeId",
        settings[1] ? settings[1].exit.device_type : null
      );

      setValue(
        "pedestrianEntrySortingId",
        settings[2] ? settings[2].entry.device_sorting : null
      );
      setValue(
        "pedestrianEntryTypeId",
        settings[2] ? settings[2].entry.device_type : null
      );
      setValue(
        "pedestrianExitSortingId",
        settings[2] ? settings[2].exit.device_sorting : null
      );
      setValue(
        "pedestrianExitTypeId",
        settings[2] ? settings[2].exit.device_type : null
      );
    }
  }, [settings, setValue]);

  // functions
  const handleMotorChange = event => {
    setEnableMotor(event.target.checked);

    if (!event.target.checked) {
      clearError("motorEntryId");
      clearError("motorEntrySorting");
      clearError("motorEntryType");
      clearError("motorExitId");
      clearError("motorExitSorting");
      clearError("motorExitType");

      unregister("motorEntryId");
      unregister("motorEntrySorting");
      unregister("motorEntryType");
      unregister("motorExitId");
      unregister("motorExitSorting");
      unregister("motorExitType");
    }
  };

  const handlePedestrianChange = event => {
    setEnablePedestrian(event.target.checked);

    if (!event.target.checked) {
      clearError("pedestrianEntryId");
      clearError("pedestrianEntrySorting");
      clearError("pedestrianEntryType");
      clearError("pedestrianExitId");
      clearError("pedestrianExitSorting");
      clearError("pedestrianExitType");

      unregister("pedestrianEntryId");
      unregister("pedestrianEntrySorting");
      unregister("pedestrianEntryType");
      unregister("pedestrianExitId");
      unregister("pedestrianExitSorting");
      unregister("pedestrianExitType");
    }
  };

  return (
    <Grid container spacing={3}>
      {/* Visitor  */}
      <Grid item container md={4} spacing={2}>
        <Grid item md={12}>
          <Typography variant="h6">Visitor Lane</Typography>
        </Grid>

        <Grid item md={12}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={true}
                name="enableVisitor"
                checked={true}
                color="primary"
              />
            }
            label="Enable"
          />
        </Grid>

        <Grid item md={12}>
          <Typography variant="caption">Entry</Typography>
        </Grid>
        <Grid item md={7}>
          <TextField
            size="small"
            id="visitorEntryId"
            name="visitorEntryId"
            label="Device ID *"
            type="text"
            variant="outlined"
            autoComplete="off"
            defaultValue={
              settings && settings[0] && settings[0].entry
                ? settings[0].entry.device_id
                : ""
            }
            inputRef={register(settingsValidate.deviceId.rules)}
            helperText={
              errors.visitorEntryId &&
              settingsValidate.deviceId.message[errors.visitorEntryId.type]
            }
            error={!!errors.visitorEntryId}
            fullWidth
          />
        </Grid>
        <Grid item md={5}>
          <Autocomplete
            options={sorting}
            getOptionLabel={option => option.name}
            onChange={(e, value) => {
              if (value) {
                setValue("visitorEntrySortingId", value.id);
              } else {
                setValue("visitorEntrySortingId", null);
              }
            }}
            defaultValue={
              settings && settings[0] && settings[0].entry
                ? sorting.find(sorting => {
                    return sorting.id === settings[0].entry.device_sorting;
                  })
                : null
            }
            renderInput={params => (
              <TextField
                inputRef={register(settingsValidate.sorting.rules)}
                error={!!errors.visitorEntrySorting}
                helperText={
                  errors.visitorEntrySorting &&
                  settingsValidate.sorting.message[
                    errors.visitorEntrySorting.type
                  ]
                }
                {...params}
                label="Sorting *"
                fullWidth
                size="small"
                name="visitorEntrySorting"
                autoComplete="off"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item md={12}>
          <Autocomplete
            options={deviceTypes}
            getOptionLabel={option => option.name}
            onChange={(e, value) => {
              if (value) {
                setValue("visitorEntryTypeId", value.id);
              } else {
                setValue("visitorEntryTypeId", null);
              }
            }}
            defaultValue={
              settings && settings[0] && settings[0].entry
                ? deviceTypes.find(type => {
                    return type.id === settings[0].entry.device_type;
                  })
                : null
            }
            renderInput={params => (
              <TextField
                inputRef={register(settingsValidate.deviceType.rules)}
                error={!!errors.visitorEntryType}
                helperText={
                  errors.visitorEntryType &&
                  settingsValidate.deviceType.message[
                    errors.visitorEntryType.type
                  ]
                }
                {...params}
                label="Choose a device type *"
                fullWidth
                size="small"
                name="visitorEntryType"
                autoComplete="off"
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item md={12}>
          <Typography variant="caption">Exit</Typography>
        </Grid>
        <Grid item md={7}>
          <TextField
            size="small"
            id="visitorExitId"
            name="visitorExitId"
            label="Device ID *"
            type="text"
            variant="outlined"
            autoComplete="off"
            defaultValue={
              settings && settings[0] && settings[0].entry
                ? settings[0].exit.device_id
                : ""
            }
            inputRef={register(settingsValidate.deviceId.rules)}
            helperText={
              errors.visitorExitId &&
              settingsValidate.deviceId.message[errors.visitorExitId.type]
            }
            error={!!errors.visitorExitId}
            fullWidth
          />
        </Grid>
        <Grid item md={5}>
          <Autocomplete
            options={sorting}
            getOptionLabel={option => option.name}
            onChange={(e, value) => {
              if (value) {
                setValue("visitorExitSortingId", value.id);
              } else {
                setValue("visitorExitSortingId", null);
              }
            }}
            defaultValue={
              settings && settings[0] && settings[0].exit
                ? sorting.find(sorting => {
                    return sorting.id === settings[0].exit.device_sorting;
                  })
                : null
            }
            renderInput={params => (
              <TextField
                inputRef={register(settingsValidate.sorting.rules)}
                error={!!errors.visitorExitSorting}
                helperText={
                  errors.visitorExitSorting &&
                  settingsValidate.sorting.message[
                    errors.visitorExitSorting.type
                  ]
                }
                {...params}
                fullWidth
                label="Sorting *"
                size="small"
                name="visitorExitSorting"
                autoComplete="off"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item md={12}>
          <Autocomplete
            options={deviceTypes}
            getOptionLabel={option => option.name}
            onChange={(e, value) => {
              if (value) {
                setValue("visitorExitTypeId", value.id);
              } else {
                setValue("visitorExitTypeId", null);
              }
            }}
            defaultValue={
              settings && settings[0] && settings[0].exit
                ? deviceTypes.find(type => {
                    return type.id === settings[0].exit.device_type;
                  })
                : null
            }
            renderInput={params => (
              <TextField
                inputRef={register(settingsValidate.deviceType.rules)}
                error={!!errors.visitorExitType}
                helperText={
                  errors.visitorExitType &&
                  settingsValidate.deviceType.message[
                    errors.visitorExitType.type
                  ]
                }
                {...params}
                label="Choose a device type *"
                fullWidth
                size="small"
                name="visitorExitType"
                autoComplete="off"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>

      {/* Motor */}
      <Grid item container md={4} spacing={2}>
        <Grid item md={12}>
          <Typography variant="h6">Motor Lane</Typography>
        </Grid>

        <Grid item md={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="enableMotor"
                checked={enableMotor}
                onChange={handleMotorChange}
                color="primary"
                inputRef={register}
              />
            }
            label="Enable"
          />
        </Grid>

        <Grid item md={12}>
          <Typography variant="caption">Entry</Typography>
        </Grid>
        <Grid item md={7}>
          <TextField
            disabled={!enableMotor}
            size="small"
            id="motorEntryId"
            name="motorEntryId"
            label="Device ID *"
            type="text"
            variant="outlined"
            autoComplete="off"
            defaultValue={
              settings && settings[1] && settings[1].entry
                ? settings[1].entry.device_id
                : ""
            }
            inputRef={
              enableMotor
                ? register(settingsValidate.deviceId.rules)
                : unregister("motorEntryId")
            }
            helperText={
              errors.motorEntryId &&
              settingsValidate.deviceId.message[errors.motorEntryId.type]
            }
            error={!!errors.motorEntryId}
            fullWidth
          />
        </Grid>
        <Grid item md={5}>
          <Autocomplete
            disabled={!enableMotor}
            options={sorting}
            getOptionLabel={option => option.name}
            onChange={(e, value) => {
              if (enableMotor) {
                if (value) {
                  setValue("motorEntrySortingId", value.id);
                } else {
                  setValue("motorEntrySortingId", null);
                }
              }
            }}
            defaultValue={
              settings && settings[1] && settings[1].entry
                ? sorting.find(sorting => {
                    return sorting.id === settings[1].entry.device_sorting;
                  })
                : null
            }
            renderInput={params => (
              <TextField
                inputRef={
                  enableMotor
                    ? register(settingsValidate.sorting.rules)
                    : unregister("motorEntrySortingId")
                }
                error={!!errors.motorEntrySorting}
                helperText={
                  errors.motorEntrySorting &&
                  settingsValidate.sorting.message[
                    errors.motorEntrySorting.type
                  ]
                }
                {...params}
                label="Sorting *"
                fullWidth
                size="small"
                name="motorEntrySorting"
                autoComplete="off"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item md={12}>
          <Autocomplete
            disabled={!enableMotor}
            options={deviceTypes}
            getOptionLabel={option => option.name}
            onChange={(e, value) => {
              if (enableMotor) {
                if (value) {
                  setValue("motorEntryTypeId", value.id);
                } else {
                  setValue("motorEntryTypeId", null);
                }
              }
            }}
            defaultValue={
              settings && settings[1] && settings[1].entry
                ? deviceTypes.find(type => {
                    return type.id === settings[1].entry.device_type;
                  })
                : null
            }
            renderInput={params => (
              <TextField
                inputRef={
                  enableMotor
                    ? register(settingsValidate.deviceType.rules)
                    : unregister("motorEntryTypeId")
                }
                error={!!errors.motorEntryType}
                helperText={
                  errors.motorEntryType &&
                  settingsValidate.deviceType.message[
                    errors.motorEntryType.type
                  ]
                }
                {...params}
                label="Choose a device type *"
                fullWidth
                size="small"
                name="motorEntryType"
                autoComplete="off"
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item md={12}>
          <Typography variant="caption">Exit</Typography>
        </Grid>
        <Grid item md={7}>
          <TextField
            disabled={!enableMotor}
            size="small"
            id="motorExitId"
            name="motorExitId"
            label="Device ID *"
            type="text"
            variant="outlined"
            autoComplete="off"
            defaultValue={
              settings && settings[1] && settings[1].exit
                ? settings[1].exit.device_id
                : ""
            }
            inputRef={
              enableMotor
                ? register(settingsValidate.deviceId.rules)
                : unregister("motorExitId")
            }
            helperText={
              errors.motorExitId &&
              settingsValidate.deviceId.message[errors.motorExitId.type]
            }
            error={!!errors.motorExitId}
            fullWidth
          />
        </Grid>
        <Grid item md={5}>
          <Autocomplete
            disabled={!enableMotor}
            options={sorting}
            getOptionLabel={option => option.name}
            onChange={(e, value) => {
              if (enableMotor) {
                if (value) {
                  setValue("motorExitSortingId", value.id);
                } else {
                  setValue("motorExitSortingId", null);
                }
              }
            }}
            defaultValue={
              settings && settings[1] && settings[1].exit
                ? sorting.find(sorting => {
                    return sorting.id === settings[1].exit.device_sorting;
                  })
                : null
            }
            renderInput={params => (
              <TextField
                inputRef={
                  enableMotor
                    ? register(settingsValidate.sorting.rules)
                    : unregister("motorExitSortingId")
                }
                error={!!errors.motorExitSorting}
                helperText={
                  errors.motorExitSorting &&
                  settingsValidate.sorting.message[errors.motorExitSorting.type]
                }
                {...params}
                fullWidth
                label="Sorting *"
                size="small"
                name="motorExitSorting"
                autoComplete="off"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item md={12}>
          <Autocomplete
            disabled={!enableMotor}
            options={deviceTypes}
            getOptionLabel={option => option.name}
            onChange={(e, value) => {
              if (enableMotor) {
                if (value) {
                  setValue("motorExitTypeId", value.id);
                } else {
                  setValue("motorExitTypeId", null);
                }
              }
            }}
            defaultValue={
              settings && settings[1] && settings[1].exit
                ? deviceTypes.find(type => {
                    return type.id === settings[1].exit.device_type;
                  })
                : null
            }
            renderInput={params => (
              <TextField
                inputRef={
                  enableMotor
                    ? register(settingsValidate.deviceType.rules)
                    : unregister("motorExitTypeId")
                }
                error={!!errors.motorExitType}
                helperText={
                  errors.motorExitType &&
                  settingsValidate.deviceType.message[errors.motorExitType.type]
                }
                {...params}
                label="Choose a device type *"
                fullWidth
                size="small"
                name="motorExitType"
                autoComplete="off"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>

      {/* Pedestrian */}
      <Grid item container md={4} spacing={2}>
        <Grid item md={12}>
          <Typography variant="h6">Pedestrian Lane</Typography>
        </Grid>

        <Grid item md={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="enablePedestrian"
                checked={enablePedestrian}
                onChange={handlePedestrianChange}
                color="primary"
                inputRef={register}
              />
            }
            label="Enable"
          />
        </Grid>

        <Grid item md={12}>
          <Typography variant="caption">Entry</Typography>
        </Grid>
        <Grid item md={7}>
          <TextField
            disabled={!enablePedestrian}
            size="small"
            id="pedestrianEntryId"
            name="pedestrianEntryId"
            label="Device ID *"
            type="text"
            variant="outlined"
            autoComplete="off"
            defaultValue={
              settings && settings[2] && settings[2].entry
                ? settings[2].entry.device_id
                : ""
            }
            inputRef={
              enablePedestrian
                ? register(settingsValidate.deviceId.rules)
                : unregister("pedestrianEntryId")
            }
            helperText={
              errors.pedestrianEntryId &&
              settingsValidate.deviceId.message[errors.pedestrianEntryId.type]
            }
            error={!!errors.pedestrianEntryId}
            fullWidth
          />
        </Grid>
        <Grid item md={5}>
          <Autocomplete
            disabled={!enablePedestrian}
            options={sorting}
            getOptionLabel={option => option.name}
            onChange={(e, value) => {
              if (enablePedestrian) {
                if (value) {
                  setValue("pedestrianEntrySortingId", value.id);
                } else {
                  setValue("pedestrianEntrySortingId", null);
                }
              }
            }}
            defaultValue={
              settings && settings[2] && settings[2].entry
                ? sorting.find(sorting => {
                    return sorting.id === settings[2].entry.device_sorting;
                  })
                : null
            }
            renderInput={params => (
              <TextField
                inputRef={
                  enablePedestrian
                    ? register(settingsValidate.sorting.rules)
                    : unregister("pedestrianEntrySortingId")
                }
                error={!!errors.pedestrianEntrySorting}
                helperText={
                  errors.pedestrianEntrySorting &&
                  settingsValidate.sorting.message[
                    errors.pedestrianEntrySorting.type
                  ]
                }
                {...params}
                label="Sorting *"
                fullWidth
                size="small"
                name="pedestrianEntrySorting"
                autoComplete="off"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item md={12}>
          <Autocomplete
            disabled={!enablePedestrian}
            options={deviceTypes}
            getOptionLabel={option => option.name}
            onChange={(e, value) => {
              if (enablePedestrian) {
                if (value) {
                  setValue("pedestrianEntryTypeId", value.id);
                } else {
                  setValue("pedestrianEntryTypeId", null);
                }
              }
            }}
            defaultValue={
              settings && settings[2] && settings[2].entry
                ? deviceTypes.find(type => {
                    return type.id === settings[2].entry.device_type;
                  })
                : null
            }
            renderInput={params => (
              <TextField
                inputRef={
                  enablePedestrian
                    ? register(settingsValidate.deviceType.rules)
                    : unregister("pedestrianEntryTypeId")
                }
                error={!!errors.pedestrianEntryType}
                helperText={
                  errors.pedestrianEntryType &&
                  settingsValidate.deviceType.message[
                    errors.pedestrianEntryType.type
                  ]
                }
                {...params}
                label="Choose a device type *"
                fullWidth
                size="small"
                name="pedestrianEntryType"
                autoComplete="off"
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item md={12}>
          <Typography variant="caption">Exit</Typography>
        </Grid>
        <Grid item md={7}>
          <TextField
            disabled={!enablePedestrian}
            size="small"
            id="pedestrianExitId"
            name="pedestrianExitId"
            label="Device ID *"
            type="text"
            variant="outlined"
            autoComplete="off"
            defaultValue={
              settings && settings[2] && settings[2].entry
                ? settings[2].exit.device_id
                : ""
            }
            inputRef={
              enablePedestrian
                ? register(settingsValidate.deviceId.rules)
                : unregister("pedestrianExitId")
            }
            helperText={
              errors.pedestrianExitId &&
              settingsValidate.deviceId.message[errors.pedestrianExitId.type]
            }
            error={!!errors.pedestrianExitId}
            fullWidth
          />
        </Grid>
        <Grid item md={5}>
          <Autocomplete
            disabled={!enablePedestrian}
            options={sorting}
            getOptionLabel={option => option.name}
            onChange={(e, value) => {
              if (enablePedestrian) {
                if (value) {
                  setValue("pedestrianExitSortingId", value.id);
                } else {
                  setValue("pedestrianExitSortingId", null);
                }
              }
            }}
            defaultValue={
              settings && settings[2] && settings[2].exit
                ? sorting.find(sorting => {
                    return sorting.id === settings[2].exit.device_sorting;
                  })
                : null
            }
            renderInput={params => (
              <TextField
                inputRef={
                  enablePedestrian
                    ? register(settingsValidate.sorting.rules)
                    : unregister("pedestrianExitSortingId")
                }
                error={!!errors.pedestrianExitSorting}
                helperText={
                  errors.pedestrianExitSorting &&
                  settingsValidate.sorting.message[
                    errors.pedestrianExitSorting.type
                  ]
                }
                {...params}
                fullWidth
                label="Sorting *"
                size="small"
                name="pedestrianExitSorting"
                autoComplete="off"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item md={12}>
          <Autocomplete
            disabled={!enablePedestrian}
            options={deviceTypes}
            getOptionLabel={option => option.name}
            onChange={(e, value) => {
              if (enablePedestrian) {
                if (value) {
                  setValue("pedestrianExitTypeId", value.id);
                } else {
                  setValue("pedestrianExitTypeId", null);
                }
              }
            }}
            defaultValue={
              settings && settings[2] && settings[2].exit
                ? deviceTypes.find(type => {
                    return type.id === settings[2].exit.device_type;
                  })
                : null
            }
            renderInput={params => (
              <TextField
                inputRef={
                  enablePedestrian
                    ? register(settingsValidate.deviceType.rules)
                    : unregister("pedestrianExitTypeId")
                }
                error={!!errors.pedestrianExitType}
                helperText={
                  errors.pedestrianExitType &&
                  settingsValidate.deviceType.message[
                    errors.pedestrianExitType.type
                  ]
                }
                {...params}
                label="Choose a device type *"
                fullWidth
                size="small"
                name="pedestrianExitType"
                autoComplete="off"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Soyal;
