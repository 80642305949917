import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { useSnackbar } from "notistack";
// components (global)
import DashboardButton from "components/Button/DashboardButton";
import ButtonProgress from "components/ButtonProgress";

// firebase
import { useFirebase, useFirestore } from "react-redux-firebase";

// form
import { useForm } from "react-hook-form";

// helpers
import { vehicleValidate } from "helpers/validation/vehicleValidate";
import { Capitalized } from "helpers/textHelpers";
// material icons
import {
  AddPhotoAlternateOutlined as AddPhotoAlternateOutlinedIcon,
  DriveEta as DriveEtaIcon,
} from "@material-ui/icons";
import moment from "moment-timezone";
// material-ui
import {
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
  },
  modalTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.white,
  },
  modalSubTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.white,
    padding: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  image: {
    maxWidth: "100%",
    height: "350px",
    alignItems: "center",
  },
  field: {
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  column2: {
    marginLeft: theme.spacing(1),
  },
  edit: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const AddVehicleGroupDialog = ({ dashboardButton, vehicle, vehicleImage }) => {
  const classes = useStyles();
  const firebase = useFirebase();
  const snackbar = useSnackbar();

  const firestore = useFirestore();
  const theme = useTheme();
  const { errors, handleSubmit, setError, setValue, register, watch } = useForm(
    {
      defaultValues: { vehicleNumber: "-" },
    }
  );

  // form
  const watchBlock = watch("blockId");

  // selector
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const selectedPropertyId = useSelector(
    (state) => state.main.selectedProperty
  );
  const vendors = useSelector((state) => state.firestore.ordered.vendors);
  const blocks = useSelector((state) => state.firestore.ordered.blocks);
  const units = useSelector((state) => state.firestore.ordered.units);
  const selectedVendor = useSelector((state) => state.main.selectedApnrVendor);
  const vendor = useSelector((state) => {
    return state.firestore.data.apnrVendors
      ? state.firestore.data.apnrVendors[selectedVendor]
      : null;
  });
  // state
  const [open, setOpen] = React.useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [token, setToken] = useState();
  const [image, setImage] = useState({
    preview: "",
    raw: "",
    vehicleNo: "-",
    loading: false,
  });
  const [select, setSelect] = useState({
    blockState: null,
    unitState: null,
  });
  const [unitList, setUnitList] = useState(null);

  // effect
  useEffect(() => {
    register({ name: "blockId" });
    register({ name: "unitId" });
  }, [register]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!submitting) {
      setOpen(false);
    }
  };

  const onSubmit = async (value) => {
    if (!submitting) {
      setSubmitting(true);
      let data = {
        vehicle_group: value.vehicleGroupId,
        name: value.groupName,
        vehicle_limit: parseInt(value.vehicleLimit),
      };

      const doc = firestore
        .collection("apnr_vendors")
        .doc(selectedVendor)
        .collection("vehicle_groups")
        .doc();

      doc.set(data).then(() => {
        if (vendor?.token != null) {
          const now = moment(new Date()).tz("Asia/Kuala_Lumpur").toDate();
          const diff = moment(
            moment(now).diff(moment(vendor?.token_last_updated.toDate()))
          );
          const diffDuration = moment.duration(diff);

          if (diffDuration.minutes() >= 25) {
            axios
              .post(
                `http://edge-remote.gotapway.com:15001/login`,
                {
                  username: "jagaapp",
                  password: "J@g@App123!",
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                firestore
                  .collection("apnr_vendors")
                  .doc(selectedVendor)
                  .set(
                    {
                      token: res.data.token,
                      token_last_updated: new Date(),
                    },
                    { merge: true }
                  )
                  .catch((e) => {
                    setSubmitting(false);
                    snackbar.enqueueSnackbar(e.message, {
                      variant: "error",
                      persist: false,
                    });
                  });
                axios
                  .put(
                    `http://edge-remote.gotapway.com:15001/add_to_vehicle_group`,
                    {
                      vehicle_limit_count: parseInt(value.vehicleLimit),

                      name: value.groupName,
                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + res.data.token,
                      },
                    }
                  )
                  .then((res) => {
                    setSubmitting(false);
                    snackbar.enqueueSnackbar(
                      "New Access Group had been added",
                      {
                        variant: "success",
                      }
                    );
                  })
                  .catch((e) => {
                    setSubmitting(false);
                    snackbar.enqueueSnackbar(e.message, {
                      variant: "error",
                      persist: false,
                    });
                  });
              })
              .catch((e) => {});
          } else {
            axios
              .put(
                `http://edge-remote.gotapway.com:15001/add_to_vehicle_group`,
                {
                  vehicle_limit_count: parseInt(value.vehicleLimit),

                  name: value.groupName,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + vendor?.token,
                  },
                }
              )
              .then((res) => {
                snackbar.enqueueSnackbar("New Access Group had been added", {
                  variant: "success",
                });
                setSubmitting(false);
                firestore
                  .collection("apnr_vendors")
                  .doc(selectedVendor)
                  .set(
                    {
                      token: res.data.token,
                      token_last_updated: new Date(),
                    },
                    { merge: true }
                  )
                  .catch((e) => {
                    setSubmitting(false);
                    snackbar.enqueueSnackbar(e.message, {
                      variant: "error",
                      persist: false,
                    });
                  });
              })
              .catch((e) => {
                setSubmitting(false);
                snackbar.enqueueSnackbar(e.message, {
                  variant: "error",
                  persist: false,
                });
              });
          }
        } else {
          axios
            .post(
              `http://edge-remote.gotapway.com:15001/login`,
              {
                username: "jagaapp",
                password: "J@g@App123!",
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              firestore
                .collection("apnr_vendors")
                .doc(selectedVendor)
                .set(
                  {
                    token: res.data.token,
                    token_last_updated: new Date(),
                  },
                  { merge: true }
                )
                .catch((e) => {
                  setSubmitting(false);
                  snackbar.enqueueSnackbar(e.message, {
                    variant: "error",
                    persist: false,
                  });
                });
              axios
                .put(
                  `http://edge-remote.gotapway.com:15001/add_to_vehicle_group`,
                  {
                    vehicle_limit_count: parseInt(value.vehicleLimit),

                    name: value.groupName,
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + res.data.token,
                    },
                  }
                )
                .then((res) => {
                  setSubmitting(false);
                  snackbar.enqueueSnackbar("New Access Group had been added", {
                    variant: "success",
                  });
                  firestore
                    .collection("apnr_vendors")
                    .doc(selectedVendor)
                    .set(
                      {
                        token: res.data.token,
                        token_last_updated: new Date(),
                      },
                      { merge: true }
                    )
                    .catch((e) => {
                      setSubmitting(false);
                    });
                })
                .catch((e) => {
                  setSubmitting(false);
                  snackbar.enqueueSnackbar(e.message, {
                    variant: "error",
                    persist: false,
                  });
                });
            })
            .catch((e) => {
              setSubmitting(false);
              snackbar.enqueueSnackbar(e.message, {
                variant: "error",
                persist: false,
              });
            });
        }
      });
    }
  };

  return (
    <div>
      {dashboardButton ? (
        <DashboardButton
          icon={<DriveEtaIcon />}
          onClick={handleClickOpen}
          title="Add Resident Vehicle"
        />
      ) : (
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          add vehicle group
        </Button>
      )}

      <form
        noValidate
        xs={12}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <Dialog
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={handleClose}
          open={open}
          fullWidth={true}
          maxWidth="md"
          scroll="paper"
        >
          <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>
            <div>
              <Typography
                className={classes.modalTitle}
                variant="h3"
                component="h2"
              >
                Add Vehicle Group
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent dividers>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <Grid
                item
                container
                direction="row"
                spacing={2}
                md={7}
                justify="center"
                alignItems="flex-start"
              >
                <Grid item md={12}>
                  <Typography variant="h4" component="h1">
                    Vehicle Group Details
                  </Typography>
                </Grid>

                <Grid item md={12}>
                  <TextField
                    size="small"
                    id="groupName"
                    name="groupName"
                    label="Name *"
                    type="text"
                    variant="outlined"
                    autoComplete="off"
                    inputRef={register(vehicleValidate.profileName.rules)}
                    helperText={
                      errors.profileName &&
                      vehicleValidate.profileName.message[
                        errors.profileName.type
                      ]
                    }
                    error={!!errors.profileName}
                    fullWidth
                  />
                </Grid>

                <Grid item md={12}>
                  <TextField
                    size="small"
                    margin="dense"
                    id="vehicleGroupId"
                    name="vehicleGroupId"
                    label="Vehicle Group *"
                    type="text"
                    readOnly={true}
                    inputRef={register}
                    autoComplete="off"
                    // value={image.vehicleNo ? image.vehicleNo : "-"}
                    fullWidth
                    // helperText={
                    //   errors.file &&
                    //   vehicleValidate.file.message[errors.file.type]
                    // }
                    // error={!!errors.file}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    size="small"
                    margin="dense"
                    id="vehicleLimit"
                    name="vehicleLimit"
                    label="Vehicle Limit *"
                    type="text"
                    readOnly={true}
                    inputRef={register}
                    autoComplete="off"
                    // value={image.vehicleNo ? image.vehicleNo : "-"}
                    fullWidth
                    // helperText={
                    //   errors.file &&
                    //   vehicleValidate.file.message[errors.file.type]
                    // }
                    // error={!!errors.file}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <ButtonProgress
              type="submit"
              containName="Save"
              margin={false}
              loading={submitting}
              disabled={submitting}
              onClick={handleSubmit(onSubmit)}
            />
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default AddVehicleGroupDialog;
