import React from "react";

import { Header } from "./components";

// material-ui
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2)
  }
}));

const ProfileList = () => {
  const classes = useStyles();

  return (
    <div>
      <Header />
      <div className={classes.root}></div>
    </div>
  );
};

export default ProfileList;
