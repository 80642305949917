import React from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import "devextreme/dist/css/dx.light.css";
import "./assets/fonts/css/gotham-rounded.css";
import "./assets/css/dx.material.datagrid-scheme.css";
// import "./assets/scss/index.scss";

import { Provider } from "react-redux";
import { Router } from "react-router-dom";

import { createBrowserHistory } from "history";

import ProfileIsLoaded from "components/ProfileLoaded/ProfileLoaded";

import Routes from "./Routes";

import { DialogProvider } from "muibox";
import { SnackbarProvider } from "notistack";

// material-ui
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { theme } from "./theme";

// firebase
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import firebaseConfig from "./services/firebaseConfig";
import rrfConfig from "./services/rrfConfig";
import createReduxStore from "./services/createReduxStore";

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);

// Initialize other services on firebase instance
firebase.firestore(); // <- needed if using firestore
firebase.functions(); // <- needed if using httpsCallable
firebase.storage(); // <- needed if using storage

const store = createReduxStore();

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

const browserHistory = createBrowserHistory();
const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <Router history={browserHistory}>
            <ProfileIsLoaded>
              <SnackbarProvider
                maxSnack={3}
                ref={notistackRef}
                action={(key) => (
                  <IconButton onClick={onClickDismiss(key)}>
                    <CloseIcon style={{ color: "white" }} />
                  </IconButton>
                )}
              >
                <DialogProvider>
                  <Routes />
                </DialogProvider>
              </SnackbarProvider>
            </ProfileIsLoaded>
          </Router>
        </ReactReduxFirebaseProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
