import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// components (global)
import { CustomTabs } from "components/Tabs";

// components (local)
import { AuditTrailsTab, DetailsTab, TransactionTab } from "./components";

// material-ui
import { Button, Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close as CloseIcon } from "@material-ui/icons";

// stores
import { closeDrawer } from "stores/main/MainActions";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    paddingBottom: theme.spacing(1),
    flexShrink: 0,
    display: "flex",
  },
  content: { paddingTop: theme.spacing(1) },
  tabContent: { padding: theme.spacing(2) },
  listOdd: { backgroundColor: "#f9f9f9" },
  listTitle: { width: theme.spacing(5) },
}));

const VisitorCardDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // selector
  const cardId = useSelector((state) => state.main.toggleDrawer.parameterId);
  const card = useSelector((state) => {
    return state.firestore.data.visitor_cards
      ? state.firestore.data.visitor_cards[cardId]
      : null;
  });

  // state
  const [tabs] = useState([
    {
      value: "details",
      label: "Details",
      content: <DetailsTab />,
    },
    {
      value: "transactions",
      label: "Transactions",
      content: <TransactionTab />,
    },
    {
      value: "audit_trails",
      label: "Audit Trails",
      content: <AuditTrailsTab />,
    },
  ]);

  // functions
  const onClose = () => {
    dispatch(closeDrawer());
  };

  return (
    <div>
      <Grid container className={classes.header} justify="space-between">
        <Grid>
          <Button onClick={onClose} size="small">
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </Grid>
      </Grid>

      <Divider />

      <Grid
        direction="row"
        alignItems="flex-start"
        container
        justify="space-between"
        className={classes.content}
      >
        <Grid item>
          <Typography component="h1" variant="h3">
            {`${card.identity_number}`}
          </Typography>
        </Grid>
        <Grid
          xs
          item
          alignItems="center"
          className={classes.header}
          container
          justify="flex-end"
          spacing={2}
        >
          <Grid item>
            {card.status !== "delete" && (
              <Button
                variant="contained"
                color="primary"
                // onClick={handleOpenEditUnit}
              >
                Edit Card
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>

      <CustomTabs tabs={tabs} defaultTabIndex={0} />
    </div>
  );
};

export default VisitorCardDetails;
