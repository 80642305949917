import React from "react";

// material-ui
import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles(theme => ({
  root: {},
  media: {
    height: 240
  }
}));

const VehicleSkeleton = () => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        title={
          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
        }
      />
      <Skeleton animation="wave" variant="rect" className={classes.media} />

      <CardContent>
        <Grid container direction="column" spacing={1}>
          <Grid item container justify="center" alignItems="center">
            <Grid item md={12}>
              <Skeleton
                animation="wave"
                height={10}
                style={{ marginBottom: 6 }}
              />
            </Grid>
            <Grid item md={12}>
              <Skeleton
                animation="wave"
                height={10}
                style={{ marginBottom: 6 }}
              />
            </Grid>
            <Grid item md={12}>
              <Skeleton
                animation="wave"
                height={10}
                style={{ marginBottom: 6 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default VehicleSkeleton;
