import React from "react";
import { useSelector } from "react-redux";

// material-ui
import {
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  tabContent: { padding: theme.spacing(2) },
  card: {
    padding: theme.spacing(2),
  },
}));

const DetailColumn = ({ title, subTitle }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item md={4}>
          <Typography variant="body2">{title}</Typography>
        </Grid>
        <Grid item md={8}>
          <Typography variant="body2">{`${
            subTitle ? subTitle.toUpperCase() : ""
          }`}</Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

const DetailsTab = () => {
  // selector
  const cardId = useSelector((state) => state.main.toggleDrawer.parameterId);
  const card = useSelector((state) => {
    return state.firestore.data.visitor_cards
      ? state.firestore.data.visitor_cards[cardId]
      : null;
  });

  if (!card) return null;

  return (
    <React.Fragment>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item container md={6} spacing={1}>
          <Grid item md={12}>
            <DetailColumn
              title="Assigned"
              subTitle={
                card.is_assign
                  ? `To ${card.unit?.block_name}, ${card.unit?.unit_name}`
                  : "No"
              }
            />
          </Grid>

          <Grid item md={12}>
            <DetailColumn title="Profile Type" subTitle={card.profile_type} />
          </Grid>

          <Grid item md={12}>
            <DetailColumn title="Card No." subTitle={card.identity_number} />
          </Grid>

          <Grid item md={12}>
            <DetailColumn title="Card Name" subTitle={card.profile_name} />
          </Grid>

          <Grid item md={12}>
            <DetailColumn title="Card Type" subTitle={card.card_type} />
          </Grid>

          <Grid item md={12}>
            <DetailColumn
              title="Anti-passback"
              subTitle={card.anti_passback ? "Yes" : "No"}
            />
          </Grid>

          <Grid item md={12}>
            <DetailColumn title="Status" subTitle={card.status} />
          </Grid>
        </Grid>
        <Grid item container md={6} spacing={1}>
          <Grid item md={12}>
            <DetailColumn title="Pass No." subTitle={card.profile_code} />
          </Grid>

          <Grid item md={12}>
            <DetailColumn title="Pass Type" subTitle={card.pass_type} />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DetailsTab;
