import React from "react";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { CSVLink } from "react-csv";

//material-ui
import { Box, DialogContentText, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//material-icon
//helpers
//assets
import selectFileImg from "assets/images/ic-select-files.png";

//styles
const useStyles = makeStyles((theme) => ({
  link: { color: "#AD2424", fontSize: "14px", textDecoration: "none" },
}));

function FileDropZone({ onDrop }) {
  const classes = useStyles();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
    multiple: false,
  });

  const selectedProperty = useSelector((state) => state.main.selectedProperty);
  const propertySelector = useSelector(
    (state) => state.firestore.data.properties[selectedProperty]
  );

  const csvTemplateData = [
    {
      name: "",
      description: "",
      door_group_id: "",
    },
  ];

  //ui
  const selectImg = (
    <Box component="img" src={selectFileImg} alt={selectFileImg}></Box>
  );

  const selectFileTxt = (
    <Typography compnent="p" variant="h4">
      Select File
    </Typography>
  );

  const selectFileSubTxt = (
    <Typography compnent="p" variant="h6">
      Drop files here or click browse thorough your machine
    </Typography>
  );

  const downloadTemplateLink = (
    <CSVLink
      filename={`access_group - ${propertySelector?.name ?? ""}.csv`}
      data={csvTemplateData}
      className={classes.link}
    >
      Download Template
    </CSVLink>
  );

  return (
    <React.Fragment>
      <Box {...getRootProps()}>
        <input {...getInputProps()} />
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.content}
        >
          <Grid item>{selectImg}</Grid>
          <Grid item className={classes.selectFile}>
            {selectFileTxt}
            {selectFileSubTxt}
          </Grid>
        </Grid>
      </Box>
      <Box p={2}>
        <DialogContentText>{downloadTemplateLink}</DialogContentText>
      </Box>
    </React.Fragment>
  );
}

export default React.memo(FileDropZone);
