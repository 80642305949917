import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { cloneDeep, find, orderBy, pick } from "lodash";
import moment from "moment";

// components (global)
import ButtonProgress from "components/ButtonProgress";
import CusomtDatePick from "components/DateTime/CustomDatePick";
import DialogWrapper from "components/Dialogs/DialogWrapper";
import DialogTitleWrapper from "components/Dialogs/DialogTitleWrapper";
import UnitAutocomplete from "components/CustomSelect/UnitAutocomplete";

// firebase
import { useFirestore } from "react-redux-firebase";

// form
import { useForm, FormContext } from "react-hook-form";

// helpers
import { residentCardValidate } from "helpers/validation/residentCardValidate";
import { Capitalized } from "helpers/textHelpers";

// material-ui
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

// stores
import { toggleEditPropertyDialogClose } from "stores/card/CardAction";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  accessLevel: {
    marginTop: "18px",
  },
  liftAccess: {
    marginTop: "18px",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const EditPropertyDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const firestore = useFirestore();

  // form
  const formMethods = useForm();
  const watchUnit = formMethods.watch("unit");

  // selector
  const { open, id } = useSelector((state) => state.card.edit_property_dialog);
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const selectedVendor = useSelector((state) => state.main.selectedVendor);

  const vendor = useSelector((state) => {
    return state.firestore.data.all_vendors
      ? state.firestore.data.all_vendors[id]
      : null;
  });

  const [submitting, setSubmitting] = useState(false);

  const [nonExpired, setNonExpired] = useState(true);

  const [integrationType] = useState([
    { id: "Auto Barrier Gate", name: "Auto Barrier Gate" },
    { id: "Assign Visitor Card", name: "Assign Visitor Card" },
  ]);

  const watchNonExpired = formMethods.watch("nonExpired", nonExpired);

  // effect

  // functions
  const handleClose = () => {
    if (!submitting) {
      dispatch(toggleEditPropertyDialogClose());
    }
  };

  const handleTypeChange = (e, value) => {
    if (value) {
      formMethods.setValue("cardType", value.id);
    } else {
      formMethods.setValue("cardType", null);
    }
  };

  const onSubmit = (value) => {
    if (!submitting) {
      setSubmitting(true);

      let data = {
        integration_type: value.integrationType,
        ultraviewer_id: value.ultraviewerId,
        ultraviewer_pw: value.ultraviewerPw,
        site_wifi_id: value.wifiId,
        site_wifi_pw: value.wifiPw,
        device_ip_1: value.deviceIp1,
        device_ip_2: value.deviceIp2,
        device_ip_3: value.deviceIp3,
      };

      firestore
        .update(`ac_vendors/${id}`, data)
        .then(() => {
          setSubmitting(false);
          handleClose();
          snackbar.enqueueSnackbar(
            `Card No. ${vendor.property_name} had been updated successfully`,
            {
              variant: "success",
            }
          );
        })
        .catch((e) => {
          setSubmitting(false);
          handleClose();
          snackbar.enqueueSnackbar(e.message, {
            variant: "error",
            persist: false,
          });
        });
    }
  };

  if (!vendor) return null;

  const propertyNameTF = (
    <TextField
      fullWidth
      disabled={true}
      size="small"
      id="propertyName"
      name="propertyName"
      label="Property Name "
      type="text"
      variant="outlined"
      autoComplete="off"
      defaultValue={vendor.property_name}
    />
  );

  const vendorTF = (
    <TextField
      fullWidth
      disabled={true}
      size="small"
      id="vendorType"
      name="vendorType"
      label="Vendor"
      type="text"
      variant="outlined"
      autoComplete="off"
      defaultValue={vendor.access_card_settings.type}
    />
  );

  const ultraviewerTF = (
    <TextField
      size="small"
      id="ultraviewerId"
      name="ultraviewerId"
      label="Ultraviewer ID "
      type="text"
      variant="outlined"
      autoComplete="off"
      defaultValue={vendor.ultraviewer_id}
      inputRef={formMethods.register}
      fullWidth
    />
  );
  const ultraviewerPwTF = (
    <TextField
      size="small"
      id="ultraviewerPw"
      name="ultraviewerPw"
      label="Ultraviewer PW "
      type="text"
      variant="outlined"
      autoComplete="off"
      defaultValue={vendor.ultraviewer_pw}
      inputRef={formMethods.register}
      fullWidth
    />
  );

  const integrationTypeAC = (
    <Autocomplete
      autoHighlight
      options={integrationType}
      getOptionLabel={(option) => (option.name ? option.name : null)}
      onChange={handleTypeChange}
      defaultValue={find(
        integrationType,
        (type) => type.id === vendor.integration_type
      )}
      renderInput={(params) => (
        <TextField
          error={!!formMethods.errors.integrationType}
          helperText={
            formMethods.errors.integrationType &&
            residentCardValidate.integrationType.message[
              formMethods.errors.integrationType.type
            ]
          }
          {...params}
          label="Choose a Integration Type *"
          fullWidth
          size="small"
          name="integrationType"
          autoComplete="off"
          variant="outlined"
          inputRef={formMethods.register(
            residentCardValidate.integrationType.rules
          )}
        />
      )}
    />
  );
  const sitewifiTF = (
    <TextField
      id="wifiId"
      name="wifiId"
      label="Site Wifi ID"
      type="text"
      variant="outlined"
      size="small"
      inputRef={formMethods.register}
      defaultValue={vendor.site_wifi_id}
      fullWidth
    />
  );

  const sitewifiPwTF = (
    <TextField
      id="wifiPw"
      name="wifiPw"
      label="Site Wifi PW"
      type="text"
      variant="outlined"
      size="small"
      inputRef={formMethods.register}
      defaultValue={vendor.site_wifi_password}
      fullWidth
    />
  );

  const leftGrid = (
    <Grid
      item
      container
      direction="row"
      spacing={2}
      md={7}
      justify="center"
      alignItems="flex-start"
    >
      <Grid item md={12}>
        <Typography variant="h4" component="h1">
          Card Details
        </Typography>
      </Grid>

      <Grid item md={12}>
        {propertyNameTF}
      </Grid>

      <Grid item md={12}>
        {vendorTF}
      </Grid>

      <Grid item md={6}>
        {ultraviewerTF}
      </Grid>

      <Grid item md={6}>
        {ultraviewerPwTF}
      </Grid>
      <Grid item md={6}>
        {sitewifiTF}
      </Grid>
      <Grid item md={6}>
        {sitewifiPwTF}
      </Grid>

      <Grid item md={12}>
        {integrationTypeAC}
      </Grid>
    </Grid>
  );

  const deviceIp1 = (
    <TextField
      id="deviceIp1"
      name="deviceIp1"
      label="Device IP 1"
      type="text"
      variant="outlined"
      size="small"
      inputRef={formMethods.register}
      defaultValue={vendor.device_ip_1}
      fullWidth
    />
  );
  const deviceIp2 = (
    <TextField
      id="deviceIp2"
      name="deviceIp2"
      label="Device IP 2"
      type="text"
      variant="outlined"
      size="small"
      inputRef={formMethods.register}
      defaultValue={vendor.device_ip_2}
      fullWidth
    />
  );
  const deviceIp3 = (
    <TextField
      id="deviceIp3"
      name="deviceIp3"
      label="Device IP 3"
      type="text"
      variant="outlined"
      size="small"
      inputRef={formMethods.register}
      defaultValue={vendor.device_ip_3}
      fullWidth
    />
  );

  const rightGrid = (
    <Grid
      item
      container
      direction="row"
      spacing={2}
      md={5}
      justify="center"
      alignItems="flex-start"
    >
      <Grid item md={12}>
        <Typography variant="h4" component="h1">
          Other Details
        </Typography>
      </Grid>
      <Grid item container md={12}>
        {deviceIp1}
      </Grid>
      <Grid item md={12}>
        {deviceIp2}
      </Grid>
      <Grid item md={12}>
        {deviceIp3}
      </Grid>
    </Grid>
  );

  return (
    <FormContext {...formMethods}>
      <form
        noValidate
        xs={12}
        onSubmit={formMethods.handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <DialogWrapper size="md" open={open}>
          <DialogTitleWrapper title="Edit Property" handleClose={handleClose} />

          <DialogContent dividers>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              {leftGrid}
              {rightGrid}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <ButtonProgress
              type="submit"
              containName="Save"
              margin={false}
              loading={submitting}
              disabled={submitting}
              onClick={formMethods.handleSubmit(onSubmit)}
            />
          </DialogActions>
        </DialogWrapper>
      </form>
    </FormContext>
  );
};

export default EditPropertyDialog;
