import React from "react";
import { colors, Button } from "@material-ui/core";

function ButtonColor({ buttonColor, children, ...rest }) {
  return (
    <Button
      {...rest}
      style={{
        backgroundColor: buttonColor && colors[buttonColor][600],
        color: "white"
      }}
    >
      {children}
    </Button>
  );
}

export default ButtonColor;
