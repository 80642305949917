import replace from "lodash/replace";

export function Capitalized(str) {
  const splitStr = str.trim().split(" ");
  if (splitStr.length === 1) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    let newStr = "";
    for (let i = 0; i < splitStr.length; i++) {
      newStr +=
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].slice(1) + " ";
    }
    return newStr.trim();
  }
}
export function replaceStringAll(content, oldContent, newContent) {
  return replace(content, new RegExp(oldContent, "g"), newContent);
}
