import React, { useState } from "react";

//components
import ButtonColor from "./ButtonColor";

//material-ui
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@material-ui/core";

//material-icons

//helpers

//utils

//redux

//assets

//styles

function MenuItemWithYesNo({
  icon,
  title,
  disabled = false,
  onClick,
  yesColor = "red",
  yesColorHex,
}) {
  //states
  const [toggle, setToggle] = useState(false);

  //functions
  const handleOnClick = () => {
    setToggle(true);
  };

  const handleYes = () => {
    onClick();
    setToggle(false);
  };

  return toggle ? (
    <MenuItem style={{ justifyContent: "space-evenly" }}>
      <ButtonColor
        buttonColorHex={yesColorHex}
        buttonColor={yesColor}
        onClick={handleYes}
        size="small"
        variant="contained"
      >
        Yes
      </ButtonColor>
      <Button
        style={{ marginLeft: "10px" }}
        size="small"
        color="primary"
        variant="contained"
        onClick={() => setToggle(false)}
      >
        No
      </Button>
    </MenuItem>
  ) : (
    <MenuItem onClick={handleOnClick} disabled={disabled}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />
    </MenuItem>
  );
}

export default React.memo(MenuItemWithYesNo);
