import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { cloneDeep, find } from "lodash";

// components (global)
import ButtonProgress from "components/ButtonProgress";
import DialogWrapper from "components/Dialogs/DialogWrapper";
import DialogTitleWrapper from "components/Dialogs/DialogTitleWrapper";

// firebase
import { useFirestore } from "react-redux-firebase";

// form
import { useForm } from "react-hook-form";

// helpers
import { visitorCardValidate } from "helpers/validation/visitorCardValidate";
import { Capitalized } from "helpers/textHelpers";

// material-ui
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

// store
import { toggleEditVisitorDialogClose } from "stores/card/CardAction";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
}));

const EditVisitorDialog = () => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const dispatch = useDispatch();
  const firestore = useFirestore();

  // form
  const { errors, handleSubmit, setValue, register } = useForm();

  // selector
  const { open, id } = useSelector((state) => state.card.edit_visitor_dialog);
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const card = useSelector((state) => {
    return id && state.firestore.data.visitor_cards
      ? state.firestore.data.visitor_cards[id]
      : null;
  });

  // state
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState(true);
  const [antiPassback, setAntiPassback] = useState(false);
  const [cardTypes] = useState([
    { id: "mifare", name: "Mifare" },
    { id: "proximity", name: "Proximity" },
  ]);

  // effect
  useEffect(() => {
    if (card) {
      setStatus(card.status === "active");
      setAntiPassback(card.anti_passback);
    }
  }, [card]);

  // functions
  const initState = () => {};

  const handleClose = () => {
    if (!submitting) {
      dispatch(toggleEditVisitorDialogClose());
      initState();
    }
  };

  const handleTypeChange = (e, value) => {
    if (value) {
      setValue("cardType", value.id);
    } else {
      setValue("cardType", null);
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.checked);
  };

  const handleAntiPassbackChange = (event) => {
    setAntiPassback(event.target.checked);
  };

  const onSubmit = (value) => {
    if (!submitting) {
      setSubmitting(true);

      const oldValue = cloneDeep(card);

      /**
       * validate whether sync to local server
       * when below elements changed, sync to local server
       * - profile name
       * - status
       * - anti passback
       */
      let isSync = card.is_sync; // Get card is_sync value to prevent sync still in progress
      let status = value.status ? "active" : "inactive";

      if (
        value.antiPassback !== card.anti_passback ||
        value.profileName !== card.profile_name ||
        status !== card.status
      ) {
        isSync = false;
      }

      firestore
        .update(`ac_profiles/${id}`, {
          action: "modify",
          attempted: 0,
          anti_passback: value.antiPassback,
          card_type: value.cardType.toLowerCase(),
          is_sync: isSync,
          pass_type: value.passType,
          profile_name: value.profileName,
          start_date: new Date(),
          status: status,
          updated_at: new Date(),
          updated_by: {
            user_id: auth.uid,
            name: profile.name,
          },
        })
        .then(() => {
          firestore
            .collection("ac_audit_trails")
            .add({
              action: "modified",
              created_at: new Date(),
              created_by: {
                user_id: auth.uid,
                name: profile.name,
              },
              description: `${Capitalized(
                profile.name
              )} had been modified visitor card ${card.identity_number}`,
              module: "access_card",
              profile: {
                id,
                identity_number: card.identity_number,
              },
              values: oldValue,
            })
            .then(() => {
              setSubmitting(false);
              handleClose();
              snackbar.enqueueSnackbar(
                `Card No. ${card.identity_number} had been updated successfully`,
                {
                  variant: "success",
                }
              );
            })
            .catch((e) => {
              setSubmitting(false);
              handleClose();
              snackbar.enqueueSnackbar(e.message, {
                variant: "error",
                persist: false,
              });
            });
        })
        .catch((e) => {
          setSubmitting(false);
          handleClose();
          snackbar.enqueueSnackbar(e.message, {
            variant: "error",
            persist: false,
          });
        });
    }
  };

  return (
    <div className={classes.root}>
      <form
        noValidate
        xs={12}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <DialogWrapper size="md" open={open}>
          <DialogTitleWrapper
            title="Edit Visitor Card"
            handleClose={handleClose}
          />

          <DialogContent dividers>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <Grid
                item
                container
                direction="row"
                spacing={2}
                md={7}
                justify="center"
                alignItems="flex-start"
              >
                <Grid item md={12}>
                  <Typography variant="h4" component="h1">
                    Card Details
                  </Typography>
                </Grid>

                <Grid item md={12}>
                  <TextField
                    disabled={true}
                    size="small"
                    id="identityNumber"
                    name="identityNumber"
                    label="Card No. *"
                    type="text"
                    variant="outlined"
                    autoComplete="off"
                    defaultValue={card.identity_number}
                    fullWidth
                  />
                </Grid>

                <Grid item md={12}>
                  <TextField
                    size="small"
                    id="profileName"
                    name="profileName"
                    label="Name *"
                    type="text"
                    variant="outlined"
                    autoComplete="off"
                    defaultValue={card.profile_name}
                    inputRef={register(visitorCardValidate.profileName.rules)}
                    helperText={
                      errors.profileName &&
                      visitorCardValidate.profileName.message[
                        errors.profileName.type
                      ]
                    }
                    error={!!errors.profileName}
                    fullWidth
                  />
                </Grid>

                <Grid item md={12}>
                  <Autocomplete
                    autoHighlight
                    options={cardTypes}
                    getOptionLabel={(option) => option.name}
                    onChange={handleTypeChange}
                    defaultValue={find(
                      cardTypes,
                      (type) => type.id === card.card_type
                    )}
                    renderInput={(params) => (
                      <TextField
                        error={!!errors.cardType}
                        helperText={
                          errors.cardType &&
                          visitorCardValidate.cardType.message[
                            errors.cardType.type
                          ]
                        }
                        {...params}
                        label="Choose a type *"
                        fullWidth
                        size="small"
                        name="cardType"
                        autoComplete="off"
                        variant="outlined"
                        inputRef={register(visitorCardValidate.cardType.rules)}
                      />
                    )}
                  />
                </Grid>

                <Grid item md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="status"
                        checked={status}
                        onChange={handleStatusChange}
                        color="primary"
                        inputRef={register}
                      />
                    }
                    label="Active"
                  />
                </Grid>

                <Grid item md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="antiPassback"
                        checked={antiPassback}
                        onChange={handleAntiPassbackChange}
                        color="primary"
                        inputRef={register}
                      />
                    }
                    label="Anti-passback"
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction="row"
                spacing={2}
                md={5}
                justify="center"
                alignItems="flex-start"
              >
                <Grid item md={12}>
                  <Typography variant="h4" component="h1">
                    Other Details
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <TextField
                    disabled={true}
                    size="small"
                    id="passNumber"
                    name="passNumber"
                    label="Pass Number *"
                    type="text"
                    defaultValue={card.profile_code}
                    inputRef={register(visitorCardValidate.passNumber.rules)}
                    error={!!errors.passNumber}
                    helperText={
                      errors.passNumber &&
                      visitorCardValidate.passNumber.message[
                        errors.passNumber.type
                      ]
                    }
                    autoComplete="off"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    size="small"
                    id="passType"
                    name="passType"
                    label="Pass Type"
                    type="text"
                    defaultValue={card.pass_type}
                    inputRef={register}
                    autoComplete="off"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <ButtonProgress
              type="submit"
              containName="Save"
              margin={false}
              loading={submitting}
              disabled={submitting}
              onClick={handleSubmit(onSubmit)}
            />
          </DialogActions>
        </DialogWrapper>
      </form>
    </div>
  );
};

export default EditVisitorDialog;
