import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { keys, includes } from "lodash";
import moment from "moment";

// components
import { StyledTextField } from "components/TextField";
import ButtonProgress from "components/ButtonProgress";

// material-ui
import {
  Grid,
  Typography,
  Divider,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  footNote: {
    margin: theme.spacing(2),
  },
  red: {
    color: "#Ad2424",
  },
}));

const QRFormatTab = () => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const firestore = useFirestore();

  // selector
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const propertyId = useSelector((state) => state.main.selectedProperty);
  const selectedProperty = useSelector(
    (state) => state.firestore.data.properties[state.main.selectedProperty]
  );

  const { errors, handleSubmit, register } = useForm({
    mode: "onChange",
    defaultValues: {
      format: selectedProperty?.access_card_qr?.format ?? "",
      excludeChars: (selectedProperty?.access_card_qr?.exclude_char ?? []).join(
        ""
      ),
    },
  });

  useEffect(() => {
    if (!!selectedProperty) {
      const selectedPurposes =
        selectedProperty.access_card_qr?.visit_purposes ?? [];
      const visitPurposes = selectedProperty.visit_purpose;
      const checkbox = {};

      keys(visitPurposes).forEach((purpose) => {
        if (!!visitPurposes[purpose]) {
          checkbox[purpose] = includes(selectedPurposes, purpose);
        }
      });

      setCheckbox(checkbox);
      generateSampleCode(selectedProperty.access_card_qr?.format ?? "");
    }
  }, [selectedProperty]);

  // state
  const [submitting, setSubmitting] = useState(false);
  const [sampleCode, setSampleCode] = useState(null);
  const [checkbox, setCheckbox] = useState({});

  const handleVisitPurposeToggle = (e) => {
    setCheckbox({
      ...checkbox,
      [e.target.name]: e.target.checked,
    });
  };

  const generateSampleCode = (value) => {
    if (!!!value || isValidFormat === false) {
      setSampleCode(null);
      return;
    }

    let sampleCode = value;
    const results = value.match(/\{[\w]+\}/g);

    if (!!results) {
      results.forEach((matchedString) => {
        let dynamicString = "";

        matchedString.split("").forEach((char) => {
          if (char === "{" || char === "}") {
            dynamicString += "";
          }

          if (char === "a") {
            dynamicString += generateRandomLowerChar();
          }

          if (char === "A") {
            dynamicString += generateRandomUpperChar();
          }

          if (char === "0") {
            dynamicString += generateRandomNumber();
          }
        });

        sampleCode = sampleCode.replace(matchedString, dynamicString);
      });
    }

    setSampleCode(sampleCode);
  };

  const generateRandomNumber = () => Math.floor(Math.random() * 10);
  const generateRandomLowerChar = () => {
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    return characters.charAt(Math.floor(Math.random() * characters.length));
  };
  const generateRandomUpperChar = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    return characters.charAt(Math.floor(Math.random() * characters.length));
  };

  const isValidFormat = (value) => {
    if (!!!value) {
      return true;
    }

    const results = value.match(/\{[\w]+\}/g);

    if (!!!results) {
      return true;
    }

    let isValid = true;
    results.forEach((matchedString) => {
      if (!/\{[01aA]+\}/.test(matchedString)) {
        isValid = false;
      }
    });

    return isValid;
  };

  const onSubmit = async ({ format, excludeChars, ...purposes }) => {
    setSubmitting(true);

    const visit_purposes = [];

    keys(purposes).forEach((key) => {
      if (purposes[key] === true) {
        visit_purposes.push(key);
      }
    });

    try {
      await firestore
        .collection("properties")
        .doc(propertyId)
        .update({
          access_card_qr: {
            exclude_char: excludeChars.split(""),
            format,
            visit_purposes,
          },
        });

      await firestore.collection("logs").add({
        created_by: {
          name: profile.name ?? "",
          uid: auth.uid ?? "",
        },
        created_at: moment().toDate(),
        type: "access_card_qr",
        property_id: propertyId,
        action_type: ["updated qr code format"],
        new_value: [
          `updated format: ${format} exclude_char: ${excludeChars} visit_purposes: ${visit_purposes.join(
            ","
          )}`,
        ],
      });

      setSubmitting(false);
      snackbar.enqueueSnackbar(`Formula updated successfully`, {
        variant: "success",
      });
    } catch (e) {
      setSubmitting(false);
      snackbar.enqueueSnackbar(`${e.message}`, {
        variant: "error",
      });
    }
  };

  //ui component
  const headerNote = (
    <>
      <Typography component="h2" gutterBottom variant="overline">
        QR FORMAT
      </Typography>
      <Typography component="h1" variant="h3">
        This will be used by guard POS to print out temporary pass
      </Typography>
    </>
  );

  const formatTF = (
    <StyledTextField
      id="format"
      label="Format"
      name="format"
      type="text"
      error={!!errors.format}
      onChange={(e) => generateSampleCode(e.target.value)}
      inputRef={register({
        validate: {
          format: isValidFormat,
        },
      })}
      helperText={
        !!errors.format && `invalid formula only character a or 0 allowed in {}`
      }
    />
  );

  const excludeCharactersTF = (
    <StyledTextField
      id="excludeChars"
      label="Exclude Characters"
      name="excludeChars"
      type="text"
      inputRef={register}
    />
  );

  const footNote = (
    <Typography variant="body2" className={classes.footNote}>
      {`Example: QAoN{0000}{aaaa} = fix characters 'QAoN' + 4 random digit + 4 alphanumeric characters `}
      {!!sampleCode && (
        <span className={classes.red}>
          <b>Sample Code {sampleCode}</b>
        </span>
      )}
    </Typography>
  );

  const visitPurposeCheckboxGroup = !!selectedProperty
    ? (keys(selectedProperty.visit_purpose) ?? []).map((purpose) =>
        !!selectedProperty.visit_purpose[purpose] ? (
          <Grid item xs={12} md={3} key={purpose}>
            <FormControlLabel
              control={
                <Checkbox
                  name={purpose}
                  checked={!!checkbox[purpose]}
                  onChange={handleVisitPurposeToggle}
                  color="primary"
                  inputRef={register}
                />
              }
              label={purpose}
            />
          </Grid>
        ) : null
      )
    : null;

  const saveButton = (
    <ButtonProgress
      type="submit"
      loading={submitting}
      containName="Save"
      disabled={submitting}
      onClick={handleSubmit(onSubmit)}
    />
  );

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {headerNote}
            </Grid>
            <Grid item xs={12} md={6}>
              {formatTF}
              {footNote}
            </Grid>
            <Grid item xs={12} md={6}>
              {excludeCharactersTF}
            </Grid>
            <Grid item container xs={12} spacing={3}>
              {visitPurposeCheckboxGroup}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>{saveButton}</CardActions>
      </form>
    </Card>
  );
};

export default QRFormatTab;
