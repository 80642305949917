import React from "react";
import {} from "react-redux";

// material-ui
import { Menu as MenuIcon } from "@material-ui/icons";
import { Grid, Hidden, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  header: { paddingBottom: theme.spacing(1) },
  search: { paddingTop: theme.spacing(2) }
}));

function PageHeader({
  pageTiltleLink,
  title,
  search,
  filter,
  firstButton,
  secondButton,
  thirdButton
}) {
  const classes = useStyles();

  return (
    <Grid
      direction="row"
      alignItems="flex-start"
      className={classes.header}
      container
      justify="space-between"
    >
      <Grid item md={9} xs={9}>
        <Typography component="h2" gutterBottom variant="overline">
          {pageTiltleLink}
        </Typography>
        <Typography component="h1" variant="h3">
          {title}
        </Typography>
      </Grid>
      <Grid
        md={3}
        xs={3}
        item
        alignItems="center"
        className={classes.header}
        container
        justify="flex-end"
        spacing={2}
      >
        {firstButton && (
          <React.Fragment>
            <Hidden smUp>
              <IconButton>
                <MenuIcon />
              </IconButton>
            </Hidden>

            <Hidden xsDown>
              <Grid item container justify="flex-end">
                {firstButton}
              </Grid>
            </Hidden>
          </React.Fragment>
        )}
        {secondButton && (
          <Grid item container justify="flex-end">
            {secondButton}
          </Grid>
        )}
        {thirdButton && <Grid item>{thirdButton}</Grid>}
      </Grid>

      <Grid item container spacing={2} md={12} className={classes.search}>
        <Grid item md={filter ? 11 : 12} xs={filter ? 10 : 12}>
          {search}
        </Grid>
        {filter && (
          <Grid
            item
            container
            md={1}
            xs={2}
            justify="center"
            alignItems="center"
          >
            {filter}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default React.memo(PageHeader);
