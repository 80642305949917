import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// components (global)
import { CustomTabs } from "components/Tabs";
import { CustomerDrawer } from "components";
import { openDrawer } from "stores/main/MainActions";

// components (local)

// material-ui
import { Close as CloseIcon } from "@material-ui/icons";
import { Button, Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// stores
import { closeDrawer } from "stores/main/MainActions";
import NotiAuditsTab from "./NotiAuditsTab";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    paddingBottom: theme.spacing(1),
    flexShrink: 0,
    display: "flex",
  },
  content: { paddingTop: theme.spacing(1) },
  tabContent: { padding: theme.spacing(2) },
  listOdd: { backgroundColor: "#f9f9f9" },
  listTitle: { width: theme.spacing(5) },
}));

const NotifcationDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const propertyId = useSelector(
    (state) => state.main.toggleDrawer.parameterId
  );

  // selector
  //   const cardId = useSelector((state) => state.main.toggleDrawer.parameterId);
  //   const card = useSelector((state) => {
  //     return state.firestore.data.resident_cards
  //       ? state.firestore.data.resident_cards[cardId]
  //       : null;
  //   });

  // state
  const [tabs] = useState([
    {
      value: "audit_trails",
      label: "Audit Trails",
      content: <NotiAuditsTab />,
    },
    {
      value: "transactions",
      label: "Transactions",
    },
  ]);

  // functions
  const onClose = () => {
    dispatch(closeDrawer());
  };

  return (
    <div>
      <Grid container className={classes.header} justify="space-between">
        <Grid>
          <Button onClick={onClose} size="small">
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </Grid>
      </Grid>
      <Divider />

      <CustomTabs tabs={tabs} defaultTabIndex={0} />
    </div>
  );
};

export default NotifcationDetails;
