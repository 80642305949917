import React, { useCallback, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { find, map, orderBy, chunk, pick, toLower } from "lodash";
import { forEachAsync } from "foreachasync";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import moment from "moment";

// assets
import selectFileImg from "assets/images/ic-select-files.png";
import uploadImg from "assets/images/ic-upload.png";
import uploadSuccessfullImg from "assets/images/ic-successfully.png";

// components (global)
import DialogWrapper from "components/Dialogs/DialogWrapper";
import DialogTitleWrapper from "components/Dialogs/DialogTitleWrapper";
import Command from "components/Table/Command";
import CustomEditCell from "components/Table/CustomEditCell";

// devexpress
import {
  DataTypeProvider,
  EditingState,
  PagingState,
  SortingState,
  IntegratedSorting,
  IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
  Grid as GridTable,
  PagingPanel,
  Table,
  TableEditColumn,
  TableEditRow,
  TableFixedColumns,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

// dropzone
import { useDropzone } from "react-dropzone";

// firesbase
import { useFirestore } from "react-redux-firebase";

// helpers
import { Capitalized } from "helpers/textHelpers";

// material-ui
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  dialogContent: { padding: "0" },
  innerContent: { padding: "40px 10px" },
  table: {
    whiteSpace: "nowrap",
    tableLayout: "auto",
    "& th": { backgroundColor: "#fafafa" },
    "& td div.flex": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  error: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  link: { color: "#AD2424", fontSize: "14px", textDecoration: "none" },
}));

// components
const TableComponentBase = ({ ...rest }) => {
  const classes = useStyles();
  return <Table.Table {...rest} className={classes.table} />;
};

const SoyalCsv = ({ open, handleDialogClose }) => {
  const classes = useStyles();
  const firestore = useFirestore();

  // selector
  const auth = useSelector((state) => state.firebase.auth);
  const userProfile = useSelector((state) => state.firebase.profile);
  const blockSelector = useSelector((state) => state.firestore.ordered.blocks);
  const unitsSelector = useSelector((state) => {
    return state.firestore.ordered.units
      ? orderBy(
          state.firestore.ordered.units,
          ["block_name", "house_number"],
          ["asc", "asc"]
        )
      : null;
  });
  const cardsSelector = useSelector(
    (state) => state.firestore.ordered.resident_cards
  );
  const selectedPropertyId = useSelector(
    (state) => state.main.selectedProperty
  );
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const accessCardSettings = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor].access_card_settings
      : null;
  });
  const unitAccessSelector = useSelector(
    (state) => state.firestore.data.unit_access
  );
  const accessLevelsSelector = useSelector((state) => {
    return state.firestore.ordered?.accessLevels
      ? orderBy(state.firestore.ordered.accessLevels, ["name"])
      : [];
  });
  const liftAccessLevelsSelector = useSelector((state) => {
    return state.firestore.ordered?.liftAccess
      ? orderBy(state.firestore.ordered.liftAccess, ["name"])
      : [];
  });

  // useMemo
  const csvTemplateData = useMemo(() => {
    const flatResult = map(unitsSelector, (unit) => {
      return {
        block: unit.block_name,
        unit: unit.house_number,
        "card id": "",
        "card no.": "",
        "card name": "",
        "card type (rfid/mifare/proximity)": "",
        "expired date (DD/MM/YYYY)": "",
        "active (Yes/No)": "",
        "anti-passback (Yes/No)": "",
        "lift card (Yes/No)": "",
        "contact no.": "",
        "vehicle No": "",
        "vehicle make": "",
        "vehicle model": "",
        "vehicle colour": "",
        "parking lot no.": "",
        "sticker no.": "",
        ...(!!accessCardSettings?.manual_assign_access_level && {
          "access level": "",
        }),
        ...(!!accessCardSettings?.manual_assign_floor_access_level && {
          "lift access": "",
        }),
      };
    });

    return orderBy(flatResult, ["block", "unit"], ["asc", "asc"]);
  }, [unitsSelector]);

  // state
  const [step, setStep] = useState(0);
  const [isSync, setIsSync] = useState(false);
  const [dataList, setDataList] = useState(null);
  const [exportDataList, setExportDataList] = useState(null);
  const [errors, setErrors] = useState([]);
  const [cardTypes] = useState(["rfid", "mifare", "proximity"]);
  const [columns] = useState([
    {
      name: "profileCode",
      title: "Card ID",
    },
    {
      name: "profileName",
      title: "Card Name",
    },
    {
      name: "cardNumber",
      title: "Card No.",
    },
    {
      name: "active",
      title: "Active",
      getCellValue: (row) =>
        row.active ? (row.active === 1 ? true : false) : true,
      component: (
        <FormControlLabel
          styles={{ alignItems: "center" }}
          control={<Checkbox name="status" color="primary" />}
        />
      ),
    },
    {
      name: "isLift",
      title: "Lift Card",
      getCellValue: (row) =>
        row.isLift ? (row.isLift === 1 ? true : false) : true,
      component: (
        <FormControlLabel
          styles={{ alignItems: "center" }}
          control={<Checkbox name="status" color="primary" />}
        />
      ),
    },
  ]);

  const [columnExtensions] = useState([]);
  const [editingStateColumnExtensions] = useState([
    {
      columnName: "profileCode",
      editingEnabled: true,
    },
  ]);
  const [editingRowIds, getEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [sorting, getSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageSizes] = useState([5, 10, 15, 0]);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  // Dropzone
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      Papa.parse(file, {
        header: false,
        complete: (results) => {
          let resultList = [];
          let exportResultList = [];
          map(results.data, (data, key) => {
            if (key > 0) {
              resultList = [
                ...resultList,
                {
                  id: key,
                  profileCode: data[0]?.trim(),
                  profileName: data[1]?.trim(),
                  cardNumber: data[2]?.trim(),
                  active: data[3]?.trim(),
                  isLift: data[4]?.trim(),
                },
              ];

              exportResultList = [
                ...exportResultList,
                {
                  id: key,
                  block: "",
                  unit: "",
                  "card id": data[0]?.trim(),
                  "card no.": data[2]?.trim(),
                  "card name": data[1]?.trim(),
                  "card type (rfid/mifare/proximity)": "",
                  "expired date (DD/MM/YYYY)": "",
                  "active (Yes/No)": data[3]?.trim() === "1" ? "Yes" : "No",
                  "anti-passback (Yes/No)": "",
                  "lift card (Yes/No)": data[4]?.trim() === "1" ? "Yes" : "No",
                  "contact no.": "",
                  "vehicle No": "",
                  "vehicle make": "",
                  "vehicle model": "",
                  "vehicle colour": "",
                  "parking lot no.": "",
                  "sticker no.": "",
                  ...(!!accessCardSettings?.manual_assign_access_level && {
                    "access level": "",
                  }),
                  ...(!!accessCardSettings?.manual_assign_floor_access_level && {
                    "lift access": "",
                  }),
                },
              ];
            }
          });

          setDataList(resultList);
          setExportDataList(exportResultList);
          if (resultList.length > 0) {
            setStep(1);
          }
        },
      });
    });
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "text/csv, application/vnd.ms-excel",
    multiple: false,
  });

  // functions
  const deleteRows = (deletedIds) => {
    const rowsForDelete = dataList.slice();
    deletedIds.forEach((rowId) => {
      const index = rowsForDelete.findIndex((row) => row.id === rowId);
      if (index > -1) {
        rowsForDelete.splice(index, 1);
      }
    });
    return rowsForDelete;
  };

  const AddedRowsChange = (value) => {
    setAddedRows(
      value.map((row) =>
        Object.keys(row).length
          ? row
          : {
              unit: "",
            }
      )
    );
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;

    if (added) {
      const startingAddedId =
        dataList.length > 0 ? dataList[dataList.length - 1].id + 1 : 0;
      changedRows = [
        ...dataList,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
    }

    if (changed) {
      changedRows = dataList.map((row) =>
        changed[row.id] ? { ...row, ...changed[row.id] } : row
      );
    }

    if (deleted) {
      changedRows = deleteRows(deleted);
    }

    setDataList(changedRows);
  };

  const handleUploadCSV = () => {
    const data = map(dataList, (doc) => {
      const exportData = doc.data();
      return {
        id: doc.id,
        block: "",
        unit: "",
        "card id": exportData.profileCode,
        "card no.": exportData.cardNumber,
        "card name": exportData.profileName,
        "card type (rfid/mifare/proximity)": "",
        "expired date (DD/MM/YYYY)": "",
        "active (Yes/No)": exportData.active === 1 ? "Yes" : "No",
        "anti-passback (Yes/No)": "",
        "lift card (Yes/No)": exportData.isLift === 1 ? "Yes" : "No",
        "contact no.": "",
        "vehicle No": "",
        "vehicle make": "",
        "vehicle model": "",
        "vehicle colour": "",
        "parking lot no.": "",
        "sticker no.": "",
        ...(!!accessCardSettings?.manual_assign_access_level && {
          "access level": "",
        }),
        ...(!!accessCardSettings?.manual_assign_floor_access_level && {
          "lift access": "",
        }),
      };
    });

    setExportDataList(data);
  };

  const sleep = (seconds) => {
    const milliseconds = seconds * 1000;
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  };

  /** Progress Content
   * Step 1: Index 0, Select & upload CSV file
   * Step 2: Index 1, Edit content
   * Step 3: Index 2, Upload to server
   * Step 4: Index 3 Success
   */
  const stepOne = (
    <React.Fragment>
      <Box {...getRootProps()}>
        <input {...getInputProps()} />
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={2}
          className={classes.innerContent}
        >
          <Grid item>
            <Box component="img" src={selectFileImg} alt={selectFileImg}></Box>
          </Grid>
          <Grid item className={classes.selectFile}>
            <Typography compnent="p" variant="h4">
              Select File
            </Typography>
            <Typography compnent="p" variant="h6">
              Drop files here or click browse thorough your machine
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box p={2}>
        {dataList && dataList.length === 0 && (
          <Typography
            variant="h6"
            component="p"
            color="error"
            className={classes.error}
          >
            Error: File Is Empty
          </Typography>
        )}
        <DialogContentText>
          <CSVLink
            filename={`resident_card_template.csv`}
            data={csvTemplateData}
            className={classes.link}
          >
            Download Template
          </CSVLink>
        </DialogContentText>
      </Box>
    </React.Fragment>
  );

  const stepTwo = (
    <React.Fragment>
      <GridTable rows={dataList} columns={columns} getRowId={(row) => row.id}>
        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box width={150}>
                <Typography component="p" variant="h6">
                  {row.cardNumber}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["cardNumber"]}
        />

        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box>
                <Typography component="p" variant="h6">
                  {row.profileCode}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["profileCode"]}
        />

        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box width={200}>
                <Typography component="p" variant="h6">
                  {row.profileName}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["profileName"]}
        />

        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box>
                <Typography component="p" variant="h6">
                  {row.active}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["active"]}
        />

        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box>
                <Typography component="p" variant="h6">
                  {row.isLift}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["isLift"]}
        />

        <SortingState sorting={sorting} onSortingChange={getSorting} />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />

        <EditingState
          editingRowIds={editingRowIds}
          onEditingRowIdsChange={getEditingRowIds}
          rowChanges={rowChanges}
          onRowChangesChange={setRowChanges}
          addedRows={addedRows}
          onAddedRowsChange={AddedRowsChange}
          onCommitChanges={commitChanges}
          columnExtensions={editingStateColumnExtensions}
        />

        <IntegratedSorting />
        <IntegratedPaging />

        <Table
          tableComponent={TableComponentBase}
          columnExtensions={columnExtensions}
        />
        <TableHeaderRow showSortingControls />

        <TableEditRow cellComponent={CustomEditCell} />

        <TableEditColumn
          width={140}
          showAddCommand={!addedRows.length}
          showEditCommand
          showDeleteCommand
          commandComponent={Command}
        />

        <TableFixedColumns leftColumns={[TableEditColumn.COLUMN_TYPE]} />

        <PagingPanel pageSizes={pageSizes} />
      </GridTable>

      {errors.length > 0 && (
        <Box
          style={{
            paddingLeft: "40px",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
        >
          <Typography compnent="error" variant="h6" color="error">
            Error
          </Typography>

          {map(errors, (error) => (
            <Typography
              key={error.index}
              compnent="error"
              variant="h6"
              color="error"
            >
              {`Row ${error.index} - ${error.message}`}
            </Typography>
          ))}
        </Box>
      )}
    </React.Fragment>
  );

  const stepThree = (
    <React.Fragment>
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={2}
        className={classes.innerContent}
      >
        <Grid item>
          <Box component="img" src={uploadImg} alt={uploadImg}></Box>
        </Grid>
        <Grid item>
          <Typography compnent="p" variant="h4">
            {uploadPercentage} %
          </Typography>
          <Typography compnent="p" variant="h6">
            Your file has been uploading, please wait for a moment.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  const stepFour = (
    <React.Fragment>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.innerContent}
      >
        <Grid item>
          <Box
            component="img"
            src={uploadSuccessfullImg}
            alt={uploadSuccessfullImg}
          />
        </Grid>
        <Grid item>
          <Typography compnent="p" variant="h4">
            Successfully
          </Typography>
          <Typography compnent="p" variant="h6">
            Your file has been uploaded sucessfully.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  const progressContent = [stepOne, stepTwo, stepThree, stepFour];

  /** Progress Content
   * Step 1: Index 0, null
   * Step 2: Index 1, Confirm & save
   * Step 3: Index 2, null
   * Step 4: Index 3 Success
   */

  const progressAction = [
    null,
    <React.Fragment>
      <Button color="primary" onClick={() => setStep(0)}>
        Back
      </Button>
      <CSVLink
        style={{ textDecoration: "none" }}
        filename={`resident-access-card${moment().format("YYYY-MM-DD")}.csv`}
        data={exportDataList}
        className={classes.link}
      >
        <Button underline="none" color="primary" variant="contained" mr={2}>
          Export CSV
        </Button>
      </CSVLink>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        onClick={handleUploadCSV}
      >
        {"Confirm & Upload"}
      </Button>
    </React.Fragment>,
    null,
    <Button
      variant="contained"
      color="primary"
      type="submit"
      onClick={handleDialogClose}
    >
      Done
    </Button>,
  ];

  return (
    <form autoComplete="off" noValidate={true}>
      <DialogWrapper size="lg" open={open}>
        <DialogTitleWrapper
          title="Upload resident csv template"
          handleClose={handleDialogClose}
        />

        <DialogContent dividers className={classes.dialogContent}>
          {progressContent[step]}
        </DialogContent>

        {progressAction[step] && (
          <DialogActions>{progressAction[step]}</DialogActions>
        )}
      </DialogWrapper>
    </form>
  );
};

export default React.memo(SoyalCsv);
