import * as actionTypes from "./ActionType";

export const toggleEditResidentDialogOpen = (payload) => {
  return {
    type: actionTypes.TOGGLE_EDIT_RESIDENT_DIALOG_OPEN,
    payload: payload,
  };
};

export const toggleEditResidentDialogClose = () => {
  return {
    type: actionTypes.TOGGLE_EDIT_RESIDENT_DIALOG_CLOSE,
  };
};

export const toggleEditVisitorDialogOpen = (payload) => {
  return {
    type: actionTypes.TOGGLE_EDIT_VISITOR_DIALOG_OPEN,
    payload: payload,
  };
};

export const toggleEditVisitorDialogClose = () => {
  return {
    type: actionTypes.TOGGLE_EDIT_VISITOR_DIALOG_CLOSE,
  };
};

export const toggleEditPropertyDialogOpen = (payload) => {
  return {
    type: actionTypes.TOGGLE_EDIT_PROPERTY_DIALOG_OPEN,
    payload: payload,
  };
};

export const toggleEditPropertyDialogClose = () => {
  return {
    type: actionTypes.TOGGLE_EDIT_PROPERTY_DIALOG_CLOSE,
  };
};

export const toggleEditVehicleDialogOpen = (payload) => {
  return {
    type: actionTypes.TOGGLE_EDIT_VEHICLE_DIALOG_OPEN,
    payload: payload,
  };
};

export const toggleEditVehicleDialogClose = () => {
  return {
    type: actionTypes.TOGGLE_EDIT_VEHICLE_DIALOG_CLOSE,
  };
};
