import React, { useState } from "react";
import { useSelector } from "react-redux";
// material-ui
import {
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    tabContent: { padding: theme.spacing(2) },
  },
}));

const PropertyInfoDetail = () => {
  const classes = useStyles();
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  // selector
  const vendorId = useSelector((state) => state.main.toggleDrawer.parameterId);
  const vendor = useSelector((state) => {
    return state.firestore.data.all_vendors
      ? state.firestore.data.all_vendors[vendorId]
      : null;
  });

  return (
    <Grid
      alignItems="flex-start"
      container
      justify="space-between"
      spacing={4}
      className={classes.tabContent}
    >
      <Grid item md={12} xs={12}>
        <Grid item md={8} xs={8}>
          <Card>
            <List component="nav">
              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="h6">Vendor Type</Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography variant="caption">
                        {vendor.access_card_settings?.type
                          ? vendor.access_card_settings?.type.toUpperCase()
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="h6">Integration Type</Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography variant="caption" gutterBottom>
                        {vendor.integration_type?.toUpperCase()}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="h6">Ultraviewer ID</Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography variant="caption" gutterBottom>
                        {vendor.ultraviewer_id}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="h6">Ultraviewer PW</Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography variant="caption" gutterBottom>
                        {vendor.ultraviewer_pw}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="h6">Site Wifi ID</Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography variant="caption" gutterBottom>
                        {vendor.site_wifi_id}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="h6">Site Wifi PW</Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography variant="caption" gutterBottom>
                        {vendor.site_wifi_pw}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="h6">
                        Auto Check Out Settings
                      </Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography variant="caption" gutterBottom>
                        {vendor.auto_checkout_settings?.enable ? "ON" : "OFF"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="h6">Device IP 1</Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography variant="caption" gutterBottom>
                        {vendor.device_ip_1}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="h6">Device IP 2</Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography variant="caption" gutterBottom>
                        {vendor.device_ip_2}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={4}>
                      <Typography variant="h6">Device IP 3</Typography>
                    </Grid>
                    <Grid item md={8} xs={8}>
                      <Typography variant="caption" gutterBottom>
                        {vendor.device_ip_3}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            </List>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PropertyInfoDetail;
