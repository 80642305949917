import React from "react";
import { useSelector } from "react-redux";
import { orderBy } from "lodash";

// components (global)
import { CustomTabs } from "components/Tabs";

// components (local)
import { VehicleGroupTab } from "./components";

// material-ui
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
}));

const VendorAccessTab = () => {
  const classes = useStyles();

  // selector
  const selectedVendor = useSelector((state) => state.main.selectedApnrVendor);
  const vendor = useSelector((state) => {
    return state.firestore.data.apnrVendors
      ? state.firestore.data.apnrVendors[selectedVendor]
      : null;
  });

  let tabs = [
    {
      value: "vehicle_group",
      label: "Vehicle Group",
      sorting: 1,
      content: <VehicleGroupTab />,
    },
  ];

  return (
    <div className={classes.root}>
      <CustomTabs
        tabs={orderBy(tabs, ["sorting"], ["asc"])}
        orientation="vertical"
        defaultTabIndex={0}
      />
    </div>
  );
};

export default VendorAccessTab;
