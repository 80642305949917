import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

// components (global)
import ButtonProgress from "components/ButtonProgress";

// firebase
import { useFirestore } from "react-redux-firebase";

// form
import { useForm } from "react-hook-form";

// helpers
import { settingsValidate } from "helpers/validation/settingsValidate";

// material-ui
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles(theme => ({
  root: {}
}));

const BlockSettings = ({ vendor, vendorId }) => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const firestore = useFirestore();
  const { errors, handleSubmit, register, watch } = useForm({
    defaultValues:
      vendor && vendor.blocked_settings
        ? {
            numberOfDay: vendor.blocked_settings.number,
            warningMessage: vendor.blocked_settings.warning_message,
            blockedMessage: vendor.blocked_settings.blocked_message
          }
        : {
            numberOfDay: 0,
            warningMessage:
              "Oops, Card No. 0000000000 still have the remaining of {x} to entry.",
            blockedMessage:
              "Oops, Card No. 0000000000 has been blocked. Please kindly proceed to the management office."
          }
  });

  const watchSms = watch("sms");

  // state
  const [sms, setSms] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // selector
  const auth = useSelector(state => state.firebase.auth);
  const profile = useSelector(state => state.firebase.profile);

  // effect
  useEffect(() => {
    if (vendor && vendor.blocked_settings) {
      setSms(vendor.blocked_settings.sms_resident);
    }
  }, [vendor, setSms]);

  // functions
  const handleOnSmsChange = event => {
    setSms(event.target.checked);
  };

  const onSubmit = value => {
    if (!submitting) {
      setSubmitting(true);

      firestore
        .update(
          { collection: "ac_vendors", doc: vendorId },
          {
            blocked_settings: {
              blocked_message: value.blockedMessage,
              warning_message: value.warningMessage,
              number: parseInt(value.numberOfDay),
              sms_resident: value.sms,
              sms_warning_message: value.smsWarningMessage
                ? value.smsWarningMessage
                : "",
              sms_blocked_message: value.smsBlockedMessage
                ? value.smsBlockedMessage
                : ""
            },
            updated_at: new Date(),
            updated_by: {
              user_id: auth.uid,
              name: profile.name
            }
          }
        )
        .then(() => {
          setSubmitting(false);
          snackbar.enqueueSnackbar("Blocked Settings had been updated", {
            variant: "success"
          });
        })
        .catch(e => {
          setSubmitting(false);
          snackbar.enqueueSnackbar(e.message, {
            variant: "error",
            persist: false
          });
        });
    }
  };

  return (
    <Card className={classes.card}>
      <form
        noValidate
        xs={12}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <CardHeader
          title="Blocked Settings"
          subheader="This section is to setup notification purposes"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid
              item
              container
              direction="row"
              spacing={3}
              alignItems="flex-start"
            >
              <Grid item md={3}>
                <TextField
                  size="small"
                  id="numberOfDay"
                  name="numberOfDay"
                  label="Number Of Day *"
                  type="number"
                  variant="outlined"
                  autoComplete="off"
                  inputRef={register(settingsValidate.numberOfDay.rules)}
                  helperText={
                    errors.numberOfDay &&
                    settingsValidate.numberOfDay.message[
                      errors.numberOfDay.type
                    ]
                  }
                  error={!!errors.numberOfDay}
                  fullWidth
                />
              </Grid>

              <Grid item md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="sms"
                      checked={sms}
                      defaultValue={sms}
                      onChange={handleOnSmsChange}
                      color="primary"
                      inputRef={register}
                    />
                  }
                  label="SMS to Owner"
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              direction="row"
              spacing={4}
              alignItems="flex-start"
            >
              <Grid item md={6}>
                <TextField
                  multiline
                  rows="4"
                  size="small"
                  id="warningMessage"
                  name="warningMessage"
                  label="Warning Message *"
                  type="text"
                  variant="outlined"
                  autoComplete="off"
                  inputRef={register(settingsValidate.warningMessage.rules)}
                  helperText={
                    errors.warningMessage &&
                    settingsValidate.warningMessage.message[
                      errors.warningMessage.type
                    ]
                  }
                  error={!!errors.warningMessage}
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  multiline
                  rows="4"
                  size="small"
                  id="blockedMessage"
                  name="blockedMessage"
                  label="Blocked Message *"
                  type="text"
                  variant="outlined"
                  autoComplete="off"
                  inputRef={register(settingsValidate.blockedMessage.rules)}
                  helperText={
                    errors.blockedMessage &&
                    settingsValidate.blockedMessage.message[
                      errors.blockedMessage.type
                    ]
                  }
                  error={!!errors.blockedMessage}
                  fullWidth
                />
              </Grid>
            </Grid>

            {(sms || watchSms) && (
              <Grid
                item
                container
                direction="row"
                spacing={4}
                alignItems="flex-start"
              >
                <Grid item md={6}>
                  <TextField
                    multiline
                    rows="4"
                    size="small"
                    id="smsWarningMessage"
                    name="smsWarningMessage"
                    label="Warning Message (SMS) *"
                    type="text"
                    variant="outlined"
                    autoComplete="off"
                    defaultValue="SMS Message"
                    inputRef={register(
                      settingsValidate.smsWarningMessage.rules
                    )}
                    helperText={
                      errors.smsWarningMessage &&
                      settingsValidate.smsWarningMessage.message[
                        errors.smsWarningMessage.type
                      ]
                    }
                    error={!!errors.smsWarningMessage}
                    fullWidth
                  />
                  <Typography gutterBottom variant="caption">
                    Noted: Maximum 100 character.
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <TextField
                    multiline
                    rows="4"
                    size="small"
                    id="smsBlockedMessage"
                    name="smsBlockedMessage"
                    label="Blocked Message (SMS) *"
                    type="text"
                    variant="outlined"
                    autoComplete="off"
                    defaultValue="SMS Message"
                    inputRef={register(
                      settingsValidate.smsBlockedMessage.rules
                    )}
                    helperText={
                      errors.smsBlockedMessage &&
                      settingsValidate.smsBlockedMessage.message[
                        errors.smsBlockedMessage.type
                      ]
                    }
                    error={!!errors.smsBlockedMessage}
                    fullWidth
                  />
                  <Typography gutterBottom variant="caption">
                    Noted: Maximum 100 character.
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CardContent>

        <Divider />

        <CardActions>
          <ButtonProgress
            margin={false}
            type="submit"
            loading={submitting}
            containName="Save"
            disabled={submitting}
            onClick={handleSubmit(onSubmit)}
          />
        </CardActions>
      </form>
    </Card>
  );
};

export default BlockSettings;
