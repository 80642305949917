import React from "react";
import clsx from "clsx";

// material icons
import SearchIcon from "@material-ui/icons/Search";

// material-ui
import { Input, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: "4px",
    alignItems: "center",
    padding: theme.spacing(1),
    display: "flex",
    flexBasis: 420
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "-0.05px"
  }
}));

const SearchInput = ({ className, style, onChange, ...rest }) => {
  const classes = useStyles();

  return (
    <Paper {...rest} className={clsx(classes.root, className)} style={style}>
      <SearchIcon className={classes.icon} />
      <Input
        {...rest}
        className={classes.input}
        onChange={event => onChange(event)}
        disableUnderline
      />
    </Paper>
  );
};

export default SearchInput;
