import React, { useCallback, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { find, map, orderBy, chunk, pick, toLower } from "lodash";
import { forEachAsync } from "foreachasync";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import moment from "moment";

// assets
import selectFileImg from "assets/images/ic-select-files.png";
import uploadImg from "assets/images/ic-upload.png";
import uploadSuccessfullImg from "assets/images/ic-successfully.png";

// components (global)
import DialogWrapper from "components/Dialogs/DialogWrapper";
import DialogTitleWrapper from "components/Dialogs/DialogTitleWrapper";
import Command from "components/Table/Command";
import CustomEditCell from "components/Table/CustomEditCell";

// devexpress
import {
  DataTypeProvider,
  EditingState,
  PagingState,
  SortingState,
  IntegratedSorting,
  IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
  Grid as GridTable,
  PagingPanel,
  Table,
  TableEditColumn,
  TableEditRow,
  TableFixedColumns,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

// dropzone
import { useDropzone } from "react-dropzone";

// firesbase
import { useFirestore } from "react-redux-firebase";

// helpers
import { Capitalized } from "helpers/textHelpers";

// material-ui
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  dialogContent: { padding: "0" },
  innerContent: { padding: "40px 10px" },
  table: {
    whiteSpace: "nowrap",
    tableLayout: "auto",
    "& th": { backgroundColor: "#fafafa" },
    "& td div.flex": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  error: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  link: { color: "#AD2424", fontSize: "14px", textDecoration: "none" },
}));

// components
const TableComponentBase = ({ ...rest }) => {
  const classes = useStyles();
  return <Table.Table {...rest} className={classes.table} />;
};

const UploadCsvDialog = ({ open, handleDialogClose }) => {
  const classes = useStyles();
  const firestore = useFirestore();

  // selector
  const auth = useSelector((state) => state.firebase.auth);
  const userProfile = useSelector((state) => state.firebase.profile);
  const blockSelector = useSelector((state) => state.firestore.ordered.blocks);
  const unitsSelector = useSelector((state) => {
    return state.firestore.ordered.units
      ? orderBy(
          state.firestore.ordered.units,
          ["block_name", "house_number"],
          ["asc", "asc"]
        )
      : null;
  });
  const cardsSelector = useSelector(
    (state) => state.firestore.ordered.resident_cards
  );
  const selectedPropertyId = useSelector(
    (state) => state.main.selectedProperty
  );
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const accessCardSettings = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor].access_card_settings
      : null;
  });
  const unitAccessSelector = useSelector(
    (state) => state.firestore.data.unit_access
  );
  const accessLevelsSelector = useSelector((state) => {
    return state.firestore.ordered?.accessLevels
      ? orderBy(state.firestore.ordered.accessLevels, ["name"])
      : [];
  });
  const liftAccessLevelsSelector = useSelector((state) => {
    return state.firestore.ordered?.liftAccess
      ? orderBy(state.firestore.ordered.liftAccess, ["name"])
      : [];
  });

  // useMemo
  const csvTemplateData = useMemo(() => {
    const flatResult = map(unitsSelector, (unit) => {
      return {
        block: unit.block_name,
        unit: unit.house_number,
        "card id": "",
        "card no.": "",
        "card name": "",
        "card type (rfid/mifare/proximity)": "",
        "expired date (DD/MM/YYYY)": "",
        "active (Yes/No)": "",
        "anti-passback (Yes/No)": "",
        "lift card (Yes/No)": "",
        "contact no.": "",
        "vehicle No": "",
        "vehicle make": "",
        "vehicle model": "",
        "vehicle colour": "",
        "parking lot no.": "",
        "sticker no.": "",
        ...(!!accessCardSettings?.manual_assign_access_level && {
          "access level": "",
        }),
        ...(!!accessCardSettings?.manual_assign_floor_access_level && {
          "lift access": "",
        }),
      };
    });

    return orderBy(flatResult, ["block", "unit"], ["asc", "asc"]);
  }, [unitsSelector]);

  // state
  const [step, setStep] = useState(0);
  const [isSync, setIsSync] = useState(false);
  const [dataList, setDataList] = useState(null);
  const [errors, setErrors] = useState([]);
  const [cardTypes] = useState(["rfid", "mifare", "proximity"]);
  const [columns] = useState([
    {
      name: "unit",
      title: "Block & Unit",
      component: (
        <Autocomplete
          size="small"
          id="unit-list"
          options={unitsSelector}
          groupBy={(option) => option.block_name}
          getOptionLabel={(option) =>
            option.house_number ? option.house_number : ""
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Unit *"
              fullWidth
              size="small"
              name="unit"
              autoComplete="off"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: "disabled",
              }}
            />
          )}
        />
      ),
    },
    {
      name: "profileCode",
      title: "Card ID",
    },
    {
      name: "cardNumber",
      title: "Card No.",
    },
    {
      name: "profileName",
      title: "Card Name",
    },
    {
      name: "cardType",
      title: "Card Type",
      component: (
        <Autocomplete
          autoHighlight
          options={cardTypes}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose a type *"
              fullWidth
              size="small"
              name="cardType"
              autoComplete="off"
              variant="outlined"
            />
          )}
        />
      ),
    },
    {
      name: "expiredDate",
      title: "Expired Date",
      component: (
        <DatePicker
          inputVariant="outlined"
          fullWidth
          size="small"
          format="dd/MM/yyyy"
          clearable={true}
        />
      ),
    },
    {
      name: "active",
      title: "Active",
      getCellValue: (row) =>
        row.active ? (row.active.toLowerCase() === "yes" ? true : false) : true,
      component: (
        <FormControlLabel
          styles={{ alignItems: "center" }}
          control={<Checkbox name="status" color="primary" />}
        />
      ),
    },
    {
      name: "antiPassback",
      title: "Anti-passback",
      getCellValue: (row) =>
        row.antiPassback
          ? row.antiPassback.toLowerCase() === "yes"
            ? true
            : false
          : true,
      component: (
        <FormControlLabel
          styles={{ alignItems: "center" }}
          control={<Checkbox name="status" color="primary" />}
        />
      ),
    },
    {
      name: "isLift",
      title: "Lift Card",
      getCellValue: (row) =>
        row.isLift ? (row.isLift.toLowerCase() === "yes" ? true : false) : true,
      component: (
        <FormControlLabel
          styles={{ alignItems: "center" }}
          control={<Checkbox name="status" color="primary" />}
        />
      ),
    },
    {
      name: "contactNo",
      title: "Contact No.",
    },
    {
      name: "vehicleNo",
      title: "Vehicle No.",
    },
    {
      name: "vehicleMake",
      title: "Vehicle Make",
    },
    {
      name: "vehicleModel",
      title: "Vehicle Model",
    },
    {
      name: "vehicleColour",
      title: "Vehicle Colour",
    },
    {
      name: "parkingLotNo",
      title: "Parking Lot No.",
    },
    {
      name: "stickerNo",
      title: "Sticker No.",
    },
    ...(accessCardSettings?.manual_assign_access_level
      ? [
          {
            name: "accessLevelName",
            title: "Access Level",
          },
        ]
      : []),
    ...(accessCardSettings?.manual_assign_floor_access_level
      ? [
          {
            name: "liftAccessLevelName",
            title: "Lift Access",
          },
        ]
      : []),
  ]);

  const [columnExtensions] = useState([]);
  const [editingStateColumnExtensions] = useState([
    {
      columnName: "profileCode",
      editingEnabled: true,
    },
  ]);
  const [editingRowIds, getEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [sorting, getSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageSizes] = useState([5, 10, 15, 0]);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  // Dropzone
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        Papa.parse(file, {
          header: false,
          complete: (results) => {
            let resultList = [];
            map(results.data, (data, key) => {
              if (key > 0) {
                const blockName = data[0]?.trim();
                const unitName = data[1]?.trim();
                const blockFound = find(blockSelector, { name: blockName });
                const unitFound = find(unitsSelector, {
                  block_id: blockFound?.id,
                  house_number: unitName,
                });

                resultList = [
                  ...resultList,
                  {
                    id: key,
                    unit: unitFound ? unitFound : null,
                    profileCode: data[2]?.trim(),
                    cardNumber: data[3]?.trim(),
                    profileName: data[4]?.trim(),
                    cardType: data[5]?.trim(),
                    expiredDate: data[6]?.trim(),
                    active: data[7]?.trim(),
                    antiPassback: data[8]?.trim(),
                    isLift: data[9]?.trim(),
                    contactNo: data[10]?.trim(),
                    vehicleNo: data[11]?.trim(),
                    vehicleMake: data[12]?.trim(),
                    vehicleModel: data[13]?.trim(),
                    vehicleColour: data[14]?.trim(),
                    parkingLotNo: data[15]?.trim(),
                    stickerNo: data[16]?.trim(),
                    ...(!!accessCardSettings?.manual_assign_access_level && {
                      accessLevelName: data[17]?.trim(),
                    }),
                    ...(!!accessCardSettings?.manual_assign_floor_access_level && {
                      liftAccessLevelName: data[18]?.trim(),
                    }),
                  },
                ];
              }
            });

            setDataList(resultList);

            if (resultList.length > 0) {
              setStep(1);
            }
          },
        });
      });
    },
    [blockSelector, unitsSelector, accessCardSettings]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "text/csv, application/vnd.ms-excel",
    multiple: false,
  });

  // functions
  const deleteRows = (deletedIds) => {
    const rowsForDelete = dataList.slice();
    deletedIds.forEach((rowId) => {
      const index = rowsForDelete.findIndex((row) => row.id === rowId);
      if (index > -1) {
        rowsForDelete.splice(index, 1);
      }
    });
    return rowsForDelete;
  };

  const AddedRowsChange = (value) => {
    setAddedRows(
      value.map((row) =>
        Object.keys(row).length
          ? row
          : {
              unit: "",
            }
      )
    );
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;

    if (added) {
      const startingAddedId =
        dataList.length > 0 ? dataList[dataList.length - 1].id + 1 : 0;
      changedRows = [
        ...dataList,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
    }

    if (changed) {
      changedRows = dataList.map((row) =>
        changed[row.id] ? { ...row, ...changed[row.id] } : row
      );
    }

    if (deleted) {
      changedRows = deleteRows(deleted);
    }

    setDataList(changedRows);
  };

  const handleUploadCSV = () => {
    const batch = [];
    const profileRef = firestore.collection("ac_profiles");
    const auditTrailRef = firestore.collection("ac_audit_trails");

    let err = [];
    let batchIndex = 0;
    let index = 0;
    const chunkSize = 30;
    const dataChunk = chunk(dataList, chunkSize);

    dataChunk.forEach((chunk) => {
      batch.push(firestore.batch());

      chunk.forEach((data) => {
        index++;

        if (
          data.profileCode === undefined ||
          data.profileCode === null ||
          data.profileCode === "" ||
          data.cardNumber === undefined ||
          data.cardNumber === null ||
          data.cardNumber === ""
        ) {
          err = [
            ...err,
            {
              index: index,
              message:
                data.profileCode === undefined ||
                data.profileCode === null ||
                data.profileCode === ""
                  ? "Invalid Card ID"
                  : data.cardNumber === undefined ||
                    data.cardNumber === null ||
                    data.cardNumber === ""
                  ? "Invalid card no."
                  : "Invalid error",
            },
          ];
        } else {
          // Validate card is exists
          const card = find(cardsSelector, (card) => {
            return (
              card.identity_number === data.cardNumber &&
              card.status !== "deleted"
            );
          });

          const antiPassback =
            data.antiPassback.toLowerCase() === "yes" ? true : false;
          const expiredDate = data.expiredDate
            ? moment(data.expiredDate, "DD/MM/YYYY").toDate()
            : null;
          const isLift = data.isLift.toLowerCase() === "yes" ? true : false;
          const status =
            data.active.toLowerCase() === "yes" ? "active" : "inactive";

          let profileDoc;
          let profile;

          if (card) {
            // Update profile
            profileDoc = profileRef.doc(card.id);

            profile = {
              action: "modify",
              anti_passback: antiPassback,
              card_type: data.cardType,
              contact_number: data.contactNo,
              expired: {
                date: expiredDate,
                enable: expiredDate !== null,
              },
              is_lift: isLift,
              is_sync: isSync,
              parking_lot_no: data.parkingLotNo,
              profile_code: data.profileCode,
              profile_name: data.profileName,
              status: status,
              sticker_serial_no: data.stickerNo,
              updated_at: new Date(),
              updated_by: {
                user_id: auth.uid,
                name: userProfile.name,
              },
              vehicle_info: {
                colour: data.vehicleColour,
                make: data.vehicleMake,
                model: data.vehicleModel,
                number: data.vehicleNo,
              },
            };
          } else {
            // New profile
            profileDoc = profileRef.doc();

            // Handle end date
            var date = new Date();
            var year = date.getFullYear();
            var month = date.getMonth();
            var day = date.getDate();

            profile = {
              action: "add",
              anti_passback: antiPassback,
              attempted: 0,
              blocked_settings: {
                count: 0,
                enable: false,
              },
              card_type: data.cardType,
              created_at: new Date(),
              created_by: {
                user_id: auth.uid,
                name: userProfile.name,
              },
              contact_number: data.contactNo,
              end_date: new Date(year + 10, month, day),
              expired: {
                date: expiredDate,
                enable: expiredDate !== null,
              },
              identity_number: data.cardNumber,
              is_lift: isLift,
              is_sync: isSync,
              parking_lot_no: data.parkingLotNo,
              profile_code: data.profileCode,
              profile_name: data.profileName,
              profile_type: "resident",
              property_id: selectedPropertyId,
              status: status,
              sticker_serial_no: data.stickerNo,
              vehicle_info: {
                colour: data.vehicleColour,
                make: data.vehicleMake,
                model: data.vehicleModel,
                number: data.vehicleNo,
              },
              vendor: {
                id: selectedVendor,
                type: accessCardSettings.type,
              },
            };
          }

          /**
           * Set Access Group, Access Level, and Lift Access
           */
          let access = {};

          if (data.unit) {
            const unitAccess = unitAccessSelector
              ? unitAccessSelector[data.unit.id]
              : null;

            if (unitAccess) {
              access = {
                ...access,
                access_groups: unitAccess.resident.access_groups,
              };

              if (isLift) {
                access = {
                  ...access,
                  lift_access: unitAccess.resident.lift_access
                    ? unitAccess.resident.lift_access
                    : accessCardSettings.type.toLowerCase() === "falco"
                    ? "00"
                    : null,
                };
              } else {
                access = {
                  ...access,
                  lift_access:
                    accessCardSettings.type.toLowerCase() === "falco"
                      ? "00"
                      : null,
                };
              }

              if (
                accessCardSettings.type.toLowerCase() === "entrypass" ||
                accessCardSettings.type.toLowerCase() === "falco"
              ) {
                access = {
                  ...access,
                  access_level: unitAccess.resident.access_level
                    ? unitAccess.resident.access_level
                    : null,
                };
              }

              if (
                accessCardSettings.type.toLowerCase() === "entrypass" &&
                ((!!accessCardSettings?.manual_assign_access_level &&
                  !!data.accessLevelName) ||
                  (!!accessCardSettings?.manual_assign_floor_access_level &&
                    isLift &&
                    !!data.liftAccessLevelName))
              ) {
                const foundAccess = find(
                  accessLevelsSelector,
                  (accessLevel) =>
                    toLower(accessLevel.name) === toLower(data.accessLevelName)
                );

                if (foundAccess) {
                  access = {
                    ...access,
                    access_level: pick(foundAccess, ["id", "name", "number"]),
                  };
                }

                // if found manual assignlift access
                const foundLiftAccess = find(
                  liftAccessLevelsSelector,
                  (liftAccess) =>
                    toLower(liftAccess.name) ===
                    toLower(data.liftAccessLevelName)
                );
                if (foundLiftAccess) {
                  access = {
                    ...access,
                    lift_access: pick(foundLiftAccess, [
                      "id",
                      "name",
                      "number",
                    ]),
                  };
                }
              }
            } else {
              access = {
                access_groups: null,
                lift_access: null,
              };

              if (
                accessCardSettings.type.toLowerCase() === "entrypass" ||
                accessCardSettings.type.toLowerCase() === "falco"
              ) {
                access = {
                  ...access,
                  access_level: 0,
                };
              }
            }

            profile = {
              ...profile,
              access,
              unit: {
                block_id: data.unit?.block_id,
                block_name: data.unit?.block_name,
                unit_id: data.unit?.id,
                unit_name: data.unit?.house_number,
              },
            };
          } else {
            access = {
              access_groups: null,
              lift_access: null,
            };

            if (
              accessCardSettings.type.toLowerCase() === "entrypass" ||
              accessCardSettings.type.toLowerCase() === "falco"
            ) {
              access = {
                ...access,
                access_level: 0,
              };
            }

            profile = {
              ...profile,
              access,
            };
          }

          if (profileDoc && profile) {
            // Insert or update to ac_profiles collection
            batch[batchIndex].set(profileDoc, profile, { merge: true });

            // Insert into ac_audit_trails collection
            const trailData = {
              action: card ? "Card Modified" : "Card Added",
              created_at: new Date(),
              created_by: {
                user_id: auth.uid,
                name: userProfile.name,
              },
              description: `${Capitalized(userProfile.name)} ${
                card ? "modified" : "added"
              } resident card ${card ? card.identity_number : data.cardNumber}`,
              module: "access_card",
              function: "upload",
              profile: {
                id: card ? card.id : profileDoc.id,
                identity_number: card ? card.identity_number : data.cardNumber,
              },
              values: card ? card : profile,
            };

            batch[batchIndex].set(auditTrailRef.doc(), trailData, {
              merge: true,
            });
          }
        }
      });

      batchIndex++;
    });

    // return;
    if (err.length > 0) {
      setErrors(err);
    } else {
      setStep(2);

      let sumPercent = 0;
      let totalBatchByPercent = Math.floor(100 / batch.length);
      const waitingPerBatch = 20; // in seconds

      forEachAsync(batch, (batch, index) => {
        sumPercent = sumPercent + totalBatchByPercent;

        if (index !== 0) sleep(waitingPerBatch);

        return batch
          .commit()
          .then(() => {
            setUploadPercentage(sumPercent);
          })
          .catch((e) => {
            console.log("error", e.message);
            setStep(1);
          });
      }).then(() => {
        console.log("aysnc done");
        setUploadPercentage(100);
        setTimeout(() => {
          setStep(3);
        }, 1000);
      });
    }
  };

  const sleep = (seconds) => {
    const milliseconds = seconds * 1000;
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  };

  /** Progress Content
   * Step 1: Index 0, Select & upload CSV file
   * Step 2: Index 1, Edit content
   * Step 3: Index 2, Upload to server
   * Step 4: Index 3 Success
   */
  const stepOne = (
    <React.Fragment>
      <Box {...getRootProps()}>
        <input {...getInputProps()} />
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={2}
          className={classes.innerContent}
        >
          <Grid item>
            <Box component="img" src={selectFileImg} alt={selectFileImg}></Box>
          </Grid>
          <Grid item className={classes.selectFile}>
            <Typography compnent="p" variant="h4">
              Select File
            </Typography>
            <Typography compnent="p" variant="h6">
              Drop files here or click browse thorough your machine
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box p={2}>
        {dataList && dataList.length === 0 && (
          <Typography
            variant="h6"
            component="p"
            color="error"
            className={classes.error}
          >
            Error: File Is Empty
          </Typography>
        )}
        <DialogContentText>
          <CSVLink
            filename={`resident_card_template.csv`}
            data={csvTemplateData}
            className={classes.link}
          >
            Download Template
          </CSVLink>
        </DialogContentText>
      </Box>
    </React.Fragment>
  );

  const stepTwo = (
    <React.Fragment>
      <GridTable rows={dataList} columns={columns} getRowId={(row) => row.id}>
        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box width={200}>
                <Typography component="p" variant="h6">
                  {row.unit
                    ? `${row.unit?.block_name}, ${row.unit?.house_number}`
                    : null}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["unit"]}
        />

        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box width={150}>
                <Typography component="p" variant="h6">
                  {row.cardNumber}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["cardNumber"]}
        />

        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box>
                <Typography component="p" variant="h6">
                  {row.profileCode}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["profileCode"]}
        />

        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box width={200}>
                <Typography component="p" variant="h6">
                  {row.profileName}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["profileName"]}
        />

        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box width={150}>
                <Typography component="p" variant="h6">
                  {row.cardType}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["cardType"]}
        />

        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box>
                <Typography component="p" variant="h6">
                  {row.active}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["active"]}
        />

        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box>
                <Typography component="p" variant="h6">
                  {row.antiPassback}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["antiPassback"]}
        />

        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box>
                <Typography component="p" variant="h6">
                  {row.isLift}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["isLift"]}
        />

        <SortingState sorting={sorting} onSortingChange={getSorting} />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />

        <EditingState
          editingRowIds={editingRowIds}
          onEditingRowIdsChange={getEditingRowIds}
          rowChanges={rowChanges}
          onRowChangesChange={setRowChanges}
          addedRows={addedRows}
          onAddedRowsChange={AddedRowsChange}
          onCommitChanges={commitChanges}
          columnExtensions={editingStateColumnExtensions}
        />

        <IntegratedSorting />
        <IntegratedPaging />

        <Table
          tableComponent={TableComponentBase}
          columnExtensions={columnExtensions}
        />
        <TableHeaderRow showSortingControls />

        <TableEditRow cellComponent={CustomEditCell} />

        <TableEditColumn
          width={140}
          showAddCommand={!addedRows.length}
          showEditCommand
          showDeleteCommand
          commandComponent={Command}
        />

        <TableFixedColumns leftColumns={[TableEditColumn.COLUMN_TYPE]} />

        <PagingPanel pageSizes={pageSizes} />
      </GridTable>

      {errors.length > 0 && (
        <Box
          style={{
            paddingLeft: "40px",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
        >
          <Typography compnent="error" variant="h6" color="error">
            Error
          </Typography>

          {map(errors, (error) => (
            <Typography
              key={error.index}
              compnent="error"
              variant="h6"
              color="error"
            >
              {`Row ${error.index} - ${error.message}`}
            </Typography>
          ))}
        </Box>
      )}
    </React.Fragment>
  );

  const stepThree = (
    <React.Fragment>
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={2}
        className={classes.innerContent}
      >
        <Grid item>
          <Box component="img" src={uploadImg} alt={uploadImg}></Box>
        </Grid>
        <Grid item>
          <Typography compnent="p" variant="h4">
            {uploadPercentage} %
          </Typography>
          <Typography compnent="p" variant="h6">
            Your file has been uploading, please wait for a moment.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  const stepFour = (
    <React.Fragment>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.innerContent}
      >
        <Grid item>
          <Box
            component="img"
            src={uploadSuccessfullImg}
            alt={uploadSuccessfullImg}
          />
        </Grid>
        <Grid item>
          <Typography compnent="p" variant="h4">
            Successfully
          </Typography>
          <Typography compnent="p" variant="h6">
            Your file has been uploaded sucessfully.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  const progressContent = [stepOne, stepTwo, stepThree, stepFour];

  /** Progress Content
   * Step 1: Index 0, null
   * Step 2: Index 1, Confirm & save
   * Step 3: Index 2, null
   * Step 4: Index 3 Success
   */

  const progressAction = [
    null,
    <React.Fragment>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={isSync}
            onChange={(event) => {
              setIsSync(event.target.checked);
            }}
          />
        }
        label="Is Sync"
      />
      <Button color="primary" onClick={() => setStep(0)}>
        Back
      </Button>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        onClick={handleUploadCSV}
      >
        {"Confirm & Upload"}
      </Button>
    </React.Fragment>,
    null,
    <Button
      variant="contained"
      color="primary"
      type="submit"
      onClick={handleDialogClose}
    >
      Done
    </Button>,
  ];

  return (
    <form autoComplete="off" noValidate={true}>
      <DialogWrapper size="lg" open={open}>
        <DialogTitleWrapper
          title="Upload resident csv template"
          handleClose={handleDialogClose}
        />

        <DialogContent dividers className={classes.dialogContent}>
          {progressContent[step]}
        </DialogContent>

        {progressAction[step] && (
          <DialogActions>{progressAction[step]}</DialogActions>
        )}
      </DialogWrapper>
    </form>
  );
};

export default React.memo(UploadCsvDialog);
