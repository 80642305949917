import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { find, forEach, map, orderBy } from "lodash";
import { forEachAsync } from "foreachasync";
import Papa from "papaparse";
import moment from "moment";

// assets
import selectFileImg from "assets/images/ic-select-files.png";
import templateCSV from "assets/templates/visitor_card_template.csv";
import uploadImg from "assets/images/ic-upload.png";
import uploadSuccessfullImg from "assets/images/ic-successfully.png";

// components (global)
import DialogWrapper from "components/Dialogs/DialogWrapper";
import DialogTitleWrapper from "components/Dialogs/DialogTitleWrapper";
import Command from "components/Table/Command";
import CustomEditCell from "components/Table/CustomEditCell";

// devexpress
import {
  DataTypeProvider,
  EditingState,
  PagingState,
  SortingState,
  IntegratedSorting,
  IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
  Grid as GridTable,
  PagingPanel,
  Table,
  TableEditColumn,
  TableEditRow,
  TableFixedColumns,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

// dropzone
import { useDropzone } from "react-dropzone";

// firesbase
import { useFirestore } from "react-redux-firebase";

// helpers
import { Capitalized } from "helpers/textHelpers";

// material-ui
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  dialogContent: { padding: "0" },
  innerContent: { padding: "40px 10px" },
  table: {
    whiteSpace: "nowrap",
    tableLayout: "auto",
    "& th": { backgroundColor: "#fafafa" },
    "& td div.flex": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  error: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

// components
const TableComponentBase = ({ ...rest }) => {
  const classes = useStyles();
  return <Table.Table {...rest} className={classes.table} />;
};

const UploadCsvDialog = ({ open, handleDialogClose }) => {
  const classes = useStyles();
  const firestore = useFirestore();

  // selector
  const auth = useSelector((state) => state.firebase.auth);
  const userProfile = useSelector((state) => state.firebase.profile);
  const cardsSelector = useSelector(
    (state) => state.firestore.ordered.resident_cards
  );
  const selectedPropertyId = useSelector(
    (state) => state.main.selectedProperty
  );
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const accessCardSettings = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor].access_card_settings
      : null;
  });

  // state
  const [isSync,setIsSync] = useState(false);
  const [step, setStep] = useState(0);
  const [dataList, setDataList] = useState(null);
  const [errors, setErrors] = useState([]);
  const [cardTypes] = useState(["mifare", "proximity"]);
  const [columns] = useState([
    {
      name: "cardNumber",
      title: "Card No.",
    },
    {
      name: "profileName",
      title: "Card Name",
    },
    {
      name: "cardType",
      title: "Card Type",
      component: (
        <Autocomplete
          autoHighlight
          options={cardTypes}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose a type *"
              fullWidth
              size="small"
              name="cardType"
              autoComplete="off"
              variant="outlined"
            />
          )}
        />
      ),
    },
    {
      name: "active",
      title: "Active",
      getCellValue: (row) =>
        row.active ? (row.active.toLowerCase() === "yes" ? true : false) : true,
      component: (
        <FormControlLabel
          styles={{ alignItems: "center" }}
          control={<Checkbox name="status" color="primary" />}
        />
      ),
    },
    {
      name: "antiPassback",
      title: "Anti-passback",
      getCellValue: (row) =>
        row.antiPassback
          ? row.antiPassback.toLowerCase() === "yes"
            ? true
            : false
          : true,
      component: (
        <FormControlLabel
          styles={{ alignItems: "center" }}
          control={<Checkbox name="status" color="primary" />}
        />
      ),
    },
    {
      name: "passNo",
      title: "Pass No.",
    },
    {
      name: "passType",
      title: "Pass Type",
    },
  ]);
  const [columnExtensions] = useState([
    // {
    //   columnName: "active",
    //   align: "center"
    // }
  ]);
  const [editingStateColumnExtensions] = useState([
    {
      columnName: "profileCode",
      editingEnabled: true,
    },
  ]);
  const [editingRowIds, getEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [sorting, getSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageSizes] = useState([5, 10, 15, 0]);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  // Dropzone
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      Papa.parse(file, {
        header: false,
        encoding: "UTF-8",
        skipEmptyLines: true,
        complete: (results, file) => {
          let resultList = [];
          map(results.data, (data, key) => {
            if (key > 0) {
              resultList = [
                ...resultList,
                {
                  id: key,
                  cardNumber: data[0]?.trim(),
                  profileName: data[1]?.trim(),
                  cardType: data[2]?.trim().toLowerCase(),
                  active: data[3] ? data[3].trim() : "Yes",
                  antiPassback: data[4] ? data[4].trim() : "Yes",
                  passNo: data[5]?.trim().toUpperCase(),
                  passType: data[6]?.trim(),
                },
              ];
            }
          });

          setDataList(resultList);

          if (resultList.length > 0) {
            setStep(1);
          }
        },
      });
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "text/csv, application/vnd.ms-excel",
    multiple: false,
  });

  // functions
  const deleteRows = (deletedIds) => {
    const rowsForDelete = dataList.slice();
    deletedIds.forEach((rowId) => {
      const index = rowsForDelete.findIndex((row) => row.id === rowId);
      if (index > -1) {
        rowsForDelete.splice(index, 1);
      }
    });
    return rowsForDelete;
  };

  const AddedRowsChange = (value) => {
    setAddedRows(
      value.map((row) =>
        Object.keys(row).length
          ? row
          : {
              unit: "",
            }
      )
    );
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;

    if (added) {
      const startingAddedId =
        dataList.length > 0 ? dataList[dataList.length - 1].id + 1 : 0;
      changedRows = [
        ...dataList,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
    }

    if (changed) {
      changedRows = dataList.map((row) =>
        changed[row.id] ? { ...row, ...changed[row.id] } : row
      );
    }

    if (deleted) {
      changedRows = deleteRows(deleted);
    }

    setDataList(changedRows);
  };

  const handleUploadCSV = () => {
    const batch = [];
    const profileRef = firestore.collection("ac_profiles");
    const auditTrailRef = firestore.collection("ac_audit_trails");

    let err = [];
    let batchIndex = 0;

    // Handle end date
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    batch.push(firestore.batch());

    forEach(dataList, (data, index) => {
      if (
        data.passNo === undefined ||
        data.passNo === null ||
        data.passNo === "" ||
        data.cardNumber === undefined ||
        data.cardNumber === null ||
        data.cardNumber === ""
      ) {
        err = [
          ...err,
          {
            index: index + 1,
            message:
              data.passNo === undefined ||
              data.passNo === null ||
              data.passNo === ""
                ? "Invalid Pass No."
                : data.cardNumber === undefined ||
                  data.cardNumber === null ||
                  data.cardNumber === ""
                ? "Invalid Card No."
                : "Invalid error",
          },
        ];
      } else {
        // Validate card is exists
        const card = find(
          cardsSelector,
          (card) => card.identity_number === data.cardNumber
        );

        const antiPassback =
          data.antiPassback.toLowerCase() === "yes" ? true : false;
        const status =
          data.active.toLowerCase() === "yes" ? "active" : "inactive";

        let profileDoc;
        let profile;

        if (card) {
          profileDoc = profileRef.doc(card.id);

          let isSync = true;

          if (
            antiPassback !== card.anti_passback ||
            data.profileName !== card.profile_name ||
            status !== card.status ||
            data.unit?.id !== card.unit.unit_id
          ) {
            isSync = false;
          }

          profile = {
            action: "modify",
            anti_passback: antiPassback,
            card_type: data.cardType,
            is_sync: isSync,
            pass_type: data.passType,
            profile_code: data.passNo,
            profile_name: data.profileName,
            status: status,
            updated_at: new Date(),
            updated_by: {
              user_id: auth.uid,
              name: userProfile.name,
            },
          };
        } else {
          profileDoc = profileRef.doc();

          profile = {
            action: "add",
            anti_passback: antiPassback,
            attempted: 0,
            card_type: data.cardType,
            created_at: new Date(),
            created_by: {
              user_id: auth.uid,
              name: userProfile.name,
            },
            end_date: new Date(year + 10, month, day),
            identity_number: data.cardNumber,
            is_assign: false,
            is_lift: false,
            is_sync: isSync,
            pass_type: data.passType,
            profile_code: data.passNo,
            profile_name: data.profileName,
            profile_type: "visitor",
            property_id: selectedPropertyId,
            status: status,
            vendor: {
              id: selectedVendor,
              type: accessCardSettings.type,
            },
          };
        }

        if (profileDoc && profile) {
          // Insert or update to ac_profiles collection
          batch[batchIndex].set(profileDoc, profile, { merge: true });
          batch.push(firestore.batch());
          batchIndex++;

          // Insert into ac_audit_trails collection
          const trailData = {
            action: card ? "modified" : "added",
            created_at: new Date(),
            created_by: {
              user_id: auth.uid,
              name: userProfile.name,
            },
            description: `${Capitalized(userProfile.name)} had been ${
              card ? "modified" : "added"
            } visitor card ${card ? card.identity_number : data.cardNumber}`,
            module: "access_card",
            function: "upload",
            profile: {
              id: card ? card.id : profileDoc.id,
              identity_number: card ? card.identity_number : data.cardNumber,
            },
            values: card ? card : profile,
          };

          batch[batchIndex].set(auditTrailRef.doc(), trailData, {
            merge: true,
          });
          batch.push(firestore.batch());
          batchIndex++;
        }
      }
    });
    // return;
    if (err.length > 0) {
      setErrors(err);
    } else {
      setStep(2);

      let sumPercent = 0;
      let totalBatchByPercent = Math.floor(100 / batch.length);

      forEachAsync(batch, (batch) => {
        sumPercent = sumPercent + totalBatchByPercent;

        return batch
          .commit()
          .then(() => {
            setUploadPercentage(sumPercent);
          })
          .catch((e) => {
            console.log("error", e.message);
            setStep(1);
          });
      }).then(() => {
        setUploadPercentage(100);
        setTimeout(() => {
          setStep(3);
        }, 1000);
      });
    }
  };

  /** Progress Content
   * Step 1: Index 0, Select & upload CSV file
   * Step 2: Index 1, Edit content
   * Step 3: Index 2, Upload to server
   * Step 4: Index 3 Success
   */
  const stepOne = (
    <React.Fragment>
      <Box {...getRootProps()}>
        <input {...getInputProps()} />
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={2}
          className={classes.innerContent}
        >
          <Grid item>
            <Box component="img" src={selectFileImg} alt={selectFileImg}></Box>
          </Grid>
          <Grid item className={classes.selectFile}>
            <Typography compnent="p" variant="h4">
              Select File
            </Typography>
            <Typography compnent="p" variant="h6">
              Drop files here or click browse thorough your machine
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box p={2}>
        {dataList && dataList.length === 0 && (
          <Typography
            variant="h6"
            component="p"
            color="error"
            className={classes.error}
          >
            Error: File Is Empty
          </Typography>
        )}
        <DialogContentText>
          <Link href={templateCSV}>Download Visitor CSV Template</Link>
        </DialogContentText>
      </Box>
    </React.Fragment>
  );

  const stepTwo = (
    <React.Fragment>
      <GridTable rows={dataList} columns={columns} getRowId={(row) => row.id}>
        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box width={150}>
                <Typography component="p" variant="h6">
                  {row.cardNumber}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["cardNumber"]}
        />

        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box>
                <Typography component="p" variant="h6">
                  {row.profileCode}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["profileCode"]}
        />

        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box width={200}>
                <Typography component="p" variant="h6">
                  {row.profileName}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["profileName"]}
        />

        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box width={150}>
                <Typography component="p" variant="h6">
                  {row.cardType}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["cardType"]}
        />

        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box>
                <Typography component="p" variant="h6">
                  {row.active}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["active"]}
        />

        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box>
                <Typography component="p" variant="h6">
                  {row.antiPassback}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["antiPassback"]}
        />

        <DataTypeProvider
          formatterComponent={({ value, row }) => (
            <React.Fragment>
              <Box>
                <Typography component="p" variant="h6">
                  {row.isLift}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          for={["isLift"]}
        />

        <SortingState sorting={sorting} onSortingChange={getSorting} />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />

        <EditingState
          editingRowIds={editingRowIds}
          onEditingRowIdsChange={getEditingRowIds}
          rowChanges={rowChanges}
          onRowChangesChange={setRowChanges}
          addedRows={addedRows}
          onAddedRowsChange={AddedRowsChange}
          onCommitChanges={commitChanges}
          columnExtensions={editingStateColumnExtensions}
        />

        <IntegratedSorting />
        <IntegratedPaging />

        <Table
          tableComponent={TableComponentBase}
          columnExtensions={columnExtensions}
        />
        <TableHeaderRow showSortingControls />

        <TableEditRow cellComponent={CustomEditCell} />

        <TableEditColumn
          width={140}
          showAddCommand={!addedRows.length}
          showEditCommand
          showDeleteCommand
          commandComponent={Command}
        />

        <TableFixedColumns leftColumns={[TableEditColumn.COLUMN_TYPE]} />

        <PagingPanel pageSizes={pageSizes} />
      </GridTable>

      {errors.length > 0 && (
        <Box
          style={{
            paddingLeft: "40px",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
        >
          <Typography compnent="error" variant="h6" color="error">
            Error
          </Typography>

          {map(errors, (error) => (
            <Typography
              key={error.index}
              compnent="error"
              variant="h6"
              color="error"
            >
              {`Row ${error.index} - ${error.message}`}
            </Typography>
          ))}
        </Box>
      )}
    </React.Fragment>
  );

  const stepThree = (
    <React.Fragment>
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={2}
        className={classes.innerContent}
      >
        <Grid item>
          <Box component="img" src={uploadImg} alt={uploadImg}></Box>
        </Grid>
        <Grid item>
          <Typography compnent="p" variant="h4">
            {uploadPercentage} %
          </Typography>
          <Typography compnent="p" variant="h6">
            Your file has been uploading, please wait for a moment.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  const stepFour = (
    <React.Fragment>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.innerContent}
      >
        <Grid item>
          <Box
            component="img"
            src={uploadSuccessfullImg}
            alt={uploadSuccessfullImg}
          />
        </Grid>
        <Grid item>
          <Typography compnent="p" variant="h4">
            Successfully
          </Typography>
          <Typography compnent="p" variant="h6">
            Your file has been uploaded sucessfully.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  const progressContent = [stepOne, stepTwo, stepThree, stepFour];

  /** Progress Content
   * Step 1: Index 0, null
   * Step 2: Index 1, Confirm & save
   * Step 3: Index 2, null
   * Step 4: Index 3 Success
   */

  const progressAction = [
    null,
    <React.Fragment>
       <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={isSync}
            onChange={(event) => {
              setIsSync(event.target.checked);
            }}
          />
        }
        label="Is Sync"
      />
      <Button color="primary" onClick={() => setStep(0)}>
        Back
      </Button>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        onClick={handleUploadCSV}
      >
        {"Confirm & Upload"}
      </Button>
    </React.Fragment>,
    null,
    <Button
      variant="contained"
      color="primary"
      type="submit"
      onClick={handleDialogClose}
    >
      Done
    </Button>,
  ];

  return (
    <form autoComplete="off" noValidate={true}>
      <DialogWrapper size="lg" open={open}>
        <DialogTitleWrapper
          title="Upload visitor csv template"
          handleClose={handleDialogClose}
        />

        <DialogContent dividers className={classes.dialogContent}>
          {progressContent[step]}
        </DialogContent>

        {progressAction[step] && (
          <DialogActions>{progressAction[step]}</DialogActions>
        )}
      </DialogWrapper>
    </form>
  );
};

export default React.memo(UploadCsvDialog);
