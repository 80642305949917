import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";

import authReducer from "redux/auth/authReducer";
import AccessRoleReducer from "stores/accessRole/AccessRoleReducer";
import CommonReducer from "stores/common/CommonReducer";
import CardReducer from "stores/card/CardReducer";
import MainReducer from "stores/main/MainReducers";
import PropertyReducer from "stores/property/PropertyReducer";
import SettingReducer from "stores/setting/SettingReducer";
import VehicleReducer from "stores/vehicle/VehicleReducer";
import dashReducer from "redux/dashboard/dashReducers";

export const initialState = {};

export default combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  authReducer: authReducer,
  dashReducer: dashReducer,
  accessRole: AccessRoleReducer,
  common: CommonReducer,
  card: CardReducer,
  main: MainReducer,
  property: PropertyReducer,
  setting: SettingReducer,
  vehicle: VehicleReducer,
});
