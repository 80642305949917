import React from "react";
import { useSelector } from "react-redux";

// components (local)
import { DeviceList, PurposeItem, ServiceItem } from "./components";

// firebase
import { useFirestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";

// material-ui
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    overflowX: "auto",
    overflowY: "hidden",
    whiteSpace: "nowrap",
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  gateSection: {
    marginBottom: theme.spacing(2),
  },
}));

const DeviceBoard = ({ vendorId }) => {
  const classes = useStyles();

  // selector
  // const vendors = useSelector(state => state.firestore.ordered.vendors);

  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  // const vendor = useSelector(state => {
  //   return state.firestore.data.vendors
  //     ? state.firestore.data.vendors[selectedVendor]
  //     : null;
  // });

  const guardUsers = useSelector((state) => state.firestore.data.guardUsers);
  const selectedProperty = useSelector((state) => state.main.selectedProperty);

  // firestore
  useFirestoreConnect([
    {
      collection: "ac_users",
      where: [
        ["property_id", "==", selectedProperty],
        ["vendor_id", "==", selectedVendor],
      ],
      orderBy: "created_at",
      storeAs: "guardUsers",
    },
  ]);

  if (!isLoaded(guardUsers) || isEmpty(guardUsers)) {
    return null;
  }

  return (
    <div className={classes.container}>
      {Object.keys(guardUsers).map((key) => (
        <DeviceList
          title={guardUsers[key].name}
          subTitle={guardUsers[key].email}
          key={key}
          id={key}
        >
          {guardUsers[key].services &&
            guardUsers[key].services.map((service, index) => (
              <ServiceItem
                title={"xSocket Service"}
                key={index}
                userId={key}
                index={index}
              />
            ))}

          {guardUsers[key].purpose_of_visit.map((purpose, index) => (
            <PurposeItem
              title={"Purpose Of Visit"}
              purpose={purpose}
              userId={key}
              index={index}
              key={index}
            />
          ))}
        </DeviceList>
      ))}
    </div>
  );
};

export default DeviceBoard;
