import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { orderBy, find } from "lodash";
import axios from "http-axios";

// components (global)
import ButtonProgress from "components/ButtonProgress";
import DialogWrapper from "components/Dialogs/DialogWrapper";
import DialogTitleWrapper from "components/Dialogs/DialogTitleWrapper";

// firebase
import { useFirebase } from "react-redux-firebase";

// form
import { useForm } from "react-hook-form";

// helpers
// material-ui
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";

import { toggleEditDoorAccessDialogClose } from "stores/setting/SettingAction";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

const EditDoorAccessDialog = () => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const { handleSubmit, setValue, register } = useForm();

  // selector
  const { open, id } = useSelector(
    (state) => state.setting.edit_door_access_dialog
  );
  const uid = useSelector((state) => state.firebase.auth.uid);
  const blockAccess = useSelector(
    (state) => state.firestore.data.blockAccess?.[id] ?? null
  );
  const accessGroups = useSelector((state) =>
    orderBy(state.firestore.ordered.accessGroups ?? [], ["name"], ["asc"])
  );
  const selectedPropertyId = useSelector(
    (state) => state.main.selectedProperty
  );

  const selectedVendor = useSelector((state) => state.main.selectedVendor);

  // state
  const [submitting, setSubmitting] = useState(false);

  // effect
  useEffect(() => {
    register("residentAccessGroup");
    register("visitorAccessGroup");

    setValue(
      "residentAccessGroup",
      find(
        accessGroups,
        (o) => o.id === blockAccess?.resident?.access_groups?.id
      )
    );

    setValue(
      "visitorAccessGroup",
      find(
        accessGroups,
        (o) => o.id === blockAccess?.visitor?.access_groups?.id
      )
    );
  }, [register, setValue, blockAccess, accessGroups]);

  // functions
  const onHandleClose = () => {
    if (!submitting) {
      dispatch(toggleEditDoorAccessDialogClose());
    }
  };

  const onSubmit = (value) => {
    if (!submitting) {
      setSubmitting(true);

      let residentAccessGroup = null;
      let visitorAccessGroup = null;

      if (!!value.residentAccessGroup) {
        // delete value.residentAccessGroup.created_at;
        // delete value.residentAccessGroup.created_by;

        residentAccessGroup = value.residentAccessGroup;
      }

      if (!!value.visitorAccessGroup) {
        // delete value.visitorAccessGroup.created_at;
        // delete value.visitorAccessGroup.created_by;

        visitorAccessGroup = value.visitorAccessGroup;
      }

      firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          };

          axios
            .post(
              `jagaCardUpdateDoorAccess`,
              {
                uid,
                property_id: selectedPropertyId,
                block_id: id,
                vendor_id: selectedVendor,
                resident_access_group: residentAccessGroup,
                visitor_access_group: visitorAccessGroup,
              },
              {
                headers: headers,
              }
            )
            .then((res) => {
              if (res.data.result === "success") {
                setSubmitting(false);
                onHandleClose();
                snackbar.enqueueSnackbar("Door Access had been added", {
                  variant: "success",
                });
              }
            })
            .catch((e) => {
              setSubmitting(false);
              onHandleClose();
              snackbar.enqueueSnackbar(e.message, {
                variant: "error",
                persist: false,
              });
            });
        });
    }
  };

  return !!blockAccess ? (
    <form
      noValidate
      xs={12}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <DialogWrapper size="md" open={open}>
        <DialogTitleWrapper
          title="Edit Door Access"
          handleClose={onHandleClose}
        />

        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item container md={12} xs={12} spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField
                  size="small"
                  margin="dense"
                  label="Block"
                  disabled={true}
                  variant="outlined"
                  defaultValue={blockAccess.block_name}
                  readOnly={true}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Divider />
            </Grid>

            <Grid item container md={6} xs={12} spacing={1}>
              <Grid item md={12} xs={12}>
                <Typography className={classes.modalTitle} variant="h4">
                  Resident
                </Typography>
                <Typography className={classes.modalTitle} variant="h6">
                  Please choose the Access Group
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Autocomplete
                  autoHighlight
                  options={accessGroups}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) =>
                    option.display_name ? option.display_name : option.name
                  }
                  onChange={(e, value) => {
                    setValue("residentAccessGroup", value ? value : null);
                  }}
                  defaultValue={find(
                    accessGroups,
                    (o) => o.id === blockAccess?.resident?.access_groups?.id
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose an access group *"
                      fullWidth
                      size="small"
                      name="accessLevel"
                      autoComplete="off"
                      variant="outlined"
                      inputRef={register}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item container md={6} xs={12} spacing={1}>
              <Grid item md={12} xs={12}>
                <Typography className={classes.modalTitle} variant="h4">
                  Visitor
                </Typography>
                <Typography className={classes.modalTitle} variant="h6">
                  Please choose the Access Group
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Autocomplete
                  autoHighlight
                  options={accessGroups}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) =>
                    option.display_name ? option.display_name : option.name
                  }
                  onChange={(e, value) => {
                    setValue("visitorAccessGroup", value ? value : null);
                  }}
                  defaultValue={find(
                    accessGroups,
                    (o) => o.id === blockAccess?.visitor?.access_groups?.id
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose an access group *"
                      fullWidth
                      size="small"
                      name="accessLevel"
                      autoComplete="off"
                      variant="outlined"
                      inputRef={register}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onHandleClose}>
            Cancel
          </Button>
          <ButtonProgress
            type="submit"
            containName="Save"
            margin={false}
            loading={submitting}
            disabled={submitting}
            onClick={handleSubmit(onSubmit)}
          />
        </DialogActions>
      </DialogWrapper>
    </form>
  ) : (
    <div></div>
  );
};

export default EditDoorAccessDialog;
