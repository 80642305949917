import React from "react";
import { map } from "lodash";

// components (local)
import MemberCard from "./MemberCard";

// material-ui
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    width: 92,
    height: 92,
    minWidth: 92,
    minHeight: 92,
    backgroundColor: theme.palette.grey[500],
    position: "absolute",
    marginTop: -46,
    marginLeft: theme.spacing(2),
  },
  card: {
    marginTop: 46,
  },
  cardContent: {
    marginTop: theme.spacing(6),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(0),
  },
  actionButton: {
    padding: 0,
  },
  showMoreButton: {
    marginLeft: "auto",
  },
}));

const MemberGrid = ({ members }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container spacing={2}>
      {map(members, (member) => (
        <Grid item md={3} key={member.id}>
          <MemberCard member={member} />
        </Grid>
      ))}
    </Grid>
  );
};

export default MemberGrid;
