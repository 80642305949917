import React, { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { orderBy, map } from "lodash";
import moment from "moment";
import axios from "axios";
// components (global)
import {
  Button,
  Grid,
  Hidden,
  Chip,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Fab,
} from "@material-ui/core";
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Sorting,
  FilterRow,
  Selection,
  Toolbar,
  Item,
  Paging,
  Scrolling,
  Pager,
} from "devextreme-react/data-grid";

// firebase
import { useFirestore } from "react-redux-firebase";

import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(2) },
  action: {
    textAlign: "center",
  },
}));

const VehicleGroupTable = () => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const firestore = useFirestore();
  const exporterRef = useRef(null);
  // variable
  const columns = [
    {
      name: "display_name",
      title: "Display Name",
    },
    {
      name: "name",
      title: "Name",
    },
    {
      name: "description",
      title: "Description",
    },
    { name: "created_at", title: "Uploaded Date" },
  ];

  const columnExtensions = [
    {
      columnName: "menuButton",
      filteringEnabled: false,
      sortingEnabled: false,
      groupingEnabled: false,
      align: "center",
      width: 200,
    },
  ];

  // selector

  const selectedVendor = useSelector((state) => state.main.selectedApnrVendor);
  const vehicleGroupsSelector = useSelector(
    (state) => state.firestore.data.vehicleGroups
  );
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const vendor = useSelector((state) => {
    return state.firestore.data.apnrVendors
      ? state.firestore.data.apnrVendors[selectedVendor]
      : null;
  });
  // memo

  const vehicleGroups = useMemo(() => {
    let flatResult = [];
    if (vehicleGroupsSelector) {
      flatResult = map(vehicleGroupsSelector, (group) => {
        return {
          ...group,
          group_id: group.vehicle_group,
          name: group.name,
          vehicle_limit: group.vehicle_limit,

          created_at: moment
            .unix(group.created_at?.seconds)
            .format("DD/MM/YYYY h:mm a"),
        };
      });
    }
    return orderBy(flatResult, ["name"], ["asc"]);
  }, [vehicleGroupsSelector]);

  // functions

  const acGroupRef = firestore
    .collection("apnr_vendors")
    .doc(selectedVendor)
    .collection("vehicle_groups");

  const handleAddVehicleGroup = async (data) => {
    if (Object.keys(data).length === 0) {
      snackbar.enqueueSnackbar(
        "Data not saved. you have submitted error records.",
        {
          variant: "error",
          persist: false,
        }
      );
      return;
    }

    try {
      await acGroupRef.add({
        created_at: new Date(),
        created_by: {
          user_id: auth.uid,
          name: profile.name,
        },
        ...data,
      });

      snackbar.enqueueSnackbar("New Access Group had been added", {
        variant: "success",
      });
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, {
        variant: "error",
        persist: false,
      });
    }

    if (vendor?.token != null) {
      const diff = moment(new Date()).diff(moment(vendor?.token_last_updated));
      const diffDuration = moment.duration(diff);
      if (diffDuration.minutes() >= 25) {
        axios
          .post(
            `http://edge-remote.gotapway.com:15001/login`,
            {
              username: "jagaapp",
              password: "J@g@App123!",
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            axios
              .put(
                `http://edge-remote.gotapway.com:15001/add_to_vehicle_group`,
                {
                  vehicle_limit_count: 4,

                  name: data.ad,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + res.data.token,
                  },
                }
              )
              .then((res) => {
                firestore
                  .collection("apnr_vendors")
                  .doc(selectedVendor)
                  .set(
                    {
                      token: res.data.token,
                      token_last_updated: new Date(),
                    },
                    { merge: true }
                  )
                  .catch((e) => {});
              })
              .catch((e) => {});
          })
          .catch((e) => {});
      } else {
        axios
          .put(
            `http://edge-remote.gotapway.com:15001/add_to_vehicle_group`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + vendor?.token,
              },
            }
          )
          .then((res) => {})
          .catch((e) => {});
      }
    } else {
      axios
        .post(
          `http://edge-remote.gotapway.com:15001/login`,
          {
            username: "jagaapp",
            password: "J@g@App123!",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          firestore
            .collection("apnr_vendors")
            .doc(selectedVendor)
            .set(
              {
                token: res.data.token,
                token_last_updated: new Date(),
              },
              { merge: true }
            )
            .catch((e) => {});
          axios
            .put(
              `http://edge-remote.gotapway.com:15001/add_to_vehicle_group`,
              {},
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + res.data.token,
                },
              }
            )
            .then((res) => {})
            .catch((e) => {});
        })
        .catch((e) => {});
    }
  };

  if (!vehicleGroups) return null;

  return (
    <div className={classes.root}>
      <React.Fragment>
        <Paper>
          <DataGrid
            columnAutoWidth={false}
            dataSource={vehicleGroups}
            // onRowDblClick={onRowDblClick}
            ref={exporterRef}
            showColumnLines={false}
          >
            <FilterRow visible={true} />

            <Column caption="Name" dataField="name" />
            <Column caption="Group Id" dataField="group_id" />
            <Column caption="Vehicle Limit" dataField="vehicle_limit" />
            <Column caption="Details" dataField="description" />

            <Paging defaultPageSize={10} />
            <Pager
              visible={true}
              displayMode="full"
              showPageSizeSelector={false}
              showInfo={true}
              showNavigationButtons={true}
            />
          </DataGrid>
        </Paper>
      </React.Fragment>
    </div>
  );
};

export default VehicleGroupTable;
