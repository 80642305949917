import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useSnackbar } from "notistack";

// components (global)
import { ButtonColor } from "components/Button";

// firebase
import { useFirestore } from "react-redux-firebase";

// material-ui
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon
} from "@material-ui/icons";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// stores
import { toggleEditServiceDialogOpen } from "stores/setting/SettingAction";

// styles
const useStyles = makeStyles(theme => ({
  root: {
    outline: "none",
    marginBottom: theme.spacing(2)
  },
  content: {
    paddingTop: 0
  }
}));

const ServiceItem = ({ title, userId, index, className, style, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const firestore = useFirestore();
  const moreRef = useRef(null);

  // state
  const [openMenu, setOpenMenu] = useState(false);
  const [service, setService] = useState(null);
  const [deleteService, setDeleteService] = useState(false);

  // selector
  const uid = useSelector(state => state.firebase.auth.uid);
  const profile = useSelector(state => state.firebase.profile);
  const user = useSelector(state => {
    return state.firestore.data.guardUsers
      ? state.firestore.data.guardUsers[userId]
      : null;
  });

  // effect
  useEffect(() => {
    if (user) {
      setService(user.services[index]);
    }
  }, [user, index]);

  // functions
  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
    setDeleteService(false);
  };

  const handleEdit = () => {
    handleMenuClose();
    dispatch(
      toggleEditServiceDialogOpen({ open: true, params: { userId, index } })
    );
  };

  const handleDeleteButtonClick = () => {
    setDeleteService(!deleteService);
  };

  const handleDelete = () => {
    if (deleteService) {
      handleMenuClose();

      // Remove item from services array
      user.services.splice(index, 1);

      firestore
        .update(
          { collection: "ac_users", doc: userId },
          {
            updated_at: new Date(),
            updated_by: {
              user_id: uid,
              name: profile.name
            },
            services: user.services
          }
        )
        .then(() => {
          snackbar.enqueueSnackbar("xSocket Service had been deleted", {
            variant: "success"
          });
        })
        .catch(e => {
          snackbar.enqueueSnackbar(e.message, {
            variant: "error",
            persist: false
          });
        });
    }
  };

  if (!service) return null;

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        action={
          <React.Fragment>
            <Tooltip title="More options">
              <IconButton onClick={handleMenuOpen} ref={moreRef} size="small">
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={moreRef.current}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              elevation={1}
              onClose={handleMenuClose}
              open={openMenu}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
            >
              <MenuItem onClick={handleEdit}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary="Edit xSocket Service" />
              </MenuItem>

              <MenuItem
                onClick={handleDeleteButtonClick}
                button={!deleteService}
              >
                {deleteService ? (
                  <React.Fragment>
                    <ButtonColor
                      buttonColor="red"
                      onClick={handleDelete}
                      size="small"
                      variant="contained"
                    >
                      Yes
                    </ButtonColor>
                    <Button
                      style={{ marginLeft: "10px" }}
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={handleDeleteButtonClick}
                    >
                      No
                    </Button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText primary="Delete xSocket Service" />
                  </React.Fragment>
                )}
              </MenuItem>
            </Menu>
          </React.Fragment>
        }
        subheader={service.type}
        subheaderTypographyProps={{ variant: "overline" }}
        title={title}
        titleTypographyProps={{ variant: "h5", gutterBottom: true }}
      />
      <CardContent className={classes.content}>
        <Grid container spacing={1}>
          <Grid item md={3}>
            Service ID
          </Grid>
          <Grid item md={9}>
            {service.service_id}
          </Grid>
          <Grid item md={3}>
            Port No.
          </Grid>
          <Grid item md={9}>
            {service.tcp_port}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ServiceItem;
