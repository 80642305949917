import React from "react";
import { colors, Chip } from "@material-ui/core";

function ChipColor({
  chipColor,
  colorIndex = 600,
  textColor = "white",
  ...rest
}) {
  return (
    <Chip
      {...rest}
      style={{
        backgroundColor: chipColor && colors[chipColor][colorIndex],
        color: textColor,
      }}
    />
  );
}

export default ChipColor;
