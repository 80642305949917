import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, filter } from "lodash";
import { useSnackbar } from "notistack";

// components (global)
import ButtonProgress from "components/ButtonProgress";

// firebase
import { useFirestore } from "react-redux-firebase";

// form
import { useForm } from "react-hook-form";

// helpers
import { deviceValidate } from "helpers/validation/deviceValidate";

// material-ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// stores
import { toggleEditServiceDialogClose } from "stores/setting/SettingAction";

// styles
const useStyles = makeStyles(theme => ({
  root: {}
}));

const EditServiceDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const firestore = useFirestore();
  const { errors, handleSubmit, register, setError } = useForm();

  // state
  const [submitting, setSubmitting] = useState(false);
  const [service, setService] = useState(null);

  // selector
  const { open, params } = useSelector(
    state => state.setting.edit_service_dialog
  );
  const uid = useSelector(state => state.firebase.auth.uid);
  const profile = useSelector(state => state.firebase.profile);
  const user = useSelector(state => {
    return state.firestore.data.guardUsers
      ? state.firestore.data.guardUsers[params.userId]
      : null;
  });

  // effect
  useEffect(() => {
    if (user) {
      setService(user.services[params.index]);
    }
  }, [user, params.index]);

  // functions
  const initialState = () => {
    setService(null);
  };

  const handleClose = () => {
    if (!submitting) {
      initialState();
      dispatch(toggleEditServiceDialogClose());
    }
  };

  const onSubmit = ({ serviceId, portNo, type }) => {
    if (!submitting) {
      setSubmitting(true);

      // Clone original object for validation
      let tempServices = cloneDeep(user.services);
      tempServices.splice(params.index, 1);

      const portExists = filter(tempServices, service => {
        return service.tcp_port === parseInt(portNo);
      });

      if (portExists.length > 0) {
        setSubmitting(false);
        setError("portNo", "exists");
        return;
      }

      user.services[params.index] = {
        service_id: serviceId,
        tcp_port: portNo,
        type
      };

      firestore
        .update(
          { collection: "ac_users", doc: params.userId },
          {
            updated_at: new Date(),
            updated_by: {
              user_id: uid,
              name: profile.name
            },
            services: user.services
          }
        )
        .then(() => {
          setSubmitting(false);
          handleClose();
          snackbar.enqueueSnackbar("xSocket Service had been updated", {
            variant: "success"
          });
        })
        .catch(e => {
          setSubmitting(false);
          handleClose();
          snackbar.enqueueSnackbar(e.message, {
            variant: "error",
            persist: false
          });
        });
    }
  };

  if (!user || !service) return null;

  return (
    <div>
      <form
        noValidate
        xs={12}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <Dialog
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={handleClose}
          open={open}
          fullWidth={true}
          maxWidth="md"
          scroll="paper"
        >
          <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>
            <div>
              <Typography
                className={classes.modalTitle}
                variant="h3"
                component="h2"
              >
                Edit xSocket Service
              </Typography>
            </div>
          </DialogTitle>

          <DialogContent dividers>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <Grid item md={4}>
                <TextField
                  fullWidth
                  disabled={true}
                  size="small"
                  id="serviceId"
                  name="serviceId"
                  label="Service ID *"
                  type="text"
                  variant="outlined"
                  autoComplete="off"
                  defaultValue={service.service_id}
                  inputRef={register(deviceValidate.serviceId.rules)}
                  helperText={
                    errors.serviceId &&
                    deviceValidate.serviceId.message[errors.serviceId.type]
                  }
                  error={!!errors.serviceId}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  fullWidth
                  size="small"
                  id="portNo"
                  name="portNo"
                  label="Port No. *"
                  type="number"
                  variant="outlined"
                  autoComplete="off"
                  defaultValue={service.tcp_port}
                  inputRef={register(deviceValidate.portNo.rules)}
                  helperText={
                    errors.portNo &&
                    deviceValidate.portNo.message[errors.portNo.type]
                  }
                  error={!!errors.portNo}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  fullWidth
                  size="small"
                  id="type"
                  name="type"
                  label="Type"
                  type="text"
                  variant="outlined"
                  autoComplete="off"
                  defaultValue={service.type}
                  inputRef={register}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <ButtonProgress
              type="submit"
              containName="Save"
              margin={false}
              loading={submitting}
              disabled={submitting}
              onClick={handleSubmit(onSubmit)}
            />
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default EditServiceDialog;
