import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { filter, forEach, map } from "lodash";

// components (global)
import { Page } from "components/Pages";

// components (local)
import {
  CreateAccessRoleDialog,
  EditAccessRoleDialog,
  InviteModeratorDialog,
} from "./components";
import SubContent from "./SubContent";

// material-ui
import {
  Edit as EditIcon,
  Group as GroupIcon,
  MoreHoriz as MoreHorizIcon,
  ToggleOn as ToggleOnIcon,
} from "@material-ui/icons";
import {
  Divider,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// stores
import { toggleEditAccessRoleDialogOpen } from "stores/accessRole/AccessRoleAction";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    height: "calc(100vh - 64px)",
  },
  listItem: {
    width: "max-content",
    minWidth: "-webkit-fill-available",
    fontSize: "14px",
    fontFamily: "Gotham Rounded Book",
    borderLeft: "10px white solid",
  },
  activeListItem: {
    fontFamily: "Gotham Rounded",
    backgroundColor: theme.palette.grey[100],
    borderLeft: `10px ${theme.palette.primary.main} solid`,
    "& p": {
      color: theme.palette.primary.main,
    },
  },
  iconInActive: {
    minWidth: "0px",
    marginRight: theme.spacing(1),
  },
  iconActive: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    minWidth: "0px",
    marginRight: theme.spacing(1),
    "& g": {
      stroke: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
    "& path": {
      stroke: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
    "& span": { color: theme.palette.primary.main },
  },
  contentInActive: {
    display: "none",
  },
  listSubheader: {
    "& span": {
      paddingLeft: theme.spacing(1),
    },
  },
  subContent: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

const Content = ({ roles, moderators }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // selector
  const editAccessRoleDialogOpen = useSelector(
    (state) => state.accessRole.edit_access_role_dialog.open
  );

  // state
  const [activePage, setActivePage] = useState({
    data: {
      id: "0",
    },
    type: "moderator",
    title: "List of Moderator",
  });
  const [menuState, setMenuState] = useState({
    anchorEl: null,
    id: null,
    name: null,
  });

  // functions
  const onModeratorItemClicked = () => {
    setActivePage({
      data: {
        id: "0",
      },
      type: "moderator",
      title: "List of Moderator",
    });
  };

  const onRoleItemClicked = (role) => {
    if (role) {
      setActivePage({
        data: role,
        type: "access_roles",
        title: role?.name,
      });
    }
  };

  const handleMenuClick = (event, id) => {
    setMenuState({
      anchorEl: event.currentTarget,
      id,
    });
  };

  const handleCloseMenu = () => {
    setMenuState({ anchorEl: null, id: null });
  };

  const handleEditClick = () => {
    dispatch(
      toggleEditAccessRoleDialogOpen({
        open: true,
        id: menuState.id,
      })
    );

    handleCloseMenu();
  };

  if (!roles) return null;

  let contents = [
    {
      id: "0",
      type: "moderator",
      label: "List of moderator",
      members: moderators,
    },
  ];

  if (roles) {
    forEach(roles, (role) => {
      contents = [
        ...contents,
        {
          id: role.id,
          type: "access_roles",
          label: role.name,
          members: role.members,
        },
      ];
    });
  }

  return (
    <Page className={classes.root} title="Access Card">
      <Grid container>
        {editAccessRoleDialogOpen && <EditAccessRoleDialog />}

        <Grid item md={3}>
          <Paper className={classes.paper} variant="outlined" square>
            <List>
              <ListItem
                className={clsx(
                  classes.listItem,
                  activePage.type === "moderator"
                    ? classes.activeListItem
                    : null
                )}
                onClick={onModeratorItemClicked}
                button
              >
                <ListItemIcon
                  className={clsx(
                    activePage.type === "moderator"
                      ? classes.iconActive
                      : classes.iconInActive
                  )}
                >
                  <GroupIcon />
                </ListItemIcon>

                <ListItemText
                  primary={
                    <Typography variant="body2">{`Moderators (${moderators?.length})`}</Typography>
                  }
                />
              </ListItem>
            </List>

            <Divider />

            <List
              subheader={
                <ListSubheader disableGutters className={classes.listSubheader}>
                  <Grid container>
                    <Grid item md={9}>
                      <Typography variant="caption">Access Role</Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      md={3}
                      alignItems="center"
                      justify="flex-end"
                    >
                      <CreateAccessRoleDialog />
                    </Grid>
                  </Grid>
                </ListSubheader>
              }
            >
              {map(roles, (role) => {
                return (
                  <ListItem
                    key={role.id}
                    className={clsx(
                      classes.listItem,
                      activePage.type === "access_roles" &&
                        role.id === activePage?.data?.id
                        ? classes.activeListItem
                        : ""
                    )}
                    onClick={() => onRoleItemClicked(role)}
                    button
                  >
                    <ListItemIcon
                      className={clsx(
                        activePage.type === "access_roles" &&
                          role.id === activePage?.data?.id
                          ? classes.iconActive
                          : classes.iconInActive
                      )}
                    >
                      <ToggleOnIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="body2">
                          {`${role.name} (${
                            role.members ? role.members.length : 0
                          })`}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="edit_dialog"
                        onClick={(event) => handleMenuClick(event, role?.id)}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>

            <Menu
              id="enitMenu"
              anchorEl={menuState.anchorEl}
              keepMounted
              open={Boolean(menuState.anchorEl)}
              onClose={handleCloseMenu}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem onClick={handleEditClick}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={`Edit Role`} />
              </MenuItem>
            </Menu>
          </Paper>
        </Grid>
        <Grid item md={9}>
          <div className={classes.subContent}>
            <Grid
              direction="row"
              alignItems="center"
              container
              justify="center"
            >
              <Grid item md={10}>
                <Typography component="h2" variant="overline" gutterBottom>
                  {activePage.type.replace("_", " ")}
                </Typography>
                <Typography component="h1" variant="h3">
                  {activePage.title}
                </Typography>
              </Grid>
              <Grid
                item
                container
                md={2}
                alignItems="center"
                justify="flex-end"
                spacing={2}
              >
                <Hidden smUp>
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    justify="flex-end"
                  >
                    <InviteModeratorDialog />
                  </Grid>
                </Hidden>

                <Hidden xsDown>
                  <Grid
                    item
                    container
                    md={12}
                    xs={12}
                    alignItems="center"
                    justify="flex-end"
                  >
                    <InviteModeratorDialog />
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </div>

          {contents.map((content) => {
            return (
              <SubContent
                key={content.id}
                className={
                  activePage.data?.type !== content?.type &&
                  activePage.data?.id !== content?.id
                    ? classes.contentInActive
                    : null
                }
                header={content.type.replace("_", " ")}
                subHeader={content.label}
                members={content.members}
              />
            );
          })}
        </Grid>
      </Grid>
    </Page>
  );
};

export default Content;
