import React, { useMemo, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { filter, includes, orderBy, map } from "lodash";
import moment from "moment";
import { toggleEditResidentDialogOpen } from "stores/card/CardAction";
import { closeDrawer } from "stores/main/MainActions";
import { statusMap } from "utility/constants";
import { Workbook } from "exceljs";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";
// components (global)
import {
  CustomerDrawer,
  Paginate,
  BottomDrawerButton,
  ChipColor,
  TrueFalseWithDot,
} from "components";
import BatchDeactivateDialog from "./components/BatchDeactivateDialog";
import BatchDeleteDialog from "./components/BatchDeleteDialog";
import BatchSuspendDialog from "./components/BatchSuspendDialog";
import BatchUnSuspendDialog from "./components/BatchUnSuspendDialog";
import BatchActivateDialog from "./components/BatchActivateDialog";

// components (local）
import {
  AddResident,
  EditResidentDialog,
  FilterDrawer,
  ResidentCardDetails,
  ResidentCard,
  ResidentSkeleton,
} from "./components";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
  bindMenu,
} from "material-ui-popup-state/hooks";

// firebase
import { isLoaded } from "react-redux-firebase";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// material-ui
import {
  FilterList as FilterListIcon,
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
} from "@material-ui/icons";
import {
  Button,
  Grid,
  Hidden,
  Chip,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Fab,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Sorting,
  FilterRow,
  Selection,
  Toolbar,
  Item,
  Paging,
  Scrolling,
  Pager,
} from "devextreme-react/data-grid";
import saveAs from "file-saver";
// stores
import { openDrawer } from "stores/main/MainActions";
import BatchReaddDialog from "./components/BatchReaddDialog";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  link: {
    marginRight: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  search: { width: "100%", marginBottom: theme.spacing(2) },
  paginate: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  title: {
    position: "relative",
    "&:after": {
      position: "absolute",
      bottom: -8,
      left: 0,
      content: '" "',
      height: 3,
      width: 48,
      backgroundColor: theme.palette.primary.main,
    },
  },
  green: { color: "#038c00" },
  grey: { color: "#979797" },
  red: { color: "#ad2424" },
}));

const Resident = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const exporterRef = useRef(null);
  const gridRef = useRef(null);
  // variable
  const perPage = 12;
  const [showExportButtons, setShowExportButtons] = useState(false);
  function onSelectionChanged({ selectedRowsData }) {
    setShowExportButtons(selectedRowsData.length > 0);
    // dynamically set height
  }
  const [showBatchEditDialog, setShowBatchEditDialog] = useState(false);
  const [showBatchEnableDialog, setShowBatchEnableDialog] = useState(false);
  const [showBatchSuspendDialog, setShowBatchSuspendDialog] = useState(false);
  const [showBatchUnSuspendDialog, setShowBatchUnSuspendDialog] =
    useState(false);

  const [showBatchDeleteDialog, setShowBatchDeleteDialog] = useState(false);
  const [showBatchReaddDialog, setShowBatchReaddDialog] = useState(false);
  const [deleteCard, setDeleteCard] = useState(false);
  const [selectedCardIds, setSelectedCardIds] = useState([]);
  const handleCloseDialog = useCallback(() => {
    setShowBatchEditDialog(false);
    setShowBatchDeleteDialog(false);
    setShowBatchSuspendDialog(false);
    setShowBatchUnSuspendDialog(false);
    setShowBatchEnableDialog(false);
    setSelectedCardIds([]);
    setShowBatchReaddDialog(false);

    exporterRef.current.instance.deselectAll();
  }, []);
  // selector
  const cardSelector = useSelector(
    (state) => state.firestore.data.resident_cards
  );
  const editDialog = useSelector((state) => state.card.edit_resident_dialog);

  // state
  const [search, setSearch] = useState({
    card_type: "",
    card_status: "",
    identity_number: "",
    profile_code: "",
    profile_name: "",
    vehicle_no: "",
    unit_id: "",
    is_suspend: "",
  });
  const [offset, setOffset] = useState(0);
  const [toggle, setToggle] = useState("module");
  const handleChange = (event, nextView) => {
    setToggle(nextView);
    setSearch({
      ...search,
      identity_number: "",
    });
  };

  // memo
  const cardsSearch = useMemo(() => {
    setOffset(0);

    // Mapped key into array
    let cardMapped = [];
    if (cardSelector) {
      cardMapped = Object.keys(cardSelector).map((key) => {
        return { ...cardSelector[key], id: key };
      });
    }

    return orderBy(
      filter(cardMapped, (card) => {
        const unitId = card.unit
          ? card.unit.unit_id.toLowerCase()
          : "no_unit_assign";

        return (
          includes(
            card.card_type?.toString().toLowerCase(),
            search.card_type?.toLowerCase()
          ) &&
          includes(
            card.identity_number?.toString().toLowerCase(),
            search.identity_number.toString().toLowerCase()
          ) &&
          includes(
            unitId,
            search.unit_id ? search.unit_id.toLowerCase() : ""
          ) &&
          includes(
            card.blocked_settings.enable.toString().toLowerCase(),
            search.is_suspend?.toString().toLowerCase()
          ) &&
          includes(
            card.status.toLowerCase(),
            search.card_status?.toLowerCase()
          ) &&
          includes(
            card.profile_code.toLowerCase(),
            search.profile_code.toLowerCase()
          ) &&
          includes(
            card.profile_name.toLowerCase(),
            search.profile_name.toLowerCase()
          ) &&
          includes(
            card.vehicle_info?.number
              ? card.vehicle_info?.number.toLowerCase()
              : "",
            search.vehicle_no.toLowerCase()
          )
        );
      }),
      ["status", "identity_number"]
    );
  }, [cardSelector, search]);

  // const listCard = useMemo(() => {
  //   const flatResult = cardSelector.map((card) => ({
  //     ...card,

  //   }))

  //   return orderBy(
  //     flatResult,

  //   )
  // }, [cardSelector])

  const pageCount = useMemo(
    () => Math.ceil(cardsSearch.length / perPage),
    [cardsSearch]
  );

  const cards = useMemo(
    () => cardsSearch.slice(offset, offset + perPage),
    [cardsSearch, offset]
  );

  const cardCsvData = useMemo(
    () =>
      cardsSearch.map((card) => {
        const block_date = card.blocked_settings?.enable_date;

        return {
          block_name: card.unit?.block_name ?? "",
          unit_name: card.unit?.unit_name ?? "",
          card_type: card.card_type,
          card_no: card.identity_number,
          staff_no: card.profile_code ?? "",
          sticker_no: card.sticker_serial_no ?? "",
          profile_name: card.profile_name ?? "",
          phone_number: card.contact_number ?? "",
          car_plate: card.vehicle_info?.number ?? "",
          parking_lot_no: card.parking_lot_no ?? "",
          anti_passback: !!card.anti_passback ? "yes" : "no",
          lift_card: !!card.is_lift ? "yes" : "no",
          status: card.status,
          created_by: card.created_by.name ?? "",
          block_date:
            card.blocked_settings?.enable === true && block_date !== undefined
              ? moment.unix(block_date.seconds).format("DD/MM/YYYY h:mm a")
              : "",
        };
      }),
    [cardsSearch]
  );

  const cardListData = useMemo(
    () =>
      cardsSearch.map((card) => {
        const block_date = card.blocked_settings?.enable_date;

        return {
          id: card.id,

          block_name: card.unit?.block_name ?? "",
          unit_name: card.unit?.unit_name ?? "",
          card_type: card.card_type,
          card_no: card.identity_number,
          staff_no: card.profile_code ?? "",
          sticker_no: card.sticker_serial_no ?? "",
          profile_name: card.profile_name ?? "",
          phone_number: card.contact_number ?? "-",
          car_plate: card.vehicle_info?.number ?? "-",
          parking_lot_no: card.parking_lot_no ?? "-",
          lift_card: !!card.is_lift ? "Yes" : "No",
          anti_passback: !!card.anti_passback ? "Yes" : "No",
          status: card.status,
          created_by: card.created_by.name ?? "",
          created_at: moment.unix(card.created_at.seconds).format("DD/MM/YYYY"),
          block_enable: card.blocked_settings?.enable ? "Yes" : "No",
          block_date:
            card.blocked_settings?.enable === true && block_date !== undefined
              ? moment.unix(block_date.seconds).format("DD/MM/YYYY")
              : "-",
          block_daycount:
            card.blocked_settings?.enable === true && block_date !== undefined
              ? card.blocked_settings?.count
              : "-",
          updated_at: moment
            .unix(card.updated_at?.seconds ?? card.created_at.seconds)
            .format("DD/MM/YYYY"),
        };
      }),
    [cardsSearch]
  );

  // functions
  const handleFilter = () => {
    dispatch(openDrawer({ toggle: "resident_card_filter" }));
  };

  const handleSearchChange = (event) => {
    setSearch({
      ...search,
      identity_number: event.target.value,
    });
  };

  const handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * perPage);
    setOffset(offset);
  };

  const handleFilterSubmit = (value) => {
    console.log(value);
    setSearch({
      ...search,
      card_type: value.cardType ? value.cardType.id : "",
      card_status: value.cardStatus
        ? value.cardStatus.id !== "suspend"
          ? value.cardStatus.id
          : ""
        : "",
      identity_number: value.identityNumber,
      profile_code: value.profileCode,
      profile_name: value.profileName,
      vehicle_no: value.vehicleNo,
      unit_id: value.unit ? value.unit.id : "",
      is_suspend: value.cardStatus ? value.cardStatus?.id === "suspend" : "",
    });
  };

  const downloadCsvButton = (
    <CSVLink
      style={{ textDecoration: "none" }}
      filename={`resident-access-card${moment().format("YYYY-MM-DD")}.csv`}
      data={cardCsvData}
      className={classes.link}
    >
      <Button underline="none" color="primary" variant="contained" mr={2}>
        Export CSV
      </Button>
    </CSVLink>
  );

  function exportToExcelGrid() {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("");
    const dataGrid = exporterRef.current.instance;

    exportDataGridToExcel({
      worksheet: worksheet,
      component: dataGrid,
      selectedRowsOnly: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        const now = moment().format("YYYYMMDD");
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `ResidentCards_${now}.xlsx`
        );
      });
    });
  }

  function batchDeactivate() {
    const dataGrid = exporterRef.current.instance;
    const selectedItems = dataGrid.getSelectedRowKeys();
    setShowBatchEditDialog(true);
    setSelectedCardIds(map(selectedItems, (item) => item.id));
    console.log(selectedItems);
  }

  function batchSuspend() {
    const dataGrid = exporterRef.current.instance;
    const selectedItems = dataGrid.getSelectedRowKeys();
    setShowBatchSuspendDialog(true);
    setSelectedCardIds(map(selectedItems, (item) => item.id));
    console.log(selectedItems);
  }

  function batchActivate() {
    const dataGrid = exporterRef.current.instance;
    const selectedItems = dataGrid.getSelectedRowKeys();
    setShowBatchEnableDialog(true);
    setSelectedCardIds(map(selectedItems, (item) => item.id));
    console.log(selectedItems);
  }

  function batchUnSuspend() {
    const dataGrid = exporterRef.current.instance;
    const selectedItems = dataGrid.getSelectedRowKeys();
    setShowBatchUnSuspendDialog(true);
    setSelectedCardIds(map(selectedItems, (item) => item.id));
    console.log(selectedItems);
  }
  function batchDelete() {
    const dataGrid = exporterRef.current.instance;
    const selectedItems = dataGrid.getSelectedRowKeys();
    setShowBatchDeleteDialog(true);
    setSelectedCardIds(map(selectedItems, (item) => item.id));
    console.log(selectedItems);
  }

  function batchReadd() {
    const dataGrid = exporterRef.current.instance;
    const selectedItems = dataGrid.getSelectedRowKeys();
    setShowBatchReaddDialog(true);
    setSelectedCardIds(map(selectedItems, (item) => item.id));
    console.log(selectedItems);
  }

  const statusCell = (cellData) => (
    <ChipColor
      chipColor={
        cellData.value === "active"
          ? "green"
          : cellData.value === "deleted"
          ? "grey"
          : cellData.value === "inactive" ||
            cellData.data.blocked_settings.enable
          ? "red"
          : "green"
      }
      colorIndex={800}
      label={cellData.value}
      size="small"
    />
  );

  const suspendedCell = (cellData) => (
    <Chip
      size="small"
      variant="outlined"
      label={statusMap[cellData.data.block_enable.toLowerCase() ?? "no"].label}
      style={{
        color:
          statusMap[cellData.data.block_enable.toLowerCase() ?? "no"].color,
        borderColor:
          statusMap[cellData.data.block_enable.toLowerCase() ?? "no"].color,
        backgroundColor: "#f7f7f7",
      }}
    />
  );

  return (
    <div className={classes.root}>
      {editDialog.open && <EditResidentDialog />}
      <Grid direction="row" alignItems="center" container justify="center">
        <Grid item md={8}>
          {/* <Typography component="h2" gutterBottom variant="overline">
            RESIDENT CARD
          </Typography>
          <Typography component="h1" variant="h2">
            Manage Resident Access Card
          </Typography> */}
          <ToggleButtonGroup
            orientation="horizontal"
            exclusive
            value={toggle}
            xs={12}
            onChange={handleChange}
          >
            <ToggleButton value="module" aria-label="list">
              <ViewModuleIcon />
            </ToggleButton>
            <ToggleButton value="list" aria-label="list">
              <ViewListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid
          item
          container
          md={4}
          alignItems="center"
          justify="flex-end"
          spacing={2}
        >
          <Hidden smUp>
            <Grid
              item
              container
              xs={10}
              md={2}
              alignItems="center"
              justify="flex-end"
            ></Grid>
            <Grid
              item
              container
              md={2}
              xs={8}
              alignItems="center"
              justify="flex-end"
            ></Grid>
            <Grid item container xs={8} alignItems="center" justify="flex-end">
              {!!cardCsvData && cardCsvData.length > 0 && downloadCsvButton}
              <AddResident />
            </Grid>
          </Hidden>

          <Hidden xsDown>
            <Grid
              item
              container
              md={12}
              xs={8}
              alignItems="center"
              justify="flex-end"
            >
              <Grid
                item
                container
                md={2}
                xs={8}
                alignItems="center"
                justify="flex-end"
              ></Grid>
              {!!cardCsvData && cardCsvData.length > 0 && downloadCsvButton}
              <AddResident />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>

      {toggle === "module" ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.header}
        >
          <Grid item md={11} xs={12}>
            <Paper>
              <TextField
                onChange={handleSearchChange}
                size="small"
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div className={classes.searchIcon}>
                        <SearchIcon />
                      </div>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search Card Number…"
              />
            </Paper>
          </Grid>
          <Grid item container md={1} justify="center" alignItems="center">
            <Tooltip title="More Filter">
              <IconButton onClick={handleFilter}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.header}
        >
          <Grid item md={11} xs={12}></Grid>

          <Grid
            item
            container
            md={1}
            justify="center"
            alignItems="center"
          ></Grid>
        </Grid>
      )}
      <Grid container>
        <Grid item md={12}>
          <Typography className={classes.title} variant="h5">
            {`Showing ${cardsSearch.length} cards`}
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.content}>
        <CustomerDrawer uniqName="view_resident_card" size={2}>
          <ResidentCardDetails />
        </CustomerDrawer>

        <CustomerDrawer uniqName="resident_card_filter">
          <FilterDrawer search={search} onSubmit={handleFilterSubmit} />
        </CustomerDrawer>

        {!isLoaded(cardSelector) ? (
          <ResidentSkeleton />
        ) : toggle === "module" ? (
          cards.length > 0 && (
            <React.Fragment>
              <Grid container>
                {cards.map((card) => (
                  <ResidentCard key={card.id} card={card} />
                ))}
              </Grid>

              <div className={classes.paginate}>
                <Paginate
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                />
              </div>
            </React.Fragment>
          )
        ) : (
          <React.Fragment>
            <Paper>
              <DataGrid
                allowColumnReordering={true}
                columnAutoWidth={true}
                dataSource={cardListData}
                // onRowDblClick={onRowDblClick}
                ref={exporterRef}
                repaintChangesOnly={true}
                onSelectionChanged={onSelectionChanged}
                rowMultiSelectWithClick={true}
                showBorders={false}
                showColumnLines={false}
              >
                <ColumnFixing enabled={true} />
                <FilterRow visible={true} />
                <Sorting mode="multiple" />
                <Selection mode="multiple" showCheckBoxesMode="onClick" />
                <Column caption="Block Name" dataField="block_name" />
                <Column caption="Unit Name" dataField="unit_name" />
                <Column caption="Card Id" dataField="staff_no" />
                <Column caption="Card Name" dataField="profile_name" />
                <Column caption="Card No." dataField="card_no" />

                <Column
                  caption="Anti Passback"
                  dataField="anti_passback"
                  cellRender={(cellData) =>
                    cellData.data.anti_passback === "Yes" ? (
                      <TrueFalseWithDot dotBoolean={true} text="Yes" />
                    ) : (
                      <TrueFalseWithDot dotBoolean={false} text="No" />
                    )
                  }
                />
                <Column
                  caption="Status"
                  dataField="status"
                  cellRender={statusCell}
                />
                <Column
                  caption="Lift Card"
                  dataField="lift_card"
                  cellRender={(cellData) =>
                    cellData.data.lift_card === "Yes" ? (
                      <TrueFalseWithDot dotBoolean={true} text="Yes" />
                    ) : (
                      <TrueFalseWithDot dotBoolean={false} text="No" />
                    )
                  }
                />
                <Column
                  caption="Suspended"
                  dataField="block_enable"
                  cellRender={suspendedCell}
                />
                <Column caption="Vehicle No" dataField="car_plate" />
                <Column caption="Phone No" dataField="phone_number" />
                <Column caption="Parking Lot No" dataField="parking_lot_no" />
                <Column caption="Suspended Date" dataField="block_date" />
                <Column
                  caption="Suspended Day Count Left"
                  dataField="block_daycount"
                />
                <Column caption="Created At" dataField="created_at" />
                <Column caption="Last Updated At" dataField="updated_at" />
                <Column
                  fixed={true}
                  fixedPosition="right"
                  allowGrouping={false}
                  allowExporting={false}
                  allowFiltering={false}
                  allowSorting={false}
                  allowHiding={false}
                  allowColumnReordering={false}
                  allowFixing={false}
                  width={50}
                  cellRender={(cellData) => (
                    <MenuButton cardData={cellData.data} />
                  )}
                />

                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  displayMode="full"
                  showPageSizeSelector={false}
                  showInfo={true}
                  showNavigationButtons={true}
                />
              </DataGrid>
            </Paper>
            <BottomDrawerButton
              open={showExportButtons}
              buttons={[
                <Fab
                  variant="extended"
                  color="primary"
                  siz="small"
                  onClick={batchReadd}
                >
                  Re-Add
                </Fab>,
                <Fab
                  variant="extended"
                  color="primary"
                  siz="small"
                  onClick={batchActivate}
                >
                  Enable
                </Fab>,

                <Fab
                  variant="extended"
                  color="primary"
                  siz="small"
                  onClick={batchDeactivate}
                >
                  Disable
                </Fab>,
                <Fab
                  variant="extended"
                  color="primary"
                  siz="small"
                  onClick={batchSuspend}
                >
                  Suspend
                </Fab>,
                <Fab
                  variant="extended"
                  color="primary"
                  siz="small"
                  onClick={batchUnSuspend}
                >
                  Un-Suspend
                </Fab>,
                <Fab
                  variant="extended"
                  color="primary"
                  siz="small"
                  onClick={batchDelete}
                >
                  Delete
                </Fab>,
                <Fab
                  variant="extended"
                  color="primary"
                  siz="small"
                  onClick={exportToExcelGrid}
                >
                  Export to Excel
                </Fab>,
              ]}
            />
            {showBatchReaddDialog && selectedCardIds.length > 0 && (
              <BatchReaddDialog
                cardIdList={selectedCardIds}
                handleClose={handleCloseDialog}
              />
            )}
            {showBatchEnableDialog && selectedCardIds.length > 0 && (
              <BatchActivateDialog
                cardIdList={selectedCardIds}
                handleClose={handleCloseDialog}
              />
            )}
            {showBatchEditDialog && selectedCardIds.length > 0 && (
              <BatchDeactivateDialog
                cardIdList={selectedCardIds}
                handleClose={handleCloseDialog}
              />
            )}

            {showBatchSuspendDialog && selectedCardIds.length > 0 && (
              <BatchSuspendDialog
                cardIdList={selectedCardIds}
                handleClose={handleCloseDialog}
              />
            )}
            {showBatchUnSuspendDialog && selectedCardIds.length > 0 && (
              <BatchUnSuspendDialog
                cardIdList={selectedCardIds}
                handleClose={handleCloseDialog}
              />
            )}

            {showBatchDeleteDialog && selectedCardIds.length > 0 && (
              <BatchDeleteDialog
                cardIdList={selectedCardIds}
                handleClose={handleCloseDialog}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const MenuButton = ({ cardData }) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "popupMenu",
  });
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(closeDrawer());
  };

  const handleCardDetails = () => {
    popupState.close();
    dispatch(
      openDrawer({ toggle: "view_resident_card", parameterId: cardData.id })
    );
  };
  const handleEditCard = () => {
    dispatch(toggleEditResidentDialogOpen({ open: true, id: cardData.id }));
    onClose();
  };

  return (
    <>
      <IconButton size="small" {...bindTrigger(popupState)}>
        <MoreHorizIcon fontSize="small" color="primary" />
      </IconButton>
      <Menu {...bindMenu(popupState)}>
        <MenuItem onClick={handleCardDetails}>
          <ListItemIcon>
            <VisibilityIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="View Details" />
        </MenuItem>
        <MenuItem onClick={handleEditCard}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit Card" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default Resident;
