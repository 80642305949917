import * as actionTypes from "./ActionTypes";

const initialState = {
  properties: null,
  selectedProperty: localStorage.getItem("selected-property")
    ? localStorage.getItem("selected-property")
    : null,
  roles: null,
  sideBar: true,
  selectPropertyToggle: false,
  isPropertyLoading: false,
  selectedVendor: localStorage.getItem("selected-vendor"),
  selectedApnrVendor: localStorage.getItem("selected-apnr-vendor"),
  toggleDrawer: { toggle: "", parameterId: "" },
};

function main(state = initialState, action) {
  switch (action.type) {
    case actionTypes.OPEN_SIDEBAR:
      return { ...state, sideBar: true };
    case actionTypes.CLOSE_SIDEBAR:
      return { ...state, sideBar: false };
    case actionTypes.OPEN_DRAWER:
      return {
        ...state,
        toggleDrawer: {
          ...state.toggleDrawer,
          ...action.payload,
        },
      };
    case actionTypes.CLOSE_DRAWER:
      return { ...state, toggleDrawer: { toggle: "", parameterId: "" } };

    case actionTypes.TOGGLE_SELECT_PROPERTY:
      return { ...state, selectPropertyToggle: !state.selectPropertyToggle };

    case actionTypes.SET_VENDOR_ID:
      return {
        ...state,
        isPropertyLoading: false,
        selectedVendor: action.payload.id,
      };

    case actionTypes.SET_APNR_VENDOR_ID:
      return {
        ...state,
        isPropertyLoading: false,
        selectedApnrVendor: action.payload.id,
      };

    case actionTypes.SET_PROPERTIES:
      return {
        ...state,
        properties: action.payload,
        selectedProperty: localStorage.getItem("selected-property")
          ? localStorage.getItem("selected-property")
          : action.payload[0].id,
      };

    case actionTypes.SET_PROPERTY:
      return {
        ...state,
        isPropertyLoading: true,
        selectedProperty: action.payload,
      };

    case actionTypes.SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };

    default:
      return state;
  }
}

export default main;
