import React from "react";
import { useSelector } from "react-redux";

// material-ui
import { Card, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    padding: theme.spacing(1),
  },
}));

const AccessLevel = ({ accessLevel }) => {
  const classes = useStyles();

  // selector
  const accessLevelSelector = useSelector((state) => {
    return state.firestore.data.accessLevels
      ? state.firestore.data.accessLevels[accessLevel?.id]
      : null;
  });

  return (
    <Grid
      item
      container
      direction="row"
      spacing={2}
      md={4}
      alignItems="flex-start"
      justify="space-between"
    >
      <Grid item container spacing={1} md={12}>
        <Grid item md={12}>
          <Typography variant="h4" component="h1">
            Access Level
          </Typography>
        </Grid>

        <Grid item md={12}>
          <Card className={classes.card}>
            <Grid container spacing={1}>
              {accessLevelSelector ? (
                <Grid item md={12}>
                  <Typography variant="caption" component="h1">
                    {`- ${
                      accessLevelSelector.display_name
                        ? accessLevelSelector.display_name
                        : accessLevelSelector.name
                    }`}
                  </Typography>
                </Grid>
              ) : (
                <Grid item md={12}>
                  <Typography variant="caption" component="h1">
                    No Access Level
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccessLevel;
