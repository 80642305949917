import React from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import {
  LogoAndLabel,
  NotifcationDetails,
  ProfileMenu,
  SearchBar,
} from "./components";
import { CustomerDrawer } from "components";
import { openDrawer, openSideBar } from "stores/main/MainActions";

//material-icons
import {
  Menu as MenuIcon,
  NotificationsNone as NotificationsNoneIcon,
} from "@material-ui/icons";

// material-ui
import { AppBar, Box, IconButton, Hidden, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
}));

export default function Topbar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedPropertyId = useSelector(
    (state) => state.main.selectedProperty
  );

  return (
    <AppBar className={classes.root}>
      <Toolbar disableGutters={true}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={() => dispatch(openSideBar())}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <Box m={1} />
          <LogoAndLabel />
        </Hidden>
        <Box
          style={{
            position: "relative",
            borderRadius: "theme.shape.borderRadius",
            marginRight: 2,
            marginLeft: 0,
            width: "100%",
            display: "inline-flex",
            height: "44px",
          }}
        ></Box>
        <ProfileMenu />
        <Hidden smDown>
          <IconButton
            color="inherit"
            // onClick={() => {
            //   dispatch(
            //     openDrawer({
            //       toggle: "viewNoti",
            //       parameterId: selectedPropertyId,
            //     })
            //   );
            // }}
          >
            <NotificationsNoneIcon color="disabled" />
            <CustomerDrawer uniqName="viewNoti" size={2}>
              <NotifcationDetails />
            </CustomerDrawer>
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}
