import React from "react";
import { useSelector } from "react-redux";

// components (local)
import {
  AddVendorDetails,
  BlockSettings,
  DoorSettings,
  EditVendorDetails,
} from "./components";

// material-ui
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    marginBottom: theme.spacing(1),
  },
}));

const General = () => {
  const classes = useStyles();

  // selector
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const vendor = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor]
      : null;
  });

  return (
    <div>
      <Grid className={classes.root} container spacing={3}>
        {!vendor && (
          <Grid item md={12}>
            <AddVendorDetails />
          </Grid>
        )}

        {vendor && !vendor.access_card_settings && (
          <Grid item md={12}>
            <AddVendorDetails vendor={vendor} vendorId={selectedVendor} />
          </Grid>
        )}

        {vendor && vendor.access_card_settings && (
          <React.Fragment>
            <Grid item md={12}>
              <EditVendorDetails vendor={vendor} vendorId={selectedVendor} />
            </Grid>

            <Grid item lg={12} md={12} xl={3} xs={12}>
              <BlockSettings vendor={vendor} vendorId={selectedVendor} />
            </Grid>

            <Grid item lg={12} md={12} xl={3} xs={12}>
              <DoorSettings vendor={vendor} vendorId={selectedVendor} />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </div>
  );
};

export default General;
