import React, { useState } from "react";
import { useDispatch } from "react-redux";

// component (glbal)
import Label from "components/Label";

// material-ui
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  colors,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

// stores
import { openDrawer } from "stores/main/MainActions";
import { toggleEditDoorAccessDialogOpen } from "stores/setting/SettingAction";

// styles
const useStyles = makeStyles((theme) => ({
  mt1: { marginTop: theme.spacing(1) },
}));

const DoorAccessCard = ({ blockAccess, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // state
  const [anchorEl, setAnchorEl] = useState(null);

  // functions
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleViewAccessDetails = () => {
    dispatch(openDrawer({ toggle: "view_door_access", parameterId: id }));
    handleCloseMenu();
  };

  const handleEditAccess = () => {
    dispatch(toggleEditDoorAccessDialogOpen({ open: true, id }));
    handleCloseMenu();
  };

  return (
    <Grid item md={3} xs={12} key={id}>
      <Menu
        id="enitMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {!!blockAccess?.resident?.access_groups?.length && (
          <MenuItem onClick={handleViewAccessDetails}>
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="View Details" />
          </MenuItem>
        )}

        <MenuItem onClick={handleEditAccess}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit Door Access" />
        </MenuItem>
      </Menu>

      <Card>
        <CardHeader
          title={blockAccess.block_name}
          action={
            <IconButton
              aria-label="settings"
              size="small"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <Grid container>
            <Grid item container md={12} justify="center" alignItems="center">
              <Typography component="h2" gutterBottom variant="overline">
                Total Door Access
              </Typography>
            </Grid>

            <Grid item container md={12}>
              <Grid
                item
                container
                md={6}
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography gutterBottom variant="overline">
                    Resident
                  </Typography>
                </Grid>
                <Grid>
                  <Label color={colors.green[600]}>
                    {!!blockAccess?.resident?.access_groups?.length
                      ? blockAccess?.resident?.access_groups?.length
                      : blockAccess?.resident?.access_groups?.name ?? ""}
                  </Label>
                </Grid>
                {!!!blockAccess?.resident?.access_groups?.length && (
                  <Grid className={classes.mt1}>
                    <Label color={colors.green[600]}>
                      Id:
                      {blockAccess?.resident?.access_groups?.door_group_id ??
                        ""}
                    </Label>
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                container
                md={6}
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography gutterBottom variant="overline">
                    Visitor
                  </Typography>
                </Grid>
                <Grid>
                  <Label color={colors.green[600]}>
                    {!!blockAccess?.visitor?.access_groups?.length
                      ? blockAccess?.visitor?.access_groups?.length
                      : blockAccess?.visitor?.access_groups?.name ?? ""}
                  </Label>
                </Grid>
                {!!!blockAccess?.visitor?.access_groups?.length && (
                  <Grid className={classes.mt1}>
                    <Label color={colors.green[600]}>
                      Id:
                      {blockAccess?.visitor?.access_groups?.door_group_id ?? ""}
                    </Label>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default DoorAccessCard;
