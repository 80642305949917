import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "http-axios";

// components (global)
import ButtonProgress from "components/ButtonProgress";

// firebase
import { useFirebase } from "react-redux-firebase";

// form
import { useForm } from "react-hook-form";

// helpers
import { deviceValidate } from "helpers/validation/deviceValidate";

// material-ui
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// stores
import { toggleEditDeviceDialogClose } from "stores/setting/SettingAction";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
}));

const EditDeviceDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const { errors, handleSubmit, register } = useForm();

  // selector
  const { open, id } = useSelector((state) => state.setting.edit_device_dialog);
  const uid = useSelector((state) => state.firebase.auth.uid);
  const selectedPropertyId = useSelector(
    (state) => state.main.selectedProperty
  );
  const vendors = useSelector((state) => state.firestore.ordered.vendors);
  const user = useSelector((state) => {
    return state.firestore.data.guardUsers
      ? state.firestore.data.guardUsers[id]
      : null;
  });

  // state
  const [submitting, setSubmitting] = useState(false);
  const [master, setMaster] = useState(false);

  // effect
  useEffect(() => {
    if (user) {
      setMaster(user.is_master);
    }
  }, [user]);

  // functions
  const handleClose = () => {
    if (!submitting) dispatch(toggleEditDeviceDialogClose());
  };

  const handleOnChange = (event) => {
    setMaster(event.target.checked);
  };

  const onSubmit = ({ email, password, name, master }) => {
    if (!submitting) {
      setSubmitting(true);

      firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          };
          console.log(token);

          const data = {
            uid: uid,
            email: email,
            password: password,
            name: name,
            master: master,
            property_id: selectedPropertyId,
            vendor_id: vendors[0].id,
          };

          axios
            .post(`jagaCardCreateDeviceAccount`, data, {
              headers: headers,
            })
            .then((res) => {
              if (res.data.result === "success") {
                setSubmitting(false);
                handleClose();
              }
            })
            .catch((e) => {
              console.log(e.message);
              setSubmitting(false);
            });
        });
    }
  };

  if (!user) return null;

  return (
    <div>
      <form
        noValidate
        xs={12}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <Dialog
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={handleClose}
          open={open}
          fullWidth={true}
          maxWidth="md"
          scroll="paper"
        >
          <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>
            <div>
              <Typography
                className={classes.modalTitle}
                variant="h3"
                component="h2"
              >
                Edit Device
              </Typography>
            </div>
          </DialogTitle>

          <DialogContent dividers>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <Grid item md={6}>
                <TextField
                  fullWidth
                  disabled={true}
                  size="small"
                  id="email"
                  name="email"
                  label="Email *"
                  type="text"
                  variant="outlined"
                  autoComplete="off"
                  defaultValue={user.email}
                  inputRef={register(deviceValidate.email.rules)}
                  helperText={
                    errors.email &&
                    deviceValidate.email.message[errors.email.type]
                  }
                  error={!!errors.email}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  size="small"
                  id="name"
                  name="name"
                  label="Name *"
                  type="text"
                  variant="outlined"
                  autoComplete="off"
                  defaultValue={user.name}
                  inputRef={register(deviceValidate.name.rules)}
                  helperText={
                    errors.name && deviceValidate.name.message[errors.name.type]
                  }
                  error={!!errors.name}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  size="small"
                  id="password"
                  name="password"
                  label="Password *"
                  type="password"
                  variant="outlined"
                  autoComplete="off"
                  inputRef={register(deviceValidate.password.rules)}
                  helperText={
                    errors.password &&
                    deviceValidate.password.message[errors.password.type]
                  }
                  error={!!errors.password}
                />
              </Grid>
              <Grid item md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="master"
                      checked={master}
                      onChange={handleOnChange}
                      color="primary"
                      inputRef={register}
                    />
                  }
                  label="Master Device"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <ButtonProgress
              type="submit"
              containName="Save"
              margin={false}
              loading={submitting}
              disabled={submitting}
              onClick={handleSubmit(onSubmit)}
            />
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default EditDeviceDialog;
