import React from "react";

// components (local)
import { AccessLevelTable } from "./components";

// material-ui
import { Grid, Typography } from "@material-ui/core";

const AccessLevelTab = () => {
  return (
    <div>
      <Grid container direction="row" alignItems="flex-start" spacing={2}>
        <Grid item container justify="space-between">
          <Grid item>
            <Typography component="h2" gutterBottom variant="overline">
              Access Level
            </Typography>
            <Typography component="h1" variant="h3">
              Manage all access level
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <AccessLevelTable />
    </div>
  );
};

export default AccessLevelTab;
