import React from "react";
import { Slide, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//styles
const useStyles = makeStyles((theme) => ({
  fab: {
    zIndex: 999,
    display: "inline-block",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    width: "fit-content",
    height: "70px",
    margin: "auto",
  },
}));
function BottomDrawerButton({ open, buttons }) {
  const classes = useStyles();
  return (
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <div className={classes.fab}>
        <Grid container justify="center" alignItems="center" spacing={2}>
          {buttons &&
            buttons.map((button, index) => {
              return (
                <Grid key={`button_${index}`} item>
                  {button}
                </Grid>
              );
            })}
        </Grid>
      </div>
    </Slide>
  );
}

export default BottomDrawerButton;
