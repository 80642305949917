import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { find } from "lodash";

// components (global)
import CustomAutocomplete from "components/CustomSelect/CustomAutocomplete";
import UnitAutocomplete from "components/CustomSelect/UnitAutocomplete";

// form
import { useForm, FormContext } from "react-hook-form";

// material-ui
import { Close as CloseIcon } from "@material-ui/icons";
import { Button, Divider, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// stores
import { closeDrawer } from "stores/main/MainActions";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    paddingBottom: theme.spacing(1),
    flexShrink: 0,
    display: "flex",
  },
  content: { paddingTop: theme.spacing(2) },
}));

const FilterDrawer = ({ search, onSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // form
  const formMethods = useForm();

  // state
  const [cardTypes] = useState([
    { id: "rfid", name: "RFID" },
    { id: "mifare", name: "Mifare" },
    { id: "proximity", name: "Proximity" },
  ]);
  const [cardStatus] = useState([
    { id: "active", name: "Active" },
    { id: "inactive", name: "Disabled" },
    { id: "deleted", name: "Deleted" },
    { id: "suspend", name: "Suspended" },
  ]);

  // functions
  const onClose = () => {
    dispatch(closeDrawer());
  };

  const onReset = () => {
    formMethods.reset();
    onSubmit({
      profileCode: "",
      profileName: "",
      identityNumber: "",
      vehicleNo: "",
      unit: {
        id: "",
      },
      cardType: null,
      cardStatus: null,
    });
    onClose();
  };

  const onFilterSubmit = (value) => {
    onSubmit(value);
    onClose();
  };

  return (
    <React.Fragment>
      <Grid container className={classes.header} justify="space-between">
        <Grid>
          <Button onClick={onClose} size="small">
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </Grid>
      </Grid>

      <Divider />

      <div className={classes.content}>
        <FormContext {...formMethods}>
          <form noValidate xs={12} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item md={12}>
                <UnitAutocomplete
                  defaultValue={search.unit_id}
                  required={false}
                />
              </Grid>

              <Grid item md={12}>
                <TextField
                  size="small"
                  id="profileCode"
                  name="profileCode"
                  label="Card ID"
                  type="text"
                  inputRef={formMethods.register}
                  defaultValue={search?.profile_code}
                  autoComplete="off"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  size="small"
                  id="identityNumber"
                  name="identityNumber"
                  label="Card No."
                  type="text"
                  inputRef={formMethods.register}
                  defaultValue={search?.identity_number}
                  autoComplete="off"
                  fullWidth
                  variant="outlined"
                />
              </Grid>

              <Grid item md={12}>
                <TextField
                  size="small"
                  id="profileName"
                  name="profileName"
                  label="Card Name"
                  type="text"
                  inputRef={formMethods.register}
                  defaultValue={search?.profile_name}
                  autoComplete="off"
                  fullWidth
                  variant="outlined"
                />
              </Grid>

              <Grid item md={12}>
                <TextField
                  size="small"
                  id="vehicleNo"
                  name="vehicleNo"
                  label="Vehicle No."
                  type="text"
                  inputRef={formMethods.register}
                  defaultValue={search?.vehicle_no}
                  autoComplete="off"
                  fullWidth
                  variant="outlined"
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <CustomAutocomplete
                  label="Choose a card type"
                  options={cardTypes}
                  name="cardType"
                  required={false}
                  defaultValue={
                    search.card_type !== ""
                      ? find(
                          cardTypes,
                          (cardType) => cardType.id === search.card_type
                        )
                      : ""
                  }
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <CustomAutocomplete
                  label="Choose a card status"
                  options={cardStatus}
                  name="cardStatus"
                  required={false}
                  defaultValue={
                    search.is_suspend !== ""
                      ? find(cardStatus, (status) => status.id === "suspend")
                      : search.card_status !== ""
                      ? find(
                          cardStatus,
                          (status) => status.id === search.card_status
                        )
                      : ""
                  }
                />
              </Grid>

              <Grid
                item
                container
                md={12}
                justify="flex-end"
                alignItems="center"
              >
                <Button color="primary" onClick={onReset}>
                  Reset
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={formMethods.handleSubmit(onFilterSubmit)}
                >
                  Filter
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormContext>
      </div>
    </React.Fragment>
  );
};

export default FilterDrawer;
