import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

// components (global)
import ButtonProgress from "components/ButtonProgress";
import DialogWrapper from "components/Dialogs/DialogWrapper";
import DialogTitleWrapper from "components/Dialogs/DialogTitleWrapper";

// firebase
import { useFirestore } from "react-redux-firebase";

// form
import { useForm } from "react-hook-form";

// helpers
import { Capitalized } from "helpers/textHelpers";
import { accessRoleValidate } from "helpers/validation/accessRoleValidate";
import { featureList } from "helpers/roleHelpers";

// material-ui
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import {
  Button,
  Checkbox,
  Collapse,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  ListItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// stores
import { toggleEditAccessRoleDialogClose } from "stores/accessRole/AccessRoleAction";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  featureTitle: {
    minHeight: "24px",
    paddingTop: "3px",
  },
  listItem: {
    minWidth: "100%",
  },
  collapse: {
    paddingLeft: theme.spacing(3),
  },
}));

const EditAccessRoleDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const firestore = useFirestore();
  const { errors, handleSubmit, register } = useForm();

  // selector
  const { open, id } = useSelector(
    (state) => state.accessRole.edit_access_role_dialog
  );
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const roles = useSelector((state) => {
    return state.firestore.data.roles ? state.firestore.data.roles[id] : null;
  });
  const selectedProperty = useSelector((state) => state.main.selectedProperty);

  // state
  const [submitting, setSubmitting] = useState(false);
  const [features, setFeatures] = useState(featureList);

  useEffect(() => {
    if (roles) {
      Object.keys(featureList).forEach((key) => {
        if (featureList[key].sub_features) {
          Object.keys(featureList[key].sub_features).forEach((subKey) => {
            let newActions = {};

            Object.keys(featureList[key].sub_features[subKey].actions).forEach(
              (actionKey) => {
                newActions = {
                  ...newActions,
                  [actionKey]: roles[key][subKey][actionKey],
                };
              }
            );

            setFeatures((prevState) => {
              return {
                ...prevState,
                [key]: {
                  ...prevState[key],
                  sub_features: {
                    ...prevState[key].sub_features,
                    [subKey]: {
                      ...prevState[key].sub_features[subKey],
                      actions: {
                        ...prevState[key].sub_features[subKey].actions,
                        ...newActions,
                      },
                    },
                  },
                },
              };
            });
          });
        } else {
          let newActions = {};

          Object.keys(featureList[key].actions).forEach((actionKey) => {
            newActions = {
              ...newActions,
              [actionKey]: roles[key][actionKey],
            };
          });

          setFeatures((prevState) => {
            return {
              ...prevState,
              [key]: {
                ...prevState[key],
                actions: {
                  ...prevState[key].actions,
                  ...newActions,
                },
              },
            };
          });
        }
      });
    }
  }, [roles]);

  // functions
  const handleClose = () => {
    if (!submitting) {
      setFeatures(featureList); // Reset all checkbox
      dispatch(toggleEditAccessRoleDialogClose());
    }
  };

  const toggleExpand = (module) => {
    setFeatures((prevState) => {
      return {
        ...prevState,
        [module]: {
          ...prevState[module],
          open: !prevState[module].open,
        },
      };
    });
  };

  const toggleSubExpand = (module, subModule) => {
    setFeatures((prevState) => {
      return {
        ...prevState,
        [module]: {
          ...prevState[module],
          sub_features: {
            ...prevState[module].sub_features,
            [subModule]: {
              ...prevState[module].sub_features[subModule],
              open: !prevState[module].sub_features[subModule].open,
            },
          },
        },
      };
    });
  };

  const handleFeatureChange = (event) => {
    const eventName = event.target.name;
    const splitStr = eventName.split("-");
    const feature = splitStr[0];
    let newState = [];

    if (splitStr.length === 2) {
      const action = splitStr[1];

      if (action === "read" && !event.target.checked) {
        Object.keys(features[feature].actions).forEach((key) => {
          newState = {
            ...newState,
            [key]: false,
          };
        });
      } else {
        newState = {
          [action]: event.target.checked,
        };
      }

      setFeatures((prevState) => {
        return {
          ...prevState,
          [feature]: {
            ...prevState[feature],
            actions: { ...prevState[feature].actions, ...newState },
          },
        };
      });
    } else if (splitStr.length === 3) {
      const subFeature = splitStr[1];
      const action = splitStr[2];

      if (action === "read" && !event.target.checked) {
        Object.keys(features[feature].sub_features[subFeature].actions).forEach(
          (key) => {
            newState = {
              ...newState,
              [key]: false,
            };
          }
        );
      } else {
        newState = {
          [action]: event.target.checked,
        };
      }

      setFeatures((prevState) => {
        return {
          ...prevState,
          [feature]: {
            ...prevState[feature],
            sub_features: {
              ...prevState[feature].sub_features,
              [subFeature]: {
                ...prevState[feature].sub_features[subFeature],
                actions: {
                  ...prevState[feature].sub_features[subFeature].actions,
                  ...newState,
                },
              },
            },
          },
        };
      });
    }
  };

  const onSubmit = ({ name, description }) => {
    if (!submitting) {
      setSubmitting(true);

      const data = {
        name,
        description,
        created_at: new Date(),
        created_by: {
          user_id: auth.uid,
          name: profile.name,
        },
      };

      Object.keys(features).forEach((key) => {
        data[key] = {};
        if (!features[key].sub_features) {
          Object.keys(features[key].actions).forEach((actionKey) => {
            data[key] = {
              ...data[key],
              [actionKey]: features[key].actions[actionKey],
            };
          });
        } else {
          Object.keys(features[key].sub_features).forEach((subKey) => {
            Object.keys(features[key].sub_features[subKey].actions).forEach(
              (actionKey) => {
                data[key] = {
                  ...data[key],
                  [subKey]: {
                    ...data[key][subKey],
                    [actionKey]:
                      features[key].sub_features[subKey].actions[actionKey],
                  },
                };
              }
            );
          });
        }
      });

      firestore
        .set(`properties/${selectedProperty}/ac_roles/${id}`, data, {
          merge: true,
        })
        .then(() => {
          setSubmitting(false);
          handleClose();
          snackbar.enqueueSnackbar(`Access Role had been added successfully`, {
            variant: "success",
          });
        })
        .catch((e) => {
          setSubmitting(false);
          handleClose();
          snackbar.enqueueSnackbar(e.message, {
            variant: "error",
            persist: false,
          });
        });
    }
  };

  // element
  const featureSelection = (type) => {
    return Object.keys(features).map(
      (key, index) =>
        index % 2 === (type === "odd" ? 0 : 1) && (
          <Grid item md={12} key={key}>
            <ListItem
              className={classes.listItem}
              onClick={() => toggleExpand(key)}
              button
            >
              {features[key].open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              <Typography variant="body1" className={classes.featureTitle}>
                {features[key].label}
              </Typography>
            </ListItem>

            <Collapse
              in={features[key].open}
              timeout="auto"
              className={classes.collapse}
              unmountOnExit
            >
              {features[key].sub_features ? (
                <Grid container direction="column">
                  {Object.keys(features[key].sub_features).map((subKey) => (
                    <Grid item md={12} key={`${key}_${subKey}`}>
                      <ListItem
                        className={classes.listItem}
                        onClick={() => toggleSubExpand(key, subKey)}
                        button
                      >
                        {features[key].sub_features[subKey].open ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                        <Typography
                          variant="body1"
                          className={classes.featureTitle}
                        >
                          {features[key].sub_features[subKey].label}
                        </Typography>
                      </ListItem>

                      <Collapse
                        in={features[key].sub_features[subKey].open}
                        timeout="auto"
                        className={classes.collapse}
                        unmountOnExit
                      >
                        <FormGroup row>
                          <Grid container>
                            {Object.keys(
                              features[key].sub_features[subKey].actions
                            ).map((actionKey) => (
                              <Grid
                                item
                                md={6}
                                key={`${key}_${subKey}_${actionKey}`}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled={
                                        actionKey !== "read" &&
                                        !features[key].sub_features[subKey]
                                          .actions["read"]
                                      }
                                      checked={
                                        features[key].sub_features[subKey]
                                          .actions[actionKey]
                                      }
                                      onChange={handleFeatureChange}
                                      name={`${key}-${subKey}-${actionKey}`}
                                      color="primary"
                                    />
                                  }
                                  label={Capitalized(
                                    actionKey.replace(/_/g, " ")
                                  )}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </FormGroup>
                      </Collapse>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <FormGroup row>
                  <Grid container>
                    {Object.keys(features[key].actions).map((actionKey) => (
                      <Grid item md={4} key={`${key}_${actionKey}`}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={
                                actionKey !== "read" &&
                                !features[key].actions["read"]
                              }
                              checked={features[key].actions[actionKey]}
                              onChange={handleFeatureChange}
                              name={`${key}-${actionKey}`}
                              color="primary"
                            />
                          }
                          label={Capitalized(actionKey)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              )}
            </Collapse>
          </Grid>
        )
    );
  };

  if (!roles) return null;

  return (
    <form
      noValidate
      xs={12}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <DialogWrapper size="md" open={open}>
        <DialogTitleWrapper
          title="Edit Access Role"
          handleClose={handleClose}
        />
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item container md={12} spacing={2}>
              <Grid item md={6}>
                <TextField
                  size="small"
                  id="name"
                  name="name"
                  label="Name of Role *"
                  type="text"
                  variant="outlined"
                  autoComplete="off"
                  defaultValue={roles.name}
                  inputRef={register(accessRoleValidate.name.rules)}
                  helperText={
                    errors.name &&
                    accessRoleValidate.name.message[errors.name.type]
                  }
                  error={!!errors.name}
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  size="small"
                  id="description"
                  name="description"
                  label="Description"
                  type="text"
                  variant="outlined"
                  autoComplete="off"
                  defaultValue={roles.description}
                  inputRef={register}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <Grid container item md={6} alignItems="flex-start">
                {featureSelection("odd")}
              </Grid>

              <Grid container item md={6}>
                {featureSelection("even")}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <ButtonProgress
            type="submit"
            containName="Save"
            margin={false}
            loading={submitting}
            disabled={submitting}
            onClick={handleSubmit(onSubmit)}
          />
        </DialogActions>
      </DialogWrapper>
    </form>
  );
};

export default EditAccessRoleDialog;
