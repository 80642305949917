import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { find, isEmpty } from "lodash";

// components (global)
import ButtonProgress from "components/ButtonProgress";
import DialogWrapper from "components/Dialogs/DialogWrapper";
import DialogTitleWrapper from "components/Dialogs/DialogTitleWrapper";

// firebase
import { useFirestore } from "react-redux-firebase";

// form
import { useForm } from "react-hook-form";

// helpers
import { visitorCardValidate } from "helpers/validation/visitorCardValidate";
import { Capitalized } from "helpers/textHelpers";

// material-ui
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
}));

const ManualAddDialog = ({ open, handleDialogClose }) => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const firestore = useFirestore();

  // form
  const {
    errors,
    handleSubmit,
    setValue,
    setError,
    clearError,
    register,
    formState,
  } = useForm();
  const { dirty } = formState;

  // selector
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const cards = useSelector((state) => state.firestore.ordered.visitor_cards);
  const selectedPropertyId = useSelector(
    (state) => state.main.selectedProperty
  );
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const accessCardSettings = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor].access_card_settings
      : null;
  });

  // state
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState(true);
  const [antiPassback, setAntiPassback] = useState(false);
  const [cardTypes] = useState([
    { id: "mifare", name: "Mifare" },
    { id: "proximity", name: "Proximity" },
  ]);

  // effect
  useEffect(() => {
    register({ name: "cardType" });
  }, [register]);

  // functions
  const initState = () => {};

  const handleClose = () => {
    if (!submitting) {
      handleDialogClose();
      initState();
    }
  };

  const handleTypeChange = (e, value) => {
    if (value) {
      setValue("cardType", value.id);
    } else {
      setValue("cardType", null);
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.checked);
  };

  const handleAntiPassbackChange = (event) => {
    setAntiPassback(event.target.checked);
  };

  const onSubmit = (value) => {
    if (!submitting) {
      setSubmitting(true);

      const createdDate = new Date();

      // Handle end date
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth();
      var day = date.getDate();

      const data = {
        action: "add",
        attempted: 0,
        anti_passback: value.antiPassback,
        card_type: value.cardType,
        created_at: createdDate,
        created_by: {
          user_id: auth.uid,
          name: profile.name,
        },
        end_date: new Date(year + 10, month, day),
        identity_number: value.identityNumber,
        is_assign: false,
        is_lift: false,
        is_sync: false,
        profile_code: value.passNumber,
        profile_name: value.profileName,
        profile_type: "visitor",
        property_id: selectedPropertyId,
        start_date: new Date(),
        status: value.status ? "active" : "inactive",
        vendor: {
          id: selectedVendor,
          type: accessCardSettings.type,
        },
      };

      const doc = firestore.collection("ac_profiles").doc();
      doc.set(data).then(() => {
        firestore
          .collection("ac_audit_trails")
          .add({
            action: "added",
            created_at: createdDate,
            created_by: {
              user_id: auth.uid,
              name: profile.name,
            },
            description: `${Capitalized(
              profile.name
            )} had been added visitor card ${value.identityNumber}`,
            module: "access_card",
            profile: {
              id: doc.id,
              identity_number: value.identityNumber,
            },
            values: data,
          })
          .then(() => {
            setSubmitting(false);
            handleClose();
            snackbar.enqueueSnackbar(
              `Card No. ${value.identityNumber} had been added successfully`,
              {
                variant: "success",
              }
            );
          })
          .catch((e) => {
            setSubmitting(false);
            handleClose();
            snackbar.enqueueSnackbar(e.message, {
              variant: "error",
              persist: false,
            });
          });
      });
    }
  };

  return (
    <form
      noValidate
      xs={12}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      className={classes.root}
    >
      <DialogWrapper size="md" open={open}>
        <DialogTitleWrapper
          title="Add Visitor Card"
          handleClose={handleClose}
        />

        <DialogContent dividers>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Grid
              item
              container
              direction="row"
              spacing={2}
              md={7}
              justify="center"
              alignItems="flex-start"
            >
              <Grid item md={12}>
                <Typography variant="h4" component="h1">
                  Card Details
                </Typography>
              </Grid>

              <Grid item md={12}>
                <TextField
                  size="small"
                  id="identityNumber"
                  name="identityNumber"
                  label="Card No. *"
                  type="text"
                  variant="outlined"
                  autoComplete="off"
                  onChange={(event) => {
                    if (event.target.value !== "") {
                      const found = find(
                        cards,
                        (card) =>
                          card.identity_number.toLowerCase() ===
                            event.target.value.toLowerCase() &&
                          card.status !== "deleted"
                      );

                      if (found) {
                        setError("identityNumber", "exists");
                      } else {
                        clearError("identityNumber");
                      }
                    }
                  }}
                  onBlur={(event) => {
                    const found = find(
                      cards,
                      (card) =>
                        card.identity_number.toLowerCase() ===
                          event.target.value.toLowerCase() &&
                        card.status !== "deleted"
                    );

                    if (found) {
                      setError("identityNumber", "exists");
                    } else {
                      clearError("identityNumber");
                    }
                  }}
                  inputRef={register(visitorCardValidate.identityNumber.rules)}
                  helperText={
                    errors.identityNumber &&
                    visitorCardValidate.identityNumber.message[
                      errors.identityNumber.type
                    ]
                  }
                  error={!!errors.identityNumber}
                  fullWidth
                />
              </Grid>

              <Grid item md={12}>
                <TextField
                  size="small"
                  id="profileName"
                  name="profileName"
                  label="Name *"
                  type="text"
                  variant="outlined"
                  autoComplete="off"
                  inputRef={register(visitorCardValidate.profileName.rules)}
                  helperText={
                    errors.profileName &&
                    visitorCardValidate.profileName.message[
                      errors.profileName.type
                    ]
                  }
                  error={!!errors.profileName}
                  fullWidth
                />
              </Grid>

              <Grid item md={12}>
                <Autocomplete
                  autoHighlight
                  options={cardTypes}
                  getOptionLabel={(option) => option.name}
                  onChange={handleTypeChange}
                  renderInput={(params) => (
                    <TextField
                      error={!!errors.cardType}
                      helperText={
                        errors.cardType &&
                        visitorCardValidate.cardType.message[
                          errors.cardType.type
                        ]
                      }
                      {...params}
                      label="Choose a type *"
                      fullWidth
                      size="small"
                      name="cardType"
                      autoComplete="off"
                      variant="outlined"
                      inputRef={register(visitorCardValidate.cardType.rules)}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="status"
                      checked={status}
                      onChange={handleStatusChange}
                      color="primary"
                      inputRef={register}
                    />
                  }
                  label="Active"
                />
              </Grid>

              <Grid item md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="antiPassback"
                      checked={antiPassback}
                      onChange={handleAntiPassbackChange}
                      color="primary"
                      inputRef={register}
                    />
                  }
                  label="Anti-passback"
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              direction="row"
              spacing={2}
              md={5}
              justify="center"
              alignItems="flex-start"
            >
              <Grid item md={12}>
                <Typography variant="h4" component="h1">
                  Other Details
                </Typography>
              </Grid>
              <Grid item md={12}>
                <TextField
                  size="small"
                  id="passNumber"
                  name="passNumber"
                  label="Pass Number *"
                  type="text"
                  onChange={(event) => {
                    if (event.target.value !== "") {
                      const found = find(
                        cards,
                        (card) =>
                          card.profile_code.toLowerCase() ===
                            event.target.value.toLowerCase() &&
                          card.status !== "deleted"
                      );

                      if (found) {
                        setError("passNumber", "exists");
                      } else {
                        clearError("passNumber");
                      }
                    }
                  }}
                  onBlur={(event) => {
                    const found = find(
                      cards,
                      (card) =>
                        card.profile_code.toLowerCase() ===
                          event.target.value.toLowerCase() &&
                        card.status !== "deleted"
                    );

                    if (found) {
                      setError("passNumber", "exists");
                    } else {
                      clearError("passNumber");
                    }
                  }}
                  inputRef={register(visitorCardValidate.passNumber.rules)}
                  helperText={
                    errors.passNumber &&
                    visitorCardValidate.passNumber.message[
                      errors.passNumber.type
                    ]
                  }
                  error={!!errors.passNumber}
                  autoComplete="off"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  size="small"
                  id="passType"
                  name="passType"
                  label="Pass Type"
                  type="text"
                  inputRef={register}
                  autoComplete="off"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <ButtonProgress
            type="submit"
            containName="Save"
            margin={false}
            loading={submitting}
            disabled={submitting || !dirty || !isEmpty(errors)}
            onClick={handleSubmit(onSubmit)}
          />
        </DialogActions>
      </DialogWrapper>
    </form>
  );
};

export default ManualAddDialog;
