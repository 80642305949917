export const doorActionList = [
  { value: "", label: "Select Action" },
  { value: "no_action", label: "No Action" },
  { value: "auto_open_barrier", label: "Auto Open Barrier" },
  { value: "permission_open_gate", label: "Permission Open Gate" },
  { value: "assign_card", label: "Assign Card" },
  { value: "assign_qr_code", label: "Assign QR Code" },
];
export const statusMap = {
  no: {
    label: "No",
    color: "#038c00",
  },
  yes: {
    label: "Suspended",
    color: "red",
  },
  draft: {
    label: "Draft",
    color: "#ff9600",
  },
  terminated: {
    label: "Terminated",
    color: "red",
  },
};
