import * as dashTypes from "./dashTypes";

const initialState = {
  currentSection: null
};

const dashReducers = (state = initialState, action) => {
  switch (action.type) {
    case dashTypes.SET_CURRENT_SECTION:
      return {
        ...state,
        currentSection: action.payload
      };
    default:
      return state;
  }
};

export default dashReducers;
