import React from "react";
import { useSelector } from "react-redux";

// components (global)
import { Page } from "components/Pages";
import { CustomTabs } from "components/Tabs";

// components (local)
import {
  DeviceTab,
  DoorAccessTab,
  FloorAccessTab,
  GeneralTab,
  VendorAccessTab,
  QRFormatTab,
  AutoCheckOutTab,
} from "./components";

// material-ui
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  content: {},
  divider: {
    marginBottom: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  type: {
    marginBottom: theme.spacing(6),
  },
}));

const CardSettings = () => {
  const classes = useStyles();

  // variable
  let tabs = [
    {
      value: "general",
      label: "General",
      content: <GeneralTab />,
    },
  ];

  // selector
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const vendor = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor]
      : null;
  });

  if (vendor && vendor.access_card_settings) {
    tabs.push(
      {
        value: "device",
        label: "Device",
        content: <DeviceTab />,
      },
      {
        value: "access_group",
        label: `${vendor.access_card_settings.type} Access`,
        content: <VendorAccessTab />,
      },
      {
        value: "door_access",
        label: "Door Access",
        content: <DoorAccessTab />,
      },
      {
        value: "floor_access",
        label: "Floor Access",
        content: <FloorAccessTab />,
      },
      {
        value: "qr_format",
        label: "QR Format",
        content: <QRFormatTab />,
      },
      {
        value: "auto_checkout",
        label: "Auto Check Out",
        content: <AutoCheckOutTab />,
      }
    );
  }

  return (
    <Page className={classes.root} title="Setting">
      <Container maxWidth={false}>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justify="space-between"
          className={classes.header}
        >
          <Grid item>
            <Typography component="h2" gutterBottom variant="overline">
              Settings
            </Typography>
            <Typography component="h1" variant="h3">
              Access Card
            </Typography>
          </Grid>
        </Grid>

        <CustomTabs tabs={tabs} defaultTabIndex={0} />
      </Container>
    </Page>
  );
};

export default CardSettings;
