import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

// components (local)
import { FadeTransitions } from "components/Pages";

// material-ui
import { Container, Grid, Divider } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const Page = ({ title, children, ...rest }) => {
  const classes = "";

  const isPropertyLoaded = useSelector((state) => state.main.isPropertyLoading);

  return (
    <div {...rest}>
      <Helmet>
        <title>JaGaCard - {title}</title>
      </Helmet>
      <FadeTransitions>
        {isPropertyLoaded ? (
          <Container maxWidth="lg">
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justify="space-between"
              className={classes.header}
            >
              <Grid item md={10}>
                <Skeleton
                  animation="wave"
                  height={10}
                  width="20%"
                  style={{ marginBottom: 6 }}
                />
                <Skeleton
                  animation="wave"
                  height={10}
                  width="60%"
                  style={{ marginBottom: 6 }}
                />
              </Grid>
              <Grid item container md={2}>
                <Skeleton animation="wave" height="100%" width="100%" />
              </Grid>
            </Grid>

            <Divider />

            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justify="space-between"
              className={classes.header}
            >
              <Grid item md={12}>
                <Skeleton
                  animation="wave"
                  height={200}
                  width="100%"
                  style={{ marginBottom: 6 }}
                />
              </Grid>
            </Grid>
          </Container>
        ) : (
          children
        )}
      </FadeTransitions>
    </div>
  );
};

export default Page;
