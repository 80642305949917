import React, { useState } from "react";
import {
  SortingState,
  EditingState,
  PagingState,
  IntegratedPaging,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";
import {
  Grid as GridTable,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  PagingPanel,
  TableFixedColumns,
} from "@devexpress/dx-react-grid-material-ui";

//components
//material-ui
import { Button, IconButton } from "@material-ui/core";

//material-icon
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";

//helpers
//assets
import { TableComponentBase } from "components/Table/ReactGridCustom";

//styles

function DataTable({ rows, columns, onCommitChanges }) {
  const [sorting, getSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [leftFixedColumns] = useState([TableEditColumn.COLUMN_TYPE]);
  const [pageSizes] = useState([5, 10, 15, 0]);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [rowChanges, setRowChanges] = useState({});

  const AddButton = ({ onExecute }) => (
    <div style={{ textAlign: "center" }}>
      <Button color="primary" onClick={onExecute} title="Create new record">
        New
      </Button>
    </div>
  );

  const EditButton = ({ onExecute }) => (
    <IconButton onClick={onExecute} title="Edit">
      <EditIcon />
    </IconButton>
  );

  const DeleteButton = ({ onExecute }) => (
    <IconButton
      onClick={() => {
        // eslint-disable-next-line
        if (window.confirm("Are you sure you want to delete this row?")) {
          onExecute();
        }
      }}
      title="Delete row"
    >
      <DeleteIcon />
    </IconButton>
  );

  const CommitButton = ({ onExecute }) => (
    <IconButton onClick={onExecute} title="Save changes">
      <SaveIcon />
    </IconButton>
  );

  const CancelButton = ({ onExecute }) => (
    <IconButton color="secondary" onClick={onExecute} title="Cancel changes">
      <CancelIcon />
    </IconButton>
  );

  const commandComponents = {
    add: AddButton,
    edit: EditButton,
    delete: DeleteButton,
    commit: CommitButton,
    cancel: CancelButton,
  };

  const Command = ({ id, onExecute }) => {
    const CommandButton = commandComponents[id];
    return <CommandButton onExecute={onExecute} />;
  };

  const deleteRows = (deletedIds) => {
    const rowsForDelete = rows.slice();
    deletedIds.forEach((rowId) => {
      const index = rowsForDelete.findIndex((row) => row.id === rowId);
      if (index > -1) {
        rowsForDelete.splice(index, 1);
      }
    });
    return rowsForDelete;
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (changed) {
      changedRows = rows.map((row) =>
        changed[row.id] ? { ...row, ...changed[row.id] } : row
      );
    }
    if (deleted) {
      changedRows = deleteRows(deleted);
    }

    onCommitChanges(changedRows);
  };

  const getRowId = (row) => row.id;

  return (
    <GridTable rows={rows} columns={columns} getRowId={getRowId}>
      <SortingState sorting={sorting} onSortingChange={getSorting} />
      <PagingState
        currentPage={currentPage}
        onCurrentPageChange={setCurrentPage}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
      />
      <EditingState
        editingRowIds={editingRowIds}
        onEditingRowIdsChange={setEditingRowIds}
        rowChanges={rowChanges}
        onRowChangesChange={setRowChanges}
        onCommitChanges={commitChanges}
      />
      <IntegratedSorting />
      <IntegratedPaging />

      <Table tableComponent={TableComponentBase} />
      <TableHeaderRow showSortingControls />
      <TableEditRow />
      <TableEditColumn
        width={170}
        showEditCommand
        showDeleteCommand
        commandComponent={Command}
      />
      <TableFixedColumns leftColumns={leftFixedColumns} />
      <PagingPanel pageSizes={pageSizes} />
    </GridTable>
  );
}

export default React.memo(DataTable);
