import React from "react";
import { useSelector } from "react-redux";

// components (local)
import { AddVendorDetails, EditVendorDetails } from "./components";

// material-ui
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    marginBottom: theme.spacing(1),
  },
}));

const General = () => {
  const classes = useStyles();

  // selector
  const selectedVendor = useSelector((state) => state.main.selectedApnrVendor);
  const vendor = useSelector((state) => {
    return state.firestore.data.apnrVendors
      ? state.firestore.data.apnrVendors[selectedVendor]
      : null;
  });

  return (
    <div>
      <Grid className={classes.root} container spacing={3}>
        {!vendor && (
          <Grid item md={12}>
            <AddVendorDetails />
          </Grid>
        )}

        {vendor && !vendor.apnr_settings && (
          <Grid item md={12}>
            <AddVendorDetails vendor={vendor} vendorId={selectedVendor} />
          </Grid>
        )}

        {vendor && vendor.apnr_settings && (
          <React.Fragment>
            <Grid item md={12}>
              <EditVendorDetails vendor={vendor} vendorId={selectedVendor} />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </div>
  );
};

export default General;
