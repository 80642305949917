export const profilePopulates = [{ child: "roles", root: "roles" }];

const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
  profileFactory: (userData, profileData, firebase) => {
    console.log("userData", userData);
    return {};
  }
};

export default rrfConfig;
