import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import includes from "lodash/includes";

// components (global)
import ButtonProgress from "components/ButtonProgress";

// firebase
import { useFirestore } from "react-redux-firebase";

// form
import { useForm } from "react-hook-form";

// helpers
import { settingsValidate } from "helpers/validation/settingsValidate";

// material-ui
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
}));

const EditVendorDetails = ({ vendor, vendorId }) => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const firestore = useFirestore();
  const { errors, handleSubmit, register } = useForm();

  // state
  const [submitting, setSubmitting] = useState(false);
  const [checkbox, setCheckbox] = useState({
    manualAssignAccessLevel:
      vendor?.access_card_settings?.manual_assign_access_level ?? false,
  });
  const [floorcheckbox, setFloorCheckbox] = useState({
    manualAssignFloorAccessLevel:
      vendor?.access_card_settings?.manual_assign_floor_access_level ?? false,
  });
  const [accessGroupCheckBox, setAccessGroupCheckBox] = useState({
    manualAssignAccessGroup:
      vendor?.access_card_settings?.manual_assign_access_group ?? false,
  });

  // selector
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const selectedPropertyId = useSelector(
    (state) => state.main.selectedProperty
  );
  const property = useSelector((state) => {
    return state.firestore.data.properties
      ? state.firestore.data.properties[selectedPropertyId]
      : null;
  });
  const vendorType = vendor?.access_card_settings?.type;

  // functions
  const onSubmit = (value) => {
    if (!submitting) {
      setSubmitting(true);

      let settings = {
        ...vendor.access_card_settings,
        ip_address: value.ipAddress,
      };

      if (vendorType.toLowerCase() === "soyal") {
        settings = {
          ...settings,
          port: parseInt(value.portNo),
          db_instance: value.dbInstance,
          db_name: value.dbName,
          db_username: value.dbUsername,
          db_password: value.dbPassword,
        };
      }

      switch (vendorType.toLowerCase()) {
        case "entrypass":
          settings = {
            ...settings,
            manual_assign_access_level:
              checkbox.manualAssignAccessLevel ?? false,
            manual_assign_floor_access_level:
              floorcheckbox.manualAssignFloorAccessLevel ?? false,
            manual_assign_access_group:
              accessGroupCheckBox.manualAssignAccessGroup ?? false,
          };
          break;
        case "falco":
          settings = {
            ...settings,
            username: value.username,
            password: value.password,
          };
          break;
        case "soyal":
          settings = {
            ...settings,
            port: parseInt(value.portNo),
            db_instance: value.dbInstance,
            db_name: value.dbName,
            db_username: value.dbUsername,
            db_password: value.dbPassword,
            site_id: value.siteId,
            door_id: value.doorId,
          };
          break;
        case "microengine":
          settings = {
            ...settings,
            port: parseInt(value.portNo),
          };
          break;
        default:
          break;
      }
      console.log(property);

      firestore
        .update(
          { collection: "ac_vendors", doc: vendorId },
          {
            updated_at: new Date(),
            updated_by: {
              user_id: auth.uid,
              name: profile.name,
            },
            ip_address: value.ipAddress,
            access_card_settings: settings,
            property_name: property.name,
          }
        )
        .then(() => {
          setSubmitting(false);
          snackbar.enqueueSnackbar("Vendor details had been updated", {
            variant: "success",
          });
        })
        .catch((e) => {
          setSubmitting(false);
          snackbar.enqueueSnackbar(e.message, {
            variant: "error",
            persist: false,
          });
        });
    }
  };

  return (
    <Card className={classes.card}>
      <form
        noValidate
        xs={12}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <CardHeader
          title="Vendor"
          subheader="This section is to setup local access card PC/Server information"
        />
        <Divider />
        <CardContent>
          <Grid container direction="row" spacing={3}>
            <Grid item container spacing={3} alignItems="flex-start">
              <Grid item md={4}>
                <TextField
                  size="small"
                  id="ipAddress"
                  name="ipAddress"
                  label="Vendor"
                  type="text"
                  variant="outlined"
                  autoComplete="off"
                  disabled={true}
                  defaultValue={vendorType.toUpperCase()}
                  fullWidth
                />
              </Grid>

              <Grid item md={4}>
                <TextField
                  size="small"
                  id="ipAddress"
                  name="ipAddress"
                  label="IP Address *"
                  type="text"
                  variant="outlined"
                  autoComplete="off"
                  defaultValue={vendor.access_card_settings.ip_address}
                  inputRef={register(settingsValidate.ipAddress.rules)}
                  helperText={
                    errors.ipAddress &&
                    settingsValidate.ipAddress.message[errors.ipAddress.type]
                  }
                  error={!!errors.ipAddress}
                  fullWidth
                />
              </Grid>

              {vendorType && vendorType.toLowerCase() === "entrypass" && (
                <>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="sms"
                          checked={!!checkbox.manualAssignAccessLevel}
                          defaultValue={false}
                          onChange={(e) => {
                            setCheckbox({
                              ...checkbox,
                              manualAssignAccessLevel: e.target.checked,
                            });
                          }}
                          color="primary"
                          inputRef={register}
                        />
                      }
                      label="Allow User to choose Access Level"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="sms"
                          checked={!!floorcheckbox.manualAssignFloorAccessLevel}
                          defaultValue={false}
                          onChange={(e) => {
                            setFloorCheckbox({
                              ...floorcheckbox,
                              manualAssignFloorAccessLevel: e.target.checked,
                            });
                          }}
                          color="primary"
                          inputRef={register}
                        />
                      }
                      label="Allow User to choose Floor Access Level"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="sms"
                          checked={
                            !!accessGroupCheckBox.manualAssignAccessGroup
                          }
                          defaultValue={false}
                          onChange={(e) => {
                            setAccessGroupCheckBox({
                              ...accessGroupCheckBox,
                              manualAssignAccessGroup: e.target.checked,
                            });
                          }}
                          color="primary"
                          inputRef={register}
                        />
                      }
                      label="Allow User to choose Access Group"
                    />
                  </Grid>
                </>
              )}

              {vendorType &&
                includes(
                  ["soyal", "microengine"],
                  vendorType.toLowerCase()
                ) && (
                  <Grid item md={2}>
                    <TextField
                      size="small"
                      id="portNo"
                      name="portNo"
                      label="Port No. *"
                      type="number"
                      variant="outlined"
                      autoComplete="off"
                      defaultValue={vendor.port ? vendor.port : "1433"}
                      inputRef={register(settingsValidate.portNo.rules)}
                      helperText={
                        errors.portNo &&
                        settingsValidate.portNo.message[errors.portNo.type]
                      }
                      error={!!errors.portNo}
                      fullWidth
                    />
                  </Grid>
                )}
            </Grid>

            {vendorType && vendorType.toLowerCase() === "falco" && (
              <React.Fragment>
                <Grid
                  item
                  container
                  direction="row"
                  spacing={3}
                  alignItems="flex-start"
                >
                  <Grid item md={4}>
                    <TextField
                      size="small"
                      id="username"
                      name="username"
                      label="Username *"
                      type="text"
                      variant="outlined"
                      autoComplete="off"
                      defaultValue={vendor.access_card_settings.username}
                      inputRef={register(settingsValidate.username.rules)}
                      helperText={
                        errors.username &&
                        settingsValidate.username.message[errors.username.type]
                      }
                      error={!!errors.username}
                      fullWidth
                    />
                  </Grid>

                  <Grid item md={4}>
                    <TextField
                      size="small"
                      id="password"
                      name="password"
                      label="Password *"
                      type="text"
                      variant="outlined"
                      autoComplete="off"
                      defaultValue={vendor.access_card_settings.password}
                      inputRef={register(settingsValidate.password.rules)}
                      helperText={
                        errors.password &&
                        settingsValidate.password.message[errors.password.type]
                      }
                      error={!!errors.password}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            )}

            {vendorType && vendorType.toLowerCase() === "soyal" && (
              <React.Fragment>
                <Grid
                  item
                  container
                  direction="row"
                  spacing={3}
                  alignItems="flex-start"
                >
                  <Grid item md={3}>
                    <TextField
                      size="small"
                      id="dbInstance"
                      name="dbInstance"
                      label="DB Instance *"
                      type="text"
                      variant="outlined"
                      autoComplete="off"
                      defaultValue={
                        vendor.access_card_settings.db_instance
                          ? vendor.access_card_settings.db_instance
                          : ""
                      }
                      inputRef={register(settingsValidate.dbInstance.rules)}
                      helperText={
                        errors.dbInstance &&
                        settingsValidate.dbInstance.message[
                          errors.dbInstance.type
                        ]
                      }
                      error={!!errors.dbInstance}
                      fullWidth
                    />
                  </Grid>

                  <Grid item md={3}>
                    <TextField
                      size="small"
                      id="dbName"
                      name="dbName"
                      label="DB Name *"
                      type="text"
                      variant="outlined"
                      autoComplete="off"
                      defaultValue={
                        vendor.access_card_settings.db_name
                          ? vendor.access_card_settings.db_name
                          : ""
                      }
                      inputRef={register(settingsValidate.dbName.rules)}
                      helperText={
                        errors.dbName &&
                        settingsValidate.dbName.message[errors.dbName.type]
                      }
                      error={!!errors.dbName}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      size="small"
                      id="dbUsername"
                      name="dbUsername"
                      label="DB Username *"
                      type="text"
                      variant="outlined"
                      autoComplete="off"
                      defaultValue={
                        vendor.access_card_settings.db_username
                          ? vendor.access_card_settings.db_username
                          : ""
                      }
                      inputRef={register(settingsValidate.dbUsername.rules)}
                      helperText={
                        errors.dbUsername &&
                        settingsValidate.dbUsername.message[
                          errors.dbUsername.type
                        ]
                      }
                      error={!!errors.dbUsername}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      size="small"
                      id="dbPassword"
                      name="dbPassword"
                      label="DB Password *"
                      type="text"
                      variant="outlined"
                      autoComplete="off"
                      defaultValue={
                        vendor.access_card_settings.db_password
                          ? vendor.access_card_settings.db_password
                          : ""
                      }
                      inputRef={register(settingsValidate.dbPassword.rules)}
                      helperText={
                        errors.dbPassword &&
                        settingsValidate.dbPassword.message[
                          errors.dbPassword.type
                        ]
                      }
                      error={!!errors.dbPassword}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      size="small"
                      id="siteId"
                      name="siteId"
                      label="Site Id"
                      type="text"
                      variant="outlined"
                      autoComplete="off"
                      defaultValue={
                        vendor.access_card_settings.site_id
                          ? vendor.access_card_settings.site_id
                          : ""
                      }
                      inputRef={register(settingsValidate.siteId.rules)}
                      helperText={
                        errors.siteId &&
                        settingsValidate.siteId.message[errors.siteId.type]
                      }
                      error={!!errors.siteId}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      size="small"
                      id="doorId"
                      name="doorId"
                      label="Door Id"
                      type="text"
                      variant="outlined"
                      autoComplete="off"
                      defaultValue={
                        vendor.access_card_settings.door_id
                          ? vendor.access_card_settings.door_id
                          : ""
                      }
                      inputRef={register(settingsValidate.doorId.rules)}
                      helperText={
                        errors.doorId &&
                        settingsValidate.doorId.message[errors.doorId.type]
                      }
                      error={!!errors.doorId}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </CardContent>

        <Divider />

        <CardActions>
          <ButtonProgress
            margin={false}
            type="submit"
            loading={submitting}
            containName="Save"
            disabled={submitting}
            onClick={handleSubmit(onSubmit)}
          />
        </CardActions>
      </form>
    </Card>
  );
};

export default EditVendorDetails;
