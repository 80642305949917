import React, { useState } from "react";
import { useSelector } from "react-redux";

// components (local)
import EntryPassFloorAccessDialog from "./EntryPassFloorAccessDialog";
import FalcoFloorAccessDialog from "./FalcoFloorAccessDialog";
import SoyalFloorAccessDialog from "./SoyalFloorAccessDialog";
import MicroEngineFloorAccessDialog from "./MicroEngineFloorAccessDialog";

const EditFloorAccess = () => {
  // selector
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const accessCardSettingSelector = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor] &&
        state.firestore.data.vendors[selectedVendor].access_card_settings
        ? state.firestore.data.vendors[selectedVendor].access_card_settings
        : null
      : null;
  });

  // state
  const [vendorType] = useState(
    accessCardSettingSelector
      ? accessCardSettingSelector.type.toLowerCase()
      : null
  );

  return (
    <React.Fragment>
      {vendorType === "entrypass" && <EntryPassFloorAccessDialog />}
      {vendorType === "falco" && <FalcoFloorAccessDialog />}
      {vendorType === "soyal" && <SoyalFloorAccessDialog />}
      {vendorType === "microengine" && <MicroEngineFloorAccessDialog />}
    </React.Fragment>
  );
};

export default EditFloorAccess;
