const initialState = {
  id: null,
  data: null
};

const PropertyReducer = (state = initialState, action) => {
  switch (action.type) {
    // case type.SET_PROPERTY:
    //   return {
    //     ...state,
    //     id: Object.keys(action.playload)[0],
    //     data: action.playload[Object.keys(action.playload)[0]]
    //   };
    default:
      return state;
  }
};

export default PropertyReducer;
