import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { orderBy, filter } from "lodash";

// components
import { SidebarNav } from "./components";
import { closeSideBar, openSideBar } from "stores/main/MainActions";

// material-ui
import {
  CreditCard as CreditCardIcon,
  Dashboard as DashboardIcon,
  DriveEta as DriveEtaIcon,
  LibraryBooks as LibraryBooksIcon,
  People as PeopleIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  SettingsRounded as SettingsIcon,
  List as ListIcon,
  Security as InternalIcon,
} from "@material-ui/icons";
import { Badge, Drawer, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// redux
import { toggleNav } from "stores/common/CommonAction";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  drawer: {
    width: "auto",
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = ({ variant, className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  // selector
  const open = useSelector((state) => state.main.sideBar);
  const requests = useSelector((state) => state.firestore.ordered.requests);
  const roles = useSelector((state) => state.main.roles);

  // state
  const shouldOpenSidebar = isDesktop ? true : open;

  useEffect(() => {
    if (isDesktop) dispatch(openSideBar());
    else dispatch(closeSideBar());
  }, [isDesktop, dispatch]);

  var pages = [
    {
      title: "Dashboard",
      href: "/dashboard",
      reference: "dashboard",
      icon: <DashboardIcon />,
      sorting: 0,
    },
  ];

  if (roles) {
    if (roles.access_card?.resident?.read || roles.access_card?.visitor?.read) {
      pages = [
        ...pages,
        {
          title: "Access Card",
          href: "/card",
          reference: "card",
          icon: <CreditCardIcon />,
          sorting: 1,
        },
      ];
    }

    if (roles.vehicle?.resident?.read || roles.vehicle?.visitor?.read) {
      pages = [
        ...pages,
        {
          title: "Vehicle",
          href: "/vehicle",
          reference: "vehicle",
          icon: <DriveEtaIcon />,
          sorting: 2,
        },
      ];
    }

    if (roles.vehicle?.resident?.read || roles.vehicle?.visitor?.read) {
      pages = [
        ...pages,
        {
          title: "Internal",
          href: "/internal",
          reference: "internal",
          icon: <InternalIcon />,
          sorting: 8,
        },
      ];
    }

    if (roles.request?.read) {
      pages = [
        ...pages,
        {
          title: "Request",
          href: "/request",
          reference: "request",
          icon: (
            <Badge
              badgeContent={
                filter(requests, (request) => request.status === "pending")
                  ?.length
              }
              color="primary"
            >
              <PlaylistAddCheckIcon />
            </Badge>
          ),
          sorting: 3,
        },
      ];
    }

    if (
      roles.access_card_settings?.device?.read ||
      roles.access_card_settings?.door_access?.read ||
      roles.access_card_settings?.floor_access?.read ||
      roles.access_card_settings?.general?.read ||
      roles.access_card_settings?.vendor_access?.read ||
      roles.vehicle_settings?.read
    ) {
      let subPages = [];
      if (
        roles.access_card_settings?.device?.read ||
        roles.access_card_settings?.door_access?.read ||
        roles.access_card_settings?.floor_access?.read ||
        roles.access_card_settings?.general?.read ||
        roles.access_card_settings?.vendor_access?.read
      ) {
        subPages = [
          ...subPages,
          {
            title: "Access Card",
            href: "/setting/card",
            reference: "accessCard",
            icon: <CreditCardIcon />,
          },
        ];
      }

      if (roles.vehicle_settings?.read) {
        subPages = [
          ...subPages,
          {
            title: "APNR",
            href: "/setting/apnr",
            reference: "apnr",
            icon: <DriveEtaIcon />,
          },
        ];
      }

      pages = [
        ...pages,
        {
          title: "Settings",
          href: "/setting",
          reference: "setting",
          icon: <SettingsIcon />,
          subPages,
          sorting: 4,
        },
      ];
    }

    if (roles.access_role?.read) {
      pages = [
        ...pages,
        {
          title: "Access Role",
          href: "/role",
          reference: "role",
          icon: <PeopleIcon />,
          sorting: 5,
        },
      ];
    }

    if (roles.transaction?.read) {
      pages = [
        ...pages,
        {
          title: "Transaction",
          href: "/transaction",
          reference: "transaction",
          icon: <ListIcon />,
          sorting: 6,
        },
      ];
    }

    if (roles.report?.transaction?.read) {
      pages = [
        ...pages,
        {
          title: "Report",
          href: "/report",
          reference: "report",
          icon: <LibraryBooksIcon />,
          sorting: 7,
        },
      ];
    }
  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      open={open}
      variant={isDesktop ? "persistent" : "temporary"}
      onClose={() => dispatch(closeSideBar())}
    >
      <div {...rest} className={clsx(classes.root)}>
        <SidebarNav
          className={classes.nav}
          pages={orderBy(pages, ["sorting", ["asc"]])}
        />
      </div>
    </Drawer>
  );
};

export default Sidebar;
