import * as actionTypes from "./ActionType";

const initialState = {
  edit_resident_dialog: { open: false, id: "" },
  edit_visitor_dialog: { open: false, id: "" },
  edit_property_dialog: { open: false, id: "" },
  edit_vehicle_dialog: { open: false, id: "" },
};

function CardReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_EDIT_RESIDENT_DIALOG_OPEN:
      return {
        ...state,
        edit_resident_dialog: {
          ...state.edit_resident_dialog,
          ...action.payload,
        },
      };
    case actionTypes.TOGGLE_EDIT_RESIDENT_DIALOG_CLOSE:
      return {
        ...state,
        edit_resident_dialog: { open: false, id: "" },
      };

    case actionTypes.TOGGLE_EDIT_VEHICLE_DIALOG_OPEN:
      return {
        ...state,
        edit_vehicle_dialog: {
          ...state.edit_vehicle_dialog,
          ...action.payload,
        },
      };
    case actionTypes.TOGGLE_EDIT_VEHICLE_DIALOG_CLOSE:
      return {
        ...state,
        edit_vehicle_dialog: { open: false, id: "" },
      };

    case actionTypes.TOGGLE_EDIT_VISITOR_DIALOG_OPEN:
      return {
        ...state,
        edit_visitor_dialog: {
          ...state.edit_visitor_dialog,
          ...action.payload,
        },
      };
    case actionTypes.TOGGLE_EDIT_VISITOR_DIALOG_CLOSE:
      return {
        ...state,
        edit_visitor_dialog: { open: false, id: "" },
      };

    case actionTypes.TOGGLE_EDIT_PROPERTY_DIALOG_OPEN:
      return {
        ...state,
        edit_property_dialog: {
          ...state.edit_property_dialog,
          ...action.payload,
        },
      };
    case actionTypes.TOGGLE_EDIT_PROPERTY_DIALOG_CLOSE:
      return {
        ...state,
        edit_property_dialog: { open: false, id: "" },
      };

    default:
      return state;
  }
}

export default CardReducer;
