import React from "react";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";

// firebase
import { isLoaded } from "react-redux-firebase";

// material-ui
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  invalidCell: {
    color: red[500],
  },
}));

const GuardTransactionTable = () => {
  const classes = useStyles();

  // selector
  const transactions = useSelector(
    (state) => state.firestore.ordered.transactions
  );

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Block & Unit</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Identity No.</TableCell>
                  <TableCell>Door</TableCell>
                  <TableCell>Event</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoaded(transactions) ? (
                  <TableRow>
                    <TableCell colSpan={5}>Loading...</TableCell>
                  </TableRow>
                ) : (
                  transactions.map((transaction) => (
                    <TableRow key={transaction.id}>
                      <TableCell>
                        <Box>
                          <Typography component="p" variant="caption">
                            {moment(transaction.created_at.toDate()).format(
                              "YYYY-MM-DD"
                            )}
                          </Typography>
                          <Typography component="p" variant="h6">
                            {moment(transaction.created_at.toDate()).format(
                              "h:mm:ss a"
                            )}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Typography component="p" variant="caption">
                            {transaction.block_name}
                          </Typography>
                          <Typography component="p" variant="h6">
                            {transaction.unit_name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography component="p" variant="h6">
                          {transaction.profile_name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Box>
                          {transaction.confidence && (
                            <Typography component="p" variant="caption">
                              {`Confidence: ${parseFloat(
                                transaction.confidence
                              ).toFixed(2)}`}
                            </Typography>
                          )}
                          <Typography component="p" variant="h6">
                            {transaction.identity_number}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography component="p" variant="h6">
                          {transaction.door_name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          component="p"
                          variant="h6"
                          className={
                            transaction.message?.toLowerCase() === "unknown"
                              ? classes.invalidCell
                              : null
                          }
                        >
                          {transaction.message}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

export default GuardTransactionTable;
