import React from "react";

// material-ui
import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles(theme => ({
  root: {},
  skeleton: {
    margin: theme.spacing(1),
    padding: theme.spacing(1)
  }
}));

const VisitorSkeleton = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <React.Fragment>
      <Grid container>
        <Grid item container md={3} justify="center">
          <Skeleton
            variant="rect"
            className={classes.skeleton}
            width={theme.spacing(34)}
            height={theme.spacing(20)}
          />
        </Grid>
        <Grid item container md={3} justify="center">
          <Skeleton
            variant="rect"
            className={classes.skeleton}
            width={theme.spacing(34)}
            height={theme.spacing(20)}
          />
        </Grid>
        <Grid item container md={3} justify="center">
          <Skeleton
            variant="rect"
            className={classes.skeleton}
            width={theme.spacing(34)}
            height={theme.spacing(20)}
          />
        </Grid>
        <Grid item container md={3} justify="center">
          <Skeleton
            variant="rect"
            className={classes.skeleton}
            width={theme.spacing(34)}
            height={theme.spacing(20)}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item container md={3} justify="center">
          <Skeleton
            variant="rect"
            className={classes.skeleton}
            width={theme.spacing(34)}
            height={theme.spacing(20)}
          />
        </Grid>
        <Grid item container md={3} justify="center">
          <Skeleton
            variant="rect"
            className={classes.skeleton}
            width={theme.spacing(34)}
            height={theme.spacing(20)}
          />
        </Grid>
        <Grid item container md={3} justify="center">
          <Skeleton
            variant="rect"
            className={classes.skeleton}
            width={theme.spacing(34)}
            height={theme.spacing(20)}
          />
        </Grid>
        <Grid item container md={3} justify="center">
          <Skeleton
            variant="rect"
            className={classes.skeleton}
            width={theme.spacing(34)}
            height={theme.spacing(20)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default VisitorSkeleton;
