import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { filter, includes, orderBy, map } from "lodash";
import { CSVLink } from "react-csv";
// components (global)
import DashboardButton from "components/Button/DashboardButton";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
// components (local)
import { useFirestore } from "react-redux-firebase";

import { closeDrawer } from "stores/main/MainActions";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  Grid,
  Paper,
  LinearProgress,
  MenuItem,
} from "@material-ui/core";
import Icon from "@mui/material/Icon";
import { makeStyles } from "@material-ui/core/styles";
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Sorting,
  FilterRow,
  Selection,
  Toolbar,
  Item,
  Paging,
  Scrolling,
  Pager,
} from "devextreme-react/data-grid";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  link: {
    marginRight: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));
const ExportUser = ({ dashboardButton }) => {
  const classes = useStyles();

  // selector
  const userSelector = useSelector(
    (state) => state.firestore.ordered.ping_server
  );
  const firestore = useFirestore();

  const [anchorEl, setAnchorEl] = useState(null);

  useFirestoreConnect(() => [
    {
      collection: `ac_pings`,

      limit: 500,
      storeAs: "ping_server",
    },
  ]);
  // stateå
  const [open, setOpen] = useState(false);
  const userData = useMemo(() => {
    let flatResult = [];
    if (userSelector) {
      return map(userSelector, (trail) => {
        return {
          ...trail,
          property_name: trail.property_name,
          description: trail.description,
          status: trail.status,
          server_ip_address: trail.server_ip_address,
          updated_date: trail.updated_at
            ? moment.unix(trail.updated_at?.seconds).format("DD/MM/YYYY")
            : moment.unix(trail.updated_at?.seconds).format("DD/MM/YYYY"),
          updated_time: trail.updated_at
            ? moment.unix(trail.updated_at?.seconds).format("h:mm:ss a")
            : moment.unix(trail.updated_at?.seconds).format("h:mm:ss a"),
        };
      });
    }
    // return orderBy(
    //   flatResult,
    //   ["created_at", "created_time"],
    //   ["desc", "desc"]
    // );
  }, [userSelector]);
  // firestore

  const handleClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // functions
  const handleClickOpen = () => {
    if (!open) setOpen(true);
  };

  return (
    <React.Fragment>
      <Paper>
        <DataGrid
          columnAutoWidth={false}
          dataSource={userData}
          // onRowDblClick={onRowDblClick}

          showColumnLines={false}
        >
          <FilterRow visible={true} />

          <Column caption="Property" dataField="property_name" />
          <Column caption="Description" dataField="description" />
          <Column caption="Status" dataField="status" />
          <Column caption="Server IP" dataField="server_ip_address" />
          <Column caption="Last Ping Date" dataField="updated_date" />
          <Column caption="Last Ping Time" dataField="updated_time" />

          <Paging defaultPageSize={10} />

          <Pager
            visible={true}
            displayMode="full"
            showPageSizeSelector={false}
            showInfo={true}
            showNavigationButtons={true}
          />
        </DataGrid>
      </Paper>
    </React.Fragment>
  );
};

export default ExportUser;
