import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

// components (global)
import ButtonProgress from "components/ButtonProgress";

// components (local)
import EntryPass from "./EntryPass";
import Falco from "./Falco";
import MicroEngine from "./MicroEngine";
import Soyal from "./Soyal";

// firebase
import { useFirestore } from "react-redux-firebase";

// form
import { useForm } from "react-hook-form";

// material-ui
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles(theme => ({
  root: {}
}));

const DoorSettings = ({ vendor, vendorId }) => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const firestore = useFirestore();
  const {
    clearError,
    errors,
    handleSubmit,
    register,
    unregister,
    setValue
  } = useForm();
  // state
  const [submitting, setSubmitting] = useState(false);

  // selector
  const auth = useSelector(state => state.firebase.auth);
  const profile = useSelector(state => state.firebase.profile);

  // functions
  const generateEntryPassData = value => {
    let data = vendor.door_settings;

    if (data) {
      // Visitor Lane
      data[0] = {
        ...vendor.door_settings[0],
        entry: {
          door_name: value.visitorEntry
        },
        exit: {
          door_name: value.visitorExit
        }
      };

      // Motor Lane
      data[1] = {
        ...vendor.door_settings[1],
        entry: {
          door_name: value.motorEntry
        },
        exit: {
          door_name: value.motorExit
        }
      };

      // Pedestrian Lane
      data[2] = {
        ...vendor.door_settings[2],
        entry: {
          door_name: value.pedestrianEntry
        },
        exit: {
          door_name: value.pedestrianEntry
        }
      };
    } else {
      data = [
        {
          abbreviation: "visitor_lane",
          entry: {
            door_name: value.visitorEntry
          },
          exit: {
            door_name: value.visitorExit
          }
        },
        {
          abbreviation: "motor_lane",
          entry: {
            door_name: value.motorEntry
          },
          exit: {
            door_name: value.motorExit
          }
        },
        {
          abbreviation: "pedestrian_lane",
          entry: {
            door_name: value.pedestrianEntry
          },
          exit: {
            door_name: value.pedestrianEntry
          }
        }
      ];
    }

    return data;
  };

  const generateFalcoData = value => {
    let data = vendor.door_settings;

    if (data) {
      // Visitor Lane
      data[0] = {
        ...vendor.door_settings[0],
        entry: {
          ip_address: value.visitorEntryIpAddress
        },
        exit: {
          ip_address: value.visitorExitIpAddress
        }
      };

      // Motor Lane
      data[1] = {
        ...vendor.door_settings[1],
        entry: {
          ip_address: value.motorEntryIpAddress
        },
        exit: {
          ip_address: value.motorExitIpAddress
        }
      };

      // Pedestrian Lane
      data[2] = {
        ...vendor.door_settings[2],
        entry: {
          ip_address: value.pedestrianEntryIpAddress
        },
        exit: {
          ip_address: value.pedestrianExitIpAddress
        }
      };
    } else {
      data = [
        {
          abbreviation: "visitor_lane",
          entry: {
            ip_address: value.visitorEntryIpAddress
          },
          exit: {
            ip_address: value.visitorExitIpAddress
          }
        },
        {
          abbreviation: "motor_lane",
          entry: {
            ip_address: value.motorEntryIpAddress
          },
          exit: {
            ip_address: value.motorExitIpAddress
          }
        },
        {
          abbreviation: "pedestrian_lane",
          entry: {
            ip_address: value.pedestrianEntryIpAddress
          },
          exit: {
            ip_address: value.pedestrianExitIpAddress
          }
        }
      ];
    }

    return data;
  };

  const generateMicroEngineData = value => {
    let data = vendor.door_settings;

    if (data) {
      // Visitor Lane
      data[0] = {
        ...vendor.door_settings[0],
        entry: {
          door_name: value.visitorEntry
        },
        exit: {
          door_name: value.visitorExit
        }
      };

      // Motor Lane
      data[1] = {
        ...vendor.door_settings[1],
        entry: {
          door_name: value.motorEntry
        },
        exit: {
          door_name: value.motorExit
        }
      };

      // Pedestrian Lane
      data[2] = {
        ...vendor.door_settings[2],
        entry: {
          door_name: value.pedestrianEntry
        },
        exit: {
          door_name: value.pedestrianEntry
        }
      };
    } else {
      data = [
        {
          abbreviation: "visitor_lane",
          entry: {
            door_name: value.visitorEntry
          },
          exit: {
            door_name: value.visitorExit
          }
        },
        {
          abbreviation: "motor_lane",
          entry: {
            door_name: value.motorEntry
          },
          exit: {
            door_name: value.motorExit
          }
        },
        {
          abbreviation: "pedestrian_lane",
          entry: {
            door_name: value.pedestrianEntry
          },
          exit: {
            door_name: value.pedestrianEntry
          }
        }
      ];
    }

    return data;
  };

  const generateSoyalData = value => {
    let data = vendor.door_settings;

    if (data) {
      // Visitor Lane
      data[0] = {
        ...vendor.door_settings[0],
        entry: {
          device_id: value.visitorEntryId,
          device_sorting: parseInt(value.visitorEntrySortingId),
          device_type: parseInt(value.visitorEntryTypeId)
        },
        exit: {
          device_id: value.visitorExitId,
          device_sorting: parseInt(value.visitorExitSortingId),
          device_type: parseInt(value.visitorExitTypeId)
        }
      };

      // Motor Lane
      data[1] = {
        ...vendor.door_settings[1],
        enable: value.enableMotor,
        entry: {
          device_id: value.enableMotor ? value.motorEntryId : "",
          device_sorting: value.enableMotor
            ? parseInt(value.motorEntrySortingId)
            : 0,
          device_type: value.enableMotor ? parseInt(value.motorEntryTypeId) : 0
        },
        exit: {
          device_id: value.enableMotor ? value.motorExitId : "",
          device_sorting: value.enableMotor
            ? parseInt(value.motorExitSortingId)
            : 0,
          device_type: value.enableMotor ? parseInt(value.motorExitTypeId) : 0
        }
      };

      // Pedestrian Lane
      data[2] = {
        ...vendor.door_settings[2],
        enable: value.enablePedestrian,
        entry: {
          device_id: value.enablePedestrian ? value.pedestrianEntryId : "",
          device_sorting: value.enablePedestrian
            ? parseInt(value.pedestrianEntrySortingId)
            : 0,
          device_type: value.enablePedestrian
            ? parseInt(value.pedestrianEntryTypeId)
            : 0
        },
        exit: {
          device_id: value.enablePedestrian ? value.pedestrianExitId : "",
          device_sorting: value.enablePedestrian
            ? parseInt(value.pedestrianExitSortingId)
            : 0,
          device_type: value.enablePedestrian
            ? parseInt(value.pedestrianExitTypeId)
            : 0
        }
      };
    } else {
      data = [
        {
          abbreviation: "visitor_lane",
          enable: true,
          entry: {
            device_id: value.visitorEntryId,
            device_sorting: parseInt(value.visitorEntrySortingId),
            device_type: parseInt(value.visitorEntryTypeId)
          },
          exit: {
            device_id: value.visitorExitId,
            device_sorting: parseInt(value.visitorExitSortingId),
            device_type: parseInt(value.visitorExitTypeId)
          }
        },
        {
          abbreviation: "motor_lane",
          enable: value.enableMotor,
          entry: {
            device_id: value.motorEntryId ? value.motorEntryId : "",
            device_sorting: value.motorEntrySortingId
              ? parseInt(value.motorEntrySortingId)
              : 0,
            device_type: value.motorEntryTypeId
              ? parseInt(value.motorEntryTypeId)
              : 0
          },
          exit: {
            device_id: value.motorExitId ? value.motorExitId : "",
            device_sorting: value.motorExitSortingId
              ? parseInt(value.motorExitSortingId)
              : 0,
            device_type: value.motorExitTypeId
              ? parseInt(value.motorExitTypeId)
              : 0
          }
        },
        {
          abbreviation: "pedestrian_lane",
          enable: value.enablePedestrian,
          entry: {
            device_id: value.pedestrianEntryId ? value.pedestrianEntryId : "",
            device_sorting: value.pedestrianEntrySortingId
              ? parseInt(value.pedestrianEntrySortingId)
              : 0,
            device_type: value.pedestrianEntryTypeId
              ? parseInt(value.pedestrianEntryTypeId)
              : 0
          },
          exit: {
            device_id: value.pedestrianExitId ? value.pedestrianExitId : "",
            device_sorting: value.pedestrianExitSortingId
              ? parseInt(value.pedestrianExitSortingId)
              : 0,
            device_type: value.pedestrianExitTypeId
              ? parseInt(value.pedestrianExitTypeId)
              : 0
          }
        }
      ];
    }

    return data;
  };

  const onSubmit = value => {
    if (!submitting) {
      setSubmitting(true);

      let data = [];

      switch (vendor.access_card_settings.type.toLowerCase()) {
        case "entrypass":
          data = generateEntryPassData(value);
          break;
        case "falco":
          data = generateFalcoData(value);
          break;
        case "microengine":
          data = generateMicroEngineData(value);
          break;
        case "soyal":
          data = generateSoyalData(value);
          break;
        default:
          data = [];
          break;
      }
      // return;
      firestore
        .update(
          { collection: "ac_vendors", doc: vendorId },
          {
            door_settings: data,
            updated_at: new Date(),
            updated_by: {
              user_id: auth.uid,
              name: profile.name
            }
          }
        )
        .then(() => {
          setSubmitting(false);
          snackbar.enqueueSnackbar("Door Settings had been updated", {
            variant: "success"
          });
        })
        .catch(e => {
          setSubmitting(false);
          snackbar.enqueueSnackbar(e.message, {
            variant: "error",
            persist: false
          });
        });
    }
  };

  return (
    <Card className={classes.card}>
      <form
        noValidate
        xs={12}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <CardHeader
          title="Door Settings"
          subheader="This section is for guard app testing purposes"
        />
        <Divider />
        <CardContent>
          {vendor.access_card_settings.type.toLowerCase() === "entrypass" && (
            <EntryPass
              errors={errors}
              register={register}
              settings={vendor.door_settings}
            />
          )}

          {vendor.access_card_settings.type.toLowerCase() === "falco" && (
            <Falco
              errors={errors}
              register={register}
              settings={vendor.door_settings}
            />
          )}

          {vendor.access_card_settings.type.toLowerCase() === "microengine" && (
            <MicroEngine
              errors={errors}
              register={register}
              settings={vendor.door_settings}
            />
          )}

          {vendor.access_card_settings.type.toLowerCase() === "soyal" && (
            <Soyal
              clearError={clearError}
              errors={errors}
              register={register}
              unregister={unregister}
              settings={vendor.door_settings}
              setValue={setValue}
            />
          )}
        </CardContent>

        <Divider />

        <CardActions>
          <ButtonProgress
            margin={false}
            type="submit"
            loading={submitting}
            containName="Save"
            disabled={submitting}
            onClick={handleSubmit(onSubmit)}
          />
        </CardActions>
      </form>
    </Card>
  );
};

export default DoorSettings;
