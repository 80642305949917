import React from "react";
import { useSelector } from "react-redux";

// components (global)
import { Page } from "components/Pages";
import { CustomTabs } from "components/Tabs";

// components (local)
import { GeneralTab, VendorAccessTab } from "./components";

// material-ui
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  content: {},
  divider: {
    marginBottom: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  type: {
    marginBottom: theme.spacing(6),
  },
}));

const ApnrSettings = () => {
  const classes = useStyles();

  // variable
  let tabs = [
    {
      value: "general",
      label: "General",
      content: <GeneralTab />,
    },
  ];

  // selector
  const selectedVendor = useSelector((state) => state.main.selectedApnrVendor);
  const vendor = useSelector((state) => {
    return state.firestore.data.apnrVendors
      ? state.firestore.data.apnrVendors[selectedVendor]
      : null;
  });

  if (vendor && vendor.apnr_settings) {
    tabs.push({
      value: "vehicle_group",
      label: `${vendor.apnr_settings.type} Access`,
      content: <VendorAccessTab />,
    });
  }

  return (
    <Page className={classes.root} title="Setting">
      <Container maxWidth={false}>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justify="space-between"
          className={classes.header}
        >
          <Grid item>
            <Typography component="h2" gutterBottom variant="overline">
              Settings
            </Typography>
            <Typography component="h1" variant="h3">
              APNR
            </Typography>
          </Grid>
        </Grid>

        <CustomTabs tabs={tabs} defaultTabIndex={0} />
      </Container>
    </Page>
  );
};

export default ApnrSettings;
