import React from "react";
import {
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
// components (local)
import { VehicleGroupTable } from "./components";

import { AddVehicleGroupDialog } from "./components";

const VehicleGroupTab = () => {
  return (
    <div>
      <Grid container direction="row" alignItems="flex-start" spacing={2}>
        <Grid item container justify="space-between">
          <Grid item>
            <Typography component="h2" gutterBottom variant="overline">
              Vehicle Group
            </Typography>
            <Typography component="h1" variant="h3">
              Manage all vehicle group
            </Typography>
          </Grid>

          <AddVehicleGroupDialog showAddButton={true} />
        </Grid>
      </Grid>

      <VehicleGroupTable />
    </div>
  );
};

export default VehicleGroupTab;
