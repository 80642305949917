import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { orderBy, parseInt, find } from "lodash";
import moment from "moment";

// components (global)
import { FilterCell } from "components/Table/CustomCell";

// devexpress
import { Getter } from "@devexpress/dx-react-core";
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
  EditingState,
} from "@devexpress/dx-react-grid";
import {
  Grid as GridTable,
  PagingPanel,
  Table,
  TableEditRow,
  TableEditColumn,
  TableFilterRow,
  TableHeaderRow,
  TableFixedColumns,
} from "@devexpress/dx-react-grid-material-ui";

// firebase
import { useFirestore } from "react-redux-firebase";

// material-ui
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from "@material-ui/icons";
import { IconButton, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(2) },
}));

const MicroEngineDataTable = () => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const firestore = useFirestore();

  // variable
  const columns = [
    {
      name: "display_name",
      title: "Display Name",
    },
    {
      name: "name",
      title: "Name",
    },
    {
      name: "description",
      title: "Description",
    },
    {
      name: "door_group_id",
      title: "Door Group Id",
    },
    { name: "created_at", title: "Uploaded Date" },
  ];

  const columnExtensions = [
    {
      columnName: "menuButton",
      filteringEnabled: false,
      sortingEnabled: false,
      groupingEnabled: false,
      align: "center",
      width: 200,
    },
  ];

  // selector
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const blockAccessSelector = useSelector(
    (state) => state.firestore.ordered.blockAccess ?? []
  );
  const accessGroupSelector = useSelector(
    (state) => state.firestore.data.accessGroups ?? []
  );

  // memo
  const accessGroups = useMemo(() => {
    let flatResult = [];
    if (accessGroupSelector) {
      flatResult = Object.keys(accessGroupSelector).map((key) => {
        const group = accessGroupSelector[key];
        const assignedBlock = find(blockAccessSelector, (o) => {
          return (
            o.resident?.access_groups?.id === key ||
            o.visitor?.access_groups?.id === key
          );
        });

        return !!group
          ? {
              ...group,
              id: key,
              editable: !!!assignedBlock,
              created_at: moment
                .unix(group.created_at?.seconds)
                .format("DD/MM/YYYY h:mm a"),
            }
          : {};
      });
    }
    return orderBy(flatResult, ["name"], ["asc"]);
  }, [accessGroupSelector, blockAccessSelector]);

  // functions
  const AddButton = ({ onExecute }) => (
    <IconButton onClick={onExecute} size="small" title="Add new row">
      <AddIcon />
    </IconButton>
  );

  const EditButton = ({ onExecute }) => (
    <IconButton onClick={onExecute} size="small" title="Edit row">
      <EditIcon />
    </IconButton>
  );

  const DeleteButton = ({ onExecute }) => (
    <IconButton
      onClick={() => {
        // eslint-disable-next-line
        if (
          window.confirm("Are you sure you want to delete this door group?")
        ) {
          onExecute();
        }
      }}
      title="Delete row"
    >
      <DeleteIcon />
    </IconButton>
  );

  const CommitButton = ({ onExecute }) => (
    <IconButton onClick={onExecute} size="small" title="Save changes">
      <SaveIcon />
    </IconButton>
  );

  const CancelButton = ({ onExecute }) => (
    <IconButton
      color="secondary"
      onClick={onExecute}
      size="small"
      title="Cancel changes"
    >
      <CancelIcon />
    </IconButton>
  );

  const commandComponents = {
    add: AddButton,
    edit: EditButton,
    delete: DeleteButton,
    commit: CommitButton,
    cancel: CancelButton,
  };

  const Command = ({ id, onExecute }) => {
    const CommandButton = commandComponents[id];
    return <CommandButton onExecute={onExecute} />;
  };

  const EditRowCell = (props) => {
    const { column } = props;

    if (column.name !== "created_at") {
      const newProps = {
        ...props,
        editingenabled: props.editingEnabled.toString(),
      };

      delete newProps.editingEnabled;

      return <TableEditRow.Cell {...newProps} />;
    } else {
      const newProps = {
        ...props,
        editingenabled: props.editingEnabled.toString(),
      };

      delete newProps.editingEnabled;
      delete newProps.onValueChange;

      return <Table.Cell {...newProps} />;
    }
  };

  const EditColumnCell = (props) => {
    const { row } = props;
    const newProps = {
      ...props,
    };

    if (!!row.editable !== true && !!row.id) {
      newProps.children = [];
    }

    return <TableEditColumn.Cell {...newProps} />;
  };

  const acGroupRef = firestore
    .collection("ac_vendors")
    .doc(selectedVendor)
    .collection("access_groups");

  const commitChanges = async ({ added, changed }) => {
    // added
    if (!!added && added.length > 0) {
      const data = added[0];
      await handleAddAccessGroup(data);
    }

    // update
    if (!!changed) {
      const docId = Object.keys(changed)[0];
      const changedValue = changed[docId];

      await handleUpdateAccessGroup(docId, changedValue);
    }
  };

  const handleAddAccessGroup = async (data) => {
    if (Object.keys(data).length === 0) {
      snackbar.enqueueSnackbar(
        "Data not saved. you have submitted error records.",
        {
          variant: "error",
          persist: false,
        }
      );
      return;
    }

    try {
      await acGroupRef.add({
        created_at: new Date(),
        created_by: {
          user_id: auth.uid,
          name: profile.name,
        },
        ...data,
      });

      snackbar.enqueueSnackbar("New Access Group had been added", {
        variant: "success",
      });
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, {
        variant: "error",
        persist: false,
      });
    }
  };

  const handleUpdateAccessGroup = async (docId, changedValue) => {
    try {
      if (!!changedValue.door_group_id) {
        changedValue.door_group_id = parseInt(changedValue.door_group_id);
      }

      await acGroupRef.doc(docId).update({
        ...changedValue,
      });

      snackbar.enqueueSnackbar("Access Group had been updated", {
        variant: "success",
      });
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, {
        variant: "error",
        persist: false,
      });
    }
  };

  if (!accessGroups) return null;

  return (
    <div className={classes.root}>
      <Paper>
        <GridTable
          getRowId={(row) => row.id}
          rows={accessGroups}
          columns={columns}
        >
          <FilteringState
            defaultFilters={[]}
            columnExtensions={columnExtensions}
          />
          <IntegratedFiltering />

          <SortingState defaultSorting={[]} />
          <IntegratedSorting />

          <PagingState defaultCurrentPage={0} defaultPageSize={5} />
          <IntegratedPaging />

          <EditingState onCommitChanges={commitChanges} />

          <Table columnExtensions={columnExtensions} />
          <TableHeaderRow showSortingControls={true} />

          <TableFilterRow cellComponent={FilterCell} />

          <TableEditRow cellComponent={EditRowCell} />
          <TableEditColumn
            showAddCommand
            showEditCommand
            commandComponent={Command}
            cellComponent={EditColumnCell}
          />

          <PagingPanel pageSizes={[5, 10, 15, 0]} />

          <Getter
            name="tableColumns"
            computed={({ tableColumns }) => {
              //   debugger;
              const result = [
                ...tableColumns.filter(
                  (c) => c.type !== TableEditColumn.COLUMN_TYPE
                ),
                {
                  key: "editCommand",
                  type: TableEditColumn.COLUMN_TYPE,
                  width: 100,
                },
              ];
              return result;
            }}
          />

          <TableFixedColumns
            leftColumns={["display_name"]}
            rightColumns={[TableEditColumn.COLUMN_TYPE]}
          />
        </GridTable>
      </Paper>
    </div>
  );
};

export default MicroEngineDataTable;
