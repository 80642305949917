import React, { useState } from "react";
import { useSelector } from "react-redux";

// components (global)
import DashboardButton from "components/Button/DashboardButton";

// components (local)
import EntryPassFloorAccessDialog from "./EntryPassFloorAccessDialog";
import FalcoDoorAccessDialog from "./FalcoFloorAccessDialog";
import SoyalFloorAccessDialog from "./SoyalFloorAccessDialog";
import MicroEngineFloorAccessDialog from "./MicroEngineFloorAccessDialog";
import Icon from "@mui/material/Icon";
// material-ui
import { Button } from "@material-ui/core";
import { TouchApp as DoorIcon } from "@material-ui/icons";

const AddFloorAccess = ({ dashboardButton }) => {
  // selector
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const accessCardSettingSelector = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor] &&
        state.firestore.data.vendors[selectedVendor].access_card_settings
        ? state.firestore.data.vendors[selectedVendor].access_card_settings
        : null
      : null;
  });

  // stateå
  const [open, setOpen] = useState(false);
  const [vendorType] = useState(
    accessCardSettingSelector
      ? accessCardSettingSelector.type.toLowerCase()
      : null
  );

  // functions
  const handleClickOpen = () => {
    if (!open) setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {dashboardButton ? (
        <DashboardButton
          icon={<DoorIcon />}
          onClick={handleClickOpen}
          title="Add Floor Access"
        />
      ) : (
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          add floor access
        </Button>
      )}

      {vendorType === "entrypass" && (
        <EntryPassFloorAccessDialog open={open} handleClose={handleClose} />
      )}

      {vendorType === "falco" && (
        <FalcoDoorAccessDialog open={open} handleClose={handleClose} />
      )}

      {vendorType === "soyal" && (
        <SoyalFloorAccessDialog open={open} handleClose={handleClose} />
      )}

      {vendorType === "microengine" && (
        <MicroEngineFloorAccessDialog open={open} handleClose={handleClose} />
      )}
    </React.Fragment>
  );
};

export default AddFloorAccess;
