import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

// material-ui
import { Edit as EditIcon, MoreVert as MoreVertIcon } from "@material-ui/icons";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// stores
import { toggleEditVisitPurposeActionDialogOpen } from "stores/setting/SettingAction";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    outline: "none",
    marginBottom: theme.spacing(2),
  },
  content: {
    paddingTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const PurposeItem = ({
  purpose,
  userId,
  index,
  title,
  onOpen,
  className,
  style,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const moreRef = useRef(null);

  // variable
  const actionTypes = {
    no_action: "No Action",
    auto_open_barrier: "Auto Open Gate",
    permission_open_gate: "Permission Open Gate",
    assign_card: "Assign Card",
    assign_qr_code: "Assign QR Code",
  };
  // state
  const [openMenu, setOpenMenu] = useState(false);

  // functions
  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleEdit = () => {
    handleMenuClose();
    dispatch(
      toggleEditVisitPurposeActionDialogOpen({
        open: true,
        params: { userId, index },
      })
    );
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        action={
          <React.Fragment>
            <Tooltip title="More options">
              <IconButton onClick={handleMenuOpen} ref={moreRef} size="small">
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={moreRef.current}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              elevation={1}
              onClose={handleMenuClose}
              open={openMenu}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={handleEdit}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary="Edit" />
              </MenuItem>
            </Menu>
          </React.Fragment>
        }
        subheader={purpose.name.default}
        subheaderTypographyProps={{ variant: "overline" }}
        title={title}
        titleTypographyProps={{ variant: "h5", gutterBottom: true }}
      />

      <Divider />

      <CardContent className={classes.content}>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <Typography color="inherit" variant="h6">
              Entry
            </Typography>
          </Grid>
          <Grid item md={3}>
            Action
          </Grid>
          <Grid item md={9}>
            {actionTypes[purpose.entry.action]}
          </Grid>
          <Grid item md={3}>
            Door Name
          </Grid>
          <Grid item md={9}>
            {purpose.entry.door_name}
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container spacing={1}>
          <Grid item md={12}>
            <Typography color="inherit" variant="h6">
              Exit
            </Typography>
          </Grid>
          <Grid item md={3}>
            Action
          </Grid>
          <Grid item md={9}>
            {actionTypes[purpose.exit.action]}
          </Grid>
          <Grid item md={3}>
            Door Name
          </Grid>
          <Grid item md={9}>
            {purpose.exit.door_name}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PurposeItem;
