import React from "react";
import { Dialog, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

function DialogWrapper({ open, children, size = "md", handleClose, ...rest }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      scroll="paper"
      maxWidth={size}
      fullScreen={fullScreen}
      fullWidth={true}
      open={open}
      aria-labelledby="dialog-component"
      onClose={handleClose}
      {...rest}
    >
      {children}
    </Dialog>
  );
}

export default DialogWrapper;
