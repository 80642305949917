import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { find, orderBy, pick } from "lodash";
import moment from "moment";

// components (global)
import ButtonProgress from "components/ButtonProgress";
import CusomtDatePick from "components/DateTime/CustomDatePick";
import DialogWrapper from "components/Dialogs/DialogWrapper";
import DialogTitleWrapper from "components/Dialogs/DialogTitleWrapper";
import UnitAutocomplete from "components/CustomSelect/UnitAutocomplete";

// components (local)
import AccessGroup from "../../../AccessGroup";
import AccessLevel from "../../../AccessLevel";
import LiftAccess from "../../../LiftAccess";

// firebase
import { useFirestore } from "react-redux-firebase";

// form
import { useForm, FormContext } from "react-hook-form";

// helpers
import { residentCardValidate } from "helpers/validation/residentCardValidate";
import { Capitalized } from "helpers/textHelpers";

// material-ui
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { OverlayWithText } from "components/Box";
import addAnimation from "components/lotties/update";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  accessLevel: {
    marginTop: "18px",
  },
  liftAccess: {
    marginTop: "18px",
  },
  accessGroup: {
    marginTop: "18px",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const ManualAddDialog = ({ open, handleDialogClose }) => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const firestore = useFirestore();

  // form
  const formMethods = useForm();
  const watchUnit = formMethods.watch("unit");

  // selector
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const cards = useSelector((state) => state.firestore.ordered.resident_cards);

  const selectedPropertyId = useSelector(
    (state) => state.main.selectedProperty
  );
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const accessCardSettings = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor].access_card_settings
      : null;
  });
  const unitAccessSelector = useSelector(
    (state) => state.firestore.data.unit_access
  );
  const accessLevelsSelector = useSelector((state) => {
    return state.firestore.ordered?.accessLevels
      ? orderBy(state.firestore.ordered.accessLevels, ["name"])
      : [];
  });
  const floorAccessLevelsSelector = useSelector((state) => {
    return state.firestore.ordered?.liftAccess
      ? orderBy(state.firestore.ordered.liftAccess, ["name"])
      : [];
  });
  const accessGroupSelector = useSelector((state) => {
    return state.firestore.ordered?.accessGroups
      ? orderBy(state.firestore.ordered.accessGroups, ["name"])
      : [];
  });

  // state
  const [selectedAccessLevelId, setSelectedAccessLevelId] = useState("");
  const [selectedFloorAccessLevelId, setSelectedFloorAccessLevelId] =
    useState("");
  const [selectedAccessGroup, setSelectedAccessGroup] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [unitAccess, setUnitAccess] = useState(null);
  const [status, setStatus] = useState(true);
  const [nonExpired, setNonExpired] = useState(true);
  const [antiPassback, setAntiPassback] = useState(false);
  const [isLift, setIsLift] = useState(false);
  const [cardTypes] = useState([
    { id: "rfid", name: "RFID" },
    { id: "mifare", name: "Mifare" },
    { id: "proximity", name: "Proximity" },
  ]);

  const watchNonExpired = formMethods.watch("nonExpired", nonExpired);

  // effect
  useEffect(() => {
    if (watchUnit) {
      setUnitAccess(unitAccessSelector?.[[watchUnit.id]]?.resident ?? null);
    } else {
      setUnitAccess(null);
    }
  }, [unitAccessSelector, watchUnit]);

  useEffect(() => {
    if (unitAccess?.access_level?.id) {
      setSelectedAccessLevelId(unitAccess.access_level.id);
    }
    if (unitAccess?.lift_access?.id) {
      setSelectedFloorAccessLevelId(unitAccess.lift_access.id);
    }
  }, [unitAccess]);

  // functions
  const handleClose = () => {
    if (!submitting) {
      handleDialogClose();
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.checked);
  };

  const handleNonExpiredChange = (event) => {
    setNonExpired(event.target.checked);
  };

  const handleAntiPassbackChange = (event) => {
    setAntiPassback(event.target.checked);
  };

  const handleIsLiftChange = (event) => {
    setIsLift(event.target.checked);
  };

  const handleAccessGroupChange = (e, value) => {
    setSelectedAccessGroup(value);
  };
  const onSubmit = (value) => {
    if (!submitting) {
      setSubmitting(true);

      if (!accessCardSettings) {
        setSubmitting(false);
        return;
      }

      if (!!!unitAccess && !!!selectedAccessLevelId) {
        setSubmitting(false);

        snackbar.enqueueSnackbar(`Please select access level or access group`, {
          variant: "error",
        });

        return;
      }

      if (
        accessCardSettings.manual_assign_access_group &&
        selectedAccessGroup.length < 1
      ) {
        setSubmitting(false);

        snackbar.enqueueSnackbar(`Please select access group`, {
          variant: "error",
        });

        return;
      }

      let access = {};
      if (value.unit) {
        const unitAccess = unitAccessSelector
          ? unitAccessSelector[value.unit.id]
          : null;

        if (unitAccess) {
          access = {
            ...access,
            access_groups: unitAccess.resident.access_groups,
          };

          if (isLift) {
            access = {
              ...access,
              lift_access: unitAccess.resident.lift_access
                ? unitAccess.resident.lift_access
                : accessCardSettings.type.toLowerCase() === "falco"
                ? "00"
                : null,
            };
          } else {
            access = {
              ...access,
              lift_access:
                accessCardSettings.type.toLowerCase() === "falco" ? "00" : null,
            };
          }

          if (
            accessCardSettings.type.toLowerCase() === "entrypass" ||
            accessCardSettings.type.toLowerCase() === "falco"
          ) {
            access = {
              ...access,
              access_level: unitAccess.resident.access_level
                ? unitAccess.resident.access_level
                : null,
            };
          }

          // if user select different access level than default
          if (
            accessCardSettings.type.toLowerCase() === "entrypass" &&
            ((!!accessCardSettings?.manual_assign_access_level &&
              !!selectedAccessLevelId) ||
              (!!accessCardSettings?.manual_assign_floor_access_level &&
                isLift &&
                !!selectedFloorAccessLevelId) ||
              (!!accessCardSettings?.manual_assign_access_group &&
                !!selectedAccessGroup))
          ) {
            const foundAccess = find(accessLevelsSelector, {
              id: selectedAccessLevelId,
            });

            if (foundAccess) {
              access = {
                ...access,
                access_level: pick(foundAccess, ["id", "name", "number"]),
              };
            }

            // if found manual assignlift access
            const foundLiftAccess = find(floorAccessLevelsSelector, {
              id: selectedFloorAccessLevelId,
            });
            if (foundLiftAccess) {
              access = {
                ...access,
                lift_access: pick(foundLiftAccess, ["id", "name", "number"]),
              };
            }
            if (selectedAccessGroup) {
              access = {
                ...access,
                access_groups: selectedAccessGroup,
              };
            }
          }
        } else {
          access = {
            access_groups: null,
            lift_access: null,
          };

          if (
            accessCardSettings.type.toLowerCase() === "entrypass" ||
            accessCardSettings.type.toLowerCase() === "falco"
          ) {
            access = {
              ...access,
              access_level: null,
            };
          }
        }
      } else {
        access = {
          access_groups: null,
          lift_access: null,
        };

        if (
          accessCardSettings.type.toLowerCase() === "entrypass" ||
          accessCardSettings.type.toLowerCase() === "falco"
        ) {
          access = {
            ...access,
            access_level: null,
          };
        }
      }

      // Handle end date
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth();
      var day = date.getDate();

      const createdDate = new Date();

      let data = {
        access,
        action: "add",
        attempted: 0,
        anti_passback: value.antiPassback,
        blocked_settings: {
          count: 0,
          enable: false,
        },
        card_type: value.cardType.toLowerCase(),
        created_at: createdDate,
        created_by: {
          user_id: auth.uid,
          name: profile.name,
        },
        contact_number: value.contactNo,
        end_date: new Date(year + 10, month, day),
        expired: {
          date: !value.nonExpired
            ? new Date(
                value.expiredDate.getFullYear(),
                value.expiredDate.getMonth(),
                value.expiredDate.getDate()
              )
            : null,
          enable: !value.nonExpired,
        },
        identity_number: value.identityNumber,
        is_lift: value.isLift,
        is_sync: false,
        parking_lot_no: value.parkingLot,
        profile_code: value.profileCode,
        profile_name: value.profileName,
        profile_type: "resident",
        property_id: selectedPropertyId,
        status: value.status ? "active" : "inactive",
        sticker_serial_no: value.stickerNo,
        vehicle_info: {
          colour: value.vehicleColour,
          make: value.vehicleMake,
          model: value.vehicleModel,
          number: value.vehicleNo,
        },
        vendor: {
          id: selectedVendor,
          type: accessCardSettings.type,
        },
      };

      if (value.unit) {
        data = {
          ...data,
          unit: {
            block_id: value.unit.block_id,
            block_name: value.unit.block_name,
            unit_id: value.unit.id,
            unit_name: value.unit.house_number,
          },
        };
      }

      const doc = firestore.collection("ac_profiles").doc();
      doc.set(data).then(() => {
        firestore
          .collection("ac_audit_trails")
          .add({
            action: "Card Added",
            created_at: createdDate,
            created_by: {
              user_id: auth.uid,
              name: profile.name,
            },
            description: `${Capitalized(
              profile.name
            )} had added resident card ${value.identityNumber}`,
            module: "access_card",
            profile: {
              id: doc.id,
              identity_number: value.identityNumber,
            },
            values: data,
          })
          .then(() => {
            setSubmitting(false);
            handleClose();
            snackbar.enqueueSnackbar(
              `Card No. ${value.identityNumber} had been added successfully`,
              {
                variant: "success",
              }
            );
          })
          .catch((e) => {
            setSubmitting(false);
            handleClose();
            snackbar.enqueueSnackbar(e.message, {
              variant: "error",
              persist: false,
            });
          });
      });
    }
  };

  const cardIdTF = (
    <TextField
      size="small"
      id="profileCode"
      name="profileCode"
      label="Card ID *"
      type="text"
      variant="outlined"
      autoComplete="off"
      inputRef={formMethods.register(residentCardValidate.profileCode.rules)}
      helperText={
        formMethods.errors.profileCode &&
        residentCardValidate.profileCode.message[
          formMethods.errors.profileCode.type
        ]
      }
      error={!!formMethods.errors.profileCode}
      fullWidth
    />
  );

  const cardNoTF = (
    <TextField
      size="small"
      id="identityNumber"
      name="identityNumber"
      label="Card No. *"
      type="number"
      variant="outlined"
      autoComplete="off"
      onChange={(event) => {
        const found = find(
          cards,
          (card) =>
            card.identity_number === event.target.value &&
            card.status !== "deleted"
        );

        if (found) {
          formMethods.setError("identityNumber", "exists");
        } else {
          formMethods.clearError("identityNumber");
        }
      }}
      onBlur={(event) => {
        const found = find(
          cards,
          (card) =>
            card.identity_number === event.target.value &&
            card.status !== "deleted"
        );

        if (found) {
          formMethods.setError("identityNumber", "exists");
        } else {
          formMethods.clearError("identityNumber");
        }
      }}
      inputRef={formMethods.register(residentCardValidate.identityNumber.rules)}
      helperText={
        formMethods.errors.identityNumber &&
        residentCardValidate.identityNumber.message[
          formMethods.errors.identityNumber.type
        ]
      }
      error={!!formMethods.errors.identityNumber}
      fullWidth
    />
  );

  const nameTF = (
    <TextField
      size="small"
      id="profileName"
      name="profileName"
      label="Name *"
      type="text"
      variant="outlined"
      autoComplete="off"
      inputRef={formMethods.register(residentCardValidate.profileName.rules)}
      helperText={
        formMethods.errors.profileName &&
        residentCardValidate.profileName.message[
          formMethods.errors.profileName.type
        ]
      }
      error={!!formMethods.errors.profileName}
      fullWidth
    />
  );

  const cardTypeAC = (
    <Autocomplete
      autoHighlight
      options={cardTypes}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          error={!!formMethods.errors.cardType}
          helperText={
            formMethods.errors.cardType &&
            residentCardValidate.cardType.message[
              formMethods.errors.cardType.type
            ]
          }
          {...params}
          label="Choose a type *"
          fullWidth
          size="small"
          name="cardType"
          autoComplete="off"
          variant="outlined"
          inputRef={formMethods.register(residentCardValidate.cardType.rules)}
        />
      )}
    />
  );

  const nonExpiredCB = (
    <FormControlLabel
      control={
        <Checkbox
          name="nonExpired"
          checked={nonExpired}
          onChange={handleNonExpiredChange}
          color="primary"
          inputRef={formMethods.register}
        />
      }
      label="Non Expired"
    />
  );

  const expiredDatePicker = (
    <CusomtDatePick
      disabled={watchNonExpired}
      label="Expired Date"
      name="expiredDate"
      defaultValue={moment(new Date()).add(1, "days").toDate()}
      minDate={moment(new Date()).add(1, "days").toDate()}
    />
  );

  const cardSettingGroup = (
    <>
      <Grid item md={4}>
        <FormControlLabel
          control={
            <Checkbox
              name="status"
              checked={status}
              onChange={handleStatusChange}
              color="primary"
              inputRef={formMethods.register}
            />
          }
          label="Active"
        />
      </Grid>

      <Grid item md={4}>
        <FormControlLabel
          control={
            <Checkbox
              name="antiPassback"
              checked={antiPassback}
              onChange={handleAntiPassbackChange}
              color="primary"
              inputRef={formMethods.register}
            />
          }
          label="Anti-passback"
        />
      </Grid>

      <Grid item md={4}>
        <FormControlLabel
          control={
            <Checkbox
              name="isLift"
              checked={isLift}
              onChange={handleIsLiftChange}
              color="primary"
              inputRef={formMethods.register}
            />
          }
          label="Is Lift"
        />
      </Grid>
    </>
  );

  const leftGrid = (
    <Grid
      item
      container
      direction="row"
      spacing={2}
      md={7}
      justify="center"
      alignItems="flex-start"
    >
      <Grid item md={12}>
        <Typography variant="h4" component="h1">
          Card Details
        </Typography>
      </Grid>

      <Grid item md={12}>
        <UnitAutocomplete required={true} />
      </Grid>

      <Grid item md={6}>
        {cardIdTF}
      </Grid>

      <Grid item md={6}>
        {cardNoTF}
      </Grid>

      <Grid item md={12}>
        {nameTF}
      </Grid>

      <Grid item md={12}>
        {cardTypeAC}
      </Grid>

      <Grid item md={4}>
        {nonExpiredCB}
      </Grid>

      <Grid item md={8}>
        {expiredDatePicker}
      </Grid>

      {cardSettingGroup}
    </Grid>
  );

  const contactNoTF = (
    <TextField
      size="small"
      id="contactNo"
      name="contactNo"
      label="Contact No."
      type="text"
      inputRef={formMethods.register}
      autoComplete="off"
      fullWidth
      variant="outlined"
    />
  );

  const vehicleNoTF = (
    <TextField
      size="small"
      id="vehicleNo"
      name="vehicleNo"
      label="Vehicle No."
      type="text"
      inputRef={formMethods.register}
      autoComplete="off"
      fullWidth
      variant="outlined"
    />
  );

  const vehicleMakeTF = (
    <TextField
      size="small"
      id="vehicleMake"
      name="vehicleMake"
      label="Vehicle Make"
      type="text"
      inputRef={formMethods.register}
      autoComplete="off"
      fullWidth
      variant="outlined"
    />
  );

  const vehicleModelTF = (
    <TextField
      id="vehicleModel"
      name="vehicleModel"
      label="Vehicle Model"
      type="text"
      variant="outlined"
      autoComplete="off"
      size="small"
      inputRef={formMethods.register}
      fullWidth
    />
  );

  const vehicleColorTF = (
    <TextField
      id="vehicleColour"
      name="vehicleColour"
      label="Vehicle Colour"
      type="text"
      variant="outlined"
      size="small"
      inputRef={formMethods.register}
      autoComplete="off"
      fullWidth
    />
  );

  const parkingLotTF = (
    <TextField
      id="parkingLot"
      name="parkingLot"
      label="Parking Lot No."
      type="text"
      variant="outlined"
      size="small"
      inputRef={formMethods.register}
      autoComplete="off"
      fullWidth
    />
  );

  const stickerNoTF = (
    <TextField
      id="stickerNo"
      name="stickerNo"
      label="Sticker No."
      type="text"
      variant="outlined"
      size="small"
      inputRef={formMethods.register}
      autoComplete="off"
      fullWidth
    />
  );

  const rightGrid = (
    <Grid
      item
      container
      direction="row"
      spacing={2}
      md={5}
      justify="center"
      alignItems="flex-start"
    >
      <Grid item md={12}>
        <Typography variant="h4" component="h1">
          Other Details
        </Typography>
      </Grid>
      <Grid item container md={12}>
        {contactNoTF}
      </Grid>
      <Grid item md={12}>
        {vehicleNoTF}
      </Grid>
      <Grid item md={12}>
        {vehicleMakeTF}
      </Grid>
      <Grid item md={12}>
        {vehicleModelTF}
      </Grid>
      <Grid item md={12}>
        {vehicleColorTF}
      </Grid>
      <Grid item md={12}>
        {parkingLotTF}
      </Grid>
      <Grid item md={12}>
        {stickerNoTF}
      </Grid>
    </Grid>
  );

  const accessLevelAC = (
    <Grid item xs={4}>
      <Autocomplete
        autoComplete={true}
        className={classes.accessLevel}
        onChange={(e, value) => {
          setSelectedAccessLevelId(value?.id ?? "");
        }}
        options={accessLevelsSelector}
        getOptionLabel={(option) =>
          option.name ?? find(accessLevelsSelector, { id: option })?.name ?? ""
        }
        getOptionSelected={(option, value) => value === option.id}
        value={selectedAccessLevelId}
        renderInput={(params) => (
          <TextField
            error={!!formMethods.errors.accessLevel}
            helperText={
              !!formMethods.errors.accessLevel && `Access Level required`
            }
            {...params}
            fullWidth
            label="Choose Access Level"
            name="accessLevel"
            size="small"
            inputRef={formMethods.register({ required: true })}
          />
        )}
      />
    </Grid>
  );
  const accessGroupAC = (
    <Grid item xs={4}>
      <Autocomplete
        multiple
        autoComplete={true}
        className={classes.accessGroup}
        value={selectedAccessGroup}
        onChange={handleAccessGroupChange}
        options={accessGroupSelector}
        getOptionLabel={(option) =>
          option.name ?? find(accessGroupSelector, { id: option })?.name ?? ""
        }
        disableCloseOnSelect
        defaultValue={selectedAccessGroup}
        getOptionSelected={(option, value) => option === value}
        renderInput={(params) => (
          <TextField
            error={!!formMethods.errors.accessGroup}
            helperText={
              !!formMethods.errors.accessGroup && `Access Group required`
            }
            {...params}
            fullWidth
            label="Choose Access Group"
            name="accessGroup"
            size="small"
            inputRef={formMethods.register({
              validate: selectedAccessGroup.length >= 1,
            })}
          />
        )}
      />
    </Grid>
  );
  const flooraccessLevelAC = (
    <Grid item xs={4}>
      <Autocomplete
        autoComplete={true}
        className={classes.liftAccess}
        onChange={(e, value) => {
          setSelectedFloorAccessLevelId(value?.id ?? "");
        }}
        options={floorAccessLevelsSelector}
        getOptionLabel={(option) =>
          option.name ??
          find(floorAccessLevelsSelector, { id: option })?.name ??
          ""
        }
        getOptionSelected={(option, value) => value === option.id}
        value={selectedFloorAccessLevelId}
        renderInput={(params) => (
          <TextField
            error={!!formMethods.errors.flooraccessLevel}
            helperText={
              !!formMethods.errors.flooraccessLevel && `Lift Access required`
            }
            {...params}
            fullWidth
            label="Choose Lift Access"
            name="liftAccess"
            size="small"
            inputRef={formMethods.register({ required: false })}
          />
        )}
      />
    </Grid>
  );

  const bottomGrid = (
    <Grid
      container
      spacing={2}
      direction="row"
      justify="space-between"
      alignItems="flex-start"
    >
      {accessCardSettings?.type?.toLowerCase() === "entrypass" ? (
        accessCardSettings?.manual_assign_access_group ? (
          accessGroupAC
        ) : (
          <AccessGroup accessGroups={unitAccess?.access_group} />
        )
      ) : null}

      {accessCardSettings?.type?.toLowerCase() === "entrypass" ? (
        accessCardSettings?.manual_assign_access_level ? (
          accessLevelAC
        ) : (
          <AccessLevel accessLevel={unitAccess?.access_level} />
        )
      ) : null}

      {accessCardSettings?.type?.toLowerCase() === "entrypass" ? (
        accessCardSettings?.manual_assign_floor_access_level ? (
          flooraccessLevelAC
        ) : (
          <LiftAccess isLift={isLift} liftAccess={unitAccess?.lift_access} />
        )
      ) : null}
    </Grid>
  );

  return (
    <React.Fragment>
      <FormContext {...formMethods}>
        <form
          noValidate
          xs={12}
          onSubmit={formMethods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <DialogWrapper size="md" open={open}>
            <DialogTitleWrapper
              title="Add Resident Card"
              handleClose={handleClose}
            />

            <DialogContent dividers>
              <Grid
                container
                spacing={2}
                direction="row"
                justify="center"
                alignItems="flex-start"
              >
                {leftGrid}
                {rightGrid}
              </Grid>
              <Divider className={classes.divider} />
              {bottomGrid}
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={handleClose}>
                Cancel
              </Button>
              <ButtonProgress
                type="submit"
                containName="Save"
                margin={false}
                loading={submitting}
                // disabled={submitting}
                disabled={
                  Object.keys(formMethods.errors).length > 0 || submitting
                }
                onClick={formMethods.handleSubmit(onSubmit)}
              />
            </DialogActions>
            <OverlayWithText
              loadingText="Adding New Card"
              open={submitting}
              animationData={addAnimation}
              height={500}
              width={500}
            />
          </DialogWrapper>
        </form>
      </FormContext>
    </React.Fragment>
  );
};

export default ManualAddDialog;
