import React, { useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { filter, forEach, includes, cloneDeep } from "lodash";

//components
import { ButtonProgress } from "components/Button";
import { DialogWrapper } from "components/Dialogs";
import { Capitalized } from "helpers/textHelpers";
import { OverlayWithText } from "components/Box";
import deleteAnimation from "components/lotties/delete";

//material-ui
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";

function BatchDeleteDialog({ cardIdList, handleClose }) {
  const firestore = useFirestore();
  const snackbar = useSnackbar();

  const [submitting, setSubmitting] = useState(false);

  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);

  const cardSelector = useSelector(
    (state) => state.firestore.data.resident_cards ?? []
  );

  const handleBatchDeactivate = async () => {
    setSubmitting(true);

    const batches = [firestore.batch()];
    const profileRef = firestore.collection("ac_profiles");
    const auditRef = firestore.collection("ac_audit_trails");

    const writeLimit = 200;
    let operationCount = 0;
    const cardArr = Object.keys(cardSelector).map((key) => ({
      id: key,
      ...cardSelector[key],
    }));

    try {
      const filteredCards = filter(cardArr, (card) =>
        includes(cardIdList, card.id)
      );

      forEach(filteredCards, (card) => {
        console.log(card);
        const oldValue = cloneDeep(card);
        if (!!card && card.status !== "deleted") {
          batches[batches.length - 1].update(profileRef.doc(card.id), {
            status: "deleted",
            updated_at: firestore.FieldValue.serverTimestamp(),
            action: "delete",
            deleted_at: firestore.FieldValue.serverTimestamp(),
            deleted_by: {
              user_id: auth.uid,
              name: profile.name,
            },
            attempted: 0,
            is_sync: false,
          });
          batches[batches.length - 1].set(auditRef.doc(), {
            action: "Card Deleted",
            created_at: firestore.FieldValue.serverTimestamp(),
            created_by: {
              user_id: auth.uid,
              name: profile.name,
            },
            description: `${Capitalized(profile.name)} deleted resident card ${
              card.identity_number
            }`,
            module: "access_card",
            profile: {
              id: card.id,
              identity_number: card.identity_number,
            },
            values: oldValue,
          });
        }

        batches.push(firestore.batch());
      });

      await Promise.all(batches.map((batch) => batch.commit()));

      snackbar.enqueueSnackbar("Cards deleted successfully", {
        variant: "success",
      });

      handleClose();
    } catch (error) {
      setSubmitting(false);
      snackbar.enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <DialogWrapper open={true} size="md">
      <DialogTitle>
        <b>CardStatus Update</b>
      </DialogTitle>
      <DialogContent style={{ height: "100px" }}>
        <Typography variant="body1">
          Delete selected Cards? Action cannot be undone.
        </Typography>
        <OverlayWithText
          loadingText="Deleting Card"
          open={submitting}
          animationData={deleteAnimation}
          height={150}
          width={200}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
          variant="contained"
        >
          Cancel
        </Button>
        <ButtonProgress
          margin={false}
          type="submit"
          onClick={handleBatchDeactivate}
          loading={submitting}
          containName={"OK"}
        />
      </DialogActions>
    </DialogWrapper>
  );
}

export default React.memo(BatchDeleteDialog);
