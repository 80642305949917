import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { includes, filter } from "lodash";

// firebase
import { isLoaded, isEmpty } from "react-redux-firebase";

// components (global)
import { LoadingSpinnerFull } from "components";

// helpers
import {
  useGetInitFirestoreData,
  useGetAccessRoleData,
  useGetVendorData,
  useGetAccessData,
  useGetModeratorsData,
  useGetApnrVendorData,
  useGetJcUser,
  useGetAllVendors,
} from "./firebaseHelpers";
import { featureList } from "helpers/roleHelpers";

// layout
import { Main as MainLayout, Minimal as MinimalLayout } from "layouts";

// redux
import { showLoading } from "stores/common/CommonAction";
import {
  setProperties,
  setRoles,
  setCurrentVendor,
  setCurrentApnrVendor,
  setProperty,
} from "stores/main/MainActions";

// views
import {
  AccessRole as AccessRoleView,
  Card as CardView,
  CardSettings as CardSettingsView,
  Dashboard as DashboardView,
  Login as LoginView,
  Report as ReportView,
  ApnrSetting as ApnrSettingView,
  TransactionList as TransactionListView,
  VehicleList as VehicleListView,
  Internal as InternalView,
} from "views";

export function IsAuthenticated() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showLoading(false));
  }, [dispatch]);

  return (
    <MainLayout>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Redirect exact from="/login" to="/dashboard" />
        <Route exact path="/dashboard" component={DashboardView} />
        <Route exact path="/card" component={CardView} />
        <Route exact path="/transaction" component={TransactionListView} />
        <Route exact path="/vehicle" component={VehicleListView} />
        <Route exact path="/setting/card" component={CardSettingsView} />
        <Route exact path="/setting/apnr" component={ApnrSettingView} />
        <Route exact path="/role" component={AccessRoleView} />
        <Route exact path="/report" component={ReportView} />
        <Route exact path="/internal" component={InternalView} />
      </Switch>
    </MainLayout>
  );
}

export function IsNotAuthenticated() {
  const dispatch = useDispatch();

  // effect
  useEffect(() => {
    dispatch(showLoading(false));
  }, [dispatch]);

  return (
    <MinimalLayout>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <Redirect exact from="/dashboard" to="/login" />
        <Route component={LoginView} exact path="/login" />
      </Switch>
    </MinimalLayout>
  );
}

export function Loading() {
  const value = useSelector((state) => state.common.loadingScreen);

  if (value) return <LoadingSpinnerFull />;

  return null;
}

export function AuthIsLoaded({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showLoading(true));
  }, [dispatch]);

  const auth = useSelector((state) => state.firebase.auth);

  if (!isLoaded(auth)) return null;
  if (isLoaded(auth) && isEmpty(auth)) {
    return <IsNotAuthenticated />;
  }

  return children;
}

export function ProfileIsLoaded({ children }) {
  const dispatch = useDispatch();

  // selector
  const profile = useSelector((state) => state.firebase.profile);

  useEffect(() => {
    dispatch(showLoading(true));
  }, [dispatch]);

  if (!isLoaded(profile)) return null;

  if (isLoaded(profile) && isEmpty(profile)) {
    return <IsNotAuthenticated />;
  }

  return children;
}

export function PropertyIsLoaded({ children }) {
  const dispatch = useDispatch();

  // selector
  const profile = useSelector((state) => state.firebase.profile);
  const properties = useSelector((state) => state.firestore.ordered.properties);
  // const defaultPropertyId = useSelector((state) =>
  //   state.firestore.ordered.properties &&
  //   state.firestore.ordered.properties.length > 0
  //     ? state.firestore.ordered.properties[0].id
  //     : ""
  // );
  // if (!localStorage.getItem("property-selected")) {
  //   dispatch(setProperty(defaultPropertyId));
  // }
  // initial property data
  useGetInitFirestoreData();

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  // effect
  useEffect(() => {
    if (profile) {
      setIsSuperAdmin(profile.is_jagacard_super_admin);
    }
  }, [profile]);

  if (!isLoaded(properties)) return null;
  if (isLoaded(properties)) {
    if (!isEmpty(properties)) {
      if (isSuperAdmin) {
        dispatch(setProperties(properties));
      } else {
        const filterProperties = filter(properties, (property) => {
          return includes(profile.properties, property.id);
        });
        console.log(filterProperties);
        dispatch(setProperties(filterProperties));
      }
    } else {
      return <IsNotAuthenticated />;
    }
  }

  return children;
}

export function AccessRoleIsLoaded({ children }) {
  const dispatch = useDispatch();

  // selector
  const profile = useSelector((state) => state.firebase.profile);
  const roles = useSelector((state) => state.firestore.data.roles);
  const selectedProperty = useSelector((state) => state.main.selectedProperty);
  useGetAccessRoleData();

  if (!isLoaded(roles)) return null;

  if (isLoaded(roles)) {
    if (profile.is_jagacard_super_admin) {
      const role = {};

      // Set all role to true
      Object.keys(featureList).forEach((key) => {
        role[key] = {};
        if (!featureList[key].sub_features) {
          Object.keys(featureList[key].actions).forEach((actionKey) => {
            role[key] = {
              ...role[key],
              [actionKey]: true,
            };
          });
        } else {
          Object.keys(featureList[key].sub_features).forEach((subKey) => {
            Object.keys(featureList[key].sub_features[subKey].actions).forEach(
              (actionKey) => {
                role[key] = {
                  ...role[key],
                  [subKey]: {
                    ...role[key][subKey],
                    [actionKey]: true,
                  },
                };
              }
            );
          });
        }
      });
      dispatch(setRoles(role));
    } else {
      if (isEmpty(roles)) {
        return <IsNotAuthenticated />;
      } else {
        console.log(profile);
        const role = roles[profile?.card_access[selectedProperty]?.role_id];
        dispatch(setRoles(role));
      }
    }
  }

  return children;
}

export function InitialDataIsLoaded() {
  const dispatch = useDispatch();
  const vendors = useSelector((state) => state.firestore.ordered.vendors);
  const apnrVendor = useSelector(
    (state) => state.firestore.ordered.apnrVendors
  );

  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const selectedApnrVendor = useSelector(
    (state) => state.main.selectedApnrVendor
  );

  // initial vendor data

  useGetAccessData();
  useGetAllVendors();
  useGetModeratorsData();
  useGetVendorData();
  useGetApnrVendorData();
  useGetJcUser();
  if (!isLoaded(vendors)) {
    return null;
  }

  if (!isLoaded(apnrVendor)) {
    return null;
  }

  if (vendors.length === 0 && selectedVendor !== null) {
    dispatch(setCurrentVendor({ id: null }));
  }

  if (vendors.length > 0 && vendors[0].id !== selectedVendor) {
    dispatch(setCurrentVendor({ id: vendors[0].id }));
  }

  if (apnrVendor.length === 0 && selectedApnrVendor !== null) {
    dispatch(setCurrentApnrVendor({ id: null }));
  }

  if (apnrVendor.length > 0 && apnrVendor[0].id !== selectedApnrVendor) {
    dispatch(setCurrentApnrVendor({ id: apnrVendor[0].id }));
  }

  return <IsAuthenticated />;
}
