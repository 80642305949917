import React, {
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import moment from "moment";
import find from "lodash/find";
import pull from "lodash/pull";
import DateFnsUtils from "@date-io/date-fns";
import saveAs from "file-saver";
import { map } from "lodash";
// devexpress
import {
  Grid as GridTable,
  TableFixedColumns,
  Table,
  TableHeaderRow,
  PagingPanel,
  Toolbar,
  TableFilterRow,
  TableGroupRow,
  GroupingPanel,
  DragDropProvider,
  ExportPanel,
  TableColumnVisibility,
} from "@devexpress/dx-react-grid-material-ui";
import {
  FilteringState,
  PagingState,
  IntegratedPaging,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  GroupingState,
  IntegratedGrouping,
} from "@devexpress/dx-react-grid";
import { Template, TemplatePlaceholder } from "@devexpress/dx-react-core";
import { GridExporter } from "@devexpress/dx-react-grid-export";

// firebase
import { useFirestore } from "react-redux-firebase";

// material-ui
import {
  Menu as MenuIcon,
  RotateLeft as RotateLeftIcon,
} from "@material-ui/icons";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  reportOption: {
    paddingTop: theme.spacing(2),
  },
  reportDetail: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const Transaction = () => {
  const classes = useStyles();
  const exporterRef = useRef(null);
  const firestore = useFirestore();
  const snackbar = useSnackbar();

  // selector
  const selectedProperty = useSelector((state) => state.main.selectedProperty);

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf("day").toDate());
  const [endDate, setEndDate] = useState(new Date());
  const [sorting, setSorting] = useState([]);
  const [grouping, setGrouping] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

  useEffect(() => {
    if (find(grouping, { columnName: "trans_date" })) {
      setHiddenColumnNames((hiddenColumnNames) => {
        return [...pull(hiddenColumnNames, "trans_time")];
      });
    } else {
      setHiddenColumnNames((hiddenColumnNames) => [
        ...hiddenColumnNames,
        "trans_time",
      ]);
    }
  }, [grouping]);

  // memo
  const transactions = useMemo(() => {
    if (transactionData) {
      return map(transactionData, (transaction) => {
        return {
          ...transaction,
          trans_date: moment
            .unix(transaction.transaction_date?.seconds)
            .format("DD/MM/YYYY"),
          trans_time: moment
            .unix(transaction.transaction_date?.seconds)
            .format("h:mm:ss a"),
        };
      });
    }
  }, [transactionData]);

  const columns = [
    {
      name: "trans_date",
      title: "Date",
    },
    {
      name: "trans_time",
      title: "Time",
    },
    {
      name: "block_name",
      title: "Block",
    },
    {
      name: "unit_name",
      title: "Unit",
    },
    {
      name: "profile_name",
      title: "Name",
    },
    {
      name: "identity_number",
      title: "Identity Number",
    },
    {
      name: "door_name",
      title: "Door",
    },
    {
      name: "message",
      title: "Event",
    },
  ];
  const columnExtensions = [
    {
      columnName: "trans_date",
      width: 150,
    },
    {
      columnName: "trans_time",
      groupingEnabled: false,
      width: 150,
    },
    {
      columnName: "block_name",
      width: 200,
    },
    {
      columnName: "unit_name",
      width: 200,
    },
    {
      columnName: "profile_name",
      width: 200,
    },
    {
      columnName: "identity_number",
      width: 150,
    },
    {
      columnName: "door",
      width: 100,
    },
    {
      columnName: "message",
      width: 150,
    },
  ];

  // callback
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  // functions
  const handleStartDateChange = (date) => {
    if (date != null) {
      if (date > endDate) {
        snackbar.enqueueSnackbar(`start date cannot later than end date`, {
          variant: "error",
        });

        return;
      }

      const newDate = moment(date, "DD/MM/YYYY");
      setStartDate(newDate.toDate());
    }
  };

  const handleEndDateChange = (date) => {
    if (date != null) {
      if (startDate > date) {
        snackbar.enqueueSnackbar(`start date cannot later than end date`, {
          variant: "error",
        });

        return;
      }

      const newDate = moment(date, "DD/MM/YYYY");
      setEndDate(newDate.toDate());
    }
  };

  const handleRestSorting = () => {
    setSorting([]);
  };

  const handleOnSearch = async () => {
    setIsLoading(true);

    const transactionSnapshot = await firestore
      .collection("ac_transactions")
      .where("property_id", "==", selectedProperty)
      .where("transaction_date", ">=", startDate)
      .where("transaction_date", "<=", endDate)
      .orderBy("transaction_date", "desc")
      .get();

    setIsLoading(false);

    if (!transactionSnapshot.empty) {
      const data = map(transactionSnapshot.docs, (doc) => {
        const transaction = doc.data();
        return {
          ...transaction,
          id: doc.id,
        };
      });

      setTransactionData(data);
    }
  };

  const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `TransactionReport_${moment(new Date()).format("DDMMYYhhmmss")}.xlsx`
      );
    });
  };

  // components
  const GroupIcon = (props) => {
    if (props.disabled) return null;
    else
      return (
        <IconButton onClick={props.onGroup}>
          <MenuIcon fontSize="small" />
        </IconButton>
      );
  };

  const ToolbarRoot = ({ ...restProps }) => {
    return (
      <Paper
        style={{ marginBottom: "4px" }}
        variant="elevation"
        elevation={1}
        component={Toolbar.Root}
        {...restProps}
      />
    );
  };

  const TableComponentBase = ({ ...restProps }) => {
    const classes = useStyles();
    return <Table.Table {...restProps} className={classes.table} />;
  };

  const GroupCellContent = ({ column, row }) => {
    switch (column.name) {
      case "checkedOut":
        return (
          <span>
            <b>{column.title}</b>: {row.value ? row.value : "Not Check Out"}
          </span>
        );
      default:
        return (
          <span>
            <b>{column.title}</b>: {row.value}
          </span>
        );
    }
  };

  const FilterCell = (props) => {
    const { filteringEnabled, column } = props;
    if (!filteringEnabled) {
      if (column.name === "menuButton")
        return (
          <TableFixedColumns.Cell
            showLeftDivider
            position={0}
            side="right"
            component={(props) => {
              return <TableHeaderRow.Cell {...props} />;
            }}
          />
        );
      else return <TableHeaderRow.Cell />;
    } else {
      if (column.type === "boolean") {
        return (
          <TableHeaderRow.Cell>
            <Select
              defaultValue=""
              id="select"
              fullWidth
              onChange={(e) => props.onFilter({ value: e.target.value })}
            >
              <MenuItem value="">&nbsp;</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </TableHeaderRow.Cell>
        );
      } else return <TableFilterRow.Cell {...props} />;
    }
  };

  return (
    <div>
      <Grid container spacing={2} className={classes.reportOption}>
        <Grid item>
          <Paper>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                inputVariant="outlined"
                fullWidth
                size="small"
                format="dd/MM/yyyy"
                label="Start Date"
                value={startDate}
                clearable={false}
                onChange={handleStartDateChange}
              />
            </MuiPickersUtilsProvider>
          </Paper>
        </Grid>
        <Grid item>
          <Paper>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                inputVariant="outlined"
                fullWidth
                size="small"
                format="dd/MM/yyyy"
                label="End Date"
                value={endDate}
                clearable={false}
                onChange={handleEndDateChange}
              />
            </MuiPickersUtilsProvider>
          </Paper>
        </Grid>
        <Grid item>
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            onClick={handleOnSearch}
          >
            Search
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.reportDetail}>
        <Grid item>
          <Chip
            label={`Transactions Between ${moment(startDate).format(
              "DD/MM/YYYY"
            )} - ${moment(endDate).format("DD/MM/YYYY")}`}
          />
        </Grid>
        <Grid item>
          <Chip label={`Total Transaction ${transactions.length}`} />
        </Grid>
      </Grid>

      <Paper>
        {isLoading && <LinearProgress />}
        <GridTable
          getRowId={(row) => row.id}
          rows={transactions}
          columns={columns}
        >
          <DataTypeProvider
            formatterComponent={({ value, row }) => (
              <React.Fragment>
                <Box>
                  <Typography component="p" variant="caption">
                    {row.trans_date}
                  </Typography>

                  <Typography component="p" variant="h6">
                    {row.trans_time}
                  </Typography>
                </Box>
              </React.Fragment>
            )}
            for={["trans_date"]}
          />

          <DataTypeProvider
            formatterComponent={({ value, row }) => (
              <Typography component="p">{row.trans_time}</Typography>
            )}
            for={["trans_time"]}
          />

          <DataTypeProvider
            formatterComponent={({ value, row }) => (
              <React.Fragment>
                <Box width={200}>
                  <Typography component="p" variant="h6">
                    {row.block_name ? row.block_name : null}
                  </Typography>
                </Box>
              </React.Fragment>
            )}
            for={["block_name"]}
          />

          <DataTypeProvider
            formatterComponent={({ value, row }) => (
              <React.Fragment>
                <Box width={200}>
                  <Typography component="p" variant="h6">
                    {row.unit_name ? row.unit_name : null}
                  </Typography>
                </Box>
              </React.Fragment>
            )}
            for={["unit_name"]}
          />

          <DataTypeProvider
            formatterComponent={({ value, row }) => (
              <React.Fragment>
                <Box width={400}>
                  <Typography component="p" variant="h6">
                    {row.profile_name}
                  </Typography>
                </Box>
              </React.Fragment>
            )}
            for={["profile_name"]}
          />

          <DataTypeProvider
            formatterComponent={({ value, row }) => (
              <React.Fragment>
                <Box width={200}>
                  {row.confidence && (
                    <Typography component="p" variant="caption">
                      {`Confidence: ${parseFloat(row.confidence).toFixed(2)}`}
                    </Typography>
                  )}
                  <Typography component="p" variant="h6">
                    {row.identity_number}
                  </Typography>
                </Box>
              </React.Fragment>
            )}
            for={["identity_number"]}
          />

          <PagingState defaultCurrentPage={0} defaultPageSize={5} />
          <IntegratedPaging />

          <FilteringState
            defaultFilters={[]}
            columnExtensions={columnExtensions}
          />
          <IntegratedFiltering />

          <SortingState
            sorting={sorting}
            onSortingChange={setSorting}
            columnExtensions={columnExtensions}
          />
          <IntegratedSorting />

          <GroupingState
            grouping={grouping}
            onGroupingChange={setGrouping}
            columnExtensions={columnExtensions}
          />
          <IntegratedGrouping />

          <DragDropProvider />

          <Table
            tableComponent={TableComponentBase}
            columnExtensions={columnExtensions}
          />
          <TableColumnVisibility
            hiddenColumnNames={hiddenColumnNames}
            onHiddenColumnNamesChange={setHiddenColumnNames}
          />
          <TableHeaderRow
            showSortingControls
            showGroupingControls
            groupButtonComponent={GroupIcon}
          />

          <TableGroupRow contentComponent={GroupCellContent} />
          <TableFilterRow cellComponent={FilterCell} />
          <Toolbar rootComponent={ToolbarRoot} />
          <ExportPanel startExport={startExport} />
          <PagingPanel pageSizes={[5, 10, 15, 0]} />
          <GroupingPanel showSortingControls showGroupingControls />

          <TableFixedColumns />

          <Template name="toolbarContent">
            <TemplatePlaceholder />
            {sorting.length > 0 && (
              <IconButton
                style={{ margin: "0px 8px" }}
                variant="contained"
                color="default"
                onClick={handleRestSorting}
              >
                <Tooltip title="Reset Sorting">
                  <RotateLeftIcon fontSize="default" />
                </Tooltip>
              </IconButton>
            )}
          </Template>
          {/* <ColumnChooser /> */}
        </GridTable>

        <GridExporter
          ref={exporterRef}
          rows={transactions}
          columns={columns}
          onSave={onSave}
        />
      </Paper>
    </div>
  );
};

export default React.memo(Transaction);
