export const accessRoleValidate = {
  name: {
    rules: {
      required: true,
    },
    message: {
      required: "Name is Required",
    },
  },
  email: {
    rules: {
      required: true,
      pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
    },
    message: {
      required: "Email is Required",
      pattern: "Invalid Email Format",
    },
  },
  role: {
    rules: {
      required: true,
    },
    message: {
      required: "Access Role is Required",
    },
  },
};
