import React, { useState } from "react";
import { useDispatch } from "react-redux";

// global components
import { SearchInput } from "components";

// components
import { AddVehicleDialog } from "../../components";

// material icons
import MenuIcon from "@material-ui/icons/Menu";

// material-ui
import {
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// redux
import { toggleAddVehicleDialog } from "stores/common/CommonAction";

// styles
const useStyles = makeStyles(theme => ({
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    justify: "space-between",
    marginTop: theme.spacing(1)
  },
  importButton: {
    marginRight: theme.spacing(1)
  }
}));

const VehicleToolbar = ({ onChange }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // states
  const [anchorEl, setAnchorEl] = useState(null);

  // functions
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleAddVehicle() {
    setAnchorEl(null);
    dispatch(toggleAddVehicleDialog());
  }

  return (
    <div>
      <Hidden mdDown>
        <div className={classes.row}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={6} md={8}>
              <SearchInput placeholder="Search vehicle" onChange={onChange} />
            </Grid>
            <Grid container item xs={6} md={4} justify="flex-end">
              <AddVehicleDialog showAddButton={true} />
            </Grid>
          </Grid>
        </div>
      </Hidden>

      <Hidden lgUp>
        <Grid container>
          <Grid item xs={10}>
            <SearchInput placeholder="Search vehicle" />
          </Grid>
          <Grid container item xs={2} justify="center">
            <IconButton
              color="inherit"
              aria-controls="action-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Hidden>

      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem onClick={handleAddVehicle}>Add Vehicle</MenuItem>
      </Menu>
    </div>
  );
};

export default VehicleToolbar;
