import React, { forwardRef, useState } from "react";
import { NavLink as RouterLink, useHistory } from "react-router-dom";
import clsx from "clsx";

// material-ui
import {
  colors,
  Button,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "0px",
    flexDirection: "column",
    color: colors.blueGrey[800],
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(1, 2),
    borderLeft: `10px white solid`,
  },
  icon: {
    color: theme.palette.icon,
    width: "auto",
    height: "auto",
    display: "flex",
    alignItems: "center",
  },
  subIcon: {
    padding: 0,
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& g": {
      stroke: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
    "& path": {
      stroke: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
    borderLeft: `10px ${theme.palette.primary.main} solid`,
  },
  iconActive: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& g": {
      stroke: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
    "& path": {
      stroke: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
    "& span": { color: theme.palette.primary.main },
  },
}));

const CustomRouterLink = forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <RouterLink {...props} />
    </div>
  );
});

const SidebarNavMenu = ({ page }) => {
  const classes = useStyles();
  const { reference, subPages, href, icon, title } = page;
  const {
    location: { pathname },
  } = useHistory();

  // state
  const [anchorEl, setAnchorEl] = useState(null);

  // variable
  const rootPageRef = pathname.split("/")[1];
  const isRootPageActive = reference === rootPageRef;

  // functions
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return subPages ? (
    <div>
      <Button
        className={clsx(classes.button, isRootPageActive ? classes.active : "")}
        onClick={handleClick}
      >
        <Grid item container direction="column" xs={12} alignItems="center">
          <div className={classes.icon}>{icon}</div>
          <div>{title}</div>
        </Grid>
      </Button>
      <Menu
        id="sub-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {subPages.map(({ reference, href, icon, title }) => {
          return (
            <ListItem
              activeClassName={classes.active}
              button
              component={CustomRouterLink}
              key={href}
              to={href}
              onClick={handleClose}
            >
              <ListItemIcon className={classes.subIcon}>{icon}</ListItemIcon>
              <ListItemText primary={title} />
            </ListItem>
          );
        })}
      </Menu>
    </div>
  ) : (
    <Button
      component={CustomRouterLink}
      to={href}
      activeClassName={clsx(classes.button, classes.active)}
      className={classes.button}
    >
      <Grid item container direction="column" xs={12} alignItems="center">
        <div>{icon}</div>
        <div>{title}</div>
      </Grid>
    </Button>
  );
};

export default SidebarNavMenu;
