import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { intersection, map, union } from "lodash";
import axios from "http-axios";

// components (global)
import ButtonProgress from "components/ButtonProgress";
import DialogWrapper from "components/Dialogs/DialogWrapper";
import DialogTitleWrapper from "components/Dialogs/DialogTitleWrapper";

// firebase
import { useFirebase } from "react-redux-firebase";

// form
import { useForm } from "react-hook-form";

// material-ui
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

// stores
import { toggleEditDoorAccessDialogClose } from "stores/setting/SettingAction";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

// functions
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

const EditEntryPassDoorAccessDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const firebase = useFirebase();
  const { handleSubmit, setValue, register } = useForm();

  // selector
  const { open, id } = useSelector(
    (state) => state.setting.edit_door_access_dialog
  );
  const uid = useSelector((state) => state.firebase.auth.uid);
  const accessGroupSelector = useSelector(
    (state) => state.firestore.ordered.accessGroups
  );
  const selectedPropertyId = useSelector(
    (state) => state.main.selectedProperty
  );
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const accessLevels = useSelector(
    (state) => state.firestore.ordered.accessLevels
  );
  const blockAccessSelector = useSelector((state) => {
    return state.firestore.data.blockAccess[id]
      ? state.firestore.data.blockAccess[id]
      : null;
  });
  const accessCardSettingSelector = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor] &&
        state.firestore.data.vendors[selectedVendor].access_card_settings
        ? state.firestore.data.vendors[selectedVendor].access_card_settings
        : null
      : null;
  });
  console.log(id);
  // state
  const [residentChecked, setResidentChecked] = useState([]);
  const [visitorChecked, setVisitorChecked] = useState([]);
  const [residentLeft, setResidentLeft] = React.useState(
    accessGroupSelector
      ? blockAccessSelector.resident
        ? accessGroupSelector.filter(
            ({ id: id1 }) =>
              !blockAccessSelector.resident.access_groups.some(
                ({ id: id2 }) => id2 === id1
              )
          )
        : accessGroupSelector
      : []
  );
  const [residentRight, setResidentRight] = React.useState(
    blockAccessSelector.resident.access_groups
  );
  const [visitorLeft, setVisitorLeft] = React.useState(
    accessGroupSelector
      ? blockAccessSelector.visitor
        ? accessGroupSelector.filter(
            ({ id: id1 }) =>
              !blockAccessSelector.visitor.access_groups.some(
                ({ id: id2 }) => id2 === id1
              )
          )
        : accessGroupSelector
      : []
  );
  const [visitorRight, setVisitorRight] = React.useState(
    blockAccessSelector.visitor ? blockAccessSelector.visitor.access_groups : []
  );
  const [submitting, setSubmitting] = useState(false);

  // effect
  useEffect(() => {
    register("residentAccessLevel");
    register("visitorAccessLevel");
  }, [register]);

  // functions
  const intialState = () => {
    setResidentChecked([]);
    setVisitorChecked([]);
    setResidentRight([]);
    setVisitorRight([]);
  };

  const handleClose = () => {
    if (!submitting) {
      intialState();
      dispatch(toggleEditDoorAccessDialogClose());
    }
  };

  /* Start For Resident */
  const numberOfResidentChecked = (items) =>
    intersection(residentChecked, items).length;
  const residentLeftChecked = intersection(residentChecked, residentLeft);
  const residentRightChecked = intersection(residentChecked, residentRight);

  const handleResidentToggleAll = (items) => () => {
    if (numberOfResidentChecked(items) === items.length) {
      setResidentChecked(not(residentChecked, items));
    } else {
      setResidentChecked(union(residentChecked, items));
    }
  };

  const handleResidentToggle = (value) => () => {
    const currentIndex = residentChecked.indexOf(value);
    const newChecked = [...residentChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setResidentChecked(newChecked);
  };

  const handleResidentCheckedRight = () => {
    setResidentRight(residentRight.concat(residentLeftChecked));
    setResidentLeft(not(residentLeft, residentLeftChecked));
    setResidentChecked(not(residentChecked, residentLeftChecked));
  };

  const handleResidentCheckedLeft = () => {
    setResidentLeft(residentLeft.concat(residentRightChecked));
    setResidentRight(not(residentRight, residentRightChecked));
    setResidentChecked(not(residentChecked, residentRightChecked));
  };

  const residentList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleResidentToggleAll(items)}
            checked={
              numberOfResidentChecked(items) === items.length &&
              items.length !== 0
            }
            indeterminate={
              numberOfResidentChecked(items) !== items.length &&
              numberOfResidentChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfResidentChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `resident-list-all-item-${value.id}-label`;

          return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleResidentToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={residentChecked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
  /* End For Resident */

  /* Start For Visitor */
  const numberOfVisitorChecked = (items) =>
    intersection(visitorChecked, items).length;
  const visitorLeftChecked = intersection(visitorChecked, visitorLeft);
  const visitorRightChecked = intersection(visitorChecked, visitorRight);

  const handleVisitorToggleAll = (items) => () => {
    if (numberOfVisitorChecked(items) === items.length) {
      setVisitorChecked(not(visitorChecked, items));
    } else {
      setVisitorChecked(union(visitorChecked, items));
    }
  };

  const handleVisitorToggle = (value) => () => {
    const currentIndex = visitorChecked.indexOf(value);
    const newChecked = [...visitorChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setVisitorChecked(newChecked);
  };

  const handleVisitorCheckedRight = () => {
    setVisitorRight(visitorRight.concat(visitorLeftChecked));
    setVisitorLeft(not(visitorLeft, visitorLeftChecked));
    setVisitorChecked(not(visitorChecked, visitorLeftChecked));
  };

  const handleVisitorCheckedLeft = () => {
    setVisitorLeft(visitorLeft.concat(visitorRightChecked));
    setVisitorRight(not(visitorRight, visitorRightChecked));
    setVisitorChecked(not(visitorChecked, visitorRightChecked));
  };

  const visitorList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleVisitorToggleAll(items)}
            checked={
              numberOfVisitorChecked(items) === items.length &&
              items.length !== 0
            }
            indeterminate={
              numberOfVisitorChecked(items) !== items.length &&
              numberOfVisitorChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfVisitorChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `visitor-list-all-item-${value.id}-label`;

          return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleVisitorToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={visitorChecked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
  /* End For Resident */

  const onSubmit = (value) => {
    if (!submitting) {
      setSubmitting(true);

      let residentAccessGroup = [];
      let visitorAccessGroup = [];
      let residentAccessLevel = null;
      let visitorAccessLevel = null;

      switch (accessCardSettingSelector.type.toLowerCase()) {
        case "entrypass":
          if (value.residentAccessLevel) {
            // delete value.residentAccessLevel.created_at;
            // delete value.residentAccessLevel.created_by;

            residentAccessLevel = value.residentAccessLevel;
          } else {
            residentAccessLevel = blockAccessSelector?.resident?.access_level;
          }

          map(residentRight, (access) => {
            residentAccessGroup.push({
              description: access.description,
              id: access.id,
              name: access.name,
            });
          });

          if (value.visitorAccessLevel) {
            // delete value.visitorAccessLevel.created_at;
            // delete value.visitorAccessLevel.created_by;

            visitorAccessLevel = value.visitorAccessLevel;
          } else {
            visitorAccessLevel = blockAccessSelector?.visitor?.access_level;
          }

          map(visitorRight, (access) => {
            visitorAccessGroup.push({
              description: access.description,
              id: access.id,
              name: access.name,
            });
          });
          break;
        case "falco":
          map(residentRight, (access) => {
            residentAccessGroup.push({
              description: access.description,
              id: access.id,
              name: access.name,
              timezone: "01",
            });
          });

          residentAccessLevel = residentAccessGroup.length > 0 ? "01" : "00";

          map(visitorRight, (access) => {
            visitorAccessGroup.push({
              description: access.description,
              id: access.id,
              name: access.name,
              timezone: "01",
            });
          });

          visitorAccessLevel = visitorAccessGroup.length > 0 ? "01" : "00";
          break;
        case "soyal":
          let residentAccessGroup = null;
          let visitorAccessGroup = null;
          if (value.residentAccessGroup) {
            delete value.residentAccessGroup.created_at;
            delete value.residentAccessGroup.created_by;

            residentAccessGroup = value.residentAccessGroup;
          } else {
            residentAccessGroup =
              blockAccessSelector?.resident?.residentAccessGroup;
          }

          break;
        default:
          break;
      }

      firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          };

          axios
            .post(
              `jagaCardUpdateDoorAccess`,
              {
                uid,
                property_id: selectedPropertyId,
                block_id: id,
                vendor_id: selectedVendor,
                resident_access_group: residentAccessGroup,
                visitor_access_group: visitorAccessGroup,
                resident_access_level: residentAccessLevel,
                visitor_access_level: visitorAccessLevel,
              },
              {
                headers: headers,
              }
            )
            .then((res) => {
              if (res.data.result === "success") {
                setSubmitting(false);
                handleClose();
                snackbar.enqueueSnackbar(
                  "Door Access had been updated successfully",
                  {
                    variant: "success",
                  }
                );
              }
            })
            .catch((e) => {
              setSubmitting(false);
              handleClose();
              snackbar.enqueueSnackbar(e.message, {
                variant: "error",
                persist: false,
              });
            });
        });
    }
  };

  return (
    <React.Fragment>
      <form
        noValidate
        xs={12}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <DialogWrapper size="md" open={open}>
          <DialogTitleWrapper
            title="Edit Door Access"
            handleClose={handleClose}
          />

          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item container md={12} xs={12}>
                <Grid item md={6} xs={12}>
                  <TextField
                    disabled={true}
                    size="small"
                    margin="dense"
                    id="blockName"
                    name="blockName"
                    label="Block Name"
                    type="text"
                    readOnly={true}
                    autoComplete="off"
                    defaultValue={blockAccessSelector.block_name}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Divider />
              </Grid>

              <Grid item md={12}>
                <Typography className={classes.modalTitle} variant="h4">
                  Resident
                </Typography>
              </Grid>

              {accessCardSettingSelector.type.toLowerCase() === "entrypass" && (
                <Grid item container md={12} spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      autoHighlight
                      options={accessLevels}
                      getOptionLabel={(option) =>
                        option.display_name ? option.display_name : option.name
                      }
                      onChange={(e, value) => {
                        setValue("residentAccessLevel", value ? value : null);
                      }}
                      defaultValue={blockAccessSelector?.resident.access_level}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose an access level *"
                          fullWidth
                          size="small"
                          name="accessLevel"
                          autoComplete="off"
                          variant="outlined"
                          inputRef={register}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid
                item
                container
                md={12}
                xs={12}
                justify="flex-start"
                alignItems="center"
              >
                <Typography className={classes.modalTitle} variant="h6">
                  Please choose the Access Group
                </Typography>
              </Grid>
              <Grid
                item
                container
                md={12}
                xs={12}
                spacing={2}
                justify="center"
                alignItems="center"
              >
                <Grid item md={5} xs={12}>
                  {residentList("Choices", residentLeft)}
                </Grid>
                <Grid item md={2} xs={12}>
                  <Hidden mdUp>
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Button
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          onClick={handleResidentCheckedLeft}
                          disabled={residentRightChecked.length === 0}
                        >
                          <ArrowUpwardIcon fontSize="small" />
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          onClick={handleResidentCheckedRight}
                          disabled={residentLeftChecked.length === 0}
                        >
                          <ArrowDownwardIcon fontSize="small" />
                        </Button>
                      </Grid>
                    </Grid>
                  </Hidden>
                  <Hidden mdDown>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      spacing={1}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleResidentCheckedRight}
                        disabled={residentLeftChecked.length === 0}
                        aria-label="move selected right"
                      >
                        &gt;
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleResidentCheckedLeft}
                        disabled={residentRightChecked.length === 0}
                        aria-label="move selected left"
                      >
                        &lt;
                      </Button>
                    </Grid>
                  </Hidden>
                </Grid>
                <Grid item md={5} xs={12}>
                  {residentList("Chosen", residentRight)}
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Divider />
              </Grid>

              <Grid item md={12}>
                <Typography className={classes.modalTitle} variant="h4">
                  Visitor
                </Typography>
              </Grid>

              {accessCardSettingSelector.type.toLowerCase() === "entrypass" && (
                <Grid item container md={12} spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      autoHighlight
                      options={accessLevels}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) => {
                        setValue("visitorAccessLevel", value ? value : null);
                      }}
                      defaultValue={blockAccessSelector?.visitor.access_level}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose an access level *"
                          fullWidth
                          size="small"
                          name="accessLevel"
                          autoComplete="off"
                          variant="outlined"
                          inputRef={register}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid
                item
                container
                md={12}
                xs={12}
                spacing={2}
                justify="center"
                alignItems="center"
              >
                <Grid item md={5} xs={12}>
                  {visitorList("Choices", visitorLeft)}
                </Grid>
                <Grid item md={2} xs={12}>
                  <Hidden mdUp>
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Button
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          onClick={handleVisitorCheckedLeft}
                          disabled={visitorRightChecked.length === 0}
                        >
                          <ArrowUpwardIcon fontSize="small" />
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          onClick={handleVisitorCheckedRight}
                          disabled={visitorLeftChecked.length === 0}
                        >
                          <ArrowDownwardIcon fontSize="small" />
                        </Button>
                      </Grid>
                    </Grid>
                  </Hidden>
                  <Hidden mdDown>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      spacing={1}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleVisitorCheckedRight}
                        disabled={visitorLeftChecked.length === 0}
                        aria-label="move selected right"
                      >
                        &gt;
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleVisitorCheckedLeft}
                        disabled={visitorRightChecked.length === 0}
                        aria-label="move selected left"
                      >
                        &lt;
                      </Button>
                    </Grid>
                  </Hidden>
                </Grid>
                <Grid item md={5} xs={12}>
                  {visitorList("Chosen", visitorRight)}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <ButtonProgress
              type="submit"
              containName="Save"
              margin={false}
              loading={submitting}
              disabled={submitting}
              onClick={handleSubmit(onSubmit)}
            />
          </DialogActions>
        </DialogWrapper>
      </form>
    </React.Fragment>
  );
};

export default EditEntryPassDoorAccessDialog;
