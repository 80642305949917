import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

// component (glbal)
import { ButtonColor } from "components/Button";
import Label from "components/Label";

//assets
import profilePlaceholder from "assets/images/profile-placeholder.png";

// firebase
import { useFirestore } from "react-redux-firebase";

//helpers
import { getUrlPath } from "helpers";

// material-ui
import {
  EditRounded as EditIcon,
  DeleteRounded as DeleteIcon,
  MoreVert as MoreVertIcon,
  VisibilityRounded as VisibilityIcon
} from "@material-ui/icons";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  colors
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// stores
import { openDrawer } from "stores/main/MainActions";
import { openVehicleEditDialog } from "stores/vehicle/VehicleAction";

// styles
const useStyles = makeStyles(theme => ({
  root: {},
  moreHoriz: {
    marginTop: theme.spacing(2)
  },

  content: {
    display: "flex",
    justifyContent: "space-between"
  },

  imageContainer: {
    height: 150,
    width: 150,
    margin: "0 auto",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "5px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  image: {
    width: "100%"
  },
  actionButton: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    "&:hover": {
      cursor: "pointer"
    }
  },
  divider: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },

  media: {
    height: 240
  }
}));

const VehicleCard = ({
  id,
  identity_number,
  file_name,
  block_name,
  unit_name,
  profile_name,
  status
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const firestore = useFirestore();

  // selector
  const auth = useSelector(state => state.firebase.auth);
  const profile = useSelector(state => state.firebase.profile);

  // state
  const [anchorEl, setAnchorEl] = useState(null);
  const [vehicleImage, setVehicleImage] = useState(profilePlaceholder);
  const [deleteVehicle, setDeleteVehicle] = useState(false);

  // effect
  useEffect(() => {
    if (file_name) {
      getUrlPath("IoT/vehicles", file_name, (url, err) => {
        if (!err) {
          setVehicleImage(url);
        } else {
          setVehicleImage(profilePlaceholder);
        }
      });
    }
  }, [file_name]);

  // functions
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleViewVehicleDetails = () => {
    dispatch(openDrawer({ toggle: "view_resident_vehicle", parameterId: id }));
    handleCloseMenu();
  };

  const handleEditVehicle = () => {
    dispatch(
      openVehicleEditDialog({ open: true, id: id, image: vehicleImage })
    );
    handleCloseMenu();
  };

  const handleDeleteVehicleButton = () => {
    setDeleteVehicle(!deleteVehicle);
  };

  const handleDeleteVehicle = () => {
    handleCloseMenu();
    firestore
      .update(
        { collection: "ac_profiles", doc: id },
        {
          deleted_at: new Date(),
          deleted_by: {
            user_id: auth.uid,
            name: profile.name
          },
          status: "delete"
        }
      )
      .then(() => {
        snackbar.enqueueSnackbar(identity_number + " had been Deleted", {
          variant: "success"
        });
      })
      .catch(e => {
        snackbar.enqueueSnackbar(e.message, {
          variant: "error",
          persist: false
        });
      });

      
  };

  return (
    <Card>
      <CardHeader
        title={identity_number}
        action={
          <React.Fragment>
            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>

            <Menu
              id="enitMenu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <MenuItem onClick={handleViewVehicleDetails}>
                <React.Fragment>
                  <ListItemIcon>
                    <VisibilityIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="View Details" />
                </React.Fragment>
              </MenuItem>

              {status.toLowerCase() !== "delete" && (
                <MenuItem onClick={handleEditVehicle}>
                  <React.Fragment>
                    <ListItemIcon>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Edit Vehicle" />
                  </React.Fragment>
                </MenuItem>
              )}

              {status.toLowerCase() !== "delete" && (
                <MenuItem
                  onClick={!deleteVehicle && handleDeleteVehicleButton}
                  button={!deleteVehicle}
                >
                  {deleteVehicle ? (
                    <React.Fragment>
                      <ButtonColor
                        buttonColor="red"
                        onClick={handleDeleteVehicle}
                        size="small"
                        variant="contained"
                      >
                        Yes
                      </ButtonColor>
                      <Button
                        style={{ marginLeft: "10px" }}
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={handleDeleteVehicleButton}
                      >
                        No
                      </Button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <ListItemIcon onClick={() => {}}>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Delete Vehicle" />
                    </React.Fragment>
                  )}
                </MenuItem>
              )}
            </Menu>
          </React.Fragment>
        }
      />

      <CardMedia className={classes.media} image={vehicleImage} />

      <CardContent className={classes.content}>
        <Grid container direction="column" spacing={1}>
          <Grid item container justify="center" alignItems="center">
            <Grid item md={3}>
              <Typography variant="subtitle2">Name</Typography>
            </Grid>
            <Grid item md={9}>
              <Typography variant="subtitle2">
                {profile_name.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container justify="center" alignItems="center">
            <Grid item md={3}>
              <Typography variant="subtitle2">Block</Typography>
            </Grid>
            <Grid item md={9}>
              <Typography variant="subtitle2">{block_name}</Typography>
            </Grid>
          </Grid>

          <Grid item container justify="center" alignItems="center">
            <Grid item md={3}>
              <Typography variant="subtitle2">Unit</Typography>
            </Grid>
            <Grid item md={9}>
              <Typography variant="subtitle2">{unit_name}</Typography>
            </Grid>
          </Grid>

          <Grid item container justify="center" alignItems="center">
            <Grid item md={3}>
              <Typography variant="subtitle2">Status</Typography>
            </Grid>
            <Grid item md={9}>
              <Label
                color={
                  status === "active" ? colors.green[600] : colors.orange[600]
                }
              >
                {status.toUpperCase()}
              </Label>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default VehicleCard;
