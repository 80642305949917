import React from "react";
import { useSelector } from "react-redux";

// assets
import LandingImgUrl from "assets/img/img-landing.png";
import LandingImgPlaceholderUrl from "assets/img/no-img-placeholder.jpg";

// library
import ProgressiveImage from "react-progressive-bg-image";

// material-ui
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles(theme => ({
  root: {
    height: "140px",
    display: "flex",
    alignItems: "center"
  },
  content: {
    textAlign: "center"
  },
  title: {
    color: "#fff"
  }
}));

const Header = () => {
  const classes = useStyles();

  // selector
  const { name } = useSelector(state => state.property.data);

  return (
    <ProgressiveImage
      src={LandingImgUrl}
      placeholder={LandingImgPlaceholderUrl}
      className={classes.root}
    >
      <Grid container>
        <Grid item xs={12} className={classes.content}>
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            {name}
          </Typography>
        </Grid>
      </Grid>
    </ProgressiveImage>
  );
};

export default Header;
