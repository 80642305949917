export const residentCardValidate = {
  unit: {
    rules: {
      required: true,
    },
    message: {
      required: "Unit is Required",
    },
  },
  profileCode: {
    rules: {
      required: true,
    },
    message: {
      required: "Card ID is Required",
      exists: "Card ID had been exists",
    },
  },
  identityNumber: {
    rules: {
      required: true,
      maxLength: 10,
    },
    message: {
      required: "Card No. is Required",
      maxLength: "Card No. must less or equal than 10",
      exists: "Card No. had been exists",
    },
  },
  profileName: {
    rules: {
      required: true,
    },
    message: { required: "Name is Required" },
  },
  cardType: {
    rules: {
      required: true,
    },
    message: { required: "Card Type is Required" },
  },

  integrationType: {
    rules: {
      required: false,
    },
    message: { required: "Integration Type is Required" },
  },
};
