import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { orderBy } from "lodash";
import moment from "moment";

// components (global)
import { FilterCell } from "components/Table/CustomCell";

// devexpress
import { Getter } from "@devexpress/dx-react-core";
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
  EditingState,
} from "@devexpress/dx-react-grid";
import {
  Grid as GridTable,
  PagingPanel,
  Table,
  TableEditRow,
  TableEditColumn,
  TableFilterRow,
  TableHeaderRow,
  TableFixedColumns,
} from "@devexpress/dx-react-grid-material-ui";

// firebase
import { useFirestore } from "react-redux-firebase";

// material-ui
import {
  Cancel as CancelIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from "@material-ui/icons";
import { IconButton, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(2) },
}));

const DataTable = () => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const firestore = useFirestore();

  // variable
  const columns = [
    {
      name: "display_name",
      title: "Display Name",
    },
    {
      name: "name",
      title: "Name",
    },
    {
      name: "description",
      title: "Description",
    },
    { name: "created_at", title: "Uploaded Date" },
  ];

  const columnExtensions = [
    {
      columnName: "menuButton",
      filteringEnabled: false,
      sortingEnabled: false,
      groupingEnabled: false,
      align: "center",
      width: 200,
    },
  ];

  // selector
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const accessGroupSelector = useSelector(
    (state) => state.firestore.data.accessGroups
  );

  // memo
  const accessGroups = useMemo(() => {
    let flatResult = [];
    if (accessGroupSelector) {
      flatResult = Object.keys(accessGroupSelector).map((key) => {
        const group = accessGroupSelector[key];

        return {
          ...group,
          id: key,
          created_at: moment
            .unix(group.created_at?.seconds)
            .format("DD/MM/YYYY h:mm a"),
        };
      });
    }
    return orderBy(flatResult, ["name"], ["asc"]);
  }, [accessGroupSelector]);

  // functions
  const EditButton = ({ onExecute }) => (
    <IconButton onClick={onExecute} size="small" title="Edit row">
      <EditIcon />
    </IconButton>
  );

  const CommitButton = ({ onExecute }) => (
    <IconButton onClick={onExecute} size="small" title="Save changes">
      <SaveIcon />
    </IconButton>
  );

  const CancelButton = ({ onExecute }) => (
    <IconButton
      color="secondary"
      onClick={onExecute}
      size="small"
      title="Cancel changes"
    >
      <CancelIcon />
    </IconButton>
  );

  const commandComponents = {
    add: "",
    edit: EditButton,
    delete: "",
    commit: CommitButton,
    cancel: CancelButton,
  };

  const Command = ({ id, onExecute }) => {
    const CommandButton = commandComponents[id];
    return <CommandButton onExecute={onExecute} />;
  };

  const EditCell = (props) => {
    const { column } = props;

    if (column.name === "display_name") {
      const newProps = {
        ...props,
        editingenabled: props.editingEnabled.toString(),
      };

      delete newProps.editingEnabled;

      return <TableEditRow.Cell {...newProps} />;
    } else {
      const newProps = {
        ...props,
        editingenabled: props.editingEnabled.toString(),
      };

      delete newProps.editingEnabled;
      delete newProps.onValueChange;

      return <Table.Cell {...newProps} />;
    }
  };

  const commitChanges = ({ changed }) => {
    if (changed) {
      Object.keys(changed).forEach((key) => {
        const value = changed[key];

        if (value) {
          firestore
            .set(
              `ac_vendors/${selectedVendor}/access_groups/${key}`,
              {
                display_name: value.display_name.trim(),
              },
              {
                merge: true,
              }
            )
            .then(() => {
              snackbar.enqueueSnackbar("Access Group had been updated", {
                variant: "success",
              });
            })
            .catch((e) => {
              snackbar.enqueueSnackbar(e.message, {
                variant: "error",
                persist: false,
              });
            });
        }
      });
    }
  };

  if (!accessGroups) return null;

  return (
    <div className={classes.root}>
      <Paper>
        <GridTable
          getRowId={(row) => row.id}
          rows={accessGroups}
          columns={columns}
        >
          <FilteringState
            defaultFilters={[]}
            columnExtensions={columnExtensions}
          />
          <IntegratedFiltering />

          <SortingState defaultSorting={[]} />
          <IntegratedSorting />

          <PagingState defaultCurrentPage={0} defaultPageSize={5} />
          <IntegratedPaging />

          <EditingState onCommitChanges={commitChanges} />

          <Table columnExtensions={columnExtensions} />
          <TableHeaderRow showSortingControls={true} />

          <TableFilterRow cellComponent={FilterCell} />

          <TableEditRow cellComponent={EditCell} />
          <TableEditColumn showEditCommand commandComponent={Command} />

          <PagingPanel pageSizes={[5, 10, 15, 0]} />

          <Getter
            name="tableColumns"
            computed={({ tableColumns }) => {
              //   debugger;
              const result = [
                ...tableColumns.filter(
                  (c) => c.type !== TableEditColumn.COLUMN_TYPE
                ),
                {
                  key: "editCommand",
                  type: TableEditColumn.COLUMN_TYPE,
                  width: 100,
                },
              ];
              return result;
            }}
          />

          <TableFixedColumns
            leftColumns={["display_name"]}
            rightColumns={[TableEditColumn.COLUMN_TYPE]}
          />
        </GridTable>
      </Paper>
    </div>
  );
};

export default DataTable;
