export const OPEN_DRAWER = "OPEN_DRAWER";
export const CLOSE_DRAWER = "CLOSE_DRAWER";
export const OPEN_SIDEBAR = "OPEN_SIDEBAR";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const TOGGLE_SELECT_PROPERTY = "TOGGLE_SELECT_PROPERTY";

export const SET_APNR_VENDOR_ID = "SET_APNR_VENDOR_ID";
export const SET_VENDOR_ID = "SET_VENDOR_ID";
export const PROPERTY_LOADED = "PROPERTY_LOADED";

export const SET_PROPERTIES = "SET_PROPERTIES";
export const SET_PROPERTY = "SET_PROPERTY";
export const SET_ROLES = "SET_ROLES";
