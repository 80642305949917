import React from "react";

import {
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon
} from "@material-ui/icons";
import { Button, IconButton } from "@material-ui/core";

const AddButton = ({ onExecute }) => (
  <div style={{ textAlign: "center" }}>
    <Button color="primary" onClick={onExecute} title="Create new row">
      + New
    </Button>
  </div>
);

const EditButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} size="small" title="Edit row">
    <EditIcon />
  </IconButton>
);

const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} size="small" title="Save changes">
    <SaveIcon />
  </IconButton>
);

const CancelButton = ({ onExecute }) => (
  <IconButton
    color="secondary"
    onClick={onExecute}
    size="small"
    title="Cancel changes"
  >
    <CancelIcon />
  </IconButton>
);

const DeleteButton = ({ onExecute }) => {
  return (
    <IconButton
      onClick={() => {
        if (window.confirm("Are you sure you want to delete this row?")) {
          onExecute();
        }
      }}
      size="small"
      title="Delete row"
    >
      <DeleteIcon />
    </IconButton>
  );
};

const Command = ({ id, onExecute }) => {
  const commandComponents = {
    add: AddButton,
    edit: EditButton,
    delete: DeleteButton,
    commit: CommitButton,
    cancel: CancelButton
  };

  const CommandButton = commandComponents[id];
  return <CommandButton onExecute={onExecute} />;
};

export default Command;
