import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

// firebase
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";

// material-ui
import {
  Avatar,
  Card,
  Grid,
  List,
  ListItemAvatar,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { red, green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import {
  Add as AddIcon,
  Block as BlockIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
  Link as LinkIcon,
  Sync as SyncIcon,
} from "@material-ui/icons";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  tabContent: { padding: theme.spacing(2) },
  title: {
    marginBottom: theme.spacing(1),
    position: "relative",
    "&:after": {
      position: "absolute",
      bottom: -8,
      left: 0,
      content: '" "',
      height: 3,
      width: 48,
      backgroundColor: theme.palette.primary.main,
    },
  },
  card: {
    padding: theme.spacing(1),
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
  },
  iconRed: {
    backgroundColor: red[500],
  },
  iconGreen: {
    backgroundColor: green[500],
  },
}));

const NotiAuditsTab = () => {
  const classes = useStyles();
  const propertyId = useSelector(
    (state) => state.main.toggleDrawer.parameterId
  );
  // selector
  const selectedProperty = useSelector((state) => state.main.selectedProperty);
  const auditTrails = useSelector(
    (state) => state.firestore.ordered.audit_trails
  );

  // firestore
  useFirestoreConnect(() => [
    {
      collection: `ac_audit_trails`,
      where: [
        ["values.property_id", "==", selectedProperty],
        ["values.profile_type", "==", "resident"],
      ],

      orderBy: [["created_at", "desc"]],
      limit: 20,
      storeAs: "audit_trails",
    },
  ]);
  console.log(selectedProperty);
  // state
  const [avatars] = useState({
    added: (
      <Tooltip title="Added">
        <Avatar className={classes.icon}>
          <AddIcon />
        </Avatar>
      </Tooltip>
    ),
    blocked: (
      <Tooltip title="Blocked">
        <Avatar className={classes.iconRed}>
          <BlockIcon />
        </Avatar>
      </Tooltip>
    ),
    deleted: (
      <Tooltip title="Deleted">
        <Avatar className={classes.iconRed}>
          <DeleteIcon />
        </Avatar>
      </Tooltip>
    ),
    modified: (
      <Tooltip title="Modified">
        <Avatar className={classes.icon}>
          <EditIcon />
        </Avatar>
      </Tooltip>
    ),
    suspended: (
      <Tooltip title="Suspended">
        <Avatar className={classes.icon}>
          <PauseIcon />
        </Avatar>
      </Tooltip>
    ),
    synced: (
      <Tooltip title="Synced">
        <Avatar className={classes.iconGreen}>
          <SyncIcon />
        </Avatar>
      </Tooltip>
    ),
    unsuspended: (
      <Tooltip title="Un-suspended">
        <Avatar className={classes.iconGreen}>
          <PlayArrowIcon />
        </Avatar>
      </Tooltip>
    ),
  });

  if (!isLoaded(auditTrails)) return null;

  return (
    <React.Fragment>
      <Grid direction="row" alignItems="center" container justify="center">
        <Grid item md={12}>
          <Typography component="h2" variant="h6" className={classes.title}>
            Showing latest 20 audit trails
          </Typography>
        </Grid>
      </Grid>

      <List>
        {auditTrails.map((trail, key) => (
          <ListItem key={key}>
            <Grid item container alignItems="center" justify="center">
              <Grid item md={1} xs={2}>
                <ListItemAvatar>{avatars[trail.action]}</ListItemAvatar>
              </Grid>

              <Grid item md={11} xs={10}>
                <Card className={classes.card}>
                  <ListItemText
                    primary={
                      trail.description +
                      ".   [Card Name: " +
                      trail.values.profile_name +
                      "]"
                    }
                    secondary={moment(trail.created_at.toDate()).format(
                      "DD-MM-YYYY h:mm:ss a"
                    )}
                  />
                </Card>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  );
};

export default NotiAuditsTab;
