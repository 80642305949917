import * as actionTypes from "./ActionType";

const initialState = {
  add_service_dialog: { open: false, id: "" },
  edit_service_dialog: { open: false, params: "" },
  edit_device_dialog: { open: false, id: "" },
  edit_door_access_dialog: { open: false, id: "" },
  edit_floor_access_dialog: { open: false, id: "" },
  edit_visit_purpose_action_dialog: { open: false, params: "" },
};

function SettingReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_ADD_SERVICE_DIALOG_OPEN:
      return {
        ...state,
        add_service_dialog: { ...state.add_service_dialog, ...action.playload },
      };
    case actionTypes.TOGGLE_ADD_SERVICE_DIALOG_CLOSE:
      return {
        ...state,
        add_service_dialog: { open: false, id: "" },
      };

    case actionTypes.TOGGLE_EDIT_SERVICE_DIALOG_OPEN:
      return {
        ...state,
        edit_service_dialog: {
          ...state.edit_service_dialog,
          ...action.playload,
        },
      };
    case actionTypes.TOGGLE_EDIT_SERVICE_DIALOG_CLOSE:
      return {
        ...state,
        edit_service_dialog: { open: false, params: "" },
      };

    case actionTypes.TOGGLE_EDIT_DEVICE_DIALOG_OPEN:
      return {
        ...state,
        edit_device_dialog: { ...state.edit_device_dialog, ...action.playload },
      };
    case actionTypes.TOGGLE_EDIT_DEVICE_DIALOG_CLOSE:
      return {
        ...state,
        edit_device_dialog: { open: false, id: "" },
      };

    case actionTypes.TOGGLE_EDIT_DOOR_ACCESS_DIALOG_OPEN:
      return {
        ...state,
        edit_door_access_dialog: {
          ...state.edit_door_access_dialog,
          ...action.playload,
        },
      };
    case actionTypes.TOGGLE_EDIT_DOOR_ACCESS_DIALOG_CLOSE:
      return {
        ...state,
        edit_door_access_dialog: { open: false, id: "" },
      };

    case actionTypes.TOGGLE_EDIT_FLOOR_ACCESS_DIALOG_OPEN:
      return {
        ...state,
        edit_floor_access_dialog: {
          ...state.edit_floor_access_dialog,
          ...action.payload,
        },
      };
    case actionTypes.TOGGLE_EDIT_FLOOR_ACCESS_DIALOG_CLOSE:
      return {
        ...state,
        edit_floor_access_dialog: { open: false, id: "" },
      };

    case actionTypes.TOGGLE_EDIT_VISIT_PURPOSE_ACTION_DIALOG_OPEN:
      return {
        ...state,
        edit_visit_purpose_action_dialog: {
          ...state.edit_visit_purpose_action_dialog,
          ...action.payload,
        },
      };
    case actionTypes.TOGGLE_EDIT_VISIT_PURPOSE_ACTION_DIALOG_CLOSE:
      return {
        ...state,
        edit_visit_purpose_action_dialog: { open: false, params: "" },
      };

    default:
      return state;
  }
}

export default SettingReducer;
