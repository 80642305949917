import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import axios from "http-axios";
import Papa from "papaparse";
import * as moment from "moment";
import { useDialog } from "muibox";

// components (global)
import ButtonProgress from "components/ButtonProgress";

// firebase
import { useFirebase } from "react-redux-firebase";

// form
import { useForm } from "react-hook-form";

// helpers
import { residentImportCardValidate as importValidator } from "helpers/validation/residentImportCardValidate";

// material icons
import { AddPhotoAlternateOutlined as AddPhotoAlternateOutlinedIcon } from "@material-ui/icons";

// material-ui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    marginRight: theme.spacing(1),
  },
}));

const ImportResidentDialog = () => {
  const classes = useStyles();
  const dialog = useDialog();
  const firebase = useFirebase();

  // form
  const { handleSubmit, register, setValue, unregister } = useForm({
    mode: "onBlur",
  });

  // selector
  const uid = useSelector((state) => state.firebase.auth.uid);
  const vendors = useSelector((state) => state.firestore.ordered.vendors);
  const blocks = useSelector((state) => state.firestore.ordered.blocks);
  const units = useSelector((state) => state.firestore.ordered.units);
  const selectedPropertyId = useSelector(
    (state) => state.main.selectedProperty
  );

  // state
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // dropzone
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) setValue("file", acceptedFiles[0]);
    },
    [setValue]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: browseFile,
  } = useDropzone({
    onDrop,
    accept: "text/csv, application/vnd.ms-excel",
    maxSize: 5000000,
  });

  // effect
  useEffect(() => {
    register("file");
    return () => unregister("file");
  }, [register, unregister]);

  // functions
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getIndex = (value, prop) => {
    for (var i = 0; i < importValidator.length; i++) {
      if (importValidator[i][prop] === value) {
        return i;
      }
    }
    return -1;
  };

  const onSubmit = ({ file }) => {
    if (!submitting) {
      setSubmitting(true);

      Papa.parse(file, {
        skipEmptyLines: true,
        complete: function (results) {
          let dataList = [];
          let errorList = [];

          var validHeader = false;

          // Validate Header
          if (results.data[0].length < importValidator.length) {
            errorList.push({
              message: "Incomplete Header",
            });
          } else {
            results.data[0].forEach((header, index) => {
              if (getIndex(header, "header") === -1) {
                errorList.push({
                  message:
                    "Invalid header at column " +
                    index +
                    ", header must be " +
                    importValidator[index].header,
                });
              }
            });

            if (errorList.length === 0) {
              validHeader = true;
            }
          }

          if (validHeader) {
            results.data.forEach((result, rowIndex) => {
              if (rowIndex > 0) {
                let rowError = false;

                // Validate required field
                result.forEach((data, columnIndex) => {
                  if (importValidator[columnIndex].required && data === "") {
                    rowError = true;

                    errorList.push({
                      message:
                        importValidator[columnIndex].name +
                        " is required at row " +
                        (rowIndex + 1),
                    });
                  }
                });

                // Validate block & unit
                const block = blocks.filter((block) => {
                  return block.name.toUpperCase() === result[0].toUpperCase();
                });

                if (block.length <= 0) {
                  rowError = true;

                  errorList.push({
                    message: "Invalid block at row " + (rowIndex + 1),
                  });
                }

                const unit = units.filter((unit) => {
                  return (
                    unit.house_number.toUpperCase() === result[1].toUpperCase()
                  );
                });

                if (unit.length <= 0) {
                  rowError = true;

                  errorList.push({
                    message: "Invalid unit at row " + (rowIndex + 1),
                  });
                }

                // Validate duplicate record
                if (!rowError) {
                  let exist = dataList.filter((data) => {
                    return data.card_no === result[2];
                  });

                  if (exist.length > 0) {
                    errorList.push({
                      message: "Duplicate card number ar row " + (rowIndex + 1),
                    });
                  } else {
                    var date = new Date();
                    var year = date.getFullYear();
                    var month = date.getMonth();
                    var day = date.getDate();

                    dataList.push({
                      access: {
                        access_groups: [
                          {
                            description: "Main Door",
                            name: "Main Door",
                          },
                        ],
                      },
                      anti_passback:
                        result[5] !== ""
                          ? result[5] === "1"
                            ? true
                            : false
                          : true,
                      block_id: block[0].id,
                      block_name: result[0],
                      blocked_settings: {
                        count: 0,
                        enable: false,
                      },
                      card_no: result[2],
                      end_date: moment(new Date(year + 20, month, day)).format(
                        "MM-DD-YYYY 00:00:00"
                      ),
                      expired_date: result[7],
                      is_sync: false,
                      unit_id: unit[0].id,
                      unit_name: result[1],
                      property_id: selectedPropertyId,
                      profile_name: result[3],
                      profile_type: "resident",
                      status:
                        result[4] !== ""
                          ? result[4] === "1"
                            ? "active"
                            : "inactive"
                          : "active",
                      staff_no: result[7],
                      vehicle_no: result[8],
                      sticker_serial_no: result[9],
                      parking_lot_no: result[10],
                      card_type: result[11],
                      vendor: {
                        id: vendors[0].id,
                        type: vendors[0].access_card_settings.company,
                      },
                    });
                  }
                }
              }
            });
          }

          if (errorList.length > 0) {
            setSubmitting(false);
            dialog.alert({
              title: "Error",
              message: (
                <DialogContent>
                  {errorList.map((err) => (
                    <div>{"- " + err.message}</div>
                  ))}
                </DialogContent>
              ),
            });
            return;
          } else {
            firebase
              .auth()
              .currentUser.getIdToken()
              .then((token) => {
                console.log("token", "Bearer " + token);
                console.log("uid", uid);

                const headers = {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                };

                const data = {
                  uid: uid,
                  property_id: selectedPropertyId,
                  card_list: dataList,
                };

                axios
                  .post(`jagaCardAddCardList`, data, {
                    headers: headers,
                  })
                  .then((res) => {
                    console.log("response", res);
                    setSubmitting(false);
                    handleClose();
                  })
                  .catch((e) => {
                    setSubmitting(false);

                    dialog.alert({
                      title: "Error",
                      message: <DialogContent>{e.message}</DialogContent>,
                    });
                  });
              });
          }
        },
      });
    }
  };

  return (
    <div>
      <Hidden mdDown>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={handleClickOpen}
        >
          Import
        </Button>
      </Hidden>

      <Hidden lgUp>
        <div onClick={handleClickOpen}>Import</div>
      </Hidden>

      <form autoComplete="off" noValidate={true}>
        <Dialog
          onClose={handleClose}
          aria-labelledby="import-dialog-title"
          aria-describedby="import-dialog-description"
          open={open}
          fullWidth
          scroll="body"
        >
          <DialogTitle id="import-dialog-title" disableTypography={true}>
            <Typography
              className={classes.modalTitle}
              variant="h3"
              component="h4"
            >
              Import Card
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <Grid item lg={12} md={12} xs={12}>
                <Box
                  padding={1}
                  marginBottom={1}
                  border="dotted"
                  {...getRootProps()}
                  textAlign="center"
                >
                  <input {...getInputProps()} />

                  <React.Fragment>
                    <AddPhotoAlternateOutlinedIcon
                      style={{ width: 60, height: 60 }}
                      // color={isDragActive ? "primary" : "disabled"}
                    />
                    <Typography variant="h6" component="h1">
                      {"Drag & Drop an csv here"}
                    </Typography>
                    <Typography variant="h6" component="h1">
                      {"*Only csv file"}
                    </Typography>
                    <Typography variant="h6" component="h1">
                      {"*File size < 5mb"}
                    </Typography>
                  </React.Fragment>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <ButtonProgress
              type="submit"
              containName="Save"
              margin={false}
              loading={submitting}
              disabled={submitting}
              onClick={handleSubmit(onSubmit)}
            />
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default ImportResidentDialog;
