import React from "react";
import { useSelector } from "react-redux";

// material-ui
import { Card, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    padding: theme.spacing(1),
  },
}));

const EntryPassAccessGroup = ({ accessGroups }) => {
  // selector
  const accessGroupsSelector = useSelector(
    (state) => state.firestore.data.accessGroups
  );

  return accessGroups?.length > 0 ? (
    accessGroups?.map((group) => {
      const accessGroup = accessGroupsSelector[group.id];

      if (!accessGroup) {
        return (
          <Grid item md={12}>
            <Typography variant="caption" component="h1">
              No Access Group
            </Typography>
          </Grid>
        );
      }

      return (
        <Grid item md={12} key={group.id}>
          <Typography variant="caption" component="h1">
            {`- ${
              accessGroup.display_name
                ? accessGroup.display_name
                : accessGroup.name
            }`}
          </Typography>
        </Grid>
      );
    })
  ) : (
    <Grid item md={12}>
      <Typography variant="caption" component="h1">
        No Access Group
      </Typography>
    </Grid>
  );
};

const FalcoAccessGroup = ({ accessGroups }) => {
  // selector
  const accessGroupsSelector = useSelector(
    (state) => state.firestore.data.accessGroups
  );

  return accessGroups?.length > 0 ? (
    accessGroups?.map((group) => {
      const accessGroup = accessGroupsSelector[group.id];

      if (!accessGroup) {
        return (
          <Grid item md={12}>
            <Typography variant="caption" component="h1">
              No Access Group
            </Typography>
          </Grid>
        );
      }

      return (
        <Grid item md={12} key={group.id}>
          <Typography variant="caption" component="h1">
            {`- ${
              accessGroup.display_name
                ? accessGroup.display_name
                : accessGroup.name
            }`}
          </Typography>
        </Grid>
      );
    })
  ) : (
    <Grid item md={12}>
      <Typography variant="caption" component="h1">
        No Access Group
      </Typography>
    </Grid>
  );
};

const SoyalAccessGroup = ({ accessGroup }) => {
  // selector
  const accessGroupSelector = useSelector(
    (state) => state.firestore.data.accessGroups[accessGroup.id]
  );

  if (!accessGroupSelector)
    return (
      <Grid item md={12}>
        <Typography variant="caption" component="h1">
          No Access Group
        </Typography>
      </Grid>
    );

  return (
    <Grid item md={12} key={accessGroup.id}>
      <Typography variant="caption" component="h1">
        {`- ${
          accessGroupSelector.display_name
            ? accessGroupSelector.display_name
            : accessGroupSelector.name
        }`}
      </Typography>
    </Grid>
  );
};

const MicroEngineAccessGroup = ({ accessGroup }) => {
  // selector
  const accessGroupSelector = useSelector(
    (state) => state.firestore.data.accessGroups[accessGroup.id]
  );

  if (!accessGroupSelector)
    return (
      <Grid item md={12}>
        <Typography variant="caption" component="h1">
          No Access Group
        </Typography>
      </Grid>
    );

  return (
    <Grid item md={12} key={accessGroup.id}>
      <Typography variant="caption" component="h1">
        {`- ${
          accessGroupSelector.display_name
            ? accessGroupSelector.display_name
            : accessGroupSelector.name
        }`}
      </Typography>
    </Grid>
  );
};

const AccessGroup = ({ accessGroups }) => {
  const classes = useStyles();

  // selector
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const vendorType = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[
          selectedVendor
        ].access_card_settings.type.toLowerCase()
      : null;
  });

  return (
    <Grid
      item
      container
      direction="row"
      spacing={2}
      md={4}
      alignItems="flex-start"
      justify="space-between"
    >
      <Grid item container spacing={1} md={12}>
        <Grid item md={12}>
          <Typography variant="h4" component="h1">
            Access Group
          </Typography>
        </Grid>

        <Grid item md={12}>
          <Card className={classes.card}>
            <Grid container spacing={1}>
              {accessGroups ? (
                <React.Fragment>
                  {vendorType === "entrypass" && (
                    <EntryPassAccessGroup accessGroups={accessGroups} />
                  )}
                  {vendorType === "falco" && (
                    <FalcoAccessGroup accessGroups={accessGroups} />
                  )}
                  {vendorType === "soyal" && (
                    <SoyalAccessGroup accessGroup={accessGroups} />
                  )}
                  {vendorType === "microengine" && (
                    <MicroEngineAccessGroup accessGroup={accessGroups} />
                  )}
                </React.Fragment>
              ) : (
                <Grid item md={12}>
                  <Typography variant="caption" component="h1">
                    No Access Group
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccessGroup;
