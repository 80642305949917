import React from "react";

// devexpress
import {
  Table,
  TableHeaderRow,
  TableFixedColumns,
  TableFilterRow,
  Toolbar
} from "@devexpress/dx-react-grid-material-ui";

export const FilterCell = props => {
  return <TableFilterRow.Cell {...props} />;
};
