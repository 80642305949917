import React, { useMemo } from "react";
import { useSelector } from "react-redux";

// components (global)
import { CustomerDrawer } from "components";

// components (local)
import {
  AddBlockAccessDialog,
  DoorAccessDrawer,
  DoorAccessTable,
  EditDoorAccessDialog,
  EditEntryPassDoorAccessDialog,
} from "./components";

// firebase
import { useFirestoreConnect } from "react-redux-firebase";

// material-ui
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const DoorAccessTab = () => {
  const classes = useStyles();

  // selector
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const vendor = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor]
      : null;
  });
  const editDoorAccessOpen = useSelector(
    (state) => state.setting.edit_door_access_dialog.open
  );
  const vendorType = useMemo(
    () => vendor?.access_card_settings?.type?.toLowerCase() ?? "",
    [vendor]
  );

  // firestore
  useFirestoreConnect([
    {
      collection: "ac_vendors",
      doc: selectedVendor,
      subcollections: [
        {
          collection: "block_access",
          orderBy: "block_name",
        },
      ],
      storeAs: "blockAccess",
    },
  ]);

  if (!vendor) return null;

  return (
    <div className={classes.root}>
      {editDoorAccessOpen &&
        (vendorType === "entrypass" ? (
          <EditEntryPassDoorAccessDialog />
        ) : (
          <EditDoorAccessDialog />
        ))}

      <CustomerDrawer uniqName="view_door_access" size={2}>
        <DoorAccessDrawer />
      </CustomerDrawer>

      <Grid container direction="row" alignItems="flex-start" spacing={2}>
        <Grid item container justify="space-between">
          <Grid item>
            <Typography component="h2" gutterBottom variant="overline">
              Door Access
            </Typography>
            <Typography component="h1" variant="h3">
              Manage all door access for resident & visitor
            </Typography>
          </Grid>

          <Grid item>
            <AddBlockAccessDialog />
          </Grid>
        </Grid>
      </Grid>

      <DoorAccessTable />
    </div>
  );
};

export default DoorAccessTab;
