import React, { useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { useFormContext } from "react-hook-form";
import moment from "moment";

// material-ui
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

const CustomDatePick = ({
  minDate,
  defaultValue,
  disabled,
  label,
  name,
  required,
}) => {
  const { register, setValue } = useFormContext();

  // state
  const [selectedDate, setSelectedDate] = useState(
    defaultValue ? defaultValue : null
  );

  // effect
  useEffect(() => {
    register({ name: name }, { required: required });
  }, [name, register, required]);

  useEffect(() => {
    setValue(
      name,
      defaultValue ? defaultValue : moment(new Date()).add(1, "days").toDate()
    );
  }, [name, defaultValue, setValue]);

  // functions
  const handleChangeDate = (date) => {
    setValue(name, date);
    setSelectedDate(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        disabled={disabled}
        disablePast={true}
        minDate={minDate}
        value={selectedDate}
        label={label}
        inputVariant="outlined"
        fullWidth
        size="small"
        format="dd/MM/yyyy"
        name={name}
        onChange={handleChangeDate}
      />
    </MuiPickersUtilsProvider>
  );
};

export default CustomDatePick;
