import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { keys, includes } from "lodash";
import moment from "moment";

// components
import { StyledTextField } from "components/TextField";
import ButtonProgress from "components/ButtonProgress";

// material-ui
import {
  Grid,
  Typography,
  Divider,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
  footNote: {
    margin: theme.spacing(2),
  },
  red: {
    color: "#Ad2424",
  },
}));

const AutoCheckOutTab = () => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const firestore = useFirestore();

  const selectedProperty = useSelector(
    (state) => state.firestore.data.properties[state.main.selectedProperty]
  );

  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const vendor = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor]
      : null;
  });
  const { errors, handleSubmit, register } = useForm();

  // state
  const [submitting, setSubmitting] = useState(false);
  const [sampleCode, setSampleCode] = useState(null);
  const [checkbox, setCheckbox] = useState({
    autoCheckOutEnabled: vendor?.auto_checkout_settings?.enable ?? false,
  });

  const onSubmit = (value) => {
    if (!submitting) {
      setSubmitting(true);
      let settings = {
        ...vendor.auto_checkout_settings,
      };

      firestore
        .update(
          { collection: "ac_vendors", doc: selectedVendor },
          {
            auto_checkout_settings: {
              door_name: value.door,
              enable: checkbox.autoCheckOutEnabled ?? false,
            },
          }
        )
        .then(() => {
          setSubmitting(false);
          snackbar.enqueueSnackbar("Auto Check Out details had been updated", {
            variant: "success",
          });
        })
        .catch((e) => {
          setSubmitting(false);
          snackbar.enqueueSnackbar(e.message, {
            variant: "error",
            persist: false,
          });
        });
    }
  };

  //ui component
  const headerNote = (
    <>
      <Typography component="h2" gutterBottom variant="overline">
        Auto Check Out
      </Typography>
      <Typography component="h1" variant="h3">
        This is to Manage Auto Check Out Settings
      </Typography>
    </>
  );

  const doorTF = (
    <StyledTextField
      id="door"
      label="Door Name"
      name="door"
      type="text"
      defaultValue={vendor.auto_checkout_settings?.door_name}
      inputRef={register}
    />
  );

  const footNote = (
    <Typography variant="body2" className={classes.footNote}>
      {`AutoCheck Out Door Name: eg. Visitor Exit `}
      {!!sampleCode && (
        <span className={classes.red}>
          <b>Sample Code {sampleCode}</b>
        </span>
      )}
    </Typography>
  );

  const autoCheckBox = (
    <FormControlLabel
      control={
        <Checkbox
          name="sms"
          checked={!!checkbox.autoCheckOutEnabled}
          defaultValue={false}
          onChange={(e) => {
            setCheckbox({
              ...checkbox,
              autoCheckOutEnabled: e.target.checked,
            });
          }}
          color="primary"
          inputRef={register}
        />
      }
      label="Enable/Disable Auto Check Out"
    />
  );

  const saveButton = (
    <ButtonProgress
      type="submit"
      loading={submitting}
      containName="Save"
      disabled={submitting}
      onClick={handleSubmit(onSubmit)}
    />
  );

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {headerNote}
            </Grid>
            <Grid item xs={12} md={6}>
              {doorTF}
              {footNote}
            </Grid>
            <Grid item xs={12} md={6}>
              {autoCheckBox}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>{saveButton}</CardActions>
      </form>
    </Card>
  );
};

export default AutoCheckOutTab;
