export const vehicleValidate = {
  file: {
    rules: {
      required: true
    },
    message: {
      required: "Vehicle Image is Required",
      unableExtract: "Unable to extract plate number",
      exists: "Vehicle had been exists"
    }
  },
  block: {
    rules: {
      required: true
    },
    message: {
      required: "Block is Required"
    }
  },
  unit: {
    rules: {
      required: true
    },
    message: {
      required: "Unit is Required"
    }
  },
  profileName: {
    rules: {
      required: true
    },
    message: { required: "Name is Required" }
  }
};
