import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

// components (global)
import ButtonProgress from "components/ButtonProgress";

// firebase
import { useFirestore } from "react-redux-firebase";

// form
import { useForm } from "react-hook-form";

// helpers
import { vehicleValidate } from "helpers/validation/vehicleValidate";

// material icons
import { AddPhotoAlternateOutlined as AddPhotoAlternateOutlinedIcon } from "@material-ui/icons";

// material-ui
import {
  Button,
  Box,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
// stores
import { closeVehicleEditDialog } from "stores/vehicle/VehicleAction";

// styles
const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
  },
  modalTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.white,
  },
  modalSubTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.white,
    padding: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  image: {
    maxWidth: "100%",
    height: "350px",
    alignItems: "center",
  },
  field: {
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  column2: {
    marginLeft: theme.spacing(1),
  },
  edit: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const EditVehicleDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const theme = useTheme();
  const { errors, handleSubmit, setError, setValue, register, watch } =
    useForm();
  const formMethods = useForm();
  // form
  const watchBlock = watch("blockId");

  // selector
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const blocks = useSelector((state) => state.firestore.ordered.blocks);
  const units = useSelector((state) => state.firestore.ordered.units);
  const { open, id } = useSelector(
    (state) => state.vehicle.vehicle_edit_dialog
  );
  const vehicle = useSelector((state) => {
    return state.firestore.data.vehicles
      ? state.firestore.data.vehicles[id]
      : null;
  });
  const selectedVendor = useSelector((state) => state.main.selectedApnrVendor);
  const vendor = useSelector((state) => {
    return state.firestore.data.apnrVendors
      ? state.firestore.data.apnrVendors[selectedVendor]
      : null;
  });

  // state
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState(true);
  const [image, setImage] = useState({
    preview: "",
    raw: "",
    vehicleNo: "",
    loading: false,
  });
  const [select, setSelect] = useState({
    blockState: null,
    unitState: null,
  });
  const [unitList, setUnitList] = useState(null);
  const [token, setToken] = useState();
  // effect
  useEffect(() => {
    register({ name: "blockId" });
    register({ name: "unitId" });
  }, [register]);

  useEffect(() => {
    if (vehicle) {
      setValue("blockId", vehicle.unit.block_id);
      setValue("unitId", vehicle.unit.unit_id);

      setSelect({
        blockState: blocks.filter(
          (block) => block.id === vehicle.unit.block_id
        )[0],
        unitState: units.filter((unit) => unit.id === vehicle.unit.unit_id)[0],
      });

      setUnitList(
        units.filter((unit) => unit.block_id === vehicle.unit.block_id)
      );
    }
  }, [vehicle, blocks, units, image, setValue]);

  // functions
  const handleOnClose = () => {
    dispatch(closeVehicleEditDialog());
  };
  const handleStatusChange = (event) => {
    setStatus(event.target.checked);
  };
  const handleImageChange = async (e) => {
    setImage({
      ...image,
      vehicleNo: "Loading...",
      loading: true,
    });

    var file = e.target.files[0];

    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios({
        method: "post",
        url: "https://plug.gospark.my/api/vehicle/verifyPlate",
        data: formData,
        headers: { "content-type": "application/octet-stream" },
      });

      if (response.data.status === "successful") {
        var maxWidth = 300;
        var maxHeight = 300;

        resize(file, maxWidth, maxHeight, (resizedDataUrl) => {
          setImage({
            preview: resizedDataUrl,
            raw: file,
            vehicleNo: response.data.raw.plate,
            loading: false,
          });
        });
      } else {
        setImage({
          ...image,
          vehicleNo: "-",
          loading: false,
        });

        setError("file", "unableExtract");
      }
    } catch (error) {
      console.log("response", error.response);
    }
  };

  const handleBlockChange = (e, value) => {
    setUnitList(null);

    if (value) {
      setSelect({ blockState: value, unitState: null });
      setValue("blockId", value.id);

      setUnitList(units.filter((unit) => unit.block_id === value.id));
    } else {
      setValue("blockId", null);
      setValue("unitId", null);
      setSelect({ blockState: null, unitState: null });
    }
  };

  const resize = (file, maxWidth, maxHeight, fn) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      var dataUrl = event.target.result;

      var image = new Image();
      image.src = dataUrl;
      image.onload = () => {
        var resizedDataUrl = resizeImage(image, maxWidth, maxHeight, 0.7);
        fn(resizedDataUrl);
      };
    };
  };

  const resizeImage = (image, maxWidth, maxHeight, quality) => {
    var canvas = document.createElement("canvas");

    var width = image.width;
    var height = image.height;

    if (width > height) {
      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
      }
    }

    canvas.width = width;
    canvas.height = height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, width, height);

    return canvas.toDataURL("image/jpeg", quality);
  };

  const cardSettingGroup = (
    <>
      <Grid item md={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="status"
              checked={status}
              onChange={handleStatusChange}
              color="primary"
              inputRef={formMethods.register}
            />
          }
          label="Active"
        />
      </Grid>
    </>
  );

  const onSubmit = async (data) => {
    if (!submitting) {
      setSubmitting(true);

      firestore
        .collection("ac_profiles")
        .doc(vehicle.id)
        .set(
          {
            access: {
              vehicle_group: data.vehicleGroup,
            },

            updated_at: new Date(),
            updated_by: {
              user_id: auth.uid,
              name: profile.name,
            },
            parking_lot_no: data.parkingLot,
            profile_name: data.profileName,
            sticker_serial_no: data.stickerNo,
            status: status ? "active" : "inactive",
            unit: {
              block_id: data.blockId,
              block_name: data.block,
              unit_id: data.unitId,
              unit_name: data.unit,
            },
            vehicle_make: data.vehicleMake,
            vehicle_model: data.vehicleModel,
            vehicle_colour: data.vehicleColour,
          },
          { merge: true }
        )
        .then(() => {
          setSubmitting(false);
          handleOnClose();
        })
        .catch((e) => {
          setSubmitting(false);
          console.log(e);
          handleOnClose();
        });
    }

    if (vendor?.token != null) {
      const diff = moment(new Date()).diff(moment(vendor?.token_last_updated));
      const diffDuration = moment.duration(diff);
      if (diffDuration.minutes() >= 25) {
        axios
          .post(
            `http://edge-remote.gotapway.com:15001/login`,
            {
              username: "jagaapp",
              password: "J@g@App123!",
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            axios
              .put(
                `http://edge-remote.gotapway.com:15001/update_vehicle_mgmt`,
                {
                  lp: data.vehicleNumber,
                  parker_status: status ? "whitelist" : "blacklist",
                  parker_type: "season",
                  name: data.profileName,
                  vehicle_make: data.vehicleMake,
                  group_id: data.vehicleGroup,
                  valid_from: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                  valid_until: moment(
                    moment(new Date()).add(30, "y").toDate()
                  ).format("YYYY-MM-DD HH:mm:ss"),
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + res.data.token,
                  },
                }
              )
              .then((res) => {
                firestore
                  .collection("apnr_vendors")
                  .doc(selectedVendor)
                  .set(
                    {
                      token: res.data.token,
                      token_last_updated: new Date(),
                    },
                    { merge: true }
                  )
                  .catch((e) => {});
              })
              .catch((e) => {});
          })
          .catch((e) => {});
      } else {
        axios
          .put(
            `http://edge-remote.gotapway.com:15001/update_vehicle_mgmt`,
            {
              lp: data.vehicleNumber,
              parker_status: status ? "whitelist" : "blacklist",
              parker_type: "season",
              name: data.profileName,
              vehicle_make: data.vehicleMake,
              group_id: data.vehicleGroup,
              valid_from: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
              valid_until: moment(
                moment(new Date()).add(30, "y").toDate()
              ).format("YYYY-MM-DD HH:mm:ss"),
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + vendor?.token,
              },
            }
          )
          .then((res) => {
            console.log("success");
          })
          .catch((e) => {});
      }
    } else {
      axios
        .post(
          `http://edge-remote.gotapway.com:15001/login`,
          {
            username: "jagaapp",
            password: "J@g@App123!",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          firestore
            .collection("apnr_vendors")
            .doc(selectedVendor)
            .set(
              {
                token: res.data.token,
                token_last_updated: new Date(),
              },
              { merge: true }
            )
            .catch((e) => {});
          axios
            .put(
              `http://edge-remote.gotapway.com:15001/update_vehicle_mgmt`,
              {
                lp: data.vehicleNumber,
                parker_status: status ? "whitelist" : "blacklist",
                parker_type: "season",
                name: data.profileName,
                vehicle_make: data.vehicleMake,
                group_id: data.vehicleGroup,
                valid_from: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                valid_until: moment(
                  moment(new Date()).add(30, "y").toDate()
                ).format("YYYY-MM-DD HH:mm:ss"),
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + res.data.token,
                },
              }
            )
            .then((res) => {})
            .catch((e) => {});
        })
        .catch((e) => {});
    }
  };

  if (!vehicle) return null;

  return (
    <form
      noValidate
      xs={12}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={handleOnClose}
        open={open}
        fullWidth={true}
        maxWidth="md"
        scroll="paper"
      >
        <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>
          <div>
            <Typography
              className={classes.modalTitle}
              variant="h3"
              component="h2"
            >
              Edit Vehicle
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Grid
              item
              container
              direction="row"
              spacing={2}
              md={7}
              justify="center"
              alignItems="flex-start"
            >
              <Grid item md={12}>
                <Typography variant="h4" component="h1">
                  Vehicle Details
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Autocomplete
                  autoHighlight
                  value={select.blockState !== null ? select.blockState : null}
                  options={blocks}
                  getOptionLabel={(option) => option.name}
                  onChange={handleBlockChange}
                  renderInput={(params) => (
                    <TextField
                      error={!!errors.block}
                      helperText={
                        errors.block &&
                        vehicleValidate.block.message[errors.block.type]
                      }
                      {...params}
                      label="Choose a block *"
                      fullWidth
                      size="small"
                      name="block"
                      autoComplete="off"
                      variant="outlined"
                      inputRef={register(vehicleValidate.block.rules)}
                    />
                  )}
                />
              </Grid>
              <Grid item md={12}>
                <Autocomplete
                  autoHighlight
                  value={select.unitState !== null ? select.unitState : null}
                  disabled={!Boolean(watchBlock)}
                  options={
                    unitList
                      ? unitList.sort(
                          (a, b) =>
                            -b.house_number.localeCompare(a.house_number)
                        )
                      : []
                  }
                  getOptionLabel={(option) => option.house_number}
                  onChange={(e, value) => {
                    if (value) {
                      setSelect({ ...select, unitState: value });
                      setValue("unitId", value.id);
                    } else {
                      setSelect({ ...select, unitState: null });
                      setValue("unitId", null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={!!errors.block}
                      helperText={
                        errors.block &&
                        vehicleValidate.block.message[errors.block.type]
                      }
                      {...params}
                      label="Choose a unit *"
                      fullWidth
                      size="small"
                      name="unit"
                      autoComplete="off"
                      variant="outlined"
                      inputRef={register(vehicleValidate.block.rules)}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "disabled",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  size="small"
                  id="profileName"
                  name="profileName"
                  label="Name *"
                  type="text"
                  variant="outlined"
                  autoComplete="off"
                  defaultValue={vehicle ? vehicle.profile_name : ""}
                  inputRef={register(vehicleValidate.profileName.rules)}
                  helperText={
                    errors.profileName &&
                    vehicleValidate.profileName.message[errors.profileName.type]
                  }
                  error={!!errors.profileName}
                  fullWidth
                />
              </Grid>
              {/* <Grid item md={12}>
                <Box
                  padding={1}
                  marginBottom={1}
                  border="dotted"
                  textAlign="center"
                >
                  {vehicleImage ? (
                    <div>
                      <img
                        src={vehicleImage}
                        alt="animage"
                        className={classes.image}
                        maxheight="300"
                        style={{ padding: theme.spacing(2) }}
                      />
                    </div>
                  ) : image.preview ? (
                    <label htmlFor="outlined-button-file">
                      <img
                        src={vehicleImage ? vehicleImage : image.preview}
                        alt="animage"
                        className={classes.image}
                        maxheight="300"
                        style={{ padding: theme.spacing(2) }}
                      />
                    </label>
                  ) : image.loading ? (
                    <Grid
                      container
                      lg={12}
                      alignItems="center"
                      justify="center"
                      style={{ height: "150px" }}
                    >
                      <CircularProgress />
                    </Grid>
                  ) : (
                    <label htmlFor="outlined-button-file">
                      <div style={{ height: "150px" }}>
                        <React.Fragment>
                          <AddPhotoAlternateOutlinedIcon
                            style={{ width: 60, height: 60 }}
                            color={"primary"}
                          />
                          <Typography variant="h6" component="h1">
                            {"Drag & Drop an image here"}
                          </Typography>
                          <Typography variant="h6" component="h1">
                            {"*Only jpg,jpeg & png file"}
                          </Typography>
                          <Typography variant="h6" component="h1">
                            {"*File size < 5mb"}
                          </Typography>
                        </React.Fragment>
                      </div>
                    </label>
                  )}

                  <input
                    accept="image/*"
                    className={classes.input}
                    id="outlined-button-file"
                    name="file"
                    type="file"
                    onChange={handleImageChange}
                    // ref={register(vehicleValidate.file.rules)}
                  />
                </Box>
              </Grid> */}
              <Grid item md={12}>
                <TextField
                  size="small"
                  margin="dense"
                  id="vehicleNumber"
                  name="vehicleNumber"
                  label="Vehicle No."
                  type="text"
                  readOnly={true}
                  inputRef={register}
                  autoComplete="off"
                  defaultValue={vehicle ? vehicle.identity_number : ""}
                  //   value={image.vehicleNo ? image.vehicleNo : "-"}
                  fullWidth
                  //   helperText={
                  //     errors.file &&
                  //     vehicleValidate.file.message[errors.file.type]
                  //   }
                  //   error={!!errors.file}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={12}>
                <TextField
                  size="small"
                  margin="dense"
                  id="vehicleGroup"
                  name="vehicleGroup"
                  label="Vehicle Group."
                  type="text"
                  readOnly={true}
                  inputRef={register}
                  autoComplete="off"
                  defaultValue={vehicle ? vehicle?.access?.vehicle_group : ""}
                  //   value={image.vehicleNo ? image.vehicleNo : "-"}
                  fullWidth
                  //   helperText={
                  //     errors.file &&
                  //     vehicleValidate.file.message[errors.file.type]
                  //   }
                  //   error={!!errors.file}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="status"
                      checked={status}
                      onChange={handleStatusChange}
                      color="primary"
                      inputRef={register}
                    />
                  }
                  label="Active"
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              direction="row"
              spacing={2}
              md={5}
              justify="center"
              alignItems="flex-start"
            >
              <Grid item md={12}>
                <Typography variant="h4" component="h1">
                  Other Details
                </Typography>
              </Grid>

              <Grid item md={12}>
                <TextField
                  size="small"
                  id="vehicleMake"
                  name="vehicleMake"
                  label="Vehicle Make"
                  type="text"
                  defaultValue={vehicle.vehicle_make}
                  inputRef={register}
                  autoComplete="off"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  id="vehicleModel"
                  name="vehicleModel"
                  label="Vehicle Model"
                  type="text"
                  variant="outlined"
                  autoComplete="off"
                  size="small"
                  defaultValue={vehicle.vehicle_model}
                  inputRef={register}
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  id="vehicleColour"
                  name="vehicleColour"
                  label="Vehicle Colour"
                  type="text"
                  variant="outlined"
                  size="small"
                  defaultValue={vehicle.vehicle_colour}
                  inputRef={register}
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  id="parkingLot"
                  name="parkingLot"
                  label="Parking Lot No."
                  type="text"
                  variant="outlined"
                  size="small"
                  defaultValue={vehicle.parking_lot_no}
                  inputRef={register}
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  id="stickerNo"
                  name="stickerNo"
                  label="Sticker No."
                  type="text"
                  variant="outlined"
                  size="small"
                  defaultValue={vehicle.sticker_serial_no}
                  inputRef={register}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleOnClose}>
            Cancel
          </Button>
          <ButtonProgress
            type="submit"
            containName="Save"
            margin={false}
            loading={submitting}
            disabled={submitting}
            onClick={handleSubmit(onSubmit)}
          />
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default EditVehicleDialog;
