import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// component (glbal)
import Label from "components/Label";

// material-ui
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  colors,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

// stores
import { openDrawer } from "stores/main/MainActions";
import { toggleEditFloorAccessDialogOpen } from "stores/setting/SettingAction";

// styles
const useStyles = makeStyles((theme) => ({
  mt1: { marginTop: theme.spacing(1) },
}));

const FloorAccessCard = ({ floorAccess, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // selector
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const accessCardSettingSelector = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[selectedVendor] &&
        state.firestore.data.vendors[selectedVendor].access_card_settings
        ? state.firestore.data.vendors[selectedVendor].access_card_settings
        : null
      : null;
  });

  // state
  const [anchorEl, setAnchorEl] = useState(null);
  const [vendorType] = useState(
    accessCardSettingSelector
      ? accessCardSettingSelector.type.toLowerCase()
      : null
  );

  // functions
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleViewAccessDetails = () => {
    dispatch(openDrawer({ toggle: "view_floor_access", parameterId: id }));
    handleCloseMenu();
  };

  const handleEditAccess = () => {
    dispatch(toggleEditFloorAccessDialogOpen({ open: true, id }));
    handleCloseMenu();
  };

  return (
    <Grid item md={3} xs={12} key={id}>
      <Menu
        id="enitMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {vendorType === "entrypass" && (
          <MenuItem onClick={handleViewAccessDetails}>
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="View Details" />
          </MenuItem>
        )}

        <MenuItem onClick={handleEditAccess}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit Floor Access" />
        </MenuItem>
      </Menu>

      <Card>
        <CardHeader
          title={`${floorAccess.block_name}, ${floorAccess.floor_name}`}
          action={
            <IconButton
              aria-label="settings"
              size="small"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <Grid container>
            <Grid item container md={12} justify="center" alignItems="center">
              <Typography component="h2" gutterBottom variant="overline">
                Lift Access
              </Typography>
            </Grid>

            <Grid item container md={12}>
              <Grid
                item
                container
                md={6}
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography gutterBottom variant="overline">
                    Resident
                  </Typography>
                </Grid>
                <Grid>
                  <Label color={colors.green[600]}>
                    {floorAccess?.resident?.lift_access ? (
                      <React.Fragment>
                        {vendorType === "entrypass" &&
                          (floorAccess.resident.lift_access.display_name
                            ? floorAccess.resident.lift_access.display_name
                            : floorAccess.resident.lift_access.name)}
                        {vendorType === "falco" &&
                          `${
                            floorAccess.resident.lift_access[0].display_name
                              ? floorAccess.resident.lift_access[0].display_name
                              : floorAccess.resident.lift_access[0].lift_id
                          }`}
                        {vendorType === "soyal" &&
                          (floorAccess.resident.lift_access.display_name
                            ? floorAccess.resident.lift_access.display_name
                            : floorAccess.resident.lift_access.name)}
                        {vendorType === "microengine" &&
                          (floorAccess.resident.lift_access.display_name
                            ? floorAccess.resident.lift_access.display_name
                            : floorAccess.resident.lift_access.name)}
                      </React.Fragment>
                    ) : (
                      "-"
                    )}
                  </Label>
                </Grid>
                {!!floorAccess?.resident?.lift_access?.floor_group_id && (
                  <Grid className={classes.mt1}>
                    <Label color={colors.green[600]}>
                      Id:
                      {floorAccess?.resident?.lift_access?.floor_group_id ?? ""}
                    </Label>
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                container
                md={6}
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography gutterBottom variant="overline">
                    Visitor
                  </Typography>
                </Grid>
                <Grid>
                  <Label color={colors.green[600]}>
                    {floorAccess?.visitor?.lift_access ? (
                      <React.Fragment>
                        {vendorType === "entrypass" &&
                          (floorAccess.visitor.lift_access.display_name
                            ? floorAccess.visitor.lift_access.display_name
                            : floorAccess.visitor.lift_access.name)}
                        {vendorType === "falco" &&
                          `${
                            floorAccess.visitor.lift_access[0].display_name
                              ? floorAccess.visitor.lift_access[0].display_name
                              : floorAccess.visitor.lift_access[0].lift_id
                          }`}
                        {vendorType === "soyal" &&
                          (floorAccess.visitor.lift_access.display_name
                            ? floorAccess.visitor.lift_access.display_name
                            : floorAccess.visitor.lift_access.name)}
                        {vendorType === "microengine" &&
                          (floorAccess.visitor.lift_access.display_name
                            ? floorAccess.visitor.lift_access.display_name
                            : floorAccess.visitor.lift_access.name)}
                      </React.Fragment>
                    ) : (
                      "-"
                    )}
                  </Label>
                </Grid>
                {!!floorAccess?.visitor?.lift_access?.floor_group_id && (
                  <Grid className={classes.mt1}>
                    <Label color={colors.green[600]}>
                      Id:
                      {floorAccess?.visitor?.lift_access?.floor_group_id ?? ""}
                    </Label>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default FloorAccessCard;
