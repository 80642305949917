import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { orderBy } from "lodash";

// components (global)
import { Page } from "components/Pages";
import { CustomTabs } from "components/Tabs";

// components (local)
import {
  ExportUser,
  VendorsData,
  PropertyInfo,
  PingServer,
  EntrypassCsv,
  CsvDashboard,
} from "./components";

// material-ui
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(3),
  },
  content: {},
}));

const Internal = () => {
  const classes = useStyles();

  // selector
  const roles = useSelector((state) => state.main.roles);

  // state
  const [tabs, setTabs] = useState([]);

  // effect
  useEffect(() => {
    var tab = [];

    if (roles.access_card_settings?.door_access?.create) {
      tab = [
        ...tab,
        {
          value: "User",
          label: "User",
          content: <ExportUser />,
          sorting: 1,
        },
      ];
    }

    if (roles.access_card_settings?.door_access?.create) {
      tab = [
        ...tab,
        {
          value: "Vendor",
          label: "Vendor",
          content: <VendorsData />,
          sorting: 0,
        },
      ];
    }

    if (roles.access_card_settings?.door_access?.create) {
      tab = [
        ...tab,
        {
          value: "PC Ping",
          label: "PC Ping",
          content: <PingServer />,
          sorting: 3,
        },
      ];
    }

    if (roles.access_card_settings?.door_access?.create) {
      tab = [
        ...tab,
        {
          value: "Csv Convert",
          label: "Csv Convert",
          content: <CsvDashboard />,
          sorting: 4,
        },
      ];
    }

    setTabs(tab);
  }, [roles.access_card_settings]);

  return (
    <Page className={classes.root} title="Internal">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography component="h1" gutterBottom variant="overline">
              Internal
            </Typography>
          </Grid>
        </Grid>

        <CustomTabs
          tabs={orderBy(tabs, ["sorting"], ["asc"])}
          defaultTabIndex={0}
        />
      </Container>
    </Page>
  );
};

export default Internal;
