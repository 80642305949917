import React from "react";
import { useSelector } from "react-redux";

// components (local)
import FloorAccessCard from "./FloorAccessCard";

// materail-ui
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(2) },
}));

const FloorAccessTable = () => {
  const classes = useStyles();

  // selector
  const floorAccessSelector = useSelector(
    (state) => state.firestore.data.floorAccess
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {floorAccessSelector
          ? Object.keys(floorAccessSelector).map((key) => {
              const floorAccess = floorAccessSelector[key];

              return (
                <FloorAccessCard floorAccess={floorAccess} key={key} id={key} />
              );
            })
          : null}
      </Grid>
    </div>
  );
};

export default FloorAccessTable;
