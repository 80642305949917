export const TOGGLE_EDIT_RESIDENT_DIALOG_OPEN =
  "TOGGLE_EDIT_RESIDENT_DIALOG_OPEN";
export const TOGGLE_EDIT_RESIDENT_DIALOG_CLOSE =
  "TOGGLE_EDIT_RESIDENT_DIALOG_CLOSE";

export const TOGGLE_EDIT_VEHICLE_DIALOG_OPEN =
  "TOGGLE_EDIT_VEHICLE_DIALOG_OPEN";

export const TOGGLE_EDIT_VEHICLE_DIALOG_CLOSE =
  "TOGGLE_EDIT_VEHICLE_DIALOG_CLOSE";

export const TOGGLE_EDIT_VISITOR_DIALOG_OPEN =
  "TOGGLE_EDIT_VISITOR_DIALOG_OPEN";
export const TOGGLE_EDIT_VISITOR_DIALOG_CLOSE =
  "TOGGLE_EDIT_VISITOR_DIALOG_CLOSE";
export const TOGGLE_EDIT_PROPERTY_DIALOG_OPEN =
  "TOGGLE_EDIT_PROPERTY_DIALOG_OPEN";
export const TOGGLE_EDIT_PROPERTY_DIALOG_CLOSE =
  "TOGGLE_EDIT_PROPERTY_DIALOG_CLOSE";
