import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

// components (global)
import ButtonProgress from "components/ButtonProgress";
import StyledSelect from "components/Select/StyledSelect";

// firebase
import { useFirestore } from "react-redux-firebase";

// form
import { useForm } from "react-hook-form";

// helpers
import { deviceValidate } from "helpers/validation/deviceValidate";
import { doorActionList } from "utility/constants";

// material-ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// stores
import { toggleEditVisitPurposeActionDialogClose } from "stores/setting/SettingAction";

// styles
const useStyles = makeStyles((theme) => ({
  root: {},
}));

const EditVisitPurposeActionDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const firestore = useFirestore();
  const { errors, handleSubmit, register, control, reset } = useForm();

  // state
  const [submitting, setSubmitting] = useState(false);
  const [purposeVisit, setPurposeVisit] = useState(null);

  // selector
  const { open, params } = useSelector(
    (state) => state.setting.edit_visit_purpose_action_dialog
  );
  const uid = useSelector((state) => state.firebase.auth.uid);
  const profile = useSelector((state) => state.firebase.profile);
  const user = useSelector((state) => {
    return state.firestore.data.guardUsers
      ? state.firestore.data.guardUsers[params.userId]
      : null;
  });
  const selectedVendor = useSelector((state) => state.main.selectedVendor);
  const vendorType = useSelector((state) => {
    return state.firestore.data.vendors
      ? state.firestore.data.vendors[
          selectedVendor
        ].access_card_settings.type.toLowerCase()
      : null;
  });

  // effect
  useEffect(() => {
    if (user) {
      const purpose = user.purpose_of_visit[params.index];

      setPurposeVisit(purpose);
      reset({
        entryAction: purpose?.entry?.action ?? "no_action",
        exitAction: purpose?.exit?.action ?? "no_action",
      });
    }
  }, [user, params.index, reset]);

  // functions
  const initialState = () => {
    setPurposeVisit(null);
  };

  const handleClose = () => {
    if (!submitting) {
      initialState();
      dispatch(toggleEditVisitPurposeActionDialogClose());
    }
  };

  const onSubmit = ({
    abbreviation,
    purposeName,
    entryAction,
    entryDoorName,
    exitAction,
    exitDoorName,
    entryDeviceId,
    entryDeviceSorting,
    entryDeviceType,
    exitDeviceId,
    exitDeviceSorting,
    exitDeviceType,
  }) => {
    if (!submitting) {
      setSubmitting(true);

      let data = [...user.purpose_of_visit];

      data[params.index] = {
        ...data[params.index],
        abbreviation: abbreviation,
        name: {
          default: purposeName,
        },
        entry: {
          action: entryAction,
          door_name: entryDoorName,
        },
        exit: {
          action: exitAction,
          door_name: exitDoorName,
        },
      };

      if (vendorType === "soyal") {
        data[params.index] = {
          ...data[params.index],
          entry: {
            ...data[params.index].entry,
            device_id: entryDeviceId,
            device_sorting: Number(entryDeviceSorting),
            device_type: Number(entryDeviceType),
          },
          exit: {
            ...data[params.index].exit,
            device_id: exitDeviceId,
            device_sorting: Number(exitDeviceSorting),
            device_type: Number(exitDeviceType),
          },
        };
      }

      firestore
        .update(
          { collection: "ac_users", doc: params.userId },
          {
            updated_at: new Date(),
            updated_by: {
              user_id: uid,
              name: profile.name,
            },
            purpose_of_visit: data,
          }
        )
        .then(() => {
          setSubmitting(false);
          handleClose();
          snackbar.enqueueSnackbar("Visit Purpose data had been updated", {
            variant: "success",
          });
        })
        .catch((e) => {
          setSubmitting(false);
          handleClose();
          snackbar.enqueueSnackbar(e.message, {
            variant: "error",
            persist: false,
          });
        });
    }
  };

  if (!user || !purposeVisit) return null;

  const abbreviationTF = (
    <TextField
      fullWidth
      size="small"
      id="abbreviation"
      name="abbreviation"
      label="Abbreviation*"
      type="text"
      variant="outlined"
      autoComplete="off"
      defaultValue={purposeVisit.abbreviation}
      inputRef={register(deviceValidate.abbreviation.rules)}
      helperText={
        errors.abbreviation &&
        deviceValidate.serviceId.message[errors.abbreviation.type]
      }
      error={!!errors.abbreviation}
    />
  );

  const purposeNameTF = (
    <TextField
      fullWidth
      size="small"
      id="purposeName"
      name="purposeName"
      label="Purpose Name*"
      type="text"
      variant="outlined"
      autoComplete="off"
      defaultValue={purposeVisit.name.default}
      inputRef={register(deviceValidate.purposeName.rules)}
      helperText={
        errors.purposeName &&
        deviceValidate.serviceId.message[errors.purposeName.type]
      }
      error={!!errors.purposeName}
    />
  );

  const entryActionSelect = (
    <StyledSelect
      label="Entry Door Action"
      control={control}
      name="entryAction"
      onChange={(selected) => selected[1].props.value}
      error={Boolean(errors.entryAction)}
      errorText={
        errors.entryAction &&
        deviceValidate.serviceId.message[errors.entryAction.type]
      }
      rules={deviceValidate.entryAction.rules}
    >
      <Select defaultValue="" value={purposeVisit}>
        {doorActionList.map((action) => (
          <MenuItem key={action.value} value={action.value}>
            {action.label}
          </MenuItem>
        ))}
      </Select>
    </StyledSelect>
  );

  const entryDoorNameTF = (
    <TextField
      fullWidth
      size="small"
      id="entryDoorName"
      name="entryDoorName"
      label="Entry Door Name *"
      type="text"
      variant="outlined"
      autoComplete="off"
      defaultValue={purposeVisit.entry.door_name}
      inputRef={register(deviceValidate.entryDoorName.rules)}
      helperText={
        errors.entryDoorName &&
        deviceValidate.entryDoorName.message[errors.entryDoorName.type]
      }
      error={!!errors.entryDoorName}
    />
  );

  const exitActionSelect = (
    <StyledSelect
      label="Exit Door Action"
      control={control}
      name="exitAction"
      onChange={(selected) => selected[1].props.value}
      error={Boolean(errors.exitAction)}
      errorText={
        errors.exitAction &&
        deviceValidate.exitAction.message[errors.exitAction.type]
      }
      rules={deviceValidate.exitAction.rules}
    >
      <Select defaultValue="">
        {doorActionList.map((action) => (
          <MenuItem key={action.value} value={action.value}>
            {action.label}
          </MenuItem>
        ))}
      </Select>
    </StyledSelect>
  );

  const exitDoorNameTF = (
    <TextField
      fullWidth
      size="small"
      id="exitDoorName"
      name="exitDoorName"
      label="Exit Door Name *"
      type="text"
      variant="outlined"
      autoComplete="off"
      defaultValue={purposeVisit.exit.door_name}
      inputRef={register(deviceValidate.exitDoorName.rules)}
      helperText={
        errors.exitDoorName &&
        deviceValidate.exitDoorName.message[errors.exitDoorName.type]
      }
      error={!!errors.exitDoorName}
    />
  );

  return (
    <div>
      <form
        noValidate
        xs={12}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <Dialog
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={handleClose}
          open={open}
          fullWidth={true}
          maxWidth="md"
          scroll="paper"
        >
          <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>
            <div>
              <Typography
                className={classes.modalTitle}
                variant="h3"
                component="h2"
              >
                Edit Purpose Visit Action
              </Typography>
            </div>
          </DialogTitle>

          <DialogContent dividers>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <Grid item xs={6}>
                {abbreviationTF}
              </Grid>
              <Grid item xs={6}>
                {purposeNameTF}
              </Grid>
              <Grid item xs={6}>
                {entryDoorNameTF}
              </Grid>
              <Grid item xs={6}>
                {entryActionSelect}
              </Grid>
              <Grid item xs={6}>
                {exitDoorNameTF}
              </Grid>
              <Grid item xs={6}>
                {exitActionSelect}
              </Grid>
              {vendorType === "soyal" && (
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    size="small"
                    id="entryDeviceId"
                    name="entryDeviceId"
                    label="Entry Device ID *"
                    type="text"
                    variant="outlined"
                    autoComplete="off"
                    defaultValue={purposeVisit.entry.device_id}
                    inputRef={register(deviceValidate.entryDeviceId.rules)}
                    helperText={
                      errors.entryDeviceId &&
                      deviceValidate.entryDeviceId.message[
                        errors.entryDeviceId.type
                      ]
                    }
                    error={!!errors.entryDeviceId}
                  />
                </Grid>
              )}
              {vendorType === "soyal" && (
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    size="small"
                    id="entryDeviceSorting"
                    name="entryDeviceSorting"
                    label="Entry Device Sorting *"
                    type="number"
                    variant="outlined"
                    autoComplete="off"
                    defaultValue={purposeVisit.entry.device_sorting}
                    inputRef={register(deviceValidate.entryDeviceSorting.rules)}
                    helperText={
                      errors.entryDeviceSorting &&
                      deviceValidate.entryDeviceSorting.message[
                        errors.entryDeviceSorting.type
                      ]
                    }
                    error={!!errors.entryDeviceSorting}
                  />
                </Grid>
              )}
              {vendorType === "soyal" && (
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    size="small"
                    id="entryDeviceType"
                    name="entryDeviceType"
                    label="Entry Device Type *"
                    type="number"
                    variant="outlined"
                    autoComplete="off"
                    defaultValue={purposeVisit.entry.device_type}
                    inputRef={register(deviceValidate.entryDeviceType.rules)}
                    helperText={
                      errors.entryDeviceType &&
                      deviceValidate.entryDeviceType.message[
                        errors.entryDeviceType.type
                      ]
                    }
                    error={!!errors.entryDeviceType}
                  />
                </Grid>
              )}
              {vendorType === "soyal" && (
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    size="small"
                    id="exitDeviceId"
                    name="exitDeviceId"
                    label="Exit Device ID *"
                    type="text"
                    variant="outlined"
                    autoComplete="off"
                    defaultValue={purposeVisit.exit.device_id}
                    inputRef={register(deviceValidate.exitDeviceId.rules)}
                    helperText={
                      errors.exitDeviceId &&
                      deviceValidate.exitDeviceId.message[
                        errors.exitDeviceId.type
                      ]
                    }
                    error={!!errors.exitDeviceId}
                  />
                </Grid>
              )}
              {vendorType === "soyal" && (
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    size="small"
                    id="exitDeviceSorting"
                    name="exitDeviceSorting"
                    label="Exit Device Sorting *"
                    type="number"
                    variant="outlined"
                    autoComplete="off"
                    defaultValue={purposeVisit.exit.device_sorting}
                    inputRef={register(deviceValidate.exitDeviceSorting.rules)}
                    helperText={
                      errors.exitDeviceSorting &&
                      deviceValidate.exitDeviceSorting.message[
                        errors.exitDeviceSorting.type
                      ]
                    }
                    error={!!errors.exitDeviceSorting}
                  />
                </Grid>
              )}
              {vendorType === "soyal" && (
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    size="small"
                    id="exitDeviceType"
                    name="exitDeviceType"
                    label="Exit Device Type *"
                    type="number"
                    variant="outlined"
                    autoComplete="off"
                    defaultValue={purposeVisit.exit.device_type}
                    inputRef={register(deviceValidate.exitDeviceType.rules)}
                    helperText={
                      errors.exitDeviceType &&
                      deviceValidate.exitDeviceType.message[
                        errors.exitDeviceType.type
                      ]
                    }
                    error={!!errors.exitDeviceType}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <ButtonProgress
              type="submit"
              containName="Save"
              margin={false}
              loading={submitting}
              disabled={submitting}
              onClick={handleSubmit(onSubmit)}
            />
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default EditVisitPurposeActionDialog;
