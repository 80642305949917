import React from "react";

// assets
import logoIcon from "assets/logo.svg";

// material-ui
import { Avatar, Box, Container, Link } from "@material-ui/core";

const LogoAndLabel = () => {
  return (
    <Container style={{ display: "contents" }}>
      <Link href="/">
        <Avatar alt="logoIcon" src={logoIcon} />
      </Link>
      <Box m={0.5} />
      <Link href="/" variant="h3" color="inherit" underline="none">
        JaGaCard
      </Link>
    </Container>
  );
};

export default LogoAndLabel;
